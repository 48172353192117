import styled from "styled-components";

import { hefBrandColorsPlusNeutrals } from "../../../types";

interface StyledCardWithTextWrapper {
  color: hefBrandColorsPlusNeutrals;
  imgUrl: string;
}

interface StyledCardWithTextHeader {
  color: hefBrandColorsPlusNeutrals;
}

interface StyledCardWithTextCardsContainerProps {
  lessThanThree?: boolean;
}

export const StyledCardWithText = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;
`;

export const StyledCardWithTextWrapper = styled.div<StyledCardWithTextWrapper>`
  /* background: rgba(214, 204, 191, 0.5); */

  background-color: ${({ theme, color }) =>
    color === "heritage"
      ? theme.colors.warmths.heritage
      : theme.colors.neutrals.offwhite};

  background-image: url(${({ imgUrl, color }) =>
    color === "heritage" ? imgUrl : "none"});

  padding: ${(props) => props.theme.spacing.eight} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.eight}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledCardWithTextContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledCardWithTextHeader = styled.h2<StyledCardWithTextHeader>`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.seven};
  color: ${({ color }) => (color === "heritage" ? "white" : "black")};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledCardWithTextCardsContainer = styled.div<StyledCardWithTextCardsContainerProps>`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: ${(props) =>
    props.lessThanThree ? "1fr 1fr" : "1fr 1fr 1fr"};
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
  }
`;
