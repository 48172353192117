import React from "react";
import { blokType } from "../../types";
import { OverviewCmp, OverviewProps } from "./Overview";

export interface IOverviewFilterProps {
  blok: blokType & OverviewProps;
}

export const OverviewFilter: React.FC<IOverviewFilterProps> = ({ blok }) => {
  return <OverviewCmp 
    title={blok.title} 
    desc={blok.desc} 
    showPagination={blok.showPagination}
    hide_shape={blok.hide_shape}
  />;
};
