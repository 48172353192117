import { StaticQuery, graphql } from "gatsby";
import * as React from "react";
import Pagination from "./Pagination";
import { AllStoryblokEntryResult, sbLinkType } from "../../types";
import { OpenProgramsContent } from "../Templates/OpenPrograms/openPrograms";

import { StyledOverviewCardsContainer } from "./partials";

import PageContext from "../../context/PageContext";
import {
  OpenProgramsOverviewCard,
  OpenProgramsOverviewCardCmp,
} from "./OpenProgramsOverviewCard";

export interface OpenProgramsOverviewQueryProps {
  folder: sbLinkType;
  tag?: Array<string>;
  card_link_label?: string;
  showPagination?: boolean;
}

export const OpenProgramsOverviewQueryCmp: React.FC<OpenProgramsOverviewQueryProps> =
  ({ folder, tag, card_link_label, showPagination }) => {
    const containerRef = React.useRef<null | Element>(null);

    let site: null | Element = null;
    if (typeof document !== "undefined") {
      site = document.getElementById("site");
    }

    function handleScroll() {
      if (site && containerRef.current) {
        site.scrollTo({
          top: containerRef.current?.offsetParent.offsetTop + 100,
          left: 0,
          behavior: "smooth",
        });
      }
    }

    function parseQueryResult(
      data: AllStoryblokEntryResult,
      filterPath: string,
      pagePath: string,
      filterTag?: Array<string>
    ): Array<OpenProgramsOverviewCard> {
      let parsedResult: Array<OpenProgramsOverviewCard> = [];

      data.allStoryblokEntry.edges.forEach((edge) => {
        const { node } = edge;
        let content: OpenProgramsContent = node.content;

        const intersection = node.tag_list?.filter((t) =>
          filterTag?.includes(t)
        );

        if (
          !filterTag ||
          filterTag.length === 0 ||
          (intersection && intersection?.length > 0)
        ) {
          if (typeof node.content === "string")
            content = JSON.parse(node.content);
          if (
            node.full_slug.startsWith(filterPath) &&
            pagePath !== node.full_slug &&
            content?.title
          ) {
            let storyCard: OpenProgramsOverviewCard = {
              id: node.id,
              title: content.title || "",
              qualifier: content.qualifier || "",
              link: `/${node.full_slug}`,
              card_link_label: card_link_label,
              learning_formats: content.learning_formats || "",
            };

            parsedResult.push(storyCard);
          }
        }
      });

      return parsedResult;
    }

    return (
      <PageContext.Consumer>
        {(pageContext) => {
          const filterPath = folder?.cached_url
            ? folder.cached_url
            : pageContext.data?.pagePath || "";

          return (
            <StaticQuery
              query={graphql`
                query {
                  allStoryblokEntry(
                    filter: {
                      field_component: { eq: "open-programs-template" }
                    }
                    sort: { fields: [published_at], order: [ASC] }
                  ) {
                    edges {
                      node {
                        id
                        full_slug
                        content
                        published_at
                        tag_list
                      }
                    }
                  }
                }
              `}
              render={(data) => {
                const parsedResult = parseQueryResult(
                  data,
                  filterPath,
                  pageContext.data?.pagePath || "",
                  tag
                );
                const [viewPage, setViewPage] = React.useState(0);

                const showArticlesNumber = showPagination ? 9 : 3;

                const totalCount = parsedResult.length;
                const totalPages = Math.ceil(totalCount / showArticlesNumber);
                const articleFrom = viewPage * showArticlesNumber;
                const articleTo = articleFrom + showArticlesNumber;

                return (
                  <>
                    {showPagination && (
                      <span>
                        Showing {articleFrom + 1}-
                        {articleTo < totalCount ? articleTo : totalCount} of{" "}
                        {totalCount} courses
                      </span>
                    )}
                    <StyledOverviewCardsContainer
                      column={"three"}
                      ref={containerRef}
                    >
                      {parsedResult &&
                        parsedResult
                          .slice(articleFrom, articleTo)
                          .map((card) => (
                            <OpenProgramsOverviewCardCmp
                              key={card.id}
                              card={card}
                            />
                          ))}
                    </StyledOverviewCardsContainer>
                    {showPagination && (
                      <Pagination
                        breakLabel={"..."}
                        pageCount={totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={(e) => {
                          handleScroll();
                          setViewPage(e);
                        }}
                      />
                    )}
                  </>
                );
              }}
            />
          );
        }}
      </PageContext.Consumer>
    );
  };
