import React from "react";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import { Footer } from "../../Footer";
import { IOPHeader, OPHeader } from "../../OpenPrograms/OpenProgramsHeader";
import Seo from "../../utils/seo";
import { StyledBodyContainer } from "./partials";

export interface OPContent extends blokType {
  qualifier: string;
  title: string;
  meta_data?: Array<blokType>;
  secondary_navigation?: Array<blokType>;
  header?: Array<IOPHeader>;
  learning_meta?: Array<blokType>;
  upcoming_cohorts?: Array<blokType>;
  further_info?: Array<blokType>;
  contact_us?: Array<blokType>;
}
interface OPTemplateProps extends BaseComponentProps {
  blok: OPContent;
}

const OPTemplate: React.FC<OPTemplateProps> = ({ blok, story, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      <StyledBodyContainer>
        {blok?.secondary_navigation?.map((blok) => {
          return <DynamicComponent key={blok._uid} blok={blok} />;
        })}

        {blok?.header?.map((headerBlok) => {
          return (
            <OPHeader title={blok.title} qualifier={blok.qualifier} left={headerBlok.left} right={headerBlok.right} />
          );
        })}

        {blok?.learning_meta?.map((learningBlok) => {
          return <DynamicComponent key={learningBlok._uid} blok={learningBlok} />;
        })}

        {blok?.further_info?.map((furtherInfoBlok) => {
          return <DynamicComponent key={furtherInfoBlok._uid} blok={furtherInfoBlok} />;
        })}
        {blok?.contact_us?.map((contactBody) => {
          return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
        })}
      </StyledBodyContainer>
      <Footer alternatives={alternates} />
    </>
  );
};

export default OPTemplate;
