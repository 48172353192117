import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  LandingPageModuleCmp,
  LandingPageModuleProps,
} from "./LandingPageModule";

interface LandingPageModuleWBlokProps {
  blok: LandingPageModuleProps & blokType;
}

export const LandingPageModule: React.FC<LandingPageModuleWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <LandingPageModuleCmp
        header={blok.header}
        body={blok.body}
        image={blok.image}
        background_colour={blok.background_colour}
        link={blok.link}
        wide={blok.wide}
      ></LandingPageModuleCmp>
    </SbEditable>
  );
};
