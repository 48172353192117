export const validColor = (valid, checked) => {
  if (valid || checked) return "#3A7E6B";
  else if (valid === false) return "#CC5F49";
  else return "#949494";
};
export const validBackground = (valid, checked) => {
  if (valid || checked) return "#3A7E6B";
  else if (valid === false) return "#FDE7E7";
  else return "#FFFFFF";
};
export const focusBorder = () => {
  return `1px solid #3A7E6B`;
};
