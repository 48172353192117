import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { SubscribeFormCmp, SubscribeFormProps } from "./SubscribeForm";

interface SubscribeFormWBlokProps {
  blok: SubscribeFormProps & blokType;
}

export const SubscribeForm: React.FC<SubscribeFormWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <SubscribeFormCmp
        title={blok.title}
        body_copy={blok.body_copy}
        email_placeholder={
          blok?.email_placeholder ? blok.email_placeholder : undefined
        }
        sign_up_label={blok?.sign_up_label ? blok.sign_up_label : undefined}
        privacy={
          blok.privacy?.content
            ? JSON.stringify(blok.privacy?.content[0])
            : undefined
        }
        thank_you_header={
          blok.thank_you_header ? blok.thank_you_header : undefined
        }
        thank_you_body={blok.thank_you_body ? blok.thank_you_body : undefined}
        required_err_msg={
          blok.required_err_msg ? blok.required_err_msg : undefined
        }
        invalid_err_msg={
          blok.invalid_err_msg ? blok.invalid_err_msg : undefined
        }
        cta_colour={blok.cta_colour}
      ></SubscribeFormCmp>
    </SbEditable>
  );
};
