import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { HeroCmp, HeroProps } from "./Hero";

export interface HeroWBlokProps {
  blok: HeroProps & blokType;
}

export const Hero: React.FC<HeroWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HeroCmp {...blok} />
    </SbEditable>
  );
};
