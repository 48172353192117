import React, { useState } from "react";

import { SbImage } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC } from "../Forms/FormSC";
import { ICTA } from "../FullBleedImgHero/FullBleedImageHero";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import {
  HeroBody,
  HeroCTAWrapper,
  HeroContentWrapper,
  HeroDesktopTitleWrapper,
  HeroImageAbsolute,
  HeroImageMobile,
  HeroMediaWrapper,
  HeroMobileTitleWrapper,
  HeroTitle,
  HeroWrapper,
} from "./styles/StyledHero";

export interface HeroProps {
  title1: string;
  title2: string;
  title3: string;
  mobileTitle: string;
  body: string;
  cta: ICTA[];
  image: sbAssetType;
  margin: boolean;
}

export const HeroCmp: React.FC<HeroProps> = ({ title1, title2, title3, mobileTitle, body, image, cta, margin }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HeroWrapper extraMargin={margin}>
        <HeroContentWrapper>
          <HeroDesktopTitleWrapper>
            <HeroTitle>{title1}</HeroTitle>
            <HeroTitle>{title2}</HeroTitle>
            <HeroTitle>{title3}</HeroTitle>
          </HeroDesktopTitleWrapper>
          <HeroMobileTitleWrapper>
            <HeroTitle>{mobileTitle}</HeroTitle>
          </HeroMobileTitleWrapper>
          <HeroBody>{body}</HeroBody>
          <HeroCTAWrapper>
            {Array.isArray(cta) &&
              cta.length >= 1 &&
              cta.map((item, index) => (
                <React.Fragment key={`cta-${index}`}>
                  {Array.isArray(item.cta_form) && item.cta_form.length >= 1 && (
                    <ButtonHEF
                      variant={index === 1 ? "secondary" : "primary"}
                      color={item.cta_colour}
                      onClick={() => setOpenModal(true)}
                      label={item.cta_label}
                    />
                  )}
                  {item.cta_link && item.cta_form?.length == 0 && (
                    <ButtonHEF
                      as={"a"}
                      variant={index === 1 ? "secondary" : "primary"}
                      color={item.cta_colour}
                      href={getUrl(item.cta_link)}
                      label={item.cta_label}
                    />
                  )}
                </React.Fragment>
              ))}
          </HeroCTAWrapper>
        </HeroContentWrapper>
        <HeroMediaWrapper>
          <HeroImageAbsolute>
            <SbImage image={image} />
          </HeroImageAbsolute>
          <HeroImageMobile>
            <SbImage image={image} />
          </HeroImageMobile>
        </HeroMediaWrapper>
      </HeroWrapper>
      {Array.isArray(cta) &&
        cta.length >= 1 &&
        cta.map(
          (cta) =>
            cta.cta_form &&
            cta.cta_form.length > 0 && (
              <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
                <FormSC
                  onClose={() => setOpenModal(false)}
                  _uid={cta.cta_form[0]._uid}
                  form={cta.cta_form[0].form}
                  component={cta.cta_form[0].component}
                  form_handler={cta.cta_form[0].form_handler}
                  sf_campaign_id={cta.cta_form[0].sf_campaign_id}
                  title={cta.cta_form[0].title}
                  qualifier={cta.cta_form[0].qualifier}
                  event_date={cta.cta_form[0].event_date}
                  event_duration={cta.cta_form[0].event_duration}
                  event_location={cta.cta_form[0].event_location}
                  thank_you_components={cta.cta_form[0].thank_you_components}
                  close_on_submit={cta.cta_form[0].close_on_submit}
                />
              </Modal>
            )
        )}
    </>
  );
};
