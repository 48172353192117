import React from "react";
import { sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";

import {
  StyledArticleAuthorModalContent,
  StyledArticleAuthorModalContentImg,
  StyledArticleAuthorModalContentText,
  StyledArticleAuthorModalContentHeader,
  StyledArticleAuthorModalContentBody,
} from "./partials";

interface ArticleAuthorModalContentProps {
  image: sbAssetType;
  author_name: string;
  bio: string;
}

export const ArticleAuthorModalContent: React.FC<ArticleAuthorModalContentProps> =
  ({ author_name, image, bio }) => {
    return (
      <StyledArticleAuthorModalContent>
        <StyledArticleAuthorModalContentImg
          src={sbImageService(image.filename, undefined, "0x263")}
          alt={image.alt}
          title={image.title}
        />
        <StyledArticleAuthorModalContentText>
          <StyledArticleAuthorModalContentHeader>
            {author_name}
          </StyledArticleAuthorModalContentHeader>
          <StyledArticleAuthorModalContentBody>
            {bio}
          </StyledArticleAuthorModalContentBody>
        </StyledArticleAuthorModalContentText>
      </StyledArticleAuthorModalContent>
    );
  };
