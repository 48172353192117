import React from "react";
import {
  DescriptionWithImageCard,
  DescriptionWithImageCardProps,
} from "./DescriptionWithImageCard";

import {
  StyledDescWImage,
  StyledDescWImageWrapper,
  StyledDescWImageTitle,
  StyledDescWImageBody,
  StyledDescWImageCardContainer,
} from "./partials";

export interface DescriptionWithImageProps {
  title: string;
  body: string;
  cards: Array<DescriptionWithImageCardProps>;
}

export const DescriptionWithImageCmp: React.FC<DescriptionWithImageProps> = ({
  title,
  body,
  cards,
}) => {
  return (
    <StyledDescWImage>
      <StyledDescWImageWrapper>
        <StyledDescWImageTitle>{title}</StyledDescWImageTitle>
        <StyledDescWImageBody>{body}</StyledDescWImageBody>
        <StyledDescWImageCardContainer lessThanThree={cards.length <= 2}>
          {cards.map((item, idx) => (
            <DescriptionWithImageCard
              key={idx}
              title={item.title}
              description={item.description}
              image={item.image}
              link={item.link}
            />
          ))}
        </StyledDescWImageCardContainer>
      </StyledDescWImageWrapper>
    </StyledDescWImage>
  );
};
