import styled from "styled-components";

export const StyledMain = styled.div`
  position: relative;
  top: 0;
  left: 0;

  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
`;

interface IStyledSite {
  banner?: boolean;
}

export const StyledSite = styled.div<IStyledSite>`
  position: relative;
  top: ${({ banner }) => (banner ? "48px" : "0")};
  left: 0;
  background: white;

  width: 100vw; /* viewport width */
  overflow-x: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    top: ${({ banner }) => (banner ? "45px!important" : "0")};
  }
`;
