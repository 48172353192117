import React, { useState, useRef, useEffect, useCallback } from "react";

import IconTriangleDown from "@ef-global/web-ui-react/lib/icons/IconTriangleDown";
import IconTriangleUp from "@ef-global/web-ui-react/lib/icons/IconTriangleDown";

import { getUrl } from "../utils/helpers";
import Cookies from "js-cookie";

import {
  StyledSecondaryNavigationBar,
  StyledSecondaryNavigationBarWrapper,
  StyledSecondaryNavigationItems,
  StyledSecondaryNavigationNavItemMore,
  StyledSecondaryNavigationNavBarIcon,
  StyledSecondaryNavigationNavItemDropdown,
  StyledNavItemSubItemsPopOut,
  StyledSecondaryNavigationVariantWrapper,
  StyledNavItemSubItemsPopOutWrapper,
  StyledSecondaryNavigationCore,
  StyledSecondaryNavigationSubItemMobile,
  StyledNavItemSubItemsPopOutImageWrapper,
  StyledSecondaryNavigationItemsWrapper,
} from "./partials";
import { blokType } from "../../types";
import { NavItem as NavItemType } from "../NavItem";
import { NavItem } from "../NavItem/NavItem";
import { NavItemWSubItemsProps } from "../NavItemWSubItems";
import { NavItemWSubItems } from "../NavItemWSubItems/NavItemWSubItems";
import { NavItemSubItem } from "../NavItemSubItem/NavItemSubItem";
import { NavItemSubItemProps } from "../NavItemSubItem";
import { MoreMenu } from "../MoreMenu/MoreMenu";
import { isActiveLink, isNavItem, isNavItemWSubItem } from "./utils";

type MenuItems = Array<NavItemType | NavItemWSubItemsProps>;
export interface SecondaryNavigationProps extends blokType {
  section_label: any;
  menu_items?: MenuItems;
  more_label?: string;
}

export const SecondaryNavigationCmp: React.FC<SecondaryNavigationProps> = ({
  section_label,
  menu_items,
  more_label = "More",
}) => {
  const ulScroll = useRef(null);
  const navBar = useRef<HTMLDivElement>(null);
  const [isMoreItemsOpen, setIsMoreItemsOpen] = useState(false);
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState(menu_items);
  const [moreItems, setMoreItems] = useState<Array<any>>([]);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [activeSubItems, setActiveSubItems] = useState<
    Array<NavItemSubItemProps>
  >([]);
  const [activeDropdownId, setActiveDropdownId] = useState<string | undefined>(
    undefined
  );
  const [itemsArray, setItemsArray] = useState<MenuItems>([]);
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      typeof window !== undefined && navBar
        ? setInnerWidth(navBar.current?.clientWidth ?? 0)
        : setInnerWidth(0);
    };

    window?.addEventListener("resize", updateWindowDimensions);

    updateWindowDimensions();

    return () => window?.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setUrl(
      typeof window !== "undefined" ? window.location.pathname : undefined
    );

    if (
      ulScroll &&
      ulScroll?.current?.offsetWidth < ulScroll?.current?.scrollWidth
    ) {
      const divWidth = ulScroll?.current?.offsetWidth;
      let overflowID = menu_items.length;

      // Find index of first child outside of div
      for (let i = 2; i < ulScroll?.current?.childNodes.length; i++) {
        if (
          ulScroll?.current?.childNodes[i].offsetLeft +
            ulScroll.current?.childNodes[i].offsetWidth >
          divWidth
        ) {
          overflowID = i;
          break;
        }
      }

      const newVis = menu_items?.slice(0, overflowID - 1);
      const newMore = menu_items?.slice(overflowID - 1, menu_items.length);
      setVisibleItems(newVis);
      if (Array.isArray(newMore)) {
        setMoreItems(newMore);
      }
    }
  }, [ulScroll, innerWidth]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (navBar.current && !navBar.current.contains(target)) {
        setIsMoreItemsOpen(false);
        setisMobileMenuOpen(false);
        setActiveDropdownId(undefined);
        setActiveSubItems([]);
      }
    },
    [navBar, isMoreItemsOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function toggleSubItemDropdown(uid: string) {
    const menuItem = menu_items?.find((item) => item._uid === uid);
    const hasSubItems = menuItem && isNavItemWSubItem(menuItem);
    const toggleMenu = activeDropdownId === uid;
    setActiveDropdownId(!toggleMenu ? uid : undefined);
    setActiveSubItems(!toggleMenu && hasSubItems ? menuItem?.sub_items : []);
    setIsMoreItemsOpen(false);
  }

  useEffect(() => {
    setItemsArray(innerWidth <= 768 ? menu_items ?? [] : visibleItems ?? []); // Needs improving
  }, [innerWidth, menu_items, visibleItems]);

  const countryValue = Cookies.get("uCountry");

  return (
    <StyledSecondaryNavigationVariantWrapper ref={navBar}>
      <StyledSecondaryNavigationBar>
        <StyledSecondaryNavigationBarWrapper>
          <StyledSecondaryNavigationCore>
            {Array.isArray(section_label) && section_label.length === 1 && (
              <label>
                <a href={getUrl(section_label[0]?.link)}>
                  {section_label[0].name}
                </a>
              </label>
            )}
            {menu_items && (
              <StyledSecondaryNavigationNavBarIcon>
                {!isMobileMenuOpen && (
                  <IconTriangleDown
                    onClick={() => setisMobileMenuOpen(!isMobileMenuOpen)}
                  ></IconTriangleDown>
                )}
                {isMobileMenuOpen && (
                  <IconTriangleUp
                    onClick={() => setisMobileMenuOpen(!isMobileMenuOpen)}
                  ></IconTriangleUp>
                )}
              </StyledSecondaryNavigationNavBarIcon>
            )}
          </StyledSecondaryNavigationCore>
          <StyledSecondaryNavigationItemsWrapper isOpen={isMobileMenuOpen}>
            {Array.isArray(visibleItems) && (
              <StyledSecondaryNavigationItems
                ref={ulScroll}
                isOpen={isMobileMenuOpen}
              >
                {itemsArray?.map((item) => (
                  <>
                    {(!item?.country || item.country === countryValue) && (
                      <>
                        {isNavItemWSubItem(item) && (
                          <>
                            <NavItemWSubItems
                              title={item.title}
                              _uid={item._uid}
                              handleClick={toggleSubItemDropdown}
                              isActive={[
                                activeDropdownId === item._uid,
                                item.sub_items.some((subItem) =>
                                  isActiveLink(url, subItem.link.cached_url)
                                ),
                                activeDropdownId !== undefined &&
                                  activeDropdownId !== item._uid,
                              ]}
                            />
                            {activeDropdownId === item._uid &&
                              activeSubItems.map((subItem) => (
                                <StyledSecondaryNavigationSubItemMobile
                                  isActive={true}
                                >
                                  <NavItemSubItem
                                    name={subItem.name}
                                    description=""
                                    link={subItem.link}
                                    isActive={isActiveLink(
                                      url,
                                      subItem.link.cached_url
                                    )}
                                  />
                                </StyledSecondaryNavigationSubItemMobile>
                              ))}
                          </>
                        )}
                        {isNavItem(item) && (
                          <NavItem
                            name={item.name}
                            link={item.link}
                            isActive={isActiveLink(url, item.link.cached_url)}
                          />
                        )}
                      </>
                    )}
                  </>
                ))}
                {Array.isArray(moreItems) && moreItems.length > 1 && (
                  <StyledSecondaryNavigationNavItemDropdown>
                    <StyledSecondaryNavigationNavItemMore
                      onClick={(_) => {
                        setIsMoreItemsOpen(!isMoreItemsOpen);
                        setActiveSubItems([]);
                        setActiveDropdownId(undefined);
                      }}
                      key={"desktop-nav-item-more"}
                    >
                      {more_label}
                      <IconTriangleDown />
                    </StyledSecondaryNavigationNavItemMore>
                  </StyledSecondaryNavigationNavItemDropdown>
                )}
              </StyledSecondaryNavigationItems>
            )}
            <MoreMenu
              moreItems={moreItems}
              isOpen={isMoreItemsOpen}
              url={url}
            />
          </StyledSecondaryNavigationItemsWrapper>
        </StyledSecondaryNavigationBarWrapper>
      </StyledSecondaryNavigationBar>
      <StyledNavItemSubItemsPopOutWrapper itemCount={activeSubItems.length}>
        <StyledNavItemSubItemsPopOutImageWrapper
          itemCount={activeSubItems.length}
        >
          <StyledNavItemSubItemsPopOut itemCount={activeSubItems.length}>
            {activeSubItems.map((item) => (
              <NavItemSubItem
                name={item.name}
                description={item.description}
                link={item.link}
                isActive={isActiveLink(url, item.link.cached_url)}
              />
            ))}
          </StyledNavItemSubItemsPopOut>
        </StyledNavItemSubItemsPopOutImageWrapper>
      </StyledNavItemSubItemsPopOutWrapper>
    </StyledSecondaryNavigationVariantWrapper>
  );
};
