import React from "react";
import {
  StyledFormatTabsIcon,
  StyledIcon,
  StyledIconTitle,
  StyledIconBody,
  StyledBodyWrapper,
} from "./partials";
import * as Icons from "@ef-global/web-ui-react/lib/icons/all";

export interface IFormatTabsIcon {
  gud_icon: string;
  iconTitle: string;
  iconBody: string;
}

export const FormatTabsIcon: React.FC<IFormatTabsIcon> = ({
  gud_icon,
  iconTitle,
  iconBody,
}) => {
  const IconComp = gud_icon ? Icons[gud_icon] : undefined;

  return (
    <StyledFormatTabsIcon>
      <StyledIcon>{typeof IconComp !== "undefined" && <IconComp />}</StyledIcon>
      <StyledBodyWrapper>
        <StyledIconTitle>{iconTitle && iconTitle}</StyledIconTitle>
        <StyledIconBody>{iconBody && iconBody}</StyledIconBody>
      </StyledBodyWrapper>
    </StyledFormatTabsIcon>
  );
};
