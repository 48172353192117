import styled from "styled-components";
import { hefBrandColors } from "../../../types";

interface StyledBodyCopyProps {
  color?: hefBrandColors;
}

export const StyledBodyCopy = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledBodyCopyWrapper = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledBodyCopyTitle = styled.h2`
  margin: 0 0 24px;
  width: 58%;
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 1.25;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    font-size: 24px;
  }
`;

export const StyledBodyCopyContent = styled.section<StyledBodyCopyProps>`
  width: 58%;
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};

  & p {
    font-weight: ${(props) => props.theme.font.light};
    line-height: 24px;
  }

  & a {
    text-decoration: underline;
  }

  & ul,
  ol {
    color: ${(props) =>
      props.color
        ? props.theme.colors.warmths[props.color]
        : props.theme.colors.warmths.heritage};
    padding-left: 20px;
    margin: 0 0 ${(props) => props.theme.spacing.three} 0;

    & li {
      padding: 0;
      margin-bottom: ${(props) => props.theme.spacing.one};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
