import styled, { css } from "styled-components";

import scribble from "../../../assets/Graphics/StatScribble.svg";

interface Props {
  rows: number;
  bottomMargin: boolean;
}

export const StatTwoWrapper = styled.div<Props>`
  position: relative;
  max-width: ${(props) => props.theme.maxWidthWPadding};
  margin: auto;
  padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(${(props) => Math.ceil(props.rows / 2)}, auto);
  row-gap: ${(props) => props.theme.spacing.five};
  column-gap: ${(props) => props.theme.spacing.three};
  z-index: 1;

  ${(props) =>
    props.rows > 2 &&
    css`
      background: no-repeat url(${scribble});
      background-position: calc(25% + 48px);
      background-size: auto 100%;
      margin-bottom: ${props.bottomMargin ? "20px" : "0"};
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
    grid-template-columns: 1fr;
    grid-template-rows: ${(props) => props.rows};
    row-gap: ${(props) => props.theme.spacing.four};
    background-position: center;
  }
`;
