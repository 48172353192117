import styled from "styled-components";

interface StyledFacultyCarouselCardProps {
  clickable?: boolean;
}

export const StyledFacultyCarouselCard = styled.div<StyledFacultyCarouselCardProps>`
  height: 330px;
  position: relative;
  & a {
    height: 100%;
    padding: 0;
    cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  }
`;

export const StyledFacultyCarouselCardImg = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  background-color: #ffffff;
`;

export const StyledFacultyCarouselCardText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  border-radius: inherit;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
`;

export const StyledFacultyCarouselCardTextHeadline = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.neutrals.white};
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 32px);
`;

export const StyledFacultyCarouselCardTextBottom = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.book};
    color: ${(props) => props.theme.colors.neutrals.white};
    margin: 0;
  }

  & div {
    height: 16px;
    width: 16px;
    margin-left: 16px;
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.white};
  }
`;
