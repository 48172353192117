import styled from "styled-components";

interface StyledLinkCardWImageCardContainerProps {
  lessThanThree?: boolean;
}

export const StyledLinkCardWImage = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledLinkCardWImageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledLinkCardWImageTitle = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledLinkCardWImageBody = styled.p`
  white-space: pre-line;
  margin-bottom: ${(props) => props.theme.spacing.four};
  font-weight: ${(props) => props.theme.font.light};
`;

export const StyledLinkCardWImageCardContainer = styled.div<StyledLinkCardWImageCardContainerProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.lessThanThree ? "1fr 1fr" : "1fr 1fr 1fr"};
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
  }
`;

export const StyledLinkContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.four};
`;
