import React, { ReactNode, useState, useEffect } from "react";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import { uniqueId } from "lodash";
import {
  StyledSelectWrapper,
  StyledCheckValidation,
  StyledCrossValidation,
  StyledSelect,
  StyledSelectIcons,
  StyledErrorMessage,
} from "./partials";

interface SelectProps {
  id: string;
  section_id: number;
  name: string;
  text?: string;
  label: string;
  defaultValue?: string;
  value?: string;
  onChange?: any;
  children?: ReactNode;
  required?: boolean;
  disable?: boolean;
  hidden?: boolean;
  isValid?: boolean;
  required_err_msg?: string;
  invalid_err_msg?: string;
}

export const Select: React.FC<SelectProps> = ({
  id = "",
  section_id,
  name = "",
  text = "",
  label = "",
  children = [],
  onChange = () => {},
  defaultValue = "",
  required = false,
  hidden = false,
  disable = false,
  isValid = undefined,
  required_err_msg = "This is required",
  value = "",
}) => {
  const htmlId = uniqueId(id);
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <StyledSelectWrapper hidden={hidden} valid={isValid}>
      {label && (
        <label htmlFor={htmlId}>
          {label} {required && " *"}
        </label>
      )}

      <StyledSelect valid={isValid}>
        <select
          id={htmlId}
          name={name}
          defaultValue={defaultValue}
          value={selected}
          onChange={(e) => {
            if (id) {
              const { value, validity } = e.target;
              const index = e.nativeEvent.target.selectedIndex;
              onChange(id, {
                value: value,
                valid: validity.valid,
                selectLabel: e.nativeEvent.target[index].text,
                section_id: section_id,
              });
            } else onChange(e);
            setSelected(e.target.value);
          }}
          required={required}
          disabled={disable}
        >
          <option value="" disabled={required}>
            {text}
          </option>
          {children}
        </select>
        <StyledSelectIcons>
          <IconChevronDown />
          {isValid && <StyledCheckValidation />}
          {isValid === false && <StyledCrossValidation />}
        </StyledSelectIcons>
      </StyledSelect>
      {isValid === false && (
        <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
      )}
    </StyledSelectWrapper>
  );
};
