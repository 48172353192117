import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { ArticleHeadlineCmp, ArticleHeadlineProps } from "./ArticleHeadline";

interface ArticleHeadlineWBlokProps {
  blok: ArticleHeadlineProps & blokType;
}

export const ArticleHeadline: React.FC<ArticleHeadlineWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ArticleHeadlineCmp
        qualifying_title={blok.qualifying_title}
        title={blok.title}
        author_name={blok.author_name}
        qualifier_colour={blok.qualifier_colour}
      ></ArticleHeadlineCmp>
    </SbEditable>
  );
};
