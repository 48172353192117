import styled from "styled-components";

interface StyledProductOverviewProps {
  color?: "heritage" | "copper" | "intensity" | "sisal" | "white";
}

export const StyledProductOverviewWrapper = styled.div<StyledProductOverviewProps>`
  padding: ${(props) => props.theme.spacing.seven} 0;

  ${({ color }) =>
    color === "white" &&
    `
    padding: 0px;
  `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;

    ${({ color }) =>
      color === "white" &&
      `
    padding: 0px;
  `}
  }
`;

export const StyledProductOverview = styled.div<StyledProductOverviewProps>`
  padding: ${(props) => props.theme.spacing.eight}
    ${(props) => props.theme.spacing.four};
  background-color: ${(props) =>
    props.color
      ? props.color === "heritage" ||
        props.color === "intensity" ||
        props.color === "copper"
        ? props.theme.colors.warmths[props.color]
        : props.theme.colors.neutrals[props.color]
      : props.theme.colors.warmths.heritage};

  > div > p,
  h2,
  a {
    color: ${({ color, theme }) =>
      color === "heritage" || color === "intensity" || color === "copper"
        ? theme.colors.neutrals.white
        : theme.colors.neutrals.black};
  }

  ${({ color }) =>
    color === "white" &&
    `
    > div > div > div {
      border: 1px solid #cbcbcb;
      border-radius: 4px;
    }
  `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledProductOverviewInner = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const StyledProductOverviewHeader = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  line-height: 1.25;
  width: 66%;
  margin: 0 0 ${(props) => props.theme.spacing.one} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    max-width: 100%;
  }
`;

export const StyledProductOverviewSubTitle = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.book};
  margin: 0 0 ${(props) => props.theme.spacing.four} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;

export const StyledProductOverviewCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing.three};
  grid-auto-rows: 1fr;
  margin: 0 0 ${(props) => props.theme.spacing.four} 0;

  & a {
    padding: ${(props) => props.theme.spacing.four};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.spacing.two};

    & a {
      padding: ${(props) => props.theme.spacing.three};
    }
  }
`;
