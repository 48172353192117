import React, { useState } from "react";
import ReactPlayer from "react-player";
import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";
import { LinkItem } from "../LinkItem";
import { getUrl, getKey } from "../utils/helpers";

import { IVideoGalleryItemProps, VideoGalleryItem } from "./VideoGalleryItem";

import {
  StyledVideoGallery,
  StyledHeader,
  StyledSelectedVideoWrapper,
  StyledSelectedVideo,
  StyledVideos,
  StyledIconPlayWrapper,
  StyledSelectedTitle,
  StyledSelectedPageLink,
  StyledSelectedDuration,
} from "./partials";

export interface VideoGalleryProps {
  title: string;
  videos: Array<IVideoGalleryItemProps>;
  _uid: string;
}

export const VideoGalleryCmp: React.FC<VideoGalleryProps> = ({
  videos,
  title,
}) => {
  const [selected, setSelected] = useState(videos[0]);
  const [showDuration, setshowDuration] = useState(true);

  function setSelectedVideo(video) {
    setSelected(video);
  }

  return (
    <StyledVideoGallery>
      <StyledHeader>{title && title}</StyledHeader>
      <StyledSelectedVideoWrapper>
        <StyledSelectedVideo>
          <ReactPlayer
            url={selected.video_link}
            light={selected.thumbnail_img.filename}
            width="100%"
            height="100%"
            playing={true}
            onReady={() => setshowDuration(false)}
            playIcon={
              <StyledIconPlayWrapper>
                <IconPlay />
              </StyledIconPlayWrapper>
            }
            controls={true}
          />
          {selected.duration && showDuration && (
            <StyledSelectedDuration>{selected.duration}</StyledSelectedDuration>
          )}
        </StyledSelectedVideo>
        <StyledSelectedTitle>{selected.video_title}</StyledSelectedTitle>
        <StyledSelectedPageLink>
          {selected.video_cta &&
            selected.video_cta.map((content, idx) => (
              <LinkItem
                key={getKey(`${content._uid}-link-item`, idx)}
                name={content.name}
                link={getUrl(content.link)}
              />
            ))}
        </StyledSelectedPageLink>
      </StyledSelectedVideoWrapper>
      <StyledVideos>
        {videos.length > 0 &&
          videos.map(
            (video, idx) =>
              video._uid !== selected._uid && (
                <VideoGalleryItem
                  key={getKey(`${video._uid}-video-gallery-item`, idx)}
                  onClick={() => setSelectedVideo(video)}
                  video_link={video.video_link}
                  video_title={video.video_title}
                  thumbnail_img={video.thumbnail_img}
                  duration={video.duration}
                  video_cta={video.video_cta}
                />
              )
          )}
      </StyledVideos>
    </StyledVideoGallery>
  );
};
