import styled from "styled-components";

interface StyleProps {
  isActive: boolean;
}

export const StyledNavItemSubItem = styled.div`
  width: 222px;
  font-size: ${(props) => props.theme.fontSize.m};
  margin-bottom: ${(props) => props.theme.spacing.five};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: 18px;
  }
`;

export const StyledNavItemSubItemTitle = styled.p<StyleProps>`
  margin-bottom: 0;
  text-decoration: ${(props) => (props.isActive ? "underline" : "none")};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    text-decoration: none;
    font-weight: ${(props) => (props.isActive ? "bold" : "")};
  }
`;

export const StyledNavItemSubItemDescription = styled.p`
  color: ${(props) => props.theme.colors.neutrals.coolGrey};
`;
