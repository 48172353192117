import styled from "styled-components";
import { docDir } from "../../../types";

interface StyledLinkItemProps {
  spaceApart?: boolean;
  direction?: docDir;
}

export const StyledLinkItem = styled.a<StyledLinkItemProps>`
  width: ${(props) => (props.spaceApart ? "100%" : "auto")};
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) =>
    props.spaceApart ? "space-between" : "initial"};
  cursor: pointer;
  font-weight: ${(props) => props.theme.font.book};
  color: ${(props) => props.theme.colors.neutrals.black};

  :lang(ar) {
    & svg {
      transform: rotate(180deg);
      margin-right: ${({ theme }) => theme.spacing.one};
    }

    :hover {
      & svg {
        transform: rotate(180deg) translateX(16px);
      }
    }
  }

  :hover {
    & svg {
      transform: translateX(16px);
    }
  }

  & svg {
    margin-left: ${(props) => props.theme.spacing.two};
    transition: transform 500ms;
  }
`;
