import React from "react";

export default (text: string) => (
  <p>
    {text.split("\n").map((t, i) => (
      <React.Fragment key={i}>
        {i !== 0 && <br />}
        {t}
      </React.Fragment>
    ))}
  </p>
);
