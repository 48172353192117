import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { FeatureStatTwoCmp, FeatureStatTwoProps } from "./FeatureStatTwo";

export interface FeatureStatTwoWBlokProps {
  blok: FeatureStatTwoProps & blokType;
}

export const FeatureStatTwo: React.FC<FeatureStatTwoWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FeatureStatTwoCmp {...blok} />
    </SbEditable>
  );
};
