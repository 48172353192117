import { props } from "cypress/types/bluebird";
import styled from "styled-components";

import { StyledLinkItem } from "../../LinkItem/partials";

interface BodyThreeProps {
  bgColour: string;
}

export const BodyThreeBackground = styled.div<BodyThreeProps>`
  background: ${(props) =>
    props.bgColour === "black" ? props.theme.colors.neutrals.black : props.theme.colors.neutrals.offwhite};
`;

export const BodyThreeWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: auto;
  padding: ${(props) => props.theme.spacing.seven} 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  column-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
    grid-gap: ${(props) => props.theme.spacing.three};
  }
`;

export const BodyThreeImageWrapper = styled.div`
  grid-column: 1 / span 4;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    grid-column: 1;
    grid-row: 1;
    width: 100%;

    img {
      width: 100%;
    }
  }
`;

export const BodyThreeContentWrapper = styled.div<BodyThreeProps>`
  grid-column: 6 / span 7;
  color: ${(props) => (props.bgColour === "black" ? props.theme.colors.neutrals.white : "#000")};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    grid-column: 1;
    grid-row: 2;
  }

  ${StyledLinkItem} {
    color: inherit;
  }
`;

export const BodyThreeTitle = styled.h4`
  color: inherit;
`;

export const BodyThreeBody = styled.p<BodyThreeProps>`
  color: inherit;
  font-weight: ${(props) => (props.bgColour === "black" ? 400 : 300)};
`;
