import React from "react";

import * as Icons from "@ef-global/web-ui-react/lib/icons/all";

import { StyledButton, StyledIconContainer } from "./partials";

interface ButtonProps {
  as?: "a" | "button";
  label?: string;
  color?: "heritage" | "copper" | "intensity" | "black";
  variant?: "primary" | "secondary";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  href?: string;
  disabled?: boolean;
  parentComponent?: string;
  testPrefix?: string;
  icon?: "IconPlay";
}

export const ButtonHEF: React.FC<ButtonProps> = ({
  as = "button",
  label,
  color,
  onClick,
  href,
  disabled = false,
  variant = "primary",
  parentComponent = "undefined",
  testPrefix = "standard",
  icon,
}) => {
  function handleHref() {
    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: parentComponent,
        p_two: label,
        p_three: href,
      },
    });

    if (typeof window !== "undefined" && href) {
      window.location.href = href;
    }
  }

  function handleClick() {
    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: parentComponent,
        p_two: label,
      },
    });

    onClick();
  }

  const IconComp = icon ? Icons[icon] : undefined;

  return (
    <>
      {href ? (
        <a href={href}>
          <StyledButton
            data-testid={`${testPrefix}-button`}
            color={color}
            disabled={disabled}
            variant={variant}
            onClick={as === "a" ? (_) => handleHref() : handleClick}
            icon={icon ? true : false}
          >
            {label}
            {icon && <StyledIconContainer>{typeof IconComp !== "undefined" && <IconComp />}</StyledIconContainer>}
          </StyledButton>
        </a>
      ) : (
        <StyledButton
          data-testid={`${testPrefix}-button`}
          color={color}
          disabled={disabled}
          variant={variant}
          onClick={as === "a" ? (_) => handleHref() : handleClick}
          icon={icon ? true : false}
        >
          {label}
          {icon && <StyledIconContainer>{typeof IconComp !== "undefined" && <IconComp />}</StyledIconContainer>}
        </StyledButton>
      )}
    </>
  );
};
