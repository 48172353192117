import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    /* overflow: auto; */
    height: 100%;
  }

  body {
    height: 100%;
    overflow: none;
  }

  .hult-ef-main {
    position: inherit;
    width: inherit;
  }

  h1 {
    font-size: 32px;
    margin: 0;
  }

  a {
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  a:hover {
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  [lang='jp'] {
    :root, p, body, button, textarea, input {
      font-family: UDShinGon, sans-serif;
    }
  }

  [lang='ja'] {
    :root, p, body, button, textarea, input {
      font-family: UDShinGon, sans-serif;
    }
  }
`;
