import React from "react";

import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import { sbRichText } from "../../types";
import { Blender } from "../Blender";
import { LinkItem, LinkItemProps } from "../LinkItem";
import {
  ColumnBodyBackgroundWrapper,
  ColumnBodyContentWrapper,
  ColumnBodyRichTextWrapper,
  ColumnBodyTitle,
  ColumnBodyWrapper,
} from "./styles/StyledColumnBody";

const resolver = new RichTextResolver();

export interface ColumnBodyProps {
  title: string;
  column1: sbRichText;
  column2: sbRichText;
  link: LinkItemProps[];
  breaker: boolean;
  breakerPosition: "top" | "bottom";
  flipBreaker: boolean;
  bgColour: "white" | "offwhite";
}

export const ColumnBodyCmp: React.FC<ColumnBodyProps> = ({
  title,
  column1,
  column2,
  link,
  breaker,
  breakerPosition,
  flipBreaker,
  bgColour,
}) => {
  return (
    <>
      {breaker && breakerPosition === "top" && bgColour !== "white" && (
        <Blender color={"offwhite"} position={breakerPosition} flip={flipBreaker} />
      )}
      <ColumnBodyBackgroundWrapper bgColour={bgColour}>
        <ColumnBodyWrapper breaker={breaker && bgColour !== "white"}>
          <ColumnBodyContentWrapper leftSide>
            <ColumnBodyTitle>{title}</ColumnBodyTitle>
            <ColumnBodyRichTextWrapper
              dangerouslySetInnerHTML={{
                __html: resolver.render(column1),
              }}
            />
          </ColumnBodyContentWrapper>
          <ColumnBodyContentWrapper leftSide={false}>
            <ColumnBodyRichTextWrapper
              dangerouslySetInnerHTML={{
                __html: resolver.render(column2),
              }}
            />
            {Array.isArray(link) && link.length > 0 && <LinkItem {...link[0]} />}
          </ColumnBodyContentWrapper>
        </ColumnBodyWrapper>
      </ColumnBodyBackgroundWrapper>
      {breaker && breakerPosition === "bottom" && bgColour !== "white" && (
        <Blender color={"offwhite"} position={breakerPosition} flip={flipBreaker} />
      )}
    </>
  );
};
