import React, { useState } from "react";
import { uniqueId } from "lodash";

import { TelephoneDropdown } from "./TelephoneDropdown";

import {
  StyledCheckValidation,
  StyledCrossValidation,
  StyledTelephoneWrapper,
  StyledInput,
  StyledErrorMessage,
} from "./partials";

interface TelephoneProps {
  id?: string;
  section_id: number;
  className?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  hide_label?: boolean;
  type?: string;
  required?: boolean;
  disable?: boolean;
  defaultValue?: string;
  hidden?: boolean;
  countryCode?: string;
  value?: string;
  onChange: any;
  isValid?: boolean;
  isoCountry?: string;
  required_err_msg?: string;
  invalid_err_msg?: string;
}

export const Telephone: React.FC<TelephoneProps> = ({
  id,
  section_id,
  placeholder,
  name,
  label,
  type,
  required = false,
  hidden = false,
  disable = false,
  countryCode = "+1",
  value = "",
  onChange,
  isValid,
  isoCountry = "US",
  required_err_msg = "This is required",
  invalid_err_msg = "Invalid data entry",
}) => {
  const [phoneValid, setPhoneValid] = useState(isValid);
  const htmlId = uniqueId(id);

  const pattern = "^[0-9]{6,20}";

  function handleChange(e, focus_out?: boolean) {
    if (e.name === name) {
      setPhoneValid(e.validity.valid);
      let newVal = e.value;

      if (newVal.length > 6) {
        if (e.value.substring(0, 3) === countryCode) {
          newVal = e.value.substring(3);
        } else if (e.value.substring(0, 2) === countryCode) {
          newVal = e.value.substring(2);
        } else if (e.value.substring(0, 4) === countryCode) {
          newVal = e.value.substring(4);
        }
      }

      onChange(id, {
        value: newVal,
        valid: e.validity.valid,
        countryCode: countryCode,
        isoCountry: isoCountry,
        section_id: section_id,
        focus_out,
      });
    } else {
      onChange(id, {
        value: value,
        valid: phoneValid,
        countryCode: e.countryCode,
        isoCountry: e.isoCountry,
        section_id: section_id,
      });
    }
  }

  return (
    <StyledTelephoneWrapper hidden={hidden} valid={isValid}>
      {label && (
        <label htmlFor={htmlId}>
          {label} {required && " *"}
        </label>
      )}
      <div>
        <TelephoneDropdown
          isoCountry={isoCountry}
          countryCode={countryCode}
          onChange={handleChange}
          valid={isValid}
        />
        <StyledInput valid={isValid}>
          <input
            id={htmlId}
            name={name}
            type={type}
            placeholder={placeholder}
            required={required}
            disabled={disable}
            value={value}
            pattern={pattern}
            onChange={(e) => {
              handleChange(e.target, false);
            }}
            onBlur={(e) => {
              handleChange(e.target, true);
            }}
          />
          {isValid && <StyledCheckValidation />}
          {isValid === false && <StyledCrossValidation />}
        </StyledInput>
      </div>
      {isValid === false &&
        (value.length > 0 ? (
          <StyledErrorMessage>{invalid_err_msg}</StyledErrorMessage>
        ) : (
          <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
        ))}
    </StyledTelephoneWrapper>
  );
};
