import styled from "styled-components"

export const StyledRadioWrapper = styled.div`
  > label {
    font-size: 14px;
  }
`

export const StyledRadioItemsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin: ${(props) => props.theme.spacing.one} -${(props) => props.theme.spacing.three} -${(props) =>
  props.theme.spacing.two}
    0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-flow: column;
  }
`

export const StyledRadioItem = styled.div`
  margin-right: ${(props) => props.theme.spacing.three};
  margin-bottom: ${(props) => props.theme.spacing.two};
  position: relative;

  & input[type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 50;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  & input[type="radio"]:checked + label,
  input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${(props) => props.theme.colors.neutrals.black};
  }
  & input[type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: ${(props) => props.theme.colors.warmths.heritage};
  }

  & input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  & input[type="radio"]:checked + label:after,
  input[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  & input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  & input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`
