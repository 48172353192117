import styled from "styled-components";

export const StyledPodcast = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledPodcastWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledPodcastHeader = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  max-width: 560px;
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledPodcastFrameWrapper = styled.div`
  height: auto;

  & iframe {
    border: none;
  }
`;

export const StyledPodcastBody = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: ${(props) => props.theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;
