import React from "react";

import { ProductCardsItem, ProductCardsItemProps } from "./ProductCardsItem";
import {
  ProductCardsBackground,
  ProductCardsBackgroundSVG,
  ProductCardsTitle,
  ProductCardsWrapper,
} from "./styles/StyledProductCards";

export interface ProductCardsProps {
  title: string;
  cards: ProductCardsItemProps[];
  bottomMargin: boolean;
}

export const ProductCardsCmp: React.FC<ProductCardsProps> = ({ title, cards, bottomMargin }) => {
  return (
    <ProductCardsBackground>
      <ProductCardsWrapper bottomMargin={bottomMargin}>
        <ProductCardsBackgroundSVG />
        <ProductCardsTitle>{title}</ProductCardsTitle>
        {cards.map((card, i) => (
          <ProductCardsItem {...card} index={i} count={cards.length} key={card._uid} />
        ))}
      </ProductCardsWrapper>
    </ProductCardsBackground>
  );
};
