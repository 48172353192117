import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { OverviewStories } from "../OverviewFilter/OverviewStories";
import { ButtonHEF } from "../Button";
import { getUrl } from "../utils/helpers";
import { sbLinkType } from "../../types";

import {
  StyledOtherProgramsFilterWrapper,
  StyledOtherProgramsContainer,
  StyledInfo,
  StyledTitle,
  StyledDesc,
  StyledShape,
  StyledCards,
} from "./partials";

export interface OtherProgramsProps {
  title: string;
  desc: string;
  hide_shape: boolean;
  cta_label: string;
  cta_link: sbLinkType;
}

export const OtherProgramsCmp: React.FC<OtherProgramsProps> = ({
  title,
  desc,
  hide_shape,
  cta_label,
  cta_link,
}) => {

  function parseQueryResult(data: any) {
    let overviewCards: Array<{}> = [];

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content = JSON.parse(node.content);
      content["slug"] = node.full_slug;
      overviewCards.push({ content });
    });
    return [overviewCards];
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            allStoryblokEntry(
              filter: { field_component: { eq: "op-template" } }
            ) {
              edges {
                node {
                  id
                  slug
                  full_slug
                  content
                  published_at
                  position
                }
              }
            }
          }
        `}
        render={(data: any) => {
          const getData = data.allStoryblokEntry.edges;
          getData.map((a) => JSON.parse(a.node.content));
          let [overviewCards] = parseQueryResult(data);

          return (
            <StyledOtherProgramsFilterWrapper>
              <StyledOtherProgramsContainer>
                <StyledInfo>
                  <StyledTitle>{title && title}</StyledTitle>
                  <StyledDesc>{desc && desc}</StyledDesc>
                </StyledInfo>
                <StyledCards>
                  {overviewCards &&
                    overviewCards.slice(0, 3).map((item) => {
                      return (
                        <OverviewStories
                          key={item.content_uid}
                          title={item.content.title}
                          qualifier={item.content.qualifier}
                          learning_formats={item.content.learning_formats}
                          bestseller={item.content.bestseller}
                          full_slug={item.content.slug}
                        />
                      );
                    })}
                </StyledCards>
                {cta_label && 
                  <ButtonHEF
                    variant="primary"
                    label={cta_label}
                    as={"a"}
                    href={getUrl(cta_link)}
                  />
                }
              </StyledOtherProgramsContainer>
              <StyledShape hide_shape={hide_shape}>
                <svg
                  viewBox="0 0 1200 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 70.7954C15.9521 64.2977 31.9767 59.4728 49.1426 55.9031C123.135 40.5152 198.945 41.5665 274.04 44.3383C355.923 47.3547 437.128 41.146 518.965 37.5102C633.914 32.4102 748.917 28.266 863.798 21.6367C887.117 20.291 910.586 17.7563 933.929 17.1637C934.771 17.0108 935.609 16.854 936.695 16.6667C945.336 15.1834 953.996 13.7688 962.648 12.3428C1002.69 5.76325 1044.91 -2.59785 1085.71 0.770287C1129.42 4.37138 1170.1 17.0162 1200 47.2837C1200 47.2837 1200 55.1209 1200 70.7954V70.9609H0V70.7954Z"
                    fill="white"
                  />
                </svg>
              </StyledShape>
            </StyledOtherProgramsFilterWrapper>
          );
        }}
      />
    </>
  );
};
