import styled from "styled-components";

export const StyledContactUs = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledContactUsContent = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 2;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledContactUsHeadline = styled.h2`
  font-size: 32px;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.neutrals.black};
  margin-bottom: ${({ theme }) => theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledContactUsDesc = styled.p`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.light};
  color: ${({ theme }) => theme.colors.neutrals.black};
  margin-bottom: ${({ theme }) => theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

interface IStyledContactUsCardContainer {
  cards?: number;
}

export const StyledContactUsCardContainer = styled.div<IStyledContactUsCardContainer>`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.three};
  grid-template-columns: ${({ cards }) =>
    cards === 4 ? "1fr 1fr" : "1fr 1fr 1fr"};

  width: ${({ cards }) => (cards === 4 ? "66%" : "100%")};

  > div {
    padding: 0;
  }

  > a {
    padding: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-gap: ${({ theme }) => theme.spacing.two};
    grid-template-columns: 100%;
    width: 100%;
  }
`;

export const StyledSideImageWrapper = styled.div`
  position: absolute;
  display: block;
  right: 0;
  top: 64px;
  z-index: 1;
  width: 392px;
  height: 376px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;
