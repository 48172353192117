import styled from "styled-components";

interface Props {
  reducePadding?: boolean;
}

export const StyledQuote = styled.div<Props>`
  padding: ${({ theme, reducePadding }) => (reducePadding ? theme.spacing.two : theme.spacing.seven)} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${({ theme, reducePadding }) => (reducePadding ? theme.spacing.two : theme.spacing.seven)}
      ${({ theme }) => theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${({ theme, reducePadding }) => (reducePadding ? theme.spacing.two : theme.spacing.four)}
      ${({ theme }) => theme.spacing.three};
  }
`;

interface IStyledQuoteWrapper {
  article?: boolean;
}

export const StyledQuoteWrapper = styled.div<IStyledQuoteWrapper>`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme, article }) => (article ? theme.articleMaxWidth : theme.maxWidth)};
  border-left: 4px solid black;
`;

export const StyledQuoteText = styled.q`
  display: block;
  quotes: "“" "”";
  max-width: initial;
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.light};
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  margin: 0 0 ${(props) => props.theme.spacing.three} ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledQuoteAuthorWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0 0 0 ${(props) => props.theme.spacing.three};
`;

export const StyledQuoteAuthor = styled.p`
  font-size: 12px;
`;

export const StyledQuoteAuthorPic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.spacing.two};
`;

export const StyledQuoteCompanyLogo = styled.img`
  height: 40px;
  width: auto;
`;
