import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import IconClose from "@ef-global/web-ui-react/lib/icons/IconClose";
import { Checkbox } from "../FormInput/Checkbox";
import { OverviewStories } from "./OverviewStories";
import Pagination from "../Overview/Pagination";

import {
  StyledOverviewFilterWrapper,
  StyledOverviewContent,
  StyledInfo,
  StyledTitle,
  StyledDesc,
  StyledFilterResults,
  StyledFilterContainer,
  StyledCaptionWrapper,
  StyledCaptionFilter,
  StyledCaption,
  StyledCheckbox,
  StyledModalFooterWrapper,
  StyledModalFooter,
  StyledFilterDesktop,
  StyledClearFilter,
  StyledSelect,
  StyledOverviewStoryCardWrapper,
  StyledOverviewCardsContainer,
  StyledNoResult,
  StyledNoResultText,
  StyledBestsellerCard,
  StyledPagination,
  StyledShape,
} from "./partials";

import { Select } from "../FormInput";
import { Modal } from "./Modal";
import { Filter } from "./Filter";
import { ButtonHEF } from "../Button";
import useWindowDimensions from "./WindowDimensions";

export interface OverviewProps {
  title: string;
  desc: string;
  showPagination?: boolean;
  hide_shape: boolean;
}

export const OverviewCmp: React.FC<OverviewProps> = ({
  title,
  desc,
  showPagination,
  hide_shape,
}) => {
  const [stories, setStories] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [sortValue, setSortValue] = useState("best");
  const [selectedFormat, setSelectedFormat] = useState([]);
  const [viewPage, setViewPage] = React.useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { width } = useWindowDimensions();
  const selectedFilterLength = selectedTopic.length + selectedFormat.length;

  const containerRef = React.useRef<null | Element>(null);
  const showArticlesNumber = showPagination ? 9 : 6;

  const clearArray = () => {
    setSelectedFormat([]);
    setSelectedTopic([]);
  };

  const topicChecker = (item) => {
    if (!selectedTopic.includes(item)) {
      return setSelectedTopic((selectedTopic) => [...selectedTopic, item]);
    } else {
      return setSelectedTopic(
        selectedTopic.filter((selectedTopic) => selectedTopic !== item)
      );
    }
  };
  const formatChecker = (item) => {
    if (!selectedFormat.includes(item)) {
      return setSelectedFormat((selectedFormat) => [...selectedFormat, item]);
    } else {
      return setSelectedFormat(
        selectedFormat.filter((selectedFormat) => selectedFormat !== item)
      );
    }
  };

  let site: null | Element = null;
  if (typeof document !== "undefined") {
    site = document.getElementById("site");
  }

  function handleScroll() {
    if (site && containerRef.current) {
      site.scrollTo({
        top: containerRef.current?.offsetParent.offsetTop + 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  function parseQueryResult(data: any) {
    let parsedResult: Array<{}> = [];
    let fullResult: Array<{}> = [];

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content = JSON.parse(node.content);
      content["slug"] = node.full_slug;
      let learningFormatsArray = content.learning_formats;

      if (content.bestseller) {
        fullResult.push({ content });
      }
      if (
        (selectedTopic?.length === 0 ||
          selectedTopic?.includes(content.qualifier)) &&
        (selectedFormat?.length === 0 ||
          learningFormatsArray.some((a) => selectedFormat.includes(a)))
      ) {
        parsedResult.push({ content });
      }
    });

    return [parsedResult, fullResult];
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            allStoryblokEntry(
              filter: { field_component: { eq: "op-template" } }
            ) {
              edges {
                node {
                  id
                  slug
                  full_slug
                  content
                  published_at
                  position
                }
              }
            }
          }
        `}
        render={(data: any) => {
          const getData = data.allStoryblokEntry.edges;
          let result = getData.map((a) => JSON.parse(a.node.content));
          let [storiesFilter, fullResult] = parseQueryResult(data);

          let learningFormatsResults = result
            .map((i) => i.learning_formats)
            .flat()
            .filter((el) => el !== undefined);
          let qualifierResults = result
            .map((i) => i.qualifier)
            .flat()
            .filter((el) => el !== undefined);

          setStories(getData);
          const uniqueFormats = [...new Set(learningFormatsResults)];
          const uniqueQualifier = [...new Set(qualifierResults)];

          const totalCount = storiesFilter.length;
          const totalPages = Math.ceil(totalCount / showArticlesNumber);
          const articleFrom = viewPage * showArticlesNumber;
          const articleTo = articleFrom + showArticlesNumber;

          return (
            <>
              <StyledOverviewFilterWrapper>
                <StyledOverviewContent>
                  <StyledInfo>
                    <StyledTitle>{title && title}</StyledTitle>
                    <StyledDesc>{desc && desc}</StyledDesc>
                  </StyledInfo>
                  <StyledFilterResults>
                    <StyledFilterContainer>
                      {width < 768 && (
                        <>
                          <ButtonHEF
                            variant="primary"
                            label={
                              selectedFilterLength > 0
                                ? `Filter (${selectedFilterLength})`
                                : "Filter"
                            }
                            onClick={(_: void) => setOpenModal(true)}
                            icon="IconFilter"
                          ></ButtonHEF>
                          <Modal
                            isOpen={openModal}
                            onClose={() => setOpenModal(false)}
                          >
                            <Filter title="Topics">
                              {uniqueQualifier.map((item) => (
                                <StyledCheckbox key={item._uid}>
                                  <Checkbox
                                    onChange={() => topicChecker(item)}
                                    key={item._uid}
                                    id={item}
                                    section_id={item}
                                    label={item}
                                    value={
                                      selectedTopic.filter(
                                        (x: string) => x == item
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    checked={
                                      selectedTopic.filter(
                                        (x: string) => x == item
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    name={item}
                                  />
                                </StyledCheckbox>
                              ))}
                            </Filter>

                            <Filter title="Learning Formats">
                              {uniqueFormats.map((item) => (
                                <StyledCheckbox
                                  key={item._uid}
                                  is_active={true}
                                >
                                  <Checkbox
                                    onChange={() => formatChecker(item)}
                                    key={item._uid}
                                    id={item}
                                    section_id={item}
                                    label={item}
                                    value={
                                      selectedFormat.filter(
                                        (x: string) => x == item
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    checked={
                                      selectedFormat.filter(
                                        (x: string) => x == item
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    name={item}
                                  />
                                </StyledCheckbox>
                              ))}
                            </Filter>
                            <StyledModalFooterWrapper>
                              <StyledModalFooter>
                                <StyledClearFilter onClick={clearArray}>
                                  <IconClose />
                                  <p>Clear filter</p>
                                </StyledClearFilter>
                              </StyledModalFooter>
                            </StyledModalFooterWrapper>
                          </Modal>
                        </>
                      )}

                      <StyledFilterDesktop>
                        <StyledCaption>Filter</StyledCaption>
                        <Filter title="Topics">
                          {uniqueQualifier.map((item) => (
                            <StyledCheckbox
                              key={item._uid}
                              is_active={true}
                              onClick={() => setViewPage(0)}
                            >
                              <Checkbox
                                onChange={() => topicChecker(item)}
                                key={item._uid}
                                id={item}
                                section_id={item}
                                label={item}
                                value={
                                  selectedTopic.filter((x: string) => x == item)
                                    .length > 0
                                    ? true
                                    : false
                                }
                                checked={
                                  selectedTopic.filter((x: string) => x == item)
                                    .length > 0
                                    ? true
                                    : false
                                }
                                name={item}
                              />
                            </StyledCheckbox>
                          ))}
                        </Filter>
                        <Filter title="Learning Formats">
                          {uniqueFormats.map((item) => (
                            <StyledCheckbox
                              key={item._uid}
                              is_active={true}
                              onClick={() => setViewPage(0)}
                            >
                              <Checkbox
                                onChange={() => formatChecker(item)}
                                key={item._uid}
                                id={item}
                                section_id={item}
                                label={item}
                                value={
                                  selectedFormat.filter(
                                    (x: string) => x == item
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                checked={
                                  selectedFormat.filter(
                                    (x: string) => x == item
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                name={item}
                              />
                            </StyledCheckbox>
                          ))}
                        </Filter>
                        <StyledClearFilter onClick={clearArray}>
                          <IconClose />
                          <p>Clear filter</p>
                        </StyledClearFilter>
                      </StyledFilterDesktop>
                    </StyledFilterContainer>

                    <StyledOverviewStoryCardWrapper>
                      <StyledCaptionWrapper>
                        <StyledCaptionFilter>
                          Showing {totalCount} of {stories.length} courses
                        </StyledCaptionFilter>

                        <StyledSelect>
                          <StyledCaptionFilter>
                            <Select
                              id={""}
                              section_id={0}
                              name={""}
                              label={"Sort by"}
                              onChange={(e: any) =>
                                setSortValue(e.target.value)
                              }
                              defaultValue={"best"}
                              required
                            >
                              <option value="alpha">{"Courses A - Z"}</option>
                              <option value="best">{"Flagship"}</option>
                            </Select>
                          </StyledCaptionFilter>
                        </StyledSelect>
                      </StyledCaptionWrapper>
                      <StyledOverviewCardsContainer>
                        <StyledOverviewCardsContainer
                          column={"three"}
                          ref={containerRef}
                        >
                          <>
                            {storiesFilter.length > 0 ? (
                              storiesFilter
                                .sort((a, b) => {
                                  if (sortValue === "alpha") {
                                    if (a.content.title > b.content.title) {
                                      return 1;
                                    } else if (
                                      a.content.title < b.content.title
                                    ) {
                                      return -1;
                                    }
                                  } else if (sortValue === "best") {
                                    if (!a.content.bestseller) {
                                      return 1;
                                    } else if (a.content.bestseller) {
                                      return -1;
                                    }
                                  }
                                  return 0;
                                })
                                .slice(articleFrom, articleTo)
                                .map((item) => {
                                  return (
                                    <OverviewStories
                                      key={item.content_uid}
                                      title={item.content.title}
                                      qualifier={item.content.qualifier}
                                      learning_formats={
                                        item.content.learning_formats
                                      }
                                      bestseller={item.content.bestseller}
                                      full_slug={item.content.slug}
                                    />
                                  );
                                })
                            ) : (
                              <StyledNoResult>
                                <StyledNoResultText>
                                  <h1>
                                    We couldn't find any courses to match your
                                    request
                                  </h1>
                                  <p>
                                    Please remove some filters and try again.
                                  </p>
                                  <p>
                                    We can also design a custom program to fit
                                    your needs. Please call us at{" "}
                                    <a href="tel:+44 020 8645 0123">
                                      +44 020 8645 0123.
                                    </a>
                                  </p>
                                </StyledNoResultText>
                                <StyledBestsellerCard>
                                  {fullResult
                                    .slice(articleFrom, articleTo)
                                    .map((item) => {
                                      return (
                                        <OverviewStories
                                          key={item.content_uid}
                                          title={item.content.title}
                                          qualifier={item.content.qualifier}
                                          learning_formats={
                                            item.content.learning_formats
                                          }
                                          bestseller={item.content.bestseller}
                                          full_slug={item.content.slug}
                                        />
                                      );
                                    })}
                                </StyledBestsellerCard>
                              </StyledNoResult>
                            )}
                          </>
                        </StyledOverviewCardsContainer>
                        <StyledPagination>
                          {storiesFilter.length > 6 && (
                            <Pagination
                              breakLabel={"..."}
                              pageCount={totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              onPageChange={(e) => {
                                handleScroll();
                                setViewPage(e);
                              }}
                            />
                          )}
                        </StyledPagination>
                      </StyledOverviewCardsContainer>
                    </StyledOverviewStoryCardWrapper>
                  </StyledFilterResults>
                </StyledOverviewContent>
                <StyledShape hide_shape={hide_shape}>
                  <svg
                    viewBox="0 0 1200 71"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 70.7954C15.9521 64.2977 31.9767 59.4728 49.1426 55.9031C123.135 40.5152 198.945 41.5665 274.04 44.3383C355.923 47.3547 437.128 41.146 518.965 37.5102C633.914 32.4102 748.917 28.266 863.798 21.6367C887.117 20.291 910.586 17.7563 933.929 17.1637C934.771 17.0108 935.609 16.854 936.695 16.6667C945.336 15.1834 953.996 13.7688 962.648 12.3428C1002.69 5.76325 1044.91 -2.59785 1085.71 0.770287C1129.42 4.37138 1170.1 17.0162 1200 47.2837C1200 47.2837 1200 55.1209 1200 70.7954V70.9609H0V70.7954Z"
                      fill="white"
                    />
                  </svg>
                </StyledShape>
              </StyledOverviewFilterWrapper>
            </>
          );
        }}
      />
    </>
  );
};
