import styled from "styled-components";

export const StyledVideoGallery = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledHeader = styled.div`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledSelectedVideoWrapper = styled.div`
`;

export const StyledSelectedVideo = styled.div`
  display: flex;
  justify-content: center;
  
  height: 540px;
  position: relative;
  width: 100%;
  border-radius: 8px;

  > div {
    border-radius: 8px;
  }
  & div[data-vimeo-initialized="true"]{
    position: relative;

  }

  .react-player__preview {
    border-radius: 8px;
  }

  & iframe {
    border: none;
    border-radius: inherit;
  }

  & video {
    height: 100%;
    width: 100%;
    border: inherit;
    border-radius: 8px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 400px;
  }
`;

export const StyledSelectedDuration = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.neutrals.white};
  background-color: ${(props) => props.theme.colors.neutrals.black};
  border-radius: 4px;
  padding: 8px 12px;
`;


export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectedTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-top: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 20px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-top: ${(props) => props.theme.spacing.one};
  }
`;

export const StyledSelectedPageLink = styled.div`
  display: inline-flex;
  align-items: center;
  padding-top: 8px;
  margin-bottom: ${(props) => props.theme.spacing.seven};
  cursor: pointer;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: 40px;
  }

`;

export const StyledPageLink = styled.div`
  padding-right: 16px;
`;

export const StyledVideos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px 16px;
  }
`;