import styled from "styled-components";

export const LearningMetaContainer = styled.div``;

export const StyledLearningWrapper = styled.div`
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.warmths.heritage} 60%,
    #f0f0f0 35%
  );
  width: 100%;
`;

export const StyledSpacer = styled.div`
  height: 48px;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.neutrals.white} 60%,
    #f0f0f0 35%
  );
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    background: #f0f0f0;
  }
`;

export const StyledSpacerCol = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  max-width: 960px;
  margin: 0 auto;

  & svg {
    display: block;
    position: absolute;
    left: 20%;
    bottom: 0;
    max-height: 48px;
    width: 60%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & svg {
      left: 0;
      max-height: 48px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin: 0 24px;
  }
`;

export const StyledSpacerGrey = styled.div`
  height: 100%;
  grid-column: col-start 8 / span 5;
  background: #f0f0f0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: col-start 1 / span 12;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  max-width: 960px;
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin-left: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-left: 0px;
  }
`;

interface IStyledInfo {
  extraBorder?: boolean;
}

export const StyledInfo = styled.div<IStyledInfo>`
  grid-column: col-start 1 / span 10;
  background-color: ${(props) => props.theme.colors.warmths.heritage};
  border-radius: ${({ extraBorder }) =>
    extraBorder ? "0 50px 50px 0" : "0 50px 0 0"};
  padding: ${(props) => props.theme.spacing.eight} 0;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding-right: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
    margin: 0;
    grid-column: col-start 1 / span 12;
    border-radius: ${({ extraBorder }) =>
      extraBorder ? "0 50px 0 0" : "0 50px 0 0"};
  }
`;

export const StyledHeader = styled.div`
  padding-bottom: ${(props) => props.theme.spacing.two};
  width: 100%;
  font-size: 32px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.neutrals.white};
  font-weight: ${(props) => props.theme.font.bold};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    padding: ${(props) => props.theme.spacing.seven} 0
      ${(props) => props.theme.spacing.three} 0;
  }
`;

interface IStyledTabHeader {
  hidden?: boolean;
}

export const StyledTabHeader = styled.div<IStyledTabHeader>`
  font-size: 32px;
  padding-top: ${(props) => props.theme.spacing.eight};
  border-top: ${({ theme, hidden }) =>
    hidden ? "none" : `1px solid ${theme.colors.neutrals.white}`};
  line-height: 40px;
  color: ${(props) => props.theme.colors.neutrals.white};
  font-weight: ${(props) => props.theme.font.bold};
  margin: 0 ${({ theme }) => theme.spacing.eight}
    ${({ theme }) => theme.spacing.four} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-right: ${({ theme }) => theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    line-height: 32px;

    margin: 0 ${({ theme }) => theme.spacing.three};
    padding: ${(props) => props.theme.spacing.seven} 0
      ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledTabsWrapper = styled.div`
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.warmths.heritage} 60%,
    #ffffff 35%
  );
  width: 100%;
`;

export const StyledTabsContainer = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin-left: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-left: 0px;
  }
`;

export const StyledTabs = styled.div`
  background-color: ${(props) => props.theme.colors.warmths.heritage};
  border-radius: 0 0 50px 0;
  grid-column: col-start 1 / span 10;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: col-start 1 / span 12;
    border-radius: 0 0 0 0;
  }
`;

export const StyledButton = styled.div``;

export const StyledTabInfo = styled.div``;
