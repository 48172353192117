import React from "react";

import {
  StyledFeatureListItem,
  StyledFeatureListItemTextContent,
  StyledFeatureListItemHeader,
  StyledFeatureListItemSubtext,
} from "./partials";

import {
  FeatureListItemIcon,
  FeatureListItemIconProps,
} from "./FeatureListItemIcon";

export interface FeatureListItemProps {
  title: string;
  body: string;
  icon: Array<FeatureListItemIconProps>;
}

export const FeatureListItem: React.FC<FeatureListItemProps> = ({
  title,
  body,
  icon,
}) => {
  return (
    <StyledFeatureListItem>
      {Array.isArray(icon) && icon.length === 1 && (
        <FeatureListItemIcon
          gud_icon={icon[0].gud_icon}
          custom_svg={icon[0].custom_svg}
          background_color={icon[0].background_color}
        />
      )}
      <StyledFeatureListItemTextContent>
        <StyledFeatureListItemHeader>{title}</StyledFeatureListItemHeader>
        {body && (
          <StyledFeatureListItemSubtext>{body}</StyledFeatureListItemSubtext>
        )}
      </StyledFeatureListItemTextContent>
    </StyledFeatureListItem>
  );
};
