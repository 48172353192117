import styled from "styled-components";

export const StyledOverviewStoryCard = styled.a`
  background: ${(props) => props.theme.colors.neutrals.white};
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.spacing.three} 0;
  padding: ${(props) => props.theme.spacing.three};
  border-radius: 4px;
  cursor: pointer;

  :hover{
    text-decoration: none;
  }
`;

export const StyledCardTitle = styled.div`
  font-weight: ${(props) => props.theme.font.bold};
`;

export const StyledCardFilterInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
    margin-top: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledCardCourseInfo = styled.div`
  padding-bottom: ${(props) => props.theme.spacing.two};
  border-bottom: 1px solid rgba(25, 25, 25, 20%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;
  }
`;

export const StyledCardQualifier = styled.div`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: ${(props) => props.theme.fontSize.s};
`;

export const StyledCardLearningFormats = styled.div`
  display: flex;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledCardFormat = styled.div`
  font-weight: ${(props) => props.theme.font.book};
  font-size: ${(props) => props.theme.fontSize.s};
  text-transform: capitalize;
  padding-left: 12px;

  &:first-of-type {
    list-style-type: none;
    padding-left: 0;
  }
`;

export const StyledBestsellerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBestseller = styled.div`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.warmths.intensity};
  display: flex;
  padding-left: ${(props) => props.theme.spacing.one};

  & svg {
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.one}
      ${(props) => props.theme.spacing.one} 0;
  }
`;