import React from "react";

import heritage_background from "../../assets/CallOutMessage/heritage.svg";
import intensity_background from "../../assets/CallOutMessage/intensity.svg";
import tacao_shape from "../../assets/CallOutMessage/tacao.svg";
import albescent_shape from "../../assets/CallOutMessage/albescent.svg";
import scribbles from "../../assets/CallOutMessage/scribbles.svg";
import speckle from "../../assets/ProductOverview/speckle.png";

import {
  StyledCallOutMsg,
  StyledCallOutMsgContainer,
  StyledDesktopBackground,
  StyledMobileShape,
  StyledScribble,
  StyledSpeckle,
  StyledTextContainer,
  StyledText,
} from "./partials";

export interface CallOutMessageProps {
  text: string;
  background: "heritage" | "intensity";
}

export const CallOutMessageCmp: React.FC<CallOutMessageProps> = ({
  text,
  background,
}) => {
  return (
      <StyledCallOutMsg>
        <StyledCallOutMsgContainer>
          <StyledDesktopBackground src={background === "heritage" ? heritage_background : intensity_background} />
          <StyledMobileShape src={background === "heritage" ? albescent_shape : tacao_shape} />
          <StyledScribble src={scribbles} />
          <StyledTextContainer background={background}>
            {text && <StyledText>{text}</StyledText>}
          </StyledTextContainer>
          <StyledSpeckle src={speckle} />
        </StyledCallOutMsgContainer>
      </StyledCallOutMsg>
  );
};
