import React, { useState } from "react";
import { hefBrandColors, sbAssetType } from "../../types";
import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import CookieContext from "../../context/CookieContext";

import { Modal } from "../Modal";
import { FormSC, FormSCProps } from "../Forms/FormSC";

import {
  StyledContactUsCard,
  StyledContactUsCardTitle,
  StyledContactUsCardBody,
} from "./partials";
import { sbImageService } from "hult-sb-ui";

export interface ContactUsCardProps {
  title: string;
  body: string;
  component: string;
  brand_colour?: hefBrandColors;
  form?: Array<FormSCProps>;
  tel?: string;
  background_image_card?: sbAssetType;
  interaction_id?: number;
}

export const ContactUsCard: React.FC<ContactUsCardProps> = ({
  title,
  body,
  component,
  brand_colour,
  form,
  tel,
  background_image_card,
  interaction_id,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const { data, set } = React.useContext(CookieContext);

  function handleClick() {
    if (!tel && !interaction_id) {
      setOpenModal(true);
    }

    if (interaction_id) {
      set && set({ driftInteractionId: interaction_id });

      if (window.drift) {
        window.drift.api.startInteraction({
          interactionId: Number(interaction_id),
        });
        const appId = process.env.GATSBY_DRIFT_APP_ID || "";
        let campaigns: any = {};
        campaigns[appId] = [Number(interaction_id)];

        localStorage.setItem(
          "DRIFT_SESSION_CAMPAIGNS",
          JSON.stringify(campaigns)
        );
        localStorage.setItem("DRIFT_OPEN", JSON.stringify(true));
      }
    }

    window.dataLayer.push({
      event: "button_click",
      eventMeta: {
        p_one: component,
        p_two: title,
      },
    });
  }

  let url = "";
  if (background_image_card?.filename) {
    url = sbImageService(background_image_card.filename, undefined, "460x0");
  }

  const backgroundStyle = {
    backgroundImage: `url('${url}')`,
    backgroundPosition: "center",
  };

  if (typeof window !== undefined && data?.driftInteractionId) {
    const appId = process.env.GATSBY_DRIFT_APP_ID || "";
    let campaigns: any = {};
    campaigns[appId] = [Number(data?.driftInteractionId)];

    localStorage.setItem("DRIFT_SESSION_CAMPAIGNS", JSON.stringify(campaigns));
  }

  return (
    <>
      <Surface
        rounded
        outline
        shadow
        as={tel ? "a" : "div"}
        href={tel ? `tel:${tel}` : undefined}
        onClick={(_) => handleClick()}
        data-testid="contact-us-card"
      >
        <StyledContactUsCard
          brand_color={brand_colour}
          style={url ? backgroundStyle : undefined}
        >
          <StyledContactUsCardTitle>{title}</StyledContactUsCardTitle>
          <StyledContactUsCardBody>
            <p>{body}</p>
            <IconArrowRight />
          </StyledContactUsCardBody>
        </StyledContactUsCard>
      </Surface>
      {Array.isArray(form) && form.length === 1 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={(_: void) => setOpenModal(false)}
            _uid={form[0]._uid}
            form={form[0].form}
            component={form[0].component}
            form_handler={form[0].form_handler}
            sf_campaign_id={form[0].sf_campaign_id}
            title={form[0].title}
            qualifier={form[0].qualifier}
            event_date={form[0].event_date}
            event_duration={form[0].event_duration}
            event_location={form[0].event_location}
            thank_you_components={form[0].thank_you_components}
            close_on_submit={form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
