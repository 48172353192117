import React from "react";

import { injectScopedInternalComponentList } from "@ef-global/sbc-utils";
import SbEditable from "storyblok-react";

import { BaseComponentProps, IDictionary } from "../types";
import { Accordion } from "./Accordion";
import ArticleAuthor from "./ArticleAuthor";
import { ArticleAuthorSC } from "./ArticleAuthor/ArticleAuthorSC";
import { ArticleBodyCopy } from "./ArticleBodyCopy";
import { ArticleHeadline } from "./ArticleHeadline";
import { ArticleImage } from "./ArticleImage";
import { ArticlePodcast } from "./ArticlePodcast";
import ArticleSocialShare from "./ArticleSocialShare";
import { BodyCenteredWithCTA } from "./BodyCenteredWithCTA";
import { BodyCopy } from "./BodyCopy";
import { BodyThree } from "./BodyThree";
import { BulletPoints } from "./BulletPoints";
import { CTAwImage } from "./CTAWithImage";
import { CallOutMesssage } from "./CallOutMessage";
import { CardWithText } from "./CardWithText";
import { CaseStudyHero } from "./CaseStudyHero";
import { CaseStudyImpact } from "./CaseStudyImpact";
import { CaseStudyImpactImage } from "./CaseStudyImpactImage";
import { CaseStudyQuote } from "./CaseStudyQuote";
import { ClientLogos } from "./ClientLogos";
import { ClientStoryCards } from "./ClientStoryCards";
import { ColumnBody } from "./ColumnBody";
// --- Local components ---
// import ComponentNotFound from "./component_not_found";
import ComponentNotFound from "./ComponentNotFound";
import { ContactUs } from "./ContactUs/ContactUs";
import { ContactUsSC } from "./ContactUs/ContactUsSC";
import { DescriptionWithImage } from "./DescriptionWithimage";
import { DigitalCoach } from "./DigitalCoach";
import { FacultyCarousel } from "./FacultyCarousel";
import { FeatureList } from "./FeatureList";
import { FeatureStat } from "./FeatureStat";
import { FeatureStatTwo } from "./FeatureStatTwo";
import { FormEmbedSB } from "./FormEmbed";
import { Form } from "./Forms/Form";
import { FormSC } from "./Forms/FormSC";
import { FullBleedImage } from "./FullBleedImage";
import { FullBleedImageHero } from "./FullBleedImgHero";
import { GeoHandler } from "./GeoHandler";
import { Header } from "./Header";
import { BodyOne } from "./Header/BodyOne";
import { HeaderTwo } from "./HeaderTwo";
import { BodyTwo } from "./HeaderTwo/BodyTwo";
import { Hero } from "./Hero";
import { HighlightedList } from "./HighlightedList";
import { HomeHeroImage } from "./HomeHeroImage";
import { HomeHeroVideo } from "./HomeHeroVideo";
import { IllustrationCard } from "./IllustrationCard";
import { ImageGallery } from "./ImageGallery";
import { JobPostings } from "./JobPostings";
import { LandingPageHeader } from "./LandingPageHeader";
import { LandingPageModule } from "./LandingPageModule";
import { LandingPageSection } from "./LandingPageSection";
import { LargeVideo } from "./LargeVideo";
import { LearningMeta } from "./LearningMeta";
import { LinkCardWithImage } from "./LinkCardWithImage";
import { LogoRow } from "./LogoRow";
import MetaData from "./MetaData";
import { MiniatureProductOverview } from "./MiniatureProductOverview";
import { ModalExample } from "./Modal/ModalExample";
import { MultiQuote } from "./MultiQuote";
import { NavItem } from "./NavItem/NavItem";
import { NavItemSubItem } from "./NavItemSubItem/NavItemSubItem";
import { NavItemWSubItems } from "./NavItemWSubItems/NavItemWSubItems";
import { Optimize } from "./Optimize";
import { ArticleOverview, EventOverview, OpenProgramsOverview } from "./Overview";
import { OverviewFilter } from "./OverviewFilter";
import { OverviewHeader } from "./OverviewHeader";
import { OtherPrograms } from "./OverviewOtherPrograms";
import { Podcast } from "./Podcast";
import { ProductCards } from "./ProductCards";
import { ProductHero } from "./ProductHero";
import { ProductOverview } from "./ProductOverview";
import { Quote } from "./Quote";
import { ArticleQuote } from "./Quote/ArticleQuote";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { SecondaryNavigationSC } from "./SecondaryNavigation/SecondaryNavigationSC";
import { SubscribeForm } from "./SubscribeForm";
import { SubscribeFormSC } from "./SubscribeForm/SubscribeFormSC";
import Article from "./Templates/Article/article";
import { Careers } from "./Templates/Careers/Careers";
import { EventTemplate } from "./Templates/Event/event";
import LandingPage from "./Templates/LandingPage/LandingPage";
import LegalPage from "./Templates/LegalPage/legalPage";
import OPTemplate from "./Templates/OPTemplate";
import OpenProgramsTemplate from "./Templates/OpenPrograms/openPrograms";
import Page from "./Templates/Page/page";
import { TextTwoColumns } from "./TextTwoColumns";
import UpcomingCohorts from "./UpcomingCohorts";
import { VideoGallery } from "./VideoGallery";
import Blank from "./blank";

const withComponentList = (type: string) => (componentList: IDictionary<React.FC<BaseComponentProps>>) => {
  if (typeof componentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return componentList[type];
};

const Components = (type: string) => withComponentList(type)(ComponentList);

const LocalComponentList = {
  page: Page,
  "legal-page": LegalPage,
  "op-template": OPTemplate,
  accordion: Accordion,
  "article-template": Article,
  "event-template": EventTemplate,
  "article-body-copy": ArticleBodyCopy,
  "article-social-share": ArticleSocialShare,
  "article-author": ArticleAuthor,
  "article-author-sc": ArticleAuthorSC,
  "article-quote": ArticleQuote,
  "article-podcast": ArticlePodcast,
  "open-programs-template": OpenProgramsTemplate,
  "careers-template": Careers,
  "upcoming-cohorts": UpcomingCohorts,
  blank: Blank,
  "secondary-navigation": SecondaryNavigation,
  "secondary-navigation-sc": SecondaryNavigationSC,
  "nav-item-w-sub-items": NavItemWSubItems,
  "nav-item-sub-item": NavItemSubItem,
  "nav-item": NavItem,
  header: Header,
  "header-two": HeaderTwo,
  "highlighted-list": HighlightedList,
  "product-overview": ProductOverview,
  "body-copy": BodyCopy,
  "body-one": BodyOne,
  "body-two": BodyTwo,
  "bullet-points": BulletPoints,
  "body-centered-with-cta": BodyCenteredWithCTA,
  "card-with-text": CardWithText,
  quote: Quote,
  "client-logos": ClientLogos,
  "contact-us": ContactUs,
  "contact-us-sc": ContactUsSC,
  "description-with-image": DescriptionWithImage,
  "form-embed": FormEmbedSB,
  "modal-example": ModalExample,
  "large-video": LargeVideo,
  "link-card-with-image": LinkCardWithImage,
  "faculty-carousel": FacultyCarousel,
  "feature-stat": FeatureStat,
  "text-two-columns": TextTwoColumns,
  "feature-list": FeatureList,
  "full-bleed-image": FullBleedImage,
  "full-bleed-image-hero": FullBleedImageHero,
  "home-hero-image": HomeHeroImage,
  "home-hero-video": HomeHeroVideo,
  "illustration-card": IllustrationCard,
  "image-gallery": ImageGallery,
  "miniature-product-overview": MiniatureProductOverview,
  "overview-header": OverviewHeader,
  optimize: Optimize,
  "subscribe-form": SubscribeForm,
  "subscribe-form-sc": SubscribeFormSC,
  "article-headline": ArticleHeadline,
  "article-image": ArticleImage,
  "form-sc": FormSC,
  form: Form,
  "multi-quote": MultiQuote,
  "article-overview": ArticleOverview,
  "event-overview": EventOverview,
  "open-programs-overview": OpenProgramsOverview,
  "meta-data": MetaData,
  podcast: Podcast,
  "learning-meta": LearningMeta,
  "op-overview": OverviewFilter,
  "op-other-programs": OtherPrograms,
  "digital-coach": DigitalCoach,
  "job-postings": JobPostings,
  "geo-handler": GeoHandler,
  "landing-page": LandingPage,
  "landing-page-header": LandingPageHeader,
  "landing-page-section": LandingPageSection,
  "landing-page-module": LandingPageModule,
  "logo-row": LogoRow,
  "cta-w-image": CTAwImage,
  "call-out-message": CallOutMesssage,
  "video-gallery": VideoGallery,
  hero: Hero,
  "client-story-cards": ClientStoryCards,
  "body-three": BodyThree,
  "feature-stat-two": FeatureStatTwo,
  "product-cards": ProductCards,
  "case-study-hero": CaseStudyHero,
  "product-hero": ProductHero,
  "column-body": ColumnBody,
  "case-study-impact": CaseStudyImpact,
  "case-study-impact-image": CaseStudyImpactImage,
  "case-study-quote": CaseStudyQuote,
};

const ScopedComponentList = {
  // The injectScopedInternalComponentList is method to inject whole components.js file if scope
  // component have more internal components than just one
  ...injectScopedInternalComponentList(
    {
      components: [
        // --- sb-mig scoped component list ---
      ],
    }
    // Components // injecting all Components you will use normally, into any of scoped components
  ),
  // If you are using scoped component, but inside your local project (installed with sb-mig add component-name --copy)
  // then use this syntax to import and use component and to inject component list to it
  // surface: withComponents(Surface)(Components) 0031B000vvvv02qFyklQAC
  // "web-ui-surface": withComponents(SurfaceStuff.WebUISurface)(Components)
};

const ComponentList: IDictionary<React.FC<BaseComponentProps>> = {
  ...LocalComponentList,
  ...ScopedComponentList,
};

const DynamicComponent: React.FC<BaseComponentProps> = ({ blok, path, content, story, settings, alternates }) => {
  if (typeof ComponentList[blok.component] !== "undefined") {
    const Component = ComponentList[blok.component];
    return (
      <SbEditable content={blok}>
        <Component
          blok={blok}
          path={path}
          content={content}
          story={story}
          settings={settings}
          alternates={alternates}
        />
      </SbEditable>
    );
  }
  return <ComponentNotFound blok={blok} />;
};

export default DynamicComponent;
