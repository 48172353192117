import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { FullBleedImageHeroCmp, FullBleedImageHeroProps } from "./FullBleedImageHero";

interface FullBleedImageHeroWBlokProps {
  blok: FullBleedImageHeroProps & blokType;
}

export const FullBleedImageHero: React.FC<FullBleedImageHeroWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FullBleedImageHeroCmp
        header={blok.header}
        body={blok.body}
        background_image={blok.background_image}
        graphic_overlay={blok.graphic_overlay}
        graphic_overlay_mobile={blok.graphic_overlay_mobile}
        cta={blok.cta}
        increaseFontSize={blok.increaseFontSize}
        centerText={blok.centerText}
        mobileImage={blok.mobileImage}
        text_color={blok.text_color}
      ></FullBleedImageHeroCmp>
    </SbEditable>
  );
};
