import styled from "styled-components";

interface Props {
  bgColour: "white" | "offwhite" | "black";
}

export const StyledArticleBodyCopy = styled.div<Props>`
  background: ${({ bgColour, theme }) =>
    (bgColour === "offwhite" && theme.colors.neutrals.offwhite) ||
    (bgColour === "white" && theme.colors.neutrals.white) ||
    (bgColour === "black" && theme.colors.neutrals.black)};
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledArticleBodyCopyWrapper = styled.article`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.articleMaxWidth};
`;

export const StyledArticleBodyCopyTitle = styled.h2<Props>`
  margin: 0 0 24px;
  width: 100%;
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 1.25;
  color: ${({ bgColour, theme }) => (bgColour === "black" ? theme.colors.neutrals.white : theme.colors.neutrals.black)};
  margin-bottom: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    font-size: 24px;
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledArticleBodyCopySubtitle = styled.h3<Props>`
  margin: 0 0 24px;
  width: 100%;
  font-size: 24px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  line-height: 32px;
  color: ${({ bgColour, theme }) => (bgColour === "black" ? theme.colors.neutrals.white : theme.colors.neutrals.black)};
  margin-bottom: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledArticleBodyCopyContent = styled.article<Props>`
  width: 100%;
  white-space: pre-line;

  font-weight: ${(props) => props.theme.font.light};

  & p {
    font-weight: ${(props) => props.theme.font.light};
    line-height: 24px;
    color: ${({ bgColour, theme }) => (bgColour === "black" ? theme.colors.neutrals.white : "inherit")};
  }

  & a {
    text-decoration: underline;
    color: ${({ bgColour, theme }) => (bgColour === "black" ? theme.colors.neutrals.white : "inherit")};
  }

  & ul,
  ol {
    color: ${({ bgColour, theme }) =>
    bgColour === "black" ? theme.colors.neutrals.white : theme.colors.warmths.heritage};
    padding-left: 20px;
    margin: 0 0 ${(props) => props.theme.spacing.three} 0;

    & li {
      padding: 0;
      margin-bottom: ${(props) => props.theme.spacing.one};
    }

    & p {
      font-weight: ${(props) => props.theme.font.light};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
