import styled from "styled-components";

export const StyledJobPostingWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.seven} 0
    ${(props) => props.theme.spacing.seven} 0;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledCardWrapper = styled.div``;

export const StyledCard = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${(props) => props.theme.spacing.three} 0;
  padding: ${(props) => props.theme.spacing.three};
  border: solid 1px ${(props) => props.theme.colors.neutrals.coolGrey};
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.neutrals.black};

  :hover {
    text-decoration: none;
  }
`;

export const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(25, 25, 25, 20%);
  padding-bottom: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledCardTitle = styled.div`
  font-weight: ${(props) => props.theme.font.bold};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-bottom: ${(props) => props.theme.spacing.one};
  }
`;

export const StyledInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;

  & > li:first-of-type {
    list-style-type: none;
    padding-right: 12px;
  }
`;

export const StyledJobCategory = styled.div`
  p {
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.one};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      padding-bottom: ${(props) => props.theme.spacing.one};
    }
  }
`;

export const StyledLocation = styled.div`
  font-size: 12px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.warmths.intensity};

  svg {
    margin-right: 4px;
  }
`;

export const StyledPaginationText = styled.div`
  font-size: 12px;
`;

export const StyledError = styled.div`
  padding: 8px 0;
`;
