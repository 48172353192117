import React, { useEffect, useState } from "react";

import { sbAssetType } from "../../types";
import sbImageService from "../utils/sbImage";
import {
  CaseStudyBodyTitle,
  CaseStudyHelpBox,
  CaseStudyHeroBackgroundWrapper,
  CaseStudyHeroCompany,
  CaseStudyHeroContentWrapper,
  CaseStudyHeroImage,
  CaseStudyHeroImageMobile,
  CaseStudyHeroIntroductionWrapper,
  CaseStudyHeroTagline,
  CaseStudyHeroTitleWrapper,
  CaseStudyHeroWrapper,
  CaseStudyParagraph,
} from "./styles/StyledCaseStudyHero";

export interface CaseStudyHeroProps {
  tagline: string;
  company: string;
  helpHeading: string;
  helpDescription: string;
  bodyTitle: string;
  bodyDescription: string;
  image: sbAssetType;
}

export const CaseStudyHeroCmp: React.FC<CaseStudyHeroProps> = ({
  tagline,
  company,
  helpHeading,
  helpDescription,
  bodyTitle,
  bodyDescription,
  image,
}) => {
  const [width, setWidth] = useState(0);

  const handleResize = () => setWidth(window.innerWidth ?? 0);

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <CaseStudyHeroWrapper>
        <CaseStudyHeroImageMobile src={sbImageService(image.filename, "500x0")} alt={image.alt} title={image.title} />
        <CaseStudyHeroContentWrapper left>
          <CaseStudyHeroTitleWrapper>
            <CaseStudyHeroTagline>{tagline}</CaseStudyHeroTagline>
            <CaseStudyHeroCompany>{company}</CaseStudyHeroCompany>
          </CaseStudyHeroTitleWrapper>
          <CaseStudyHelpBox width={(width - 960) / 2}>
            <CaseStudyParagraph bold>{helpHeading}</CaseStudyParagraph>
            <CaseStudyParagraph>{helpDescription}</CaseStudyParagraph>
          </CaseStudyHelpBox>
        </CaseStudyHeroContentWrapper>
        <CaseStudyHeroContentWrapper left={false}>
          <CaseStudyHeroImage src={sbImageService(image.filename, "0x500")} alt={image.alt} title={image.title} />
        </CaseStudyHeroContentWrapper>
      </CaseStudyHeroWrapper>
      <CaseStudyHeroBackgroundWrapper>
        <CaseStudyHeroIntroductionWrapper>
          <CaseStudyBodyTitle>{bodyTitle}</CaseStudyBodyTitle>
          <CaseStudyParagraph>{bodyDescription}</CaseStudyParagraph>
        </CaseStudyHeroIntroductionWrapper>
      </CaseStudyHeroBackgroundWrapper>
    </>
  );
};
