import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  BodyCenteredWithCTACmp,
  BodyCenteredWithCTACmpProps,
} from "./BodyCenteredWithCTA";

interface BodyCenteredWithCTAWBlokProps {
  blok: BodyCenteredWithCTACmpProps & blokType;
}

export const BodyCenteredWithCTA: React.FC<BodyCenteredWithCTAWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <BodyCenteredWithCTACmp
        _uid={blok._uid}
        component={blok.component}
        title={blok.title}
        cta_label={blok.cta_label}
        cta_link={blok.cta_link}
        cta_form={blok.cta_form}
        cta_colour={blok.cta_colour}
      ></BodyCenteredWithCTACmp>
    </SbEditable>
  );
};
