import React from "react";
import {
  LinkCardWithImageItem,
  LinkCardWithImageItemProps,
} from "./LinkCardWithImageItem";

import { LinkItemProps, LinkItem } from "../LinkItem";

import {
  StyledLinkCardWImage,
  StyledLinkCardWImageWrapper,
  StyledLinkCardWImageTitle,
  StyledLinkCardWImageBody,
  StyledLinkCardWImageCardContainer,
  StyledLinkContainer,
} from "./partials";

export interface LinkCardWithImageProps {
  _uid: string;
  component: string;
  title: string;
  body: string;
  cards: Array<LinkCardWithImageItemProps>;
  link?: Array<LinkItemProps>;
}

export const LinkCardWithImageCmp: React.FC<LinkCardWithImageProps> = ({
  component,
  title,
  body,
  cards,
  link,
}) => {
  return (
    <StyledLinkCardWImage>
      <StyledLinkCardWImageWrapper>
        <StyledLinkCardWImageTitle>{title}</StyledLinkCardWImageTitle>
        <StyledLinkCardWImageBody>{body}</StyledLinkCardWImageBody>
        <StyledLinkCardWImageCardContainer lessThanThree={cards.length <= 2}>
          {cards.map((item, idx) => (
            <LinkCardWithImageItem
              key={idx}
              image={item.image}
              link={item.link}
              title={item.title}
            />
          ))}
        </StyledLinkCardWImageCardContainer>

        {Array.isArray(link) && link.length === 1 && (
          <StyledLinkContainer>
            <LinkItem {...link[0]} parentComponent={component} />
          </StyledLinkContainer>
        )}
      </StyledLinkCardWImageWrapper>
    </StyledLinkCardWImage>
  );
};
