import styled from "styled-components";

interface StyledDescWImageCardBodyProps {
  linkBottom?: boolean;
}

export const StyledDescWImageCard = styled.div``;

export const StyledDescWImageCardImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 310px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);
  background-color: #ffffff;
`;

export const StyledDescWImageCardTitle = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  margin: ${(props) => props.theme.spacing.two} 0
    ${(props) => props.theme.spacing.one} 0;
`;

export const StyledDescWImageCardBody = styled.div<StyledDescWImageCardBodyProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: ${(props) => props.theme.font.light};
  text-overflow: ellipsis;
  margin-bottom: ${(props) =>
    props.linkBottom ? props.theme.spacing.two : "0"};
`;
