import React, { useState, useRef } from "react";

import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import IconArrowLeft from "@ef-global/web-ui-react/lib/icons/IconArrowLeft";

import { ImageGalleryItemProps, ImageGalleryItem } from "./ImageGalleryItem";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

import {
  StyledImageGallery,
  StyledImageGalleryItemWrapper,
  StyledImageGalleryImagesContainer,
  StyledImageGalleryPaginationContainer,
  StyledImageGalleryPagination,
  StyledImageGalleryPaginationDot,
  StyledImageGalleryControls,
  StyledImageGalleryControlsItem,
} from "./partials";

export interface ImageGalleryProps {
  images: Array<ImageGalleryItemProps>;
}

export const ImageGalleryCmp: React.FC<ImageGalleryProps> = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const swiperRef = useRef(null);

  return (
    <StyledImageGallery>
      <StyledImageGalleryItemWrapper>
        <StyledImageGalleryImagesContainer>
          <Swiper
            ref={swiperRef}
            slidesPerView={"auto"}
            onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          >
            {images.map((item, idx) => (
              <SwiperSlide key={`swiper-slide-${idx}`}>
                <ImageGalleryItem
                  image={item.image}
                  video={item.video}
                  video_link={item.video_link}
                  video_preview={item.video_preview}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledImageGalleryImagesContainer>
        <StyledImageGalleryPaginationContainer>
          {images.length > 1 && (
            <StyledImageGalleryPagination>
              {images.map((item, idx) => (
                <StyledImageGalleryPaginationDot
                  key={`pagination-dot-${idx}`}
                  isActive={idx === current}
                />
              ))}
            </StyledImageGalleryPagination>
          )}
          {images.length > 1 && (
            <StyledImageGalleryControls>
              <StyledImageGalleryControlsItem
                onClick={() => swiperRef.current.swiper.slidePrev()}
                disabled={current === 0}
              >
                <IconArrowLeft />
              </StyledImageGalleryControlsItem>
              <StyledImageGalleryControlsItem
                onClick={() => swiperRef.current.swiper.slideNext()}
                disabled={current === images.length - 1}
              >
                <IconArrowRight />
              </StyledImageGalleryControlsItem>
            </StyledImageGalleryControls>
          )}
        </StyledImageGalleryPaginationContainer>
      </StyledImageGalleryItemWrapper>
    </StyledImageGallery>
  );
};
