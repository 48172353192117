import styled from "styled-components";
import { StyledButton } from "../../Button/partials";

interface IStyleShapeProps {
  hide_shape: boolean;
}

export const StyledOtherProgramsFilterWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};
  margin: 0 auto;
`;

export const StyledOtherProgramsContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.eight} 0;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  & ${StyledButton} {
    font-size: 14px;
    padding: ${(props) => props.theme.spacing.one}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledInfo = styled.div`
  width: 55%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  padding-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledDesc = styled.div`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.font.light};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 16px;
  }
`;

export const StyledCards = styled.div`
  padding: ${(props) => props.theme.spacing.one} 0;
`;

export const StyledShape = styled.div<IStyleShapeProps>`
  height: 0;
  position: relative;
  z-index: 1;

  ${(props) => (props.hide_shape ? `display: none;` : `width: 100%;`)}

  & svg {
    position: absolute;
    bottom: 0;
  }
`;
