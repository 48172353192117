import styled from "styled-components";

import { StyledButton } from "../../Button/partials/StyledButton";

interface StyledFullBleedImageProps {
  background_image?: string;
  open?: boolean;
  mobile?: boolean;
  increaseFontSize?: boolean;
  centerText?: boolean;
  mobileImage?: string;
  textColor?: "light" | "dark";
}

export const StyledFullBleedImageHeader = styled.div<StyledFullBleedImageProps>`
  position: relative;
  min-height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("${(props) => props.background_image}");

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    background-image: url("${(props) => props.mobileImage}");
  }
`;

export const StyledFullBleedImageHeaderContainer = styled.div<StyledFullBleedImageProps>`
  margin: 0 auto;
  display: grid;
  max-width: ${({ theme }) => theme.maxWidth};
  padding: ${({ theme }) => theme.spacing.four} 0;
  justify-content: ${({ centerText }) => (centerText ? "center" : "inherit")};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    height: ${(props) => props.background_image && "50vh"};
    padding: ${({ theme }) => theme.spacing.one} ${({ theme }) => theme.spacing.three}
      ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  }
`;

export const StyledButtonContainerDesktop = styled.div<StyledFullBleedImageProps>`
  ${StyledButton} {
    ${({ theme }) => `background: ${theme.colors.neutrals.white}; color:${theme.colors.neutrals.black}`};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    opacity: ${(props) => props.open && "0"};
  }
`;

export const StyledBodyCenteredWModal = styled.div<StyledFullBleedImageProps>``;

export const StyledHeader = styled.header<StyledFullBleedImageProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 24px 24px 0 24px;

  ${StyledButton} {
    transition: 0.2s;
    opacity: ${(props) => (props.open ? 0 : 1)};
  }
`;

export const StyledFullBleedImageHeroMainContentDesktop = styled.div<StyledFullBleedImageProps>`
  display: grid;
  padding: ${({ theme }) => theme.spacing.seven} 0 188px 0;
  z-index: 2;
  max-width: ${({ increaseFontSize }) => (increaseFontSize ? "632px" : "386px")};

  & button {
    margin-top: ${({ theme }) => theme.spacing.two};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: ${({ theme }) => theme.spacing.four} 0;
    z-index: 5;
    position: relative;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledFullBleedImageHeroMainContentMobile = styled.div`
  padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  align-items: center;

  & button {
    margin: ${({ theme }) => theme.spacing.two} auto 0 auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledFullBleedImageHeroHeader = styled.h1<StyledFullBleedImageProps>`
  position: relative;
  z-index: 10;
  font-size: ${({ increaseFontSize }) => (increaseFontSize ? "64px" : "48px")};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme, textColor }) =>
    `${textColor === "light" ? theme.colors.neutrals.white : theme.colors.neutrals.black};`};
  margin-bottom: ${({ theme }) => theme.spacing.one};
  line-height: ${({ increaseFontSize }) => (increaseFontSize ? "72px" : "56px")};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    color: black;
    text-align: center;
  }
`;

export const StyledFullBleedImageHeroBody = styled.p<StyledFullBleedImageProps>`
  position: relative;
  z-index: 10;
  font-size: ${({ increaseFontSize }) => (increaseFontSize ? "32px" : "16px")};
  font-weight: ${({ theme }) => theme.font.light};
  margin: 0;
  line-height: 24px;
  line-height: ${({ increaseFontSize }) => (increaseFontSize ? "40px" : "24px")};
  color: ${({ theme, textColor }) =>
    `${textColor === "light" ? theme.colors.neutrals.white : theme.colors.neutrals.black};`};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.s}) {
    color: black;
    text-align: center;
    font-weight: ${({ theme }) => theme.font.light};
  }
`;

export const StyledGrapicOverlay = styled.img<StyledFullBleedImageProps>`
  display: ${({ mobile }) => (mobile ? "none" : "block")};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: contain;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: ${(props) => (props.mobile ? "block" : "none")};
    bottom: -1px;
  }
`;

export const StyledOverlay = styled.div<StyledFullBleedImageProps>`
  ${(props) =>
    props.mobileImage
      ? "position: absolute; height: auto; width: 100%; top: 0; bottom: 0; background: linear-gradient(rgba(0, 0, 0, 0));"
      : "display: none"};
`;
