import styled, { css } from "styled-components";

interface StyledLanguageSelectorContainerProps {
  right?: boolean;
}

export const StyledLanguageSelectorContainer = styled.div<StyledLanguageSelectorContainerProps>`
  display: flex;
  flex-flow: row;
  align-items: center;

  & svg {
    height: 16px;
    width: 16px;
  }

  & select {
    font-size: 12px;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    border: none;

    margin-left: ${(props) => props.theme.spacing.two};

    ${(props) =>
      props.right &&
      css`
        margin-left: 0;
        text-align-last: right;
        text-align: -webkit-right;
      `}

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 14px;
    }
  }
`;
