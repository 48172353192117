import styled from "styled-components"

import { focusBorder, validColor } from "./helpers"

interface StyledSelectProps {
  hidden?: boolean
  valid?: boolean
}

export const StyledSelectWrapper = styled.div<StyledSelectProps>`
  display: ${({ hidden }) => (hidden ? "none;" : "inline-block;")}
  width: 100%;
  height: auto;

  & label {
    width: 100%;
    font-size: 14px;
    margin-bottom: ${(props) => props.theme.spacing.one};

    line-height: 1;
    color: #2b2b2b;
    display: flex;
    justify-content: space-between;

    & span{
      color: #EE6160;
    }
  }

  &:focus-within: {
    border: ${focusBorder()};
  }
`

export const StyledSelect = styled.div<StyledSelectProps>`
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  overflow: hidden;
  align-items: center;

  height: auto;
  width: 100%;
  position: relative;

  & select {
    width: 100%;
    min-height: 24px;
    border-radius: 4px;
    border: 1px solid ${(props) => validColor(props.valid)};

    line-height: 1.4;
    padding: 12px ${(props) => props.theme.spacing.two};
    font-size: 16px;
    margin: 0;
    -webkit-appearance: none;
    color: black;
  }

  & select:focus {
    outline: none;
  }
`
export const StyledSelectIcons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  pointer-events: none;
  top: 0;
  height: 100%;

  & svg {
    height: 1.6rem;
    margin: 0 ${(props) => props.theme.spacing.two} 0 0;
  }
`