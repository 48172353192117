import styled from "styled-components";

import rust from "../../../assets/Graphics/ClientStoryCards/RustBG.svg";
import scribble from "../../../assets/Graphics/ClientStoryCards/Scribble.svg";
import taupe from "../../../assets/Graphics/ClientStoryCards/TaupeBG.svg";

interface Props {
  showHover: boolean;
}

export const StoryCardWrapper = styled.div<Props>`
  height: 384px;
  border-radius: 4px;
  display: ${(props) => (props.showHover ? "none" : "flex")};
  flex-direction: column;
  background: white;

  @media screen and (max-width: 1024px) {
    height: auto;
    display: flex;
  }
`;

export const StoryCardMobileLinkWrapper = styled.a`
  cursor: pointer;
  flex: 1;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}) {
    pointer-events: none;
    cursor: default;
  }
`;

export const StoryCardDip = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.two} ${(props) => props.theme.spacing.three};

  svg {
    margin-bottom: 4px;
    align-self: flex-end;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 80px;
  }
`;

export const StoryCardImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;

  picture {
    display: block;
    height: 0;
  }
`;

export const StoryCardHeading = styled.h6`
  margin: 0;
  max-width: 90%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StoryCardBody = styled.p`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

export const StoryCardHoverBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.two};
  margin-top: ${(props) => props.theme.spacing.one};

  p {
    margin: 0;
  }
`;

export const StoryCardLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const StoryCardHoverWrapper = styled.div<Props>`
  display: ${(props) => (props.showHover ? "flex" : "none")};
  height: 384px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-end;
  background: white;
  padding: 0 ${(props) => props.theme.spacing.three} ${(props) => props.theme.spacing.two};
  background: no-repeat url(${scribble}), no-repeat url(${taupe}), no-repeat url(${rust}), white;
  background-origin: border-box;
  background-position: 10% 17%, bottom left, bottom right;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
