import React from "react";
import { sbLinkType } from "../../../types";
import { getUrl } from "../../utils/helpers";
import { StyledMenuItem } from "./partials";

export interface MenuItemProps {
  cta_label: string;
  cta_link: sbLinkType;
  onClick: (e: React.MouseEvent<Element, MouseEvent>, cta_link: sbLinkType) => void;
}

export const MenuItemCmp: React.FC<MenuItemProps> = ({
  cta_label,
  cta_link,
  onClick,
}) => {
  return (
    <StyledMenuItem
      href={getUrl(cta_link)}
      onClick={(e) => onClick(e, cta_link)}
    >
      {cta_label}
    </StyledMenuItem>
  );
};
