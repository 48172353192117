import React from "react";

import { sbAssetType, sbLinkType } from "../../types";

import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";

import {
  StyledLinkCardWImageCard,
  StyledLinkCardWImageCardImg,
  StyledLinkCardWImageCardLink,
} from "./partials";
import { sbImageService } from "hult-sb-ui";

export interface LinkCardWithImageItemProps {
  title: string;
  image: sbAssetType;
  link: sbLinkType;
}

export const LinkCardWithImageItem: React.FC<LinkCardWithImageItemProps> = ({
  title,
  image,
  link,
}) => {
  const url = link?.url ? link?.url : `/${link.cached_url}`;

  function handleClick() {
    window.dataLayer.push({
      event: "link_click",
      eventMeta: {
        p_one: "link-card-with-image-item",
        p_two: title,
        p_three: url,
      },
    });
  }

  return (
    <StyledLinkCardWImageCard>
      <Surface
        as="a"
        href={link?.url ? link?.url : `/${link.cached_url}`}
        deepShadow
        rounded
        onClick={(_) => handleClick()}
      >
        <StyledLinkCardWImageCardImg
          src={sbImageService(image.filename, undefined, "300x0", image.focus)}
          alt={image.alt}
          title={image?.title}
        />
        <StyledLinkCardWImageCardLink>
          <p>{title}</p>
          <IconArrowRight />
        </StyledLinkCardWImageCardLink>
      </Surface>
    </StyledLinkCardWImageCard>
  );
};
