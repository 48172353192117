import React from "react";
import { FacebookShareButton } from "react-share";
import IconSocialFbAlt from "@ef-global/web-ui-react/lib/icons/IconSocialFbAlt";

interface FacebookShareProps {
  share_url: string;
}

const FacebookShareCmp: React.FC<FacebookShareProps> = ({ share_url }) => (
  <FacebookShareButton url={share_url}>
    <IconSocialFbAlt />
  </FacebookShareButton>
);

export default FacebookShareCmp;
