import styled from "styled-components";

export const StyledTextTwoColumnsItem = styled.div``;

export const StyledTextTwoColumnsItemHeader = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledTextTwoColumnsItemBody = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;
`;
