import styled, { css } from "styled-components";

interface StyledUpcomingCohortsWrapperProps {
  dGraphic?: boolean;
  mGraphic?: boolean;
}

export const StyledUpcomingCohortsWrapper = styled.div<StyledUpcomingCohortsWrapperProps>`
  position: relative;
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};
  padding: ${({ theme, dGraphic }) =>
    dGraphic
      ? `${theme.spacing.nine} 0 ${theme.spacing.eight} 0;`
      : `${theme.spacing.eight} 0;`}
  margin: ${({ theme }) => theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
      padding: ${({ theme, dGraphic }) =>
        dGraphic
          ? `${theme.spacing.nine} ${theme.spacing.three} ${theme.spacing.eight} ${theme.spacing.three}; `
          : `${theme.spacing.eight} ${theme.spacing.three};`}
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${({ theme, mGraphic }) =>
      mGraphic
        ? `${theme.spacing.eight} ${theme.spacing.three} ${theme.spacing.seven} ${theme.spacing.three} ;`
        : `${theme.spacing.seven} ${theme.spacing.three};`}
    grid-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledUpcomingCohorts = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  & h2 {
    font-size: 32px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.four};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 24px;
    }
  }

  // & a {
  //   display: flex;
  //   position: relative;
  //   cursor: pointer;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 0 auto;
  //   position: relative;
  //   font-size: 14px;
  //   width: fit-content;
  //   overflow: hidden;
  //   text-align: center;
  //   margin-top: ${(props) => props.theme.spacing.four};

  //   & svg {
  //     margin-left: ${({ theme }) => theme.spacing.two};
  //   }
  // }

  > a {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: ${(props) => props.theme.spacing.four};

    & svg {
      margin-left: ${({ theme }) => theme.spacing.two};
    }
  }

  > a:before,
  > a:after {
    cursor: initial;
    content: "";
    border-top: 1px solid rgba(25, 25, 25, 0.3);
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  > a:after {
    margin: 0 0 0 20px;
  }
`;

interface StyledCohortCardsProps {
  isOpen?: boolean;
  maxHeight?: string;
  hiddenDiv?: boolean;
}

export const StyledUpcomingCohortCards = styled.div<StyledCohortCardsProps>`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.three};
  grid-template-columns: 1fr 1fr;
  overflow: hidden;

  transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;

  max-height: ${(props) =>
    props.isOpen
      ? props.maxHeight
        ? props.maxHeight
        : props.maxHeight
      : "160px"};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-gap: ${({ theme }) => theme.spacing.two};

    max-height: ${(props) =>
      props.isOpen
        ? props.maxHeight
          ? props.maxHeight
          : props.maxHeight
        : "322px"};
  }
`;

interface StyledCohortsGrapicOverlayProps {
  placement?: string;
  mobile?: boolean;
}

export const StyledCohortsGrapicOverlay = styled.img<StyledCohortsGrapicOverlayProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: contain;

  ${(props) =>
    props.placement === "top" &&
    css`
      top: -1px;
      -webkit-transform: rotate(-180deg); /* Safari */
      -moz-transform: rotate(-180deg); /* Firefox 3.6 Firefox 4 */
      -ms-transform: rotate(-180deg); /* IE9 */
      -o-transform: rotate(-180deg); /* Opera */
      transform: rotate(-180deg); /* W3C */
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.mobile ? "block" : "none")};
  }
`;

export const StyledUpcomingCohortsBottom = styled.div`
  margin-top: ${(props) => props.theme.spacing.four};
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a {
    display: flex;
    align-items: center;

    float: right;
    font-size: 14px;

    & svg {
      height: 16px;
      width: 16px;

      margin-right: ${(props) => props.theme.spacing.two};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 100%;

    & a {
      justify-content: center;
      float: initial;
      margin-top: ${(props) => props.theme.spacing.three};
    }
  }
`;

export const StyledUpcomingCohortsBottomBtnGroup = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-flow: row;
  grid-auto-flow: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-auto-flow: row;

    & button {
      width: 100%;
    }
`;
