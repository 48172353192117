import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FeatureStatCmp, FeatureStatProps } from "./FeatureStat";

interface FeatureStatWBlokProps {
  blok: FeatureStatProps & blokType;
}

export const FeatureStat: React.FC<FeatureStatWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FeatureStatCmp
        title={blok.title}
        sub_header={blok.sub_header}
        stat_items={blok.stat_items}
      ></FeatureStatCmp>
    </SbEditable>
  );
};
