import styled from "styled-components";

interface StyledGateProps {
  formVisible?: boolean;
}

export const StyledGate = styled.div<StyledGateProps>`
  display: ${({ formVisible }) => (formVisible ? "none" : "block")};
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledGateWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};

  & h4 {
    font-size: 32px;
    font-weight: ${({ theme }) => theme.font.bold};
    margin-bottom: ${({ theme }) => theme.spacing.one};
    max-width: 550px;
  }

  & p {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.font.light};
    margin-bottom: ${({ theme }) => theme.spacing.three};
    max-width: 550px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & h4 {
      font-size: 24px;
    }
  }
`;

export const StyledGateBtnGroup = styled.div`
  display: flex;
  flex-flow: row;

  & button {
    margin-right: ${({ theme }) => theme.spacing.three};
  }
`;

export const StyledGateForm = styled.div<StyledGateProps>`
  display: ${({ formVisible }) => (formVisible ? "block" : "none")};
`;
