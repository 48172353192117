import styled from "styled-components";

export const StyledFeatureList = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFeatureListWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledFeatureListHeader = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  max-width: 560px;
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledFeatureListItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: ${(props) => props.theme.spacing.six};
  column-gap: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    row-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledFeatureListBody = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: ${(props) => props.theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;
