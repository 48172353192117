import React from "react";
import { FeatureListItem, FeatureListItemProps } from "./FeatureListItem";

import {
  StyledFeatureList,
  StyledFeatureListWrapper,
  StyledFeatureListHeader,
  StyledFeatureListBody,
  StyledFeatureListItemsWrapper,
} from "./partials";

export interface FeatureListProps {
  title: string;
  description: string;
  features: Array<FeatureListItemProps>;
}

export const FeatureListCmp: React.FC<FeatureListProps> = ({
  title,
  description,
  features,
}) => {
  return (
    <StyledFeatureList>
      <StyledFeatureListWrapper>
        <StyledFeatureListHeader>{title}</StyledFeatureListHeader>
        <StyledFeatureListBody>{description}</StyledFeatureListBody>
        <StyledFeatureListItemsWrapper>
          {features.map((item, idx) => (
            <FeatureListItem
              key={idx}
              title={item.title}
              body={item.body}
              icon={item.icon}
            />
          ))}
        </StyledFeatureListItemsWrapper>
      </StyledFeatureListWrapper>
    </StyledFeatureList>
  );
};
