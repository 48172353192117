import React from "react";
import { TwitterShareButton } from "react-share";
import IconSocialTw from "@ef-global/web-ui-react/lib/icons/IconSocialTw";

interface TwitterShareProps {
  share_url: string;
}

const TwitterShareCmp: React.FC<TwitterShareProps> = ({ share_url }) => (
  <TwitterShareButton url={share_url}>
    <IconSocialTw />
  </TwitterShareButton>
);

export default TwitterShareCmp;
