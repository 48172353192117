import React, { useState } from "react";

import {
  StyledOPHeaderContent,
  StyledOPHeaderContentQualifier,
  StyledOPHeaderContentTitle,
  StyledOPHeaderContentPara,
  StyledOPHeaderContentParaContainer,
  StyledOPHeaderContentIntro,
} from "./styles/op-header-content.styles";
import { ButtonHEF } from "../../Button";
import { Modal } from "../../Modal";
import { LargeVideoCmp } from "../../LargeVideo/LargeVideo";

export interface IHeaderContent {
  title: string;
  qualifier: string;
  introduction: string;
  video?: Array<{ button_label: string; video_link: string }>;
  paragraphs: Array<{ title: string; body: string }>;
}

export const HeaderContent: React.FC<IHeaderContent> = ({
  title,
  qualifier,
  introduction,
  paragraphs,
  video,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <StyledOPHeaderContent>
        <StyledOPHeaderContentQualifier>
          {qualifier}
        </StyledOPHeaderContentQualifier>
        <StyledOPHeaderContentTitle>{title}</StyledOPHeaderContentTitle>
        <StyledOPHeaderContentIntro>
          <StyledOPHeaderContentPara>{introduction}</StyledOPHeaderContentPara>
          {Array.isArray(video) && video.length > 0 && (
            <ButtonHEF
              variant="secondary"
              label={video[0].button_label}
              onClick={(_: void) => setOpenModal(true)}
              icon="IconPlay"
            />
          )}
        </StyledOPHeaderContentIntro>

        {Array.isArray(paragraphs) &&
          paragraphs.map((pg) => {
            return (
              <StyledOPHeaderContentParaContainer>
                <h2>{pg.title}</h2>
                <StyledOPHeaderContentPara>{pg.body}</StyledOPHeaderContentPara>
              </StyledOPHeaderContentParaContainer>
            );
          })}
      </StyledOPHeaderContent>
      {openModal && Array.isArray(video) && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          {openModal && (
            <LargeVideoCmp video_link={video[0].video_link} autoplay />
          )}
        </Modal>
      )}
    </>
  );
};
