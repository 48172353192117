import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { AllStoryblokEntryResult, blokType, storyType } from "../../types";
import { Form, FormProps } from "./Form";
import { fieldPrefill } from "./FormGeneral";
import { StyledFormContainer } from "./partials";

export interface FormStoryProps extends storyType {
  content: blokType & FormProps;
}

export interface FormSCProps extends blokType {
  form_handler: string;
  qualifier: string;
  title: string;
  form: string;
  onClose?: any;
  thank_you_components: Array<blokType>;
  close_on_submit?: boolean;
  event_date?: string;
  event_duration?: string;
  event_location?: string;
  op_price?: string;
  prefills?: Array<fieldPrefill>;
  onSubmit?: any;
  sf_campaign_id?: string;
}

export const FormSC: React.FC<FormSCProps> = ({
  form_handler,
  form,
  title,
  qualifier,
  event_date,
  event_location,
  event_duration,
  op_price,
  onClose,
  thank_you_components,
  close_on_submit,
  prefills,
  onSubmit,
  sf_campaign_id
}) => {
  function parseQueryResult(data: AllStoryblokEntryResult, id: string): any {
    let parsedResult: blokType;

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: blokType = node.content;
      if (typeof node.content === "string") content = JSON.parse(node.content);
      if (node.uuid == id) {
        parsedResult = content;
      }
    });

    return parsedResult;
  }

  function handleSubmit(close?: boolean) {
    if (close ) {
      onClose();
    }

    if (onSubmit) {
      onSubmit()
    }
  }

  const titleContent = {
    title: title,
    qualifier: qualifier,
    event_date: event_date,
    event_duration: event_duration,
    event_location: event_location,
    op_price: op_price,
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allStoryblokEntry(filter: { field_component: { eq: "form" } }) {
            edges {
              node {
                uuid
                id
                content
              }
            }
            totalCount
          }
        }
      `}
      render={(data) => {
        const parsedResult = parseQueryResult(data, form);

        return (
          <StyledFormContainer>
            {parsedResult && (
              <Form
                form_handler={form_handler}
                sf_campaign_id={sf_campaign_id}
                prefills={prefills}
                form={parsedResult}
                thank_you_components={thank_you_components}
                close_on_submit={close_on_submit}
                titleContent={titleContent}
                onSubmit={(close?: boolean) => handleSubmit(close)}
              />
            )}
          </StyledFormContainer>
        );
      }}
    />
  );
};
