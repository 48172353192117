import React from "react";

import DynamicComponent from "../../";
import CookieContext, { CookieContextProps } from "../../../context/CookieContext";
import { BaseComponentProps, blokType, sbAssetType } from "../../../types";
import { ArticleHeadlineProps } from "../../ArticleHeadline/ArticleHeadline";
import { CaseStudyHeroProps } from "../../CaseStudyHero/CaseStudyHero";
import { Gate } from "../../ContentGate";
import { Footer } from "../../Footer";
import { FullBleedImageHeroProps } from "../../FullBleedImgHero/FullBleedImageHero";
import Seo from "../../utils/seo";
import { StyledBodyContainer } from "./partials";

export interface ArticleContent extends blokType {
  secondary_navigation?: Array<blokType>;
  header?: Array<blokType & (ArticleHeadlineProps & CaseStudyHeroProps & FullBleedImageHeroProps)>;
  content?: Array<blokType>;
  article_share?: Array<blokType>;
  article_author?: Array<blokType>;
  further_info?: Array<blokType>;
  article_overview?: Array<blokType>;
  meta_data?: Array<blokType>;
  preview_image?: sbAssetType;
  contact_us?: Array<blokType>;
  gated?: boolean;
}
interface ArticleProps extends BaseComponentProps {
  blok: ArticleContent;
}

const Article: React.FC<ArticleProps> = ({ blok, story, alternates }) => {
  const { data } = React.useContext(CookieContext) as CookieContextProps;

  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      <StyledBodyContainer>
        {blok?.secondary_navigation?.map((navBlok) => {
          return <DynamicComponent key={navBlok._uid} blok={navBlok} />;
        })}

        {blok?.header?.map((headerBlok) => {
          return <DynamicComponent key={headerBlok._uid} blok={headerBlok} />;
        })}
        {blok?.article_share?.map((shareBlok) => {
          return (
            <DynamicComponent
              key={shareBlok._uid}
              blok={shareBlok}
              path={story?.slug === "home" ? "" : story?.full_slug}
            />
          );
        })}
        {blok?.content?.map((contentBlok, idx) => {
          if (blok.gated && !data?.gateClose && idx > 0) return;
          return <DynamicComponent key={contentBlok._uid} blok={contentBlok} />;
        })}
        {blok.gated && !data?.gateClose && <Gate />}
        {(blok.gated && data?.gateClose) ||
          (!blok.gated && (
            <>
              {blok?.article_author?.map((authorBlok) => {
                return <DynamicComponent key={authorBlok._uid} blok={authorBlok} />;
              })}
              {blok?.further_info?.map((furtherBlok) => {
                return <DynamicComponent key={furtherBlok._uid} blok={furtherBlok} />;
              })}
              {blok?.article_overview?.map((afterBlok) => {
                return <DynamicComponent key={afterBlok._uid} blok={afterBlok} />;
              })}
              {blok?.contact_us?.map((contactBody) => {
                return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
              })}
            </>
          ))}
      </StyledBodyContainer>
      <Footer alternatives={alternates} />
    </>
  );
};

export default Article;
