import React from "react";
import { blokType } from "../../types";

import { ArticleAuthorCmp, ArticleAuthorBlokProps } from "./ArticleAuthor";

export interface ArticleAuthorProps {
  blok: blokType & ArticleAuthorBlokProps;
}

export const ArticleAuthor: React.FC<ArticleAuthorProps> = ({ blok }) => (
  <ArticleAuthorCmp
    image={blok.image}
    author_name={blok.author_name}
    position_and_company={blok.position_and_company}
    bio={blok.bio}
    read_more_label={blok.read_more_label}
  />
);

export default ArticleAuthor;
