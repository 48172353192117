import React from "react";
// @ts-ignore
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import IconCheck from "@ef-global/web-ui-react/lib/icons/IconCheck";
import { uniqueId } from "lodash";

import { StyledCheckbox, StyledCheckboxWrapper } from "./partials";

const resolver = new RichTextResolver();

interface CheckboxProps {
  id: string;
  section_id: number;
  label?: string;
  name?: string;
  disable?: boolean;
  hidden?: boolean;
  value?: boolean;
  onChange?: any;
  checked?: boolean;
  required?: boolean;
  isValid?: boolean;
  richText?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  section_id,
  value = "",
  name = "",
  label = "",
  onChange = () => {},
  disable = false,
  hidden = false,
  checked = false,
  required = false,
  isValid = undefined,
  richText,
}) => {
  const htmlId = uniqueId(id);

  function handleClick() {
    // !value is used as its been toggled by the user
    const valid = required ? required && !value : true;
    onChange(id, { value: !value, valid: valid, section_id: section_id });
  }

  const showValidStyle = required ? isValid : undefined;

  return (
    <StyledCheckboxWrapper>
      <StyledCheckbox valid={showValidStyle} checked={checked}>
        <div onClick={handleClick}>
          <input
            id={htmlId}
            type="checkbox"
            required={required}
            disabled={disable}
            hidden={hidden}
            checked={checked}
            name={name}
            onChange={() => void 0}
          />
          {value && <IconCheck />}
        </div>
        {label && !richText && <label htmlFor={htmlId}>{label}</label>}
        {richText && (
          <label
            htmlFor={htmlId}
            dangerouslySetInnerHTML={{
              __html: resolver.render(JSON.parse(label)),
            }}
          ></label>
        )}
        {required && " *"}
      </StyledCheckbox>
    </StyledCheckboxWrapper>
  );
};
