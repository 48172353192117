import React, { useState } from "react";
import { blokType } from "../../types";
import { FormGeneral, FormGeneralProps, fieldPrefill } from "./FormGeneral";
import IconCalendarOutline from "@ef-global/web-ui-react/lib/icons/IconCalendarOutline";
import IconLocationOutline from "@ef-global/web-ui-react/lib/icons/IconLocationOutline";
import IconPriceOutline from "@ef-global/web-ui-react/lib/icons/IconPriceOutline";
import IconAcademicMaterial from "@ef-global/web-ui-react/lib/icons/IconAcademicMaterial";
import IconCourseDurationOutline from "@ef-global/web-ui-react/lib/icons/IconCourseDurationOutline";
import DynamicComponent from "../";

import {
  StyledFormHeaderContainer,
  StyledFormHeaderTitle,
  StyledFormHeaderQualifier,
  StyledFormHeaderEventDetailsContainer,
  StyledFormHeaderEventDetailsItem,
  StyledThankYouContainer,
} from "./partials";

export interface FormProps {
  form_handler: string;
  prefills?: Array<fieldPrefill>;
  form: blokType & FormGeneralProps;
  titleContent: any;
  onSubmit?: any;
  thank_you_components: Array<blokType>;
  close_on_submit?: boolean;
  sf_campaign_id?: string;
}

export const Form: React.FC<FormProps> = ({
  form_handler,
  form,
  titleContent,
  onSubmit,
  thank_you_components,
  close_on_submit,
  prefills,
  sf_campaign_id
}) => {
  const [submitted, setSubmitted] = useState(false);

  function handleSubmit() {
    setSubmitted(true);
    onSubmit(close_on_submit);
  }

  return (
    <>
      {!submitted && (
        <>
          <StyledFormHeaderContainer>
            <StyledFormHeaderQualifier>
              {titleContent.qualifier}
            </StyledFormHeaderQualifier>
            <StyledFormHeaderTitle>{titleContent.title}</StyledFormHeaderTitle>
            {(titleContent.event_date ||
              titleContent.event_duration ||
              titleContent.event_location) && (
              <StyledFormHeaderEventDetailsContainer>
                {titleContent.event_location && (
                  <StyledFormHeaderEventDetailsItem>
                    {titleContent.event_location.toLowerCase() ===
                      "virtual" && <IconAcademicMaterial />}
                    {titleContent.event_location.toLowerCase() !==
                      "virtual" && <IconLocationOutline />}

                    <span>{titleContent.event_location}</span>
                  </StyledFormHeaderEventDetailsItem>
                )}
                {titleContent.op_price && (
                  <StyledFormHeaderEventDetailsItem>
                    <IconPriceOutline />
                    <span>{titleContent.op_price}</span>
                  </StyledFormHeaderEventDetailsItem>
                )}

                {titleContent.event_date && (
                  <StyledFormHeaderEventDetailsItem>
                    <IconCalendarOutline />
                    <span>{titleContent.event_date}</span>
                  </StyledFormHeaderEventDetailsItem>
                )}
                {titleContent.event_duration && (
                  <StyledFormHeaderEventDetailsItem>
                    <IconCourseDurationOutline />
                    <span>{titleContent.event_duration}</span>
                  </StyledFormHeaderEventDetailsItem>
                )}
              </StyledFormHeaderEventDetailsContainer>
            )}
          </StyledFormHeaderContainer>
          <FormGeneral
            form_handler={form_handler}
            component={form.component}
            fields_section={form.fields_section}
            form_type={form.form_type}
            privacy={form.privacy}
            secondary_privacy={form.secondary_privacy}
            primary_colour={form.primary_colour}
            cta_label={form.cta_label}
            onSubmit={() => handleSubmit()}
            thank_you_components={thank_you_components}
            prefills={prefills}
            sf_campaign_id={sf_campaign_id}
          ></FormGeneral>
        </>
      )}

      {submitted && (
        <StyledThankYouContainer>
          {thank_you_components.map((blok) => {
            return (
              <DynamicComponent key={blok._uid} blok={blok} content={blok} />
            );
          })}
        </StyledThankYouContainer>
      )}
    </>
  );
};