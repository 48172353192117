import React, { useState, useEffect } from "react";
import IconLocation from "@ef-global/web-ui-react/lib/icons/IconLocation";
import Pagination from "../Overview/Pagination";
import queryString from "query-string";

import {
  StyledJobPostingWrapper,
  StyledTitle,
  StyledCardWrapper,
  StyledCard,
  StyledTop,
  StyledBottom,
  StyledCardTitle,
  StyledJobCategory,
  StyledLocation,
  StyledInfoContent,
  StyledPaginationText,
  StyledError,
} from "./partials";
import { JobRoleCmp } from "./JobRole";
import {
  StyledFilterFormGrid,
  StyledFilterFormWrapper,
} from "./partials/StyledFilterForm";
import { Input, Select } from "../FormInput";
import { Button } from "@ef-global/web-ui-react";

export interface JobPostingsProps {
  title: string;
  showPagination?: boolean;
  error_msg: string;
  job_title_label: string;
  job_title_placeholder: string;
  location_label: string;
  location_placeholder: string;
  category_label: string;
  category_placeholder: string;
  not_found_title: string;
  not_found_description: string;
  clear_filter_button: string;
}
export const JobPostingsCmp: React.FC<JobPostingsProps> = ({
  title,
  showPagination = true,
  error_msg,
  job_title_label,
  job_title_placeholder,
  location_label,
  location_placeholder,
  category_label,
  category_placeholder,
  not_found_title,
  not_found_description,
  clear_filter_button,
}) => {
  const containerRef = React.useRef<null | Element>(null);

  let site: null | Element = null;
  if (typeof document !== "undefined") {
    site = document.getElementById("site");
  }

  function handleScroll() {
    if (site && containerRef?.current) {
      site.scrollTo({
        top: containerRef?.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hultEfJobs, setHultEfJobs] = React.useState([]);
  const [filteredJobs, setFilteredJobs] = React.useState([]);
  const [hultEfJobsCategories, setHultEfJobsCategories] = React.useState([]);
  const [hultEfJobsLocations, setHultEfJobsLocations] = React.useState([]);
  const [showDetail, setShowDetail] = React.useState(false);
  const [viewPage, setViewPage] = React.useState(0);

  const [jobTitleFilter, setJobTitleFilter] = React.useState<
    string | undefined
  >(undefined);
  const [categoryFilter, setCategoryFilter] = React.useState<
    string | undefined
  >(undefined);
  const [locationFilter, setLocationFilter] = React.useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const query = queryString.parse(window.location.search);
      const id = query.jid;

      if (id !== undefined) {
        setShowDetail(true);
      }
    }

    fetch(`${process.env.GATSBY_EF_JOBS_API}?pageSize=1000`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${error_msg}`);
        }
        return response.json();
      })
      .then((actualData) => {
        const categories = [];
        const locations = [];
        const jobs = actualData.jobs
          .filter((job) => job.companyName === "Hult EF Corporate Education")
          .map((job) => ({
            ...job,
            categoryNames: job.categories.map((category) => category.title),
            locationNames: job.locations.map(
              (location) => `${location.city}, ${location.country}`
            ),
          }));

        jobs?.forEach((job) => {
          job?.categories?.forEach((category) =>
            categories.push(category.title)
          );
          job?.locations?.forEach((location) =>
            locations.push(`${location.city}, ${location.country}`)
          );
        });
        setError(null);
        setHultEfJobs(jobs);
        setFilteredJobs(jobs);
        setHultEfJobsCategories([...new Set(categories)]);
        setHultEfJobsLocations([...new Set(locations)]);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleClick(id) {
    window.location.href = `?jid=${id.replace("/job/", "")}`;
  }

  const showArticlesNumber = showPagination ? 12 : 3;
  const totalCount = filteredJobs.length;
  const totalPages = Math.ceil(totalCount / showArticlesNumber);
  const articleFrom = viewPage * showArticlesNumber;
  const articleTo = articleFrom + showArticlesNumber;

  React.useEffect(() => {
    setFilteredJobs(
      hultEfJobs.filter((job) => {
        let valid = true;
        if (jobTitleFilter) {
          if (!job.title.toLowerCase().includes(jobTitleFilter.toLowerCase()))
            valid = false;
        }
        if (categoryFilter) {
          if (!job.categoryNames.includes(categoryFilter)) valid = false;
        }
        if (locationFilter && locationFilter.length) {
          if (!job.locationNames.includes(locationFilter)) valid = false;
        }
        return valid;
      })
    );
    setViewPage(0);
  }, [jobTitleFilter, categoryFilter, locationFilter]);

  const handleClearFilter = () => {
    setCategoryFilter("");
    setJobTitleFilter("");
    setLocationFilter("");
  };

  return showDetail ? (
    <JobRoleCmp error_msg={error_msg} />
  ) : (
    <StyledJobPostingWrapper>
      <StyledTitle>{title}</StyledTitle>
      {hultEfJobs && (
        <StyledFilterFormWrapper>
          <StyledFilterFormGrid>
            <Input
              section_id={0}
              name="job_title"
              label={job_title_label}
              type="text"
              placeholder={job_title_placeholder}
              onChange={(event) => setJobTitleFilter(event.currentTarget.value)}
              value={jobTitleFilter}
            />
            <Select
              id="category"
              section_id={1}
              name="category"
              label={category_label}
              text={category_placeholder}
              onChange={(_, { value }) => setCategoryFilter(value)}
              value={categoryFilter}
            >
              {hultEfJobsCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </Select>
            <Select
              id="location"
              section_id={1}
              name="location"
              label={location_label}
              text={location_placeholder}
              onChange={(_, { value }) => setLocationFilter(value)}
              value={locationFilter}
            >
              {hultEfJobsLocations.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </Select>
          </StyledFilterFormGrid>
        </StyledFilterFormWrapper>
      )}
      {showPagination && filteredJobs.length > 0 && (
        <StyledPaginationText>
          Showing {articleFrom + 1}-
          {articleTo < totalCount ? articleTo : totalCount} of {totalCount}{" "}
          positions
        </StyledPaginationText>
      )}
      {loading && <div>A moment please...</div>}
      {error && <StyledError>{`${error}`}</StyledError>}
      {!loading && !error && !filteredJobs.length && (
        <>
          <h5>{not_found_title || "Not found title"}</h5>
          <p>{not_found_description || "Not found description"}</p>
          <Button onClick={handleClearFilter} secondary small>
            {clear_filter_button || "clear filters"}
          </Button>
        </>
      )}
      <StyledCardWrapper ref={containerRef}>
        {filteredJobs &&
          filteredJobs.slice(articleFrom, articleTo).map((job) => (
            <StyledCard
              key={job.jobviteId}
              onClick={() => handleClick(job.jobUrl)}
            >
              <StyledTop>
                <StyledCardTitle>{job.title}</StyledCardTitle>
                <StyledInfoContent>
                  {job.jobType ? (
                    <>
                      <li>{job.jobType}</li>
                      <li>{job.experienceLevel}</li>
                    </>
                  ) : (
                    <li>{job.experienceLevel}</li>
                  )}
                </StyledInfoContent>
              </StyledTop>
              <StyledBottom>
                <StyledJobCategory>
                  {job.categories &&
                    job.categories.map((category) => <p>{category.title}</p>)}
                </StyledJobCategory>
                {job.locations &&
                  job.locations.map((location) => (
                    <StyledLocation>
                      <IconLocation /> {location.city}, {location.country}
                    </StyledLocation>
                  ))}
              </StyledBottom>
            </StyledCard>
          ))}
        {showPagination && filteredJobs.length > showArticlesNumber && (
          <Pagination
            breakLabel={"..."}
            pageCount={totalPages}
            pageRangeDisplayed={11}
            marginPagesDisplayed={1}
            page={viewPage}
            onPageChange={(e) => {
              handleScroll();
              setViewPage(e);
            }}
          />
        )}
      </StyledCardWrapper>
    </StyledJobPostingWrapper>
  );
};
