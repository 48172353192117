import styled from "styled-components";

interface StyledFilterAccordionProps {
  open: boolean
  headerHeight: number
  bodyHeight: number
}
interface StyledFilterAccordionHeaderProps {
  open: boolean
}

export const StyledFilterAccordion = styled.div<StyledFilterAccordionProps>`
  overflow: hidden;
  transition: .2s;
  border-bottom: 1px solid rgba(25, 25, 25, 20%);
  
  ${props => props.open ? `
    height: calc(${props.headerHeight}px + ${props.bodyHeight}px + 16px);
    ` : `
    height: ${props.headerHeight}px;
    `}

@media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
  margin: 0 ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFilterAccordionHeader = styled.header<StyledFilterAccordionHeaderProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.three} 0;
  
  & p{
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.m};
    font-weight: ${(props) => props.theme.font.bold};
  }
  & svg{
    ${props => props.open ? ` transform: rotate(180deg);` : `transform: rotate(0deg);`}
  }
`;

export const StyledFilterAccordionBody = styled.div`
  text-transform: capitalize;
`;

