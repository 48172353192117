import styled from "styled-components";

export const StyledArticleAuthorModalContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  max-width: ${(props) => props.theme.maxWidth};
  margin: 140px auto;

  display: grid;
  grid-template-columns: 1fr minmax(auto, 500px);
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 ${(props) => props.theme.spacing.three} 0
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-flow: column-reverse;
    margin: 84px auto;
    grid-gap: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledArticleAuthorModalContentText = styled.div``;

export const StyledArticleAuthorModalContentImg = styled.img`
  height: 263px;
  width: 468px;
  object-fit: cover;
  border-radius: 4px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 242px;
    max-width: 468px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledArticleAuthorModalContentHeader = styled.p`
  font-size: 32px;
  margin-bottom: ${(props) => props.theme.spacing.one};
  font-weight: ${(props) => props.theme.font.bold};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledArticleAuthorModalContentBody = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  font-weight: ${(props) => props.theme.font.light};
`;
