import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { HomeHeroCmp, HomeHeroProps } from "./HomeHero";

interface HomeHeroWBlokProps {
  blok: HomeHeroProps & blokType;
}

export const HomeHeroImage: React.FC<HomeHeroWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HomeHeroCmp
        header={blok.header}
        body={blok.body}
        image={blok.image}
        cta_label={blok.cta_label}
        cta_colour={blok.cta_colour}
      ></HomeHeroCmp>
    </SbEditable>
  );
};
