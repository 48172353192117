import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  LandingPageSectionCmp,
  LandingPageSectionProps,
} from "./LandingPageSection";

interface LandingPageSectionWBlokProps {
  blok: LandingPageSectionProps & blokType;
}

export const LandingPageSection: React.FC<LandingPageSectionWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <LandingPageSectionCmp
        section={blok.section}
        custom_id={blok.custom_id}
        id={blok.id}
      ></LandingPageSectionCmp>
    </SbEditable>
  );
};
