import * as React from "react";
import { StyledBanner } from "./partials";

import { sbLinkType } from "../../types";
import { getUrl } from "../utils/helpers";

interface IBannerProps {
  banner_notice?: string;
  banner_link: sbLinkType;
}

export const BannerCmp: React.FunctionComponent<IBannerProps> = ({
  banner_notice,
  banner_link,
}) => {
  return (
    <StyledBanner
      id="banner"
      href={banner_link ? getUrl(banner_link) : undefined}
      hoverLink={banner_link ? true : false}
    >
      <p>{banner_notice}</p>
    </StyledBanner>
  );
};
