import React from "react";

import {
  StyledTextTwoColumnsItem,
  StyledTextTwoColumnsItemHeader,
  StyledTextTwoColumnsItemBody,
} from "./partials";

export interface TextTwoColumnsItemProps {
  title: string;
  description: string;
}

export const TextTwoColumnsItem: React.FC<TextTwoColumnsItemProps> = ({
  title,
  description,
}) => {
  return (
    <StyledTextTwoColumnsItem>
      <StyledTextTwoColumnsItemHeader>{title}</StyledTextTwoColumnsItemHeader>
      <StyledTextTwoColumnsItemBody>{description}</StyledTextTwoColumnsItemBody>
    </StyledTextTwoColumnsItem>
  );
};
