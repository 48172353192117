import styled from "styled-components";

export const StyledOpenProgramsHeadline = styled.div`
  position: relative;
  display: block;

  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledOpenProgramsHeadlineContent = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledOpenProgramsHeadlineHeader = styled.h1`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 48px;
  font-weight: ${({ theme }) => theme.font.bold};
  margin-bottom: ${({ theme }) => theme.spacing.one};
  max-width: 65%;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
    max-width: 100%;
  }
`;

export const StyledOpenProgramsHeadlineQualifier = styled.p`
  color: ${({ theme }) => theme.colors.warmths.intensity};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.book};
  margin-bottom: ${({ theme }) => theme.spacing.two};
  max-width: 65%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;

export const StyledOpenProgramsHeadlineDesc = styled.p`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.light};
  margin-bottom: 0;
  max-width: 65%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;
