import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { ArticleImageCmp, ArticleImageProps } from "./ArticleImage";

interface ArticleImageWBlokProps {
  blok: ArticleImageProps & blokType;
}

export const ArticleImage: React.FC<ArticleImageWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ArticleImageCmp
        image={blok.image}
        video={blok.video}
        video_preview={blok.video_preview}
        video_link={blok.video_link}
        caption={blok.caption}
      ></ArticleImageCmp>
    </SbEditable>
  );
};
