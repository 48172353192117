import styled from "styled-components";

export const StyledFeatureStatItem = styled.div`
  max-width: 386px;
`;

export const StyledFeatureStatItemHeader = styled.p`
  font-size: 80px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 56px;
  }
`;

export const StyledFeatureStatItemBody = styled.p`
  font-weight: ${(props) => props.theme.font.light};
`;
