import React from "react";

import { StatItemDescription, StatItemTitle, StatItemWrapper } from "./styles/StyledFeatureStatTwoItem";

export interface FeatureStatTwoItemProps {
  title: string;
  description: string;
  index: number;
}

export const FeatureStatTwoItem: React.FC<FeatureStatTwoItemProps> = ({ title, description, index }) => {
  return (
    <StatItemWrapper index={index}>
      <StatItemTitle>{title}</StatItemTitle>
      <StatItemDescription>{description}</StatItemDescription>
    </StatItemWrapper>
  );
};
