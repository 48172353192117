import styled from "styled-components";

interface StyledCallOutMessageProps {
  background?: "heritage" | "intensity";
}

export const StyledCallOutMsg = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledCallOutMsgContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  position: relative;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledDesktopBackground = styled.img`
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledMobileShape = styled.img`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const StyledScribble = styled.img`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    position: absolute;
    right: 2px;
    top: 0;
    z-index: 10;
  }
`;

export const StyledTextContainer = styled.div<StyledCallOutMessageProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    position: static;
    transform: translateY(0%);
    grid-template-columns: repeat(4, [col-start] 1fr);
    gap: 16px;
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
    background: ${(props) =>
      props.background === "heritage"
        ? props.theme.colors.warmths.heritage
        : props.theme.colors.warmths.intensity};
  }
`;

export const StyledText = styled.h1`
  display: grid;
  grid-column: col-start 2 / span 8;
  width: 100%;
  margin-bottom: 0;
  font-size: 32px;
  color: ${(props) => props.theme.colors.neutrals.white};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 920px) {
    font-size: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    -webkit-line-clamp: 7;
    grid-column: col-start 1 / span 4;
  }
`;

export const StyledSpeckle = styled.img`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;
