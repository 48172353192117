import React from "react";
// @ts-ignore
import * as Icons from "@ef-global/web-ui-react/lib/icons/all";

import { sbAssetType } from "../../types";

import { StyledFeatureListItemIcon } from "./partials";

export interface FeatureListItemIconProps {
  gud_icon: string;
  custom_svg: sbAssetType;
  background_color: "heritage" | "copper" | "intensity" | undefined;
}

export const FeatureListItemIcon: React.FC<FeatureListItemIconProps> = ({
  gud_icon,
  custom_svg,
  background_color,
}) => {
  const IconComp = gud_icon ? Icons[gud_icon] : undefined;

  return (
    <StyledFeatureListItemIcon background_color={background_color}>
      {typeof IconComp !== "undefined" && <IconComp />}
      {typeof IconComp == "undefined" && custom_svg && (
        <img
          src={custom_svg.filename}
          alt={custom_svg.alt}
          title={custom_svg?.title}
        />
      )}
    </StyledFeatureListItemIcon>
  );
};
