import React from "react";
import { BaseComponentProps } from "../../types";

const ComponentNotFound: React.FC<BaseComponentProps> = ({ blok }) => {
  // if (process.env.NODE_ENV === "development")
    return (
      <div>
        Component {blok.component} is not defined. Add it to components.js
      </div>
    );
  // return null;
};

export default ComponentNotFound;
