import styled, { css } from "styled-components";

import { hefBrandColors } from "../../../types";

interface ButtonProps {
  color?: hefBrandColors | "black";
  as?: "a" | "button";
  full_width?: boolean;
  variant?: "primary" | "secondary";
  disabled?: boolean;
  icon?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 48px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: ${({ icon }) => (icon ? "4px 4px 4px 24px" : "16px 32px")};
  line-height: 16px;
  white-space: nowrap;
  width: ${(props) => (props.full_width ? "100%" : "min-content")};
  color: ${(props) => props.theme.colors.neutrals.white};

  border-radius: 24px;

  background: ${(props) =>
    props.color ? props.theme.colors.warmths[props.color] : props.theme.colors.warmths.heritage};

  &:hover {
    box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
  }

  &:focus {
    box-shadow: 0 2px 8px
      ${(props) => (props.color ? props.theme.colors.warmths[props.color] : props.theme.colors.warmths.heritage)};
  }

  :disabled {
    opacity: 0.6;
    box-shadow: none;
  }

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: white;
      color: black;
      border: 1px solid rgba(25, 25, 25, 0.2);

      &:hover {
        box-shadow: 0 3px 8px 0 rgb(25 25 25 / 24%);
        border: 1px solid rgba(25, 25, 25, 0.13);
      }
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    min-height: 48px;
    border-radius: 24px;
    padding: 16px 32px;
    line-height: 16px;
  }
`;

export const StyledIconContainer = styled.div`
  background: ${({ theme }) => theme.colors.warmths.heritage};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.neutrals.white};

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;
