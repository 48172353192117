import styled from "styled-components";

export const ImpactBoxWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.spacing.one};
  background: #2f2f2f;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const ImpactBoxTitle = styled.h4`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutrals.white};
`;

export const ImpactBoxBody = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutrals.white};
  hyphens: auto;
`;
