import React, { useEffect, useState } from "react"

// @ts-ignore
import countryTelephoneCode from "country-telephone-code"

import CookieContext from "../../context/CookieContext"
import { blokType, hefBrandColors } from "../../types"
import { ButtonHEF } from "../Button"
import { getKey } from "../utils/helpers"
import { FormField, FormFieldProps } from "./FormField"
import { FormFieldSection } from "./FormFieldSection"
import { StyledForm, StyledFormFields } from "./partials"
import { cookiePrefill, initialFields } from "./utils/index"

export interface fieldPrefill {
  name: string
  value: string
}

function prefillFormFields(sections: Array<any>, prefills: Array<fieldPrefill>) {
  const parsedSections: Array<any> = []

  sections.forEach((s, section_idx) => {
    const parsedFields: Array<any> = []
    if (s.fields) {
      s.fields.forEach((f: FormFieldProps) => {
        let prefill = undefined

        if (!prefill && f.cookie) {
          prefill = cookiePrefill(f.cookie)
        }

        if (!prefill && f.default) {
          prefill = f.default
        }

        const defPref = prefills.filter((e) => e.name === f.name)
        if (defPref.length > 0) {
          prefill = defPref[0].value
        }

        const isValid = prefill ? true : null

        const field = {
          ...f,
          id: f._uid,
          value: prefill,
          valid: isValid,
          default: prefill,
          section_id: section_idx,
        }

        /* Required field and no prefill from cookie or salesforce, so ensure its not hidden */
        if (f.required && !prefill) {
          field.hidden = false
        }

        // If field type telephone add additional properties
        if (f.type === "tel") {
          const countryCookie = cookiePrefill("uCountry")
          if (countryCookie) {
            field.countryCode = `+${countryTelephoneCode(countryCookie)}`
            field.isoCountry = countryCookie
          } else {
            field.countryCode = "+1"
            field.isoCountry = "US"
          }
        }

        parsedFields.push(field)
      })
      parsedSections.push({ ...s, fields: parsedFields })
    } else {
      parsedSections.push({ ...s })
    }
  })

  return parsedSections
}

export interface FormGeneralProps {
  form_handler: string
  component?: string
  _uid?: string
  fields_section: Array<FormFieldProps>
  form_type: string
  privacy: FormFieldProps
  secondary_privacy?: FormFieldProps
  primary_colour?: hefBrandColors
  cta_label?: string
  onSubmit?: any
  thank_you_components: Array<blokType>
  close_on_submit?: boolean
  prefills?: Array<fieldPrefill>
  sf_campaign_id?: string;
}

export const FormGeneral: React.FC<FormGeneralProps> = ({
  form_handler,
  component = "form",
  fields_section,
  form_type,
  privacy,
  secondary_privacy,
  primary_colour,
  cta_label,
  onSubmit,
  prefills = [],
  sf_campaign_id
}) => {
  const [sections, setFieldSections] = useState(initialFields(fields_section, [privacy, secondary_privacy]))
  const [startedForm, setStartedForm] = useState(false)
  const { getUtms } = React.useContext(CookieContext)
  useEffect(() => {
    if (typeof window !== "undefined") {
      setFieldSections(prefillFormFields(sections, prefills))
    }
  }, [])

  function handleValueChange(id: number, obj: any) {
    const fe = [...sections]

    // // Find field and set value/valid properties
    const idx = fe[obj.section_id].fields.findIndex((el) => el.id === id)

    if (fe[obj.section_id].fields[idx].type === "tel") {
      fe[obj.section_id].fields[idx].countryCode = obj.countryCode

      fe[obj.section_id].fields[idx].isoCountry = obj.isoCountry
    }

    fe[obj.section_id].fields[idx].value = obj.value

    // If current valid is null it must be true before being invalid
    if (
      (fe[obj.section_id].fields[idx].value && obj.focus_out) ||
      (fe[obj.section_id].fields[idx].valid === null && obj.valid) ||
      fe[obj.section_id].fields[idx].valid ||
      fe[obj.section_id].fields[idx].valid === false
    ) {
      fe[obj.section_id].fields[idx].valid = obj.valid
    }

    if (!startedForm) {
      window.dataLayer.push({
        event: "form_started",
        eventMeta: {
          p_one: form_type,
        },
      })

      setStartedForm(true)
    }

    setFieldSections(fe)
  }

  function checkValidity() {
    let allValid = true

    const checkedFields = [...sections]
    const invalidFields: Array<any> = []
    checkedFields.forEach((s) => {
      if (s.fields) {
        s.fields.forEach((f: FormFieldProps) => {
          // Mark the fields that are required but not valid
          if (!f.valid && f.required) {
            allValid = false
            f.valid = false
            invalidFields.push(f.name)
          }
        })
      }
    })

    return [allValid, checkedFields, invalidFields.join(",")]
  }

  function submitForm() {
    // Construct JSON
    const json: any = {}
    json["formType"] = form_type
    json["formHandler"] = form_handler

    if (sf_campaign_id) json["sfCampaignId"] = sf_campaign_id;

    sections.forEach((s) => {
      if (s.fields) {
        s.fields.forEach((f: FormFieldProps) => {
          if (f.name === "privacy") {
            // Do not add privacy to json
          } else if (f.name === "secondary_privacy") {
            // Do not add secondary_privacy to json
          } else if (f.type === "tel" && f.value) {
            json[f.name] = `${f.countryCode}${f.value}`
          } else {
            json[f.name] = f.value
          }
        })
      }
    })

    // Combine utm and field objects
    const union = { ...json, ...getUtms }

    fetch(`${process.env.GATSBY_FORM_API}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...union,
      }),
    })
      .then((response) => {
        // Push success event to GA
        if (response.status === 200) {
          window.dataLayer.push({
            event: "form_success",
            eventMeta: {
              p_one: form_type,
            },
          })
        }

        // Show thank you message or close window
        onSubmit()
      })

      .catch((error) => {
        window.dataLayer.push({
          event: "form_network_error",
          eventMeta: {
            p_one: form_type,
            p_two: error,
          },
        })
      })
  }

  function handleSubmit() {
    window.dataLayer.push({
      event: "form_submit",
      eventMeta: {
        p_one: form_type,
      },
    })

    const valid = checkValidity()

    setFieldSections(valid[1])

    if (valid[0]) {
      submitForm()
    } else {
      // Invalid data, therefore send event to GA
      window.dataLayer.push({
        event: "form_validation_error",
        eventMeta: {
          p_one: form_type,
          p_two: valid[2],
        },
      })
    }
  }

  return (
    <StyledForm onSubmit={(e) => e.preventDefault()}>
      <StyledFormFields>
        {sections &&
          sections.map((s, idx) => (
            <FormFieldSection
              key={getKey("form-field-section", idx)}
              title={s.title}
              section_layout={s.section_layout}
              title_size={s.title_size}
            >
              {Array.isArray(s.fields) &&
                s.fields.map((f: FormFieldProps, fidx: number) => (
                  <FormField
                    key={getKey(`${idx}-form-field-`, f._uid ? f._uid : fidx)}
                    blok={f}
                    onValueChange={handleValueChange}
                  />
                ))}
            </FormFieldSection>
          ))}
      </StyledFormFields>

      <ButtonHEF
        testPrefix="form"
        onClick={handleSubmit}
        as="button"
        variant="primary"
        parentComponent={component}
        label={cta_label}
        color={primary_colour}
      />
    </StyledForm>
  )
}