import React from "react";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType, sbAssetType, sbLinkType } from "../../../types";
import { Footer } from "../../Footer";
import Seo from "../../utils/seo";
import { EventHeadline } from "./EventHeadline";
import { StyledBodyContainer, StyledSpacer } from "./partials";

export interface EventContent extends blokType {
  secondary_navigation?: Array<blokType>;
  content?: Array<blokType>;
  upcoming_events?: Array<blokType>;
  meta_data?: Array<blokType>;
  title: string;
  sub_title: string;
  cta_precursor_title: string;
  cta_precursor_body: string;
  cta_label: string;
  cta_form: Array<blokType>;
  cta_link: sbLinkType;
  event_date: string;
  event_location: string;
  event_time: string;
  preview_image?: sbAssetType;
  contact_us?: Array<blokType>;
}
interface EventProps extends BaseComponentProps {
  blok: EventContent;
}

export const EventTemplate: React.FC<EventProps> = ({ blok, story, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      <StyledBodyContainer>
        {blok?.secondary_navigation?.map((navBlok) => {
          return <DynamicComponent key={navBlok._uid} blok={navBlok} />;
        })}
        <StyledSpacer />
        {blok.title && blok.sub_title && blok.cta_precursor_title && (
          <EventHeadline
            _uid={blok._uid}
            title={blok.title}
            sub_title={blok.sub_title}
            cta_precursor_title={blok.cta_precursor_title}
            cta_precursor_body={blok.cta_precursor_body}
            cta_label={blok.cta_label}
            cta_form={blok.cta_form}
            cta_link={blok.cta_link}
            event_date={blok.event_date}
            event_location={blok.event_location}
            event_time={blok.event_time}
          />
        )}
        {blok?.content?.map((contentBlok) => {
          return <DynamicComponent key={contentBlok._uid} blok={contentBlok} />;
        })}
        {blok?.upcoming_events?.map((upcomingBlok) => {
          return <DynamicComponent key={upcomingBlok._uid} blok={upcomingBlok} />;
        })}
        {blok?.contact_us?.map((contactBody) => {
          return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
        })}
      </StyledBodyContainer>
      <Footer alternatives={alternates} />
    </>
  );
};
