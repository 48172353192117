import React from "react";

import { HeaderContent, IHeaderContent } from "./HeaderContent";
import {
  StyledOpenProgramsHeader,
  StyledOpenProgramsHeaderContainer,
} from "./styles/op-header.styles";
import { UpcomingCohorts, IUpcomingCohortsProps } from "./UpcomingCohorts";

export interface IOPHeader {
  title: string;
  qualifier: string;
  left: Array<IHeaderContent>;
  right: Array<IUpcomingCohortsProps>;
}

export const OPHeader: React.FC<IOPHeader> = ({
  title,
  qualifier,
  left,
  right,
}) => {
  return (
    <StyledOpenProgramsHeader>
      <StyledOpenProgramsHeaderContainer>
        <HeaderContent
          title={title}
          qualifier={qualifier}
          introduction={left[0].introduction}
          paragraphs={left[0].paragraphs}
          video={left[0].video}
        />
        <UpcomingCohorts
          title={right[0].title}
          cards={right[0].cards}
          component={right[0].component}
          contact_number={right[0].contact_number}
          primary_cta_label={right[0].primary_cta_label}
          primary_cta_form={right[0].primary_cta_form}
          primary_cta_color={right[0].primary_cta_color}
          secondary_cta_label={right[0].secondary_cta_label}
          secondary_cta_form={right[0].secondary_cta_form}
          show_more_label={right[0].show_more_label}
          show_less_label={right[0].show_less_label}
        />
      </StyledOpenProgramsHeaderContainer>
    </StyledOpenProgramsHeader>
  );
};
