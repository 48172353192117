import React from "react";
import { AccordionItem, IAccordionItem } from "./AccordionItem";

import { StyledAccordion, StyledAccordionHeader } from "./partials";

export interface IAccordionCmp {
  title: string;
  items: Array<IAccordionItem>;
}

export const AccordionCmp: React.FC<IAccordionCmp> = ({ title, items }) => {
  return (
    <StyledAccordion>
      <StyledAccordionHeader>{title}</StyledAccordionHeader>
      {items.map((item) => 
        { return <AccordionItem
          title={item.title}
          body={item.body}
        /> }
      )}
    </StyledAccordion>
  );
};
