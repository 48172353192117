import styled from "styled-components";

export const StyledBodyContainer = styled.main`
  left: 0;
  position: relative;
  height: auto;
  width: 100%;
  
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-bottom: 75px;
  }
`;
