import React from "react";
import EmailShare from "./EmailShare";
import FacebookShare from "./FacebookShare";
import LinkedInShare from "./LinkedInShare";
import TwitterShare from "./TwitterShare";
import WhatsAppShare from "./WhatsAppShare";

import { StyledArticleShare, StyledArticleShareContent } from "./partials";
export interface ArticleShareBlokProps {
  share_article_label: string;
  share_url: string;
  facebook: boolean;
  linkedin: boolean;
  twitter: boolean;
  whatsapp: boolean;
  email: boolean;
}

export const ArticleShareCmp: React.FC<ArticleShareBlokProps> = ({
  share_article_label,
  share_url,
  facebook,
  linkedin,
  twitter,
  whatsapp,
  email,
}) => {
  return (
    <StyledArticleShare>
      <StyledArticleShareContent>
        {share_article_label && <p>{share_article_label}</p>}
        {facebook && <FacebookShare share_url={share_url} />}
        {linkedin && <LinkedInShare share_url={share_url} />}
        {twitter && <TwitterShare share_url={share_url} />}
        {whatsapp && <WhatsAppShare share_url={share_url} />}
        {email && <EmailShare share_url={share_url} />}
      </StyledArticleShareContent>
    </StyledArticleShare>
  );
};
