import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { IllustrationCardCmp, IllustrationCardProps } from "./IllustrationCard";

interface IllustrationCardWBlokProps {
  blok: IllustrationCardProps & blokType;
}

export const IllustrationCard: React.FC<IllustrationCardWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <IllustrationCardCmp
        _uid={blok._uid}
        component={blok.component}
        title={blok.title}
        body_copy={blok.body_copy}
        cards={blok.cards}
        link={blok.link}
      ></IllustrationCardCmp>
    </SbEditable>
  );
};
