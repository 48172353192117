import React from "react";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import { Footer } from "../../Footer";
import Seo from "../../utils/seo";
import { OpenProgramsHeadline } from "./OpenProgramHeadline";
import { StyledBodyContainer, StyledSpacer } from "./partials";

export interface OpenProgramsContent extends blokType {
  qualifier?: string;
  title?: string;
  description?: string;
  meta_data?: Array<blokType>;
  secondary_navigation?: Array<blokType>;
  header?: Array<blokType>;
  upcoming_cohorts?: Array<blokType>;
  course_overview?: Array<blokType>;
  course_overview_video?: Array<blokType>;
  what_you_will_learn?: Array<blokType>;
  further_info?: Array<blokType>;
  course_faculty?: Array<blokType>;
  open_programs_overview?: Array<blokType>;
  contact_us?: Array<blokType>;
  learning_formats?: string;
}
interface OpenProgramsTemplateProps extends BaseComponentProps {
  blok: OpenProgramsContent;
}

const OpenProgramsTemplate: React.FC<OpenProgramsTemplateProps> = ({ blok, story, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      <StyledBodyContainer>
        {blok?.secondary_navigation?.map((blok) => {
          return <DynamicComponent key={blok._uid} blok={blok} />;
        })}
        <StyledSpacer />
        {blok.title && blok.qualifier && blok.description && (
          <OpenProgramsHeadline
            _uid={blok._uid}
            header={blok.title}
            qualifier={blok.qualifier}
            body={blok.description}
          />
        )}
        {blok?.upcoming_cohorts?.map((opBlok) => {
          return <DynamicComponent key={opBlok._uid} blok={opBlok} />;
        })}
        {blok?.course_overview?.map((courseOverviewBlok) => {
          return <DynamicComponent key={courseOverviewBlok._uid} blok={courseOverviewBlok} />;
        })}
        {blok?.course_overview_video?.map((courseVideoBlok) => {
          return <DynamicComponent key={courseVideoBlok._uid} blok={courseVideoBlok} />;
        })}
        {blok?.what_you_will_learn?.map((blok) => {
          return <DynamicComponent key={blok._uid} blok={blok} />;
        })}
        {blok?.further_info?.map((furtherInfoBlok) => {
          return <DynamicComponent key={furtherInfoBlok._uid} blok={furtherInfoBlok} />;
        })}
        {blok?.course_faculty?.map((facultyBlok) => {
          return <DynamicComponent key={facultyBlok._uid} blok={facultyBlok} />;
        })}
        {blok?.open_programs_overview?.map((cardsBlok) => {
          return <DynamicComponent key={cardsBlok._uid} blok={cardsBlok} />;
        })}
        {blok?.contact_us?.map((contactBody) => {
          return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
        })}
      </StyledBodyContainer>
      <Footer alternatives={alternates} />
    </>
  );
};

export default OpenProgramsTemplate;
