import styled from "styled-components";

import bg from "../../../assets/Graphics/ProductCardsBG.svg";

interface Props {
  bottomMargin: boolean;
}

export const ProductCardsBackground = styled.div`
  background: ${(props) => props.theme.colors.neutrals.offwhite};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    background: none;
  }
`;

export const ProductCardsWrapper = styled.div<Props>`
  position: relative;
  max-width: ${(props) => props.theme.maxWidthWPadding};
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  column-gap: ${(props) => props.theme.spacing.three};
  row-gap: ${(props) => props.theme.spacing.seven};
  padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three}
    ${(props) => (props.bottomMargin ? props.theme.spacing.seven : props.theme.spacing.four)};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three}
      ${(props) => (props.bottomMargin ? props.theme.spacing.seven : props.theme.spacing.four)};
  }
`;

export const ProductCardsBackgroundSVG = styled.div`
  position: absolute;
  width: 100vw;
  height: 230px;
  content: "";
  bottom: 0;
  left: calc(0px - ((100vw - 100%) / 2));
  background: no-repeat url(${bg});
  background-size: 100%;

  @media screen and (max-width: 1372px) {
    background-size: auto 100%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const ProductCardsTitle = styled.h2`
  grid-column: 1 / span 8;
  position: relative;
  font-size: 40px;
  line-height: 46px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0;
    z-index: 0;
    font-size: 32px;
    line-height: 40px;
    grid-column: 1 / span 12;

    ::before {
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: calc(100% + 233px);
      top: -32px;
      left: -24px;
      content: "";
      background: ${(props) => props.theme.colors.neutrals.offwhite};
    }
  }
`;
