import React from "react";
import { uniqueId } from "lodash";

import {
  StyledInputWrapper,
  StyledInput,
  StyledCheckValidation,
  StyledCrossValidation,
  StyledErrorMessage,
} from "./partials";

interface InputProps {
  id?: string;
  section_id: number;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  hide_label?: boolean;
  type?: string;
  required?: boolean;
  disable?: boolean;
  hidden?: boolean;
  value?: string;
  onChange?: any;
  onBlur?: any;
  isValid?: boolean;
  required_err_msg?: string;
  invalid_err_msg?: string;
}

export const Input: React.FC<InputProps> = ({
  id = "",
  section_id,
  placeholder = "",
  name = "",
  label = "",
  type = "text",
  required = false,
  hidden = false,
  disable = false,
  value = "",
  onChange = () => {},
  onBlur = () => {},
  isValid = undefined,
  required_err_msg = "This is required",
  invalid_err_msg = "Invalid data entry",
}) => {
  const htmlId = uniqueId(id);

  let pattern = null;

  // Validation on email
  if (type === "email") {
    pattern = "^[\\p{L}\\d.!#$%&+'^_`{}~-]+@+[\\p{L}\\d-]+\\.[\\p{L}\\d.-]+$";
  } else {
    pattern = "^[^()/><{}~;]*$";
  }

  return (
    <StyledInputWrapper hidden={hidden} valid={isValid}>
      {label && (
        <label htmlFor={htmlId}>
          {label}
          {required && " *"}
        </label>
      )}
      <StyledInput valid={isValid}>
        <input
          id={htmlId}
          name={name}
          type="text"
          placeholder={placeholder}
          required={required}
          disabled={disable}
          value={value}
          pattern={pattern}
          onChange={(e) => {
            if (id) {
              const { value, validity } = e.target;
              onChange(id, {
                value: value,
                valid: validity.valid,
                section_id: section_id,
              });
            } else onChange(e);
          }}
          onBlur={(e) => {
            if (id) {
              const { value, validity } = e.target;
              onChange(id, {
                value: value,
                valid: validity.valid,
                section_id: section_id,
                focus_out: true,
              });
            } else onChange(e);
          }}
        />
        {isValid && <StyledCheckValidation />}
        {isValid === false && <StyledCrossValidation />}
      </StyledInput>
      {isValid === false &&
        (value.length > 0 ? (
          <StyledErrorMessage>{invalid_err_msg}</StyledErrorMessage>
        ) : (
          <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
        ))}
    </StyledInputWrapper>
  );
};
