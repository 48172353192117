import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { ColumnBodyCmp, ColumnBodyProps } from "./ColumnBody";

export interface ColumnBodyWBlokProps {
  blok: ColumnBodyProps & blokType;
}

export const ColumnBody: React.FC<ColumnBodyWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ColumnBodyCmp {...blok} />
    </SbEditable>
  );
};
