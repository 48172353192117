import React, { useState, useEffect, useCallback } from "react";
import { getKey } from "../../utils/helpers";

import {
  StyledMultiSelectContainer,
  StyledMultiSelectWrapper,
  StyledErrorMessage,
} from "../partials";

import { MultiSelectOption } from "./MultiSelectOption";

interface MultiSelectProps {
  id: string;
  section_id: number;
  name: string;
  text?: string;
  label: string;
  onChange?: any;
  options?: Array<any>;
  required?: boolean;
  isValid?: boolean;
  value?: string;
  checked_items?: string[];
  required_err_msg?: string;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  id = "",
  section_id,
  name = "",
  text = "",
  label = "",
  options = [],
  onChange = () => {},
  required = false,
  isValid = undefined,
  value = "",
  checked_items,
  required_err_msg = "Please select at least one option",
}) => {
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);

  const handleValueChange = useCallback(
    (value: string, add: boolean) => {
      const items = [...checkedItems];
      const index = items.indexOf(value);
      if (index > -1 && !add) {
        items.splice(index, 1);
      }
      if (add) {
        items.push(value);
      }

      onChange(id, {
        value: items.toString(),
        checked_items: items,
        valid: required ? items.length > 0 : true,
        section_id: section_id,
      });
      setCheckedItems(items);
    },
    [checkedItems]
  );

  const isChecked = (value) => checkedItems.includes(value);

  useEffect(() => {
    setCheckedItems(checked_items || []);
  }, [checked_items]);

  return (
    <StyledMultiSelectContainer key={getKey("multi-select-", id)}>
      {label && (
        <label>
          {label} {required && " *"}
        </label>
      )}

      <StyledMultiSelectWrapper>
        {Array.isArray(options) &&
          options.length > 1 &&
          options.map((item, idx) => (
            <MultiSelectOption
              key={getKey(`${id}-multi-select-item-`, idx)}
              value={item.value}
              label={item.label}
              checked={isChecked(item.value)}
              onChange={(value: string, add: boolean) =>
                handleValueChange(value, add)
              }
            />
          ))}
      </StyledMultiSelectWrapper>
      {isValid === false && (
        <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
      )}
    </StyledMultiSelectContainer>
  );
};
