import styled from "styled-components";

interface StyledBulletPointsItemProps {
  bullet_color?: "heritage" | "copper" | "intensity";
}

export const StyledBulletPointsItem = styled.div<StyledBulletPointsItemProps>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  color: ${(props) =>
    props.bullet_color
      ? props.theme.colors.warmths[props.bullet_color]
      : props.theme.colors.neutrals.black};
  grid-gap: ${(props) => props.theme.spacing.two};
`;

export const StyledBulletPointsItemTextWrapper = styled.div``;

export const StyledBulletPointsItemTextTitle = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledBulletPointsItemTextDescription = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: 0;
`;
