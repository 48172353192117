import styled from "styled-components";

export const StyledJobDetailsWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.seven} 0
    ${(props) => props.theme.spacing.seven} 0;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledJobDetails = styled.div``;

export const StyledDetailCategory = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.warmths.heritage};
  margin-bottom: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.one};
  }
`;

export const StyledDetailTitle = styled.div`
  font-size: 48px;
  font-weight: ${(props) => props.theme.font.bold};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin: ${(props) => props.theme.spacing.four} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    margin: ${(props) => props.theme.spacing.three} 0;
  }
`;

export const StyledJobType = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDetailExperience = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledDetailLocation = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.warmths.heritage};
  color: ${(props) => props.theme.colors.neutrals.white};
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50px;
  margin-right: ${(props) => props.theme.spacing.two};
  svg {
    font-size: 16px;
  }
`;

export const StyledDetailDesc = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.four};

  p {
    margin: 0;
    max-width: 100%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.three};
  }
`;

export const StyledLink = styled.a`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  font-weight: ${(props) => props.theme.font.light};
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;

  & svg {
    transition: transform 500ms ease 0s;
    min-width: 14px;
    min-height: 14px;
    margin-right: 8px;
  }

  :hover {
    & svg {
      transform: translateX(-4px);
    }
  }
`;
