import React, { ReactNode } from "react";

import {
  StyledFormFieldSection,
  StyledFormFieldSectionTitle,
  StyledFormFieldSectionFieldsContainer,
} from "./partials";

export interface FormFieldSectionProps {
  title?: string;
  title_size?: "small" | "large";
  section_layout?: string;
  children: ReactNode;
}

export const FormFieldSection: React.FC<FormFieldSectionProps> = ({
  title,
  title_size = "large",
  section_layout = "full-width",
  children,
}) => {
  return (
    <StyledFormFieldSection>
      {title && (
        <StyledFormFieldSectionTitle title_size={title_size}>
          {title}
        </StyledFormFieldSectionTitle>
      )}
      <StyledFormFieldSectionFieldsContainer layout={section_layout}>
        {children}
      </StyledFormFieldSectionFieldsContainer>
    </StyledFormFieldSection>
  );
};
