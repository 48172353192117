import React, { useState } from "react";
import { hefBrandColors } from "../../types";
import { ButtonHEF } from "../Button";
import { Input, Checkbox } from "../FormInput";
import circle from "../../assets/Subscribe/black-circle.png";
import CookieContext from "../../context/CookieContext";

import {
  StyledSubscribeFormContainer,
  StyledSubscribeForm,
  StyledSubscribeFormContent,
  StyledSubscribeFormTitle,
  StyledSubscribeFormBody,
  StyledBlob,
  StyledButtonGroup,
} from "./partials";

export interface SubscribeFormProps {
  form_handler: string;
  title: string;
  component: string;
  body_copy: string;
  email_placeholder?: string;
  privacy: any;
  sign_up_label?: string;
  cta_colour?: hefBrandColors;
  thank_you_header?: string;
  thank_you_body?: string;
  required_err_msg?: string;
  invalid_err_msg?: string;
}

const initialFields = [
  {
    id: "0",
    name: "email",
    valid: undefined,
    value: "",
    type: "email",
    required: true,
  },
  { id: "1", name: "privacy", valid: undefined, value: false, required: true },
];

export const SubscribeFormCmp: React.FC<SubscribeFormProps> = ({
  form_handler,
  title,
  component,
  body_copy,
  email_placeholder = "Email Address",
  sign_up_label = "Sign up",
  required_err_msg = "This is required",
  invalid_err_msg = "Invalid data entry",
  cta_colour = "heritage",
  privacy,
  thank_you_header = "Thank you for signing up to the Hult EF newsletter.",
  thank_you_body = "Join other industry leaders and stay up to date with the latest language and leadership innovation and insights every month.",
}) => {
  const [startedForm, setStartedForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fields, setFields] = useState(initialFields);
  const { getUtms } = React.useContext(CookieContext);

  const form_type = "subscribe";

  function submitForm() {
    // Construct JSON
    let json: any = {};
    json["formType"] = form_type;
    json["formHandler"] = form_handler;

    fields.forEach((f) => {
      if (f.name === "privacy") {
        // Do not add privacy to json
      } else {
        json[f.name] = f.value;
      }
    });

    // Combine utm and field objects
    let union = { ...json, ...getUtms };

    fetch(`${process.env.GATSBY_FORM_API}`, {
      method: "post",

      body: JSON.stringify({
        ...union,
      }),
    })
      .then((response) => {
        // Push success event to GA
        if (response.status === 200) {
          window.dataLayer.push({
            event: "form_success",
            eventMeta: {
              p_one: form_type,
            },
          });
        }
        // Show thank you message
        setSubmitted(true);
      })

      .catch((error) => {
        window.dataLayer.push({
          event: "form_network_error",
          eventMeta: {
            p_one: form_type,
            p_two: error,
          },
        });
      });
  }

  function handleSubmit() {
    // Send form submit event
    window.dataLayer.push({
      event: "form_submit",
      eventMeta: {
        p_one: form_type,
      },
    });

    const valid = checkValidity();
    setFields(valid[1]);

    if (valid[0]) {
      submitForm();
    } else {
      // Invalid data, therefore send event to GA
      window.dataLayer.push({
        event: "form_validation_error",
        eventMeta: {
          p_one: form_type,
          p_two: valid[2],
        },
      });
    }
  }
  function handleValueChange(id: number, obj: any) {
    let fe = [...fields];

    // Find field and set value/valid properties
    const idx = fe.findIndex((el) => el.id === id);

    fe[idx].value = obj.value;

    // If current valid is null it must be true before being invalid
    if (
      (typeof fe[idx].valid === "undefined" && obj.valid) ||
      fe[idx].valid ||
      fe[idx].valid === false
    ) {
      fe[idx].valid = obj.valid;
    }

    if (!startedForm) {
      window.dataLayer.push({
        event: "form_started",
        eventMeta: {
          p_one: form_type,
        },
      });

      setStartedForm(true);
    }

    setFields(fe);
  }

  function checkValidity() {
    let allValid = true;

    let checkedFields = [...fields];
    let invalidFields: Array<any> = [];

    checkedFields.forEach((f) => {
      // Mark the fields that are required but not valid
      if (!f.valid && f.required) {
        allValid = false;
        f.valid = false;
        invalidFields.push(f.name);
      }
    });

    return [allValid, checkedFields, invalidFields.join(",")];
  }

  return (
    <StyledSubscribeFormContainer>
      <StyledBlob>
        <svg
          height="300"
          viewBox="0 0 958 416"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.523 48.7983C935.563 10.5334 901.223 -4.54708 863.572 1.17397C807.691 9.6514 741.903 10.1843 682.025 10.999C617.059 11.8871 552.178 14.2515 487.261 17.0385C355.374 22.6983 223.437 29.0503 91.3836 26.4654C60.3227 25.8651 33.2524 48.9085 25.4612 77.8996C7.69633 97.6722 0.493537 127.19 10.5039 151.759C-3.963 189.901 -1.89105 237.77 7.11398 274.62C21.1824 332.192 70.7621 365.655 126.349 377.213C177.682 387.889 230.272 387.161 282.359 385.237C339.166 383.142 395.495 387.448 452.265 389.972C532.011 393.513 611.781 396.385 691.477 400.985C707.655 401.923 723.936 403.681 740.125 404.091C740.714 404.195 741.29 404.305 742.044 404.44C748.045 405.469 754.047 406.449 760.048 407.435C787.829 412.005 817.119 417.799 845.421 415.466C884.046 412.281 920.385 398.18 940.467 362.727C957.827 332.057 954.701 293.076 953.169 259.209C949.981 188.713 969.211 118.192 947.523 48.7983Z"
            fill="#D6CCBF"
            fillOpacity="0.4"
          />
        </svg>
        <StyledSubscribeFormContent submitted={submitted}>
          {!submitted && (
            <>
              <StyledSubscribeFormTitle>{title}</StyledSubscribeFormTitle>
              <StyledSubscribeFormBody>{body_copy}</StyledSubscribeFormBody>
              <StyledSubscribeForm>
                <Input
                  type="email"
                  id={fields[0].id}
                  name={fields[0].name}
                  placeholder={email_placeholder}
                  section_id={0}
                  isValid={fields[0].valid}
                  onChange={handleValueChange}
                  value={fields[0].value}
                  required={fields[0].required}
                  required_err_msg={required_err_msg}
                  invalid_err_msg={invalid_err_msg}
                />
                <Checkbox
                  name={fields[1].name}
                  section_id={0}
                  id={fields[1].id}
                  required={fields[1].required}
                  label={privacy}
                  onChange={handleValueChange}
                  isValid={fields[1].valid}
                  richText={true}
                  value={fields[1].value}
                  required_err_msg={required_err_msg}
                />
                <StyledButtonGroup>
                  <img src={circle} alt="" title="" />
                  <ButtonHEF
                    label={sign_up_label}
                    parentComponent={component}
                    onClick={(_: void) => handleSubmit()}
                    color={cta_colour}
                  />
                </StyledButtonGroup>
              </StyledSubscribeForm>
            </>
          )}
          {submitted && (
            <>
              <StyledSubscribeFormTitle>
                {thank_you_header}
              </StyledSubscribeFormTitle>
              <StyledSubscribeFormBody>
                {thank_you_body}
              </StyledSubscribeFormBody>
            </>
          )}
        </StyledSubscribeFormContent>
      </StyledBlob>
    </StyledSubscribeFormContainer>
  );
};
