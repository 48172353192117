import React from "react";
import { sbAssetType } from "../../types";
import { Blender } from "../Blender";

import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledHeaderContentWrapper,
  StyledHeaderAssetWrapper,
} from "./partials";

export interface HeaderProps {
  title: string;
  mini_title?: string;
  image?: sbAssetType;
  mini_title_colour?: "heritage" | "copper" | "intensity";
  text_size?: "small" | "large";
  body_variant?: boolean;
  blender?: "off" | "top" | "bottom";
  background_colour: "offwhite" | "intensity" | "heritage";
}

export const HeaderCmp: React.FC<HeaderProps> = ({
  title,
  mini_title,
  image,
  mini_title_colour,
  text_size,
  body_variant = false,
  blender,
  background_colour,
}) => {
  return (
    <>
    {blender === "top" && <Blender color={background_colour} />}
    <StyledHeader background_colour={background_colour}>
      <StyledHeaderWrapper mini_color={mini_title_colour} text_size={text_size} background_colour={background_colour}>
        <StyledHeaderContentWrapper>
          {mini_title && <p>{mini_title}</p>}
          {!body_variant && <h1>{title}</h1>}
          {body_variant && <h2>{title}</h2>}
        </StyledHeaderContentWrapper>
      </StyledHeaderWrapper>
      {image?.filename && (
        <StyledHeaderAssetWrapper>
          <img src={image.filename} alt={image?.alt} title={image?.title}></img>
        </StyledHeaderAssetWrapper>
      )}
    </StyledHeader>
    {blender === "bottom" && (
        <Blender color={background_colour} position="bottom" />
      )}
    </>
  );
};
