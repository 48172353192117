import styled from "styled-components";

export const StyledCardWithTextItem = styled.div`
  & a {
    height: 100%;
    padding: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledCardWithTextItemImg = styled.img`
  height: 167px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing.two};
`;

export const StyledCardWithTextContentContainer = styled.div`
  padding: ${(props) => props.theme.spacing.one};
`;

export const StyledCardWithTextItemTitle = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledCardWithTextItemDescription = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const StyledCardWithTextItemLinkItem = styled.div`
  & a {
    padding-left: 0px;
    padding-bottom: 0px;
  }
`;
