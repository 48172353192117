import React from "react";
import { hefBrandColors, sbLinkType } from "../../types";

import { ButtonHEF } from "../Button";
import { getUrl } from "../utils/helpers";

export interface OverviewProps {
  title: string;
  body_copy: string;
  component: string;
  cta_label: string;
  cta_link: sbLinkType;
  cta_colour: hefBrandColors;
  card_link_label?: string;
  column_layout?: "one" | "three";
}

export const Overview: React.FC<OverviewProps> = ({
  title,
  body_copy,
  component,
  cta_label,
  cta_link,
  cta_colour,
  children,
}) => {
  return (
    <div id="overview">
      {title && <h2>{title}</h2>}
      {body_copy && <p>{body_copy}</p>}

      {children}

      {cta_link && cta_label && (
        <ButtonHEF
          color={cta_colour}
          as="a"
          parentComponent={component}
          href={getUrl(cta_link)}
          label={cta_label}
        />
      )}
    </div>
  );
};
