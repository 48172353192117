import React from "react";
import { StyledDropdownListItem } from "./partials";

interface DropdownListItemProps {
  children?: React.ReactNode;
  onItemClick?: any;
}

export const DropdownListItem: React.FC<DropdownListItemProps> = ({
  children,
  onItemClick,
}) => {
  return (
    <StyledDropdownListItem onClick={onItemClick}>
      {children}
    </StyledDropdownListItem>
  );
};
