import React, { useContext } from "react";

import { SbImage, sbImageService } from "hult-sb-ui";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { blokType, sbAssetType, sbLinkType, sbRichText } from "../../types";
import { LanguageSelector } from "../LanguageSelector";
import { getKey, getUrl } from "../utils/helpers";
import { FooterLinkList, FooterLinkListProps } from "./FooterLinkList";
import {
  StyledFooter,
  StyledFooterBottom,
  StyledFooterBottomBreaker,
  StyledFooterContentContainer,
  StyledFooterCopright,
  StyledFooterCoprightContainer,
  StyledFooterLinksContainer,
  StyledFooterLogo,
  StyledFooterLogoContainer,
  StyledFooterLogoPartnerLogos,
  StyledPicture,
} from "./partials";

const resolver = new RichTextResolver();

export interface IFooter extends blokType {
  logo: sbAssetType;
  ef_logo: sbAssetType;
  hult_logo: sbAssetType;
  ef_logo_link: sbLinkType;
  hult_logo_link: sbLinkType;
  link_list: Array<FooterLinkListProps>;
  copyright: sbRichText;
  copyright_logo?: sbAssetType;
}

export const Footer: React.FC<{ alternatives?: string[]; simple?: boolean }> = ({ alternatives, simple = false }) => {
  const { settings } = useContext(SettingsContextHooks);
  const footer = settings?.content?.footer;

  function handleClick(footer_link: sbLinkType) {
    if (typeof window !== undefined && footer_link && typeof footer_link?.linktype !== undefined) {
      window.open(getUrl(footer_link), "_blank");
    }
  }

  return (
    <>
      {Array.isArray(footer) && footer.length > 0 && (
        <StyledFooter>
          <StyledFooterContentContainer>
            <StyledFooterLogoContainer>
              <StyledFooterLogo
                src={sbImageService(footer[0].logo.filename, "170x40")}
                alt={footer[0].logo.alt}
                title={footer[0].logo.title}
                loading="lazy"
                width="170"
                height="40"
                data-testid="footer__logo"
              ></StyledFooterLogo>
              <StyledFooterLogoPartnerLogos>
                {footer[0].ef_logo.filename && (
                  <StyledPicture
                    onClick={(_) => handleClick(footer[0].ef_logo_link)}
                    linkable={footer[0].ef_logo_link ? true : false}
                  >
                    <source
                      srcSet={sbImageService(footer[0].ef_logo.filename, undefined, "58x40", "webp")}
                      type="image/webp"
                    />
                    <img
                      src={sbImageService(footer[0].ef_logo.filename, undefined, "58x40")}
                      alt={footer[0].ef_logo?.alt}
                      title={footer[0].ef_logo?.title}
                      loading="lazy"
                      width="58"
                      height="40"
                    />
                  </StyledPicture>
                )}
                {footer[0].hult_logo.filename && (
                  <StyledPicture
                    onClick={(_) => handleClick(footer[0].hult_logo_link)}
                    linkable={footer[0].ef_logo_link ? true : false}
                  >
                    <source
                      srcSet={sbImageService(footer[0].hult_logo.filename, undefined, "58x40", "webp")}
                      type="image/webp"
                    />
                    <img
                      src={sbImageService(footer[0].hult_logo.filename, undefined, "58x40")}
                      alt={footer[0].hult_logo.alt}
                      title={footer[0].hult_logo.title}
                      loading="lazy"
                      width="78"
                      height="40"
                    />
                  </StyledPicture>
                )}
              </StyledFooterLogoPartnerLogos>
            </StyledFooterLogoContainer>
            {!simple && (
              <StyledFooterLinksContainer data-testid="footer__link-container">
                {footer[0].link_list.map((list, idx: number) => (
                  <FooterLinkList key={getKey("footer-link-list-", idx)} links={list.links} header={list.header} />
                ))}
              </StyledFooterLinksContainer>
            )}

            <StyledFooterBottomBreaker />
            {footer[0].copyright && (
              <StyledFooterBottom>
                {!simple && <LanguageSelector alternatives={alternatives} />}

                <StyledFooterCoprightContainer>
                  <StyledFooterCopright
                    dangerouslySetInnerHTML={
                      footer[0].copyright.type === "doc"
                        ? {
                          __html: resolver.render(footer[0].copyright),
                        }
                        : { __html: `<p>${footer[0].copyright}</p>` }
                    }
                  ></StyledFooterCopright>
                  {footer[0].copyright_logo && footer[0].copyright_logo.filename && (
                    <SbImage image={footer[0].copyright_logo} width="16px" height="16px" />
                  )}
                </StyledFooterCoprightContainer>
              </StyledFooterBottom>
            )}
          </StyledFooterContentContainer>
        </StyledFooter>
      )}
    </>
  );
};
