import { useEffect, useState } from "react";

import StoryblokClient from "storyblok-js-client";

import config from "../../gatsby-config";
import { storyType } from "../types";

interface globalWindow extends Window {
  StoryblokBridge?: { new ({ resolveRelations: [] }): StoryblokBridge };
}

const sbConfig: any = config.plugins.find((item: any = {}) => item.resolve === "gatsby-source-storyblok");

const Storyblok = new StoryblokClient({
  accessToken: sbConfig.options.accessToken,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export default function useStoryblok(location: Location, originalStory?: storyType, originalSettings?: storyType) {
  const [story, setStory] = useState(originalStory);
  const [settings, setSettings] = useState(originalSettings);

  if (story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  function getSettingsSlug(storyFullSlug: string): string {
    let settingsSlug = "en/settings";

    const storyLang = storyFullSlug.split("/");
    if (storyLang.length > 1 && storyLang[0] !== "en") {
      settingsSlug = `${storyLang[0]}/settings`;
    }

    return settingsSlug;
  }

  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  function initEventListeners() {
    const { StoryblokBridge } = window as globalWindow & typeof globalThis;
    const resolveRelations = sbConfig.options.resolveRelations.join(",");

    if (typeof StoryblokBridge !== "undefined") {
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: sbConfig.options.resolveRelations,
      });

      storyblokInstance.on(["published", "change"], () => {
        // reloade project on save an publish
        window.location.reload();
      });

      storyblokInstance.on(["input"], (event) => {
        // live updates when editing
        if (event && story && event.story._uid === story._uid) {
          setStory(event.story);
        }
      });

      storyblokInstance.on(["enterEditmode"], (event) => {
        // loading the story with the client
        if (event) {
          Storyblok.get(`cdn/stories/${event.storyId}`, {
            version: "draft",
            resolve_relations: resolveRelations,
          })
            .then(({ data }) => {
              if (data.story) {
                const storyData = data.story;

                // load page language settings
                const settingsSlug = getSettingsSlug(storyData.full_slug);
                Storyblok.get(`cdn/stories/${settingsSlug}`, {
                  version: "draft",
                  resolve_relations: resolveRelations,
                })
                  .then(({ data }) => {
                    if (data.story) {
                      const settingsData = data.story;
                      setStory(storyData);
                      setSettings(settingsData);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });

      // Enable for Preview Mode
      if (location.search.includes("_storyblok")) {
        const params = new URL(location.href).searchParams;
        const storyId = params.get("_storyblok");
        if (storyId) {
          Storyblok.get(`cdn/stories/${storyId}`, {
            version: "draft",
            resolve_relations: resolveRelations,
          })
            .then(({ data }) => {
              if (data.story) {
                const storyData = data.story;

                // load page language settings
                const settingsSlug = getSettingsSlug(storyData.full_slug);
                Storyblok.get(`cdn/stories/${settingsSlug}`, {
                  version: "draft",
                  resolve_relations: resolveRelations,
                })
                  .then(({ data }) => {
                    if (data.story) {
                      const settingsData = data.story;
                      setStory(storyData);
                      setSettings(settingsData);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  }

  function addBridge(callback: Function) {
    // check if the script is already present
    const existingScript = document.getElementById("storyblokBridge");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        // call a function once the bridge is loaded
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // load bridge only inside the storyblok editor
    if (location.search.includes("_storyblok")) {
      // first load the bridge and then attach the events
      addBridge(initEventListeners);
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  return { story, settings };
}
