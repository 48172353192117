import React from "react";

import { uniqueId } from "lodash";

import {
  StyledInputWrapper,
  StyledInput,
  StyledCheckValidation,
  StyledCrossValidation,
  StyledErrorMessage,
} from "./partials";

interface TextAreaProps {
  id: string;
  section_id: number;
  className?: string;
  name: string;
  placeholder?: string;
  label?: string;
  hidden?: boolean;
  required?: boolean;
  disable?: boolean;
  defaultValue?: string;
  value?: string;
  onChange: any;
  isValid?: boolean;
  type?: string;
  required_err_msg?: string;
  invalid_err_msg?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  id,
  section_id,
  placeholder,
  name = "",
  label,
  required = false,
  hidden = false,
  disable = false,
  value = "",
  onChange,
  isValid,
  type = "textarea",
  required_err_msg = "This is required",
  invalid_err_msg = "Invalid data entry",
}) => {
  const htmlId = uniqueId(id);

  const pattern = "^[^()/><{}~;]*$";

  const regex = new RegExp(pattern);

  return (
    <StyledInputWrapper hidden={hidden} valid={isValid}>
      {label && (
        <label htmlFor={htmlId}>
          {label} {required && "*"}
        </label>
      )}

      <StyledInput valid={isValid}>
        <textarea
          id={htmlId}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disable}
          value={value}
          style={{
            width: "100%",
            minHeight: "150px",
            resize: "none",
          }}
          onChange={(e) => {
            if (id) {
              const { value, validity } = e.target;

              onChange(id, {
                value: value,
                valid: validity.valid && regex.test(value),
                section_id: section_id,
              });
            } else onChange(e);
          }}
        />

        {isValid && <StyledCheckValidation />}

        {isValid === false && <StyledCrossValidation />}
      </StyledInput>
      {isValid === false &&
        (value.length > 0 ? (
          <StyledErrorMessage>{invalid_err_msg}</StyledErrorMessage>
        ) : (
          <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
        ))}
    </StyledInputWrapper>
  );
};
