import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { CaseStudyHeroCmp, CaseStudyHeroProps } from "./CaseStudyHero";

export interface CaseStudyHeroWBlokProps {
  blok: CaseStudyHeroProps & blokType;
}

export const CaseStudyHero: React.FC<CaseStudyHeroWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CaseStudyHeroCmp {...blok} />
    </SbEditable>
  );
};
