import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { BulletPointsCmp, BulletPointsProps } from "./BulletPoints";

interface BulletPointsWBlokProps {
  blok: BulletPointsProps & blokType;
}

export const BulletPoints: React.FC<BulletPointsWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <BulletPointsCmp
        title={blok.title}
        sub_header={blok.sub_header}
        bullets={blok.bullets}
        show_more_label={blok.show_more_label}
        show_less_label={blok.show_less_label}
      ></BulletPointsCmp>
    </SbEditable>
  );
};
