import React from "react";
import { LinkedinShareButton } from "react-share";
import IconSocialLiAlt from "@ef-global/web-ui-react/lib/icons/IconSocialLiAlt";

interface LinkedInShareProps {
  share_url: string;
}

const LinkedInShareCmp: React.FC<LinkedInShareProps> = ({ share_url }) => (
  <LinkedinShareButton url={share_url}>
    <IconSocialLiAlt />
  </LinkedinShareButton>
);

export default LinkedInShareCmp;
