import styled, { css } from "styled-components";
import { hefBrandColors } from "../../../types";

export const StyledHomeHero = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  flex-flow: row;
  margin: -32px auto 0 auto;
  max-width: ${(props) => props.theme.maxWidth};

  padding: 0;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: 0 ${(props) => props.theme.spacing.four};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
    flex-flow: column-reverse;
  }
`;

export const StyledHomeHeroMain = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 24px;
  height: auto;
  width: 40%;
  min-width: 340px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    min-width: 100px;
    margin: 0;
  }
`;

export const StyledHomeHeroMainContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: center;
    position: relative;
    margin-top: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledHomeHeroHeader = styled.h1`
  font-size: 48px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.neutrals.black};
  margin-bottom: ${(props) => props.theme.spacing.one};
  line-height: 56px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledHomeHeroBody = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;
  line-height: 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 auto;
  }
`;

interface StyledHomeHeroLinkProps {
  colour?: hefBrandColors;
}

export const StyledHomeHeroLinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-flow: row;
  width: fit-content;
  margin-top: ${(props) => props.theme.spacing.three};

  & a {
    margin-right: ${(props) => props.theme.spacing.two};
  }

  & svg {
    transition: transform 500ms;
  }

  :hover {
    & svg {
      transform: translateY(4px);
    }

    & a {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: ${(props) => props.theme.spacing.three} auto 0 auto;
  }
`;

export const StyledHomeHeroButton = styled.button<StyledHomeHeroLinkProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: ${(props) =>
    props.colour
      ? props.theme.colors.warmths[props.colour]
      : props.theme.colors.warmths.copper};
  color: ${(props) => props.theme.colors.neutrals.white};
`;

export const StyledHomeHeroAside = styled.div`
  position: relative;
  height: 708px;
  overflow: visible;
  width: 638px;
  margin-right: -88px;

  width: 100%;

  & img {
    display: block;
    height: 707px;
    position: absolute;
    overflow: visible;
    bottom: 0;
    right: 0;
    object-fit: none;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    max-height: 500px;
    margin-right: 0px;
    max-width: 451px;

    & img {
      height: 100%;
      object-fit: contain;
      right: 0;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 342px;
    max-height: 392px;
    height: 100%;
    margin: 0 auto;
    img {
      position: relative;
      height: auto;
      top: initial;
      right: initial;
    }
  }
`;
