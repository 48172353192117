import Cookies from "js-cookie"
import { uniqueId } from "lodash"

export function cookiePrefill(cookie) {
  return Cookies.get(cookie) ? Cookies.get(cookie) : ""
}

export function initialFields(sections, privacies, termsConfig, campaignID) {
  let fe = [...sections]

  const privaciesObj = {
    title: undefined,
    layout: "one-column-two-thirds",
    fields: [],
  }

  privacies.map((privacy) => {
    if (!privacy || !privacy.content[0].content) return

    const privacyId = uniqueId()
    const label = privacy?.content ? JSON.stringify(privacy?.content[0]) : undefined

    const privacyEl = {
      section_id: fe.length,
      id: privacyId,
      _uid: privacyId,
      label: label,
      required: true,
      type: "checkbox",
      name: "privacy",
      value: false,
      valid: null,
      richText: label ? true : false,
    }

    privaciesObj.fields.push(privacyEl)
  })

  fe.push(privaciesObj)

  return fe
}