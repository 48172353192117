import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { HeaderCmp, HeaderProps } from "./Header";

interface HeaderWBlokProps {
  blok: HeaderProps & blokType;
}

export const BodyOne: React.FC<HeaderWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HeaderCmp
        title={blok.title}
        component={blok.component}
        description={blok.description}
        image={blok.image}
        video={blok.video}
        video_preview={blok.video_preview}
        asset_link={blok.asset_link}
        link={blok?.link}
        alternative_variant={blok?.alternative_variant}
        body_variant
        loading="lazy"
        blender={blok?.blender}
        background_colour={blok?.background_colour}
      ></HeaderCmp>
    </SbEditable>
  );
};
