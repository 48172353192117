import React from "react";
import { StyledTabWrapper, StyledFormatTab } from "./partials";

export interface IFormatTabsItem {
  formatTitle: string;
  _uid: string;
  currentId: string;
  onClick: any;
}

export const FormatTabsItem: React.FC<IFormatTabsItem> = ({
  formatTitle,
  _uid,
  currentId,
  onClick,
}) => {
  return (
      <StyledTabWrapper onClick={() => onClick()}>
      <StyledFormatTab is_visible={currentId === _uid}>
        {formatTitle}
      </StyledFormatTab>
    </StyledTabWrapper>
  );
};
