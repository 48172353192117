import * as React from "react";

import { StaticQuery, graphql } from "gatsby";

import PageContext from "../../context/PageContext";
import { AllStoryblokEntryResult, blokType, sbLinkType } from "../../types";
import { ArticleHeadlineProps } from "../ArticleHeadline/ArticleHeadline";
import { CaseStudyHeroProps } from "../CaseStudyHero/CaseStudyHero";
import { ArticleContent } from "../Templates/Article/article";
import { ArticleOverviewCard, ArticleOverviewCardCmp } from "./ArticleOverviewCard";
import Pagination from "./Pagination";
import { StyledOverviewCardsContainer, StyledPaginationContext } from "./partials";

export interface ArticleOverviewQueryProps {
  folder: sbLinkType;
  tag?: Array<string>;
  card_link_label?: string;
  showPagination?: boolean;
}

export const ArticleOverviewQueryCmp: React.FC<ArticleOverviewQueryProps> = ({
  folder,
  tag,
  card_link_label,
  showPagination = false,
}) => {
  const containerRef = React.useRef<null | Element>(null);

  let site: null | Element = null;
  if (typeof document !== "undefined") {
    site = document.getElementById("site");
  }

  function handleScroll() {
    if (site && containerRef?.current) {
      site.scrollTo({
        top: containerRef?.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  function parseQueryResult(
    data: AllStoryblokEntryResult,
    filterPath: string,
    pagePath: string,
    filterTag?: Array<string>
  ): Array<ArticleOverviewCard> {
    const parsedResult: Array<ArticleOverviewCard> = [];

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: ArticleContent = node.content;

      const intersection = node.tag_list?.filter((t) => filterTag?.includes(t));
      // console.log(content);

      if (!filterTag || filterTag.length === 0 || (intersection && intersection?.length > 0)) {
        if (typeof node.content === "string") content = JSON.parse(node.content);
        if (
          node.full_slug.startsWith(filterPath) &&
          content.header &&
          content.header.length > 0 &&
          pagePath.indexOf(node.full_slug) === -1
        ) {
          const header = content.header[0];

          const storyCard: ArticleOverviewCard = {
            id: node.id,
            title: header?.title || header?.company || header?.header,
            qualifier: header?.qualifying_title || header?.tagline,
            link: `/${node.full_slug}`,
            qualifier_colour: header.qualifier_colour,
            image: content?.preview_image,
            card_link_label: card_link_label,
          };

          parsedResult.push(storyCard);
        }
      }
    });

    return parsedResult;
  }

  return (
    <PageContext.Consumer>
      {(pageContext) => {
        const filterPath = folder?.cached_url ? folder.cached_url : pageContext.data?.pagePath || "";

        return (
          <StaticQuery
            query={graphql`
              query {
                allStoryblokEntry(
                  filter: { field_component: { eq: "article-template" } }
                  sort: { fields: [published_at], order: [DESC] }
                ) {
                  edges {
                    node {
                      id
                      full_slug
                      content
                      published_at
                      tag_list
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const parsedResult = parseQueryResult(data, filterPath, pageContext.data?.pagePath || "", tag);
              const [viewPage, setViewPage] = React.useState(0);

              const showArticlesNumber = showPagination ? 9 : 3;

              const totalCount = parsedResult.length;
              const totalPages = Math.ceil(totalCount / showArticlesNumber);
              const articleFrom = viewPage * showArticlesNumber;
              const articleTo = articleFrom + showArticlesNumber;

              return (
                <>
                  {showPagination && (
                    <StyledPaginationContext>
                      Showing {articleFrom + 1}-{articleTo < totalCount ? articleTo : totalCount} of {totalCount}{" "}
                      articles
                    </StyledPaginationContext>
                  )}

                  <StyledOverviewCardsContainer column={"one"} ref={containerRef}>
                    {parsedResult &&
                      parsedResult
                        .slice(articleFrom, articleTo)
                        .map((card) => <ArticleOverviewCardCmp key={card.id} card={card} />)}
                  </StyledOverviewCardsContainer>
                  {showPagination && (
                    <Pagination
                      breakLabel={"..."}
                      pageCount={totalPages}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={(e) => {
                        handleScroll();
                        setViewPage(e);
                      }}
                    />
                  )}
                </>
              );
            }}
          />
        );
      }}
    </PageContext.Consumer>
  );
};
