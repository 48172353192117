import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { ProductOverviewCmp, ProductOverviewProps } from "./ProductOverview";

interface ProductOverviewWBlokProps {
  blok: ProductOverviewProps & blokType;
}

export const ProductOverview: React.FC<ProductOverviewWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <ProductOverviewCmp
        component={blok.component}
        header={blok.header}
        cards={blok.cards}
        background_colour={blok.background_colour}
        background_image={blok?.background_image}
        layout={blok?.layout}
        breaker={blok.breaker}
        link={blok.link}
      ></ProductOverviewCmp>
    </SbEditable>
  );
};
