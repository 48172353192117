import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { DigitalCoachCmp, DigitalCoachProps } from "./DigitalCoach";

interface DigitalCoachWBlokProps {
  blok: DigitalCoachProps & blokType;
}

export const DigitalCoach: React.FC<DigitalCoachWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <DigitalCoachCmp
        title={blok.title}
        desc={blok.desc}
        link={blok.link}
      ></DigitalCoachCmp>
    </SbEditable>
  );
};
