import styled from "styled-components";

export const StyledSubscribeFormContainer = styled.div`
  padding: ${(props) => props.theme.spacing.four} 0
    ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0
      ${(props) => props.theme.spacing.seven} 0;
  }
`;

export const StyledBlob = styled.div`
  position: relative;
  height: auto;
  max-height: 416px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
  }

  > svg {
    width: 100%;
    min-height: 400px;

    position: relative;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      width: auto;
      margin-left: -80px;
    }
  }
`;

interface StyledSubscribeFormContentProps {
  submitted?: boolean;
}

export const StyledSubscribeFormContent = styled.div<StyledSubscribeFormContentProps>`
  margin: 0 auto;
  max-width: 468px;
  margin-top: ${({ theme, submitted }) =>
    submitted ? "0" : theme.spacing.five};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three};
    margin-top: ${(props) => props.theme.spacing.four};
    width: 100%;
  }

  & label {
    text-align: start;
    margin-left: ${(props) => props.theme.spacing.one};
  }
`;

export const StyledSubscribeForm = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.two};

  & div {
    max-width: 386px;
    margin: 0 auto;
  }

  & button {
    margin: ${(props) => props.theme.spacing.two} auto 0 auto;
    text-align: center;
  }
`;

export const StyledSubscribeFormTitle = styled.h2`
  font-size: 32px;
  text-align: center;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledSubscribeFormBody = styled.p`
  font-size: 16px;
  text-align: center;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0 0 ${(props) => props.theme.spacing.four} 0;
`;

export const StyledButtonGroup = styled.div`
  position: relative;
  padding: 0 45px;

  & button {
    z-index: 2;
    position: relative;
  }

  & img {
    position: absolute;
    right: 0;
    z-index: 1;
  }
`;
