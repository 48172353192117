import styled from "styled-components"

import { StyledButton, StyledIconContainer } from "../../Button/partials"
import { StyledSelectIcons, StyledSelectWrapper } from "../../FormInput/partials/StyledSelectWrapper"

interface IStyleFilter {
  is_active: boolean
}

interface IStyleShapeProps {
  hide_shape: boolean
}

export const StyledOverviewFilterWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};
  margin: 0 auto;
`

export const StyledOverviewContent = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.seven} 0 ${(props) => props.theme.spacing.seven} 0;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.neutrals.black};

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`

export const StyledInfo = styled.div`
  width: 55%;
  margin-bottom: ${(props) => props.theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`

export const StyledTitle = styled.div`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`

export const StyledDesc = styled.div`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.font.light};
`

export const StyledFilterResults = styled.div`
  display: flex;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`

export const StyledFilterContainer = styled.div`
  margin-right: ${(props) => props.theme.spacing.three};
  width: 342px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-right: 0;
    width: 100%;
  }

  & ${StyledButton} {
    width: 100%;
    flex-direction: row-reverse;
    border-radius: 4px;
    font-size: ${(props) => props.theme.fontSize.m};
    height: 48px;

    &:nth-last-child(1) {
      width: auto;
      position: absolute;
      bottom: 32px;
      right: 24px;
      z-index: 3147483647;
    }

    & ${StyledIconContainer} {
      margin: 0 8px 0 0;
      width: 16px;
    }
  }
`

export const StyledFilter = styled.div`
  border-bottom: 1px solid rgba(25, 25, 25, 20%);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 ${(props) => props.theme.spacing.three};
  }
`

export const StyledCaption = styled.div`
  font-size: ${(props) => props.theme.fontSize.s};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.font.bold};
    border-bottom: 1px solid rgba(25, 25, 25, 20%);
    padding: ${(props) => props.theme.spacing.two} ${(props) => props.theme.spacing.three};
    width: 100%;
  }
`

export const StyledCaptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    align-items: center;
    margin-top: ${(props) => props.theme.spacing.four};
  }
`

export const StyledSubTitle = styled.div<IStyleFilter>`
  font-size: ${(props) => props.theme.fontSize.m};
  font-weight: ${(props) => props.theme.font.bold};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing.three} 0;
`

export const StyledCheckbox = styled.div<IStyleFilter>`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing.one} 0;
`

export const StyledModalFooterWrapper = styled.div`
  border-top: 1px solid rgba(25, 25, 25, 20%);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${(props) => props.theme.spacing.three};
`

export const StyledCaptionFilter = styled.div`
  font-size: ${(props) => props.theme.fontSize.s};
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const StyledSelect = styled.div`
  margin-right: -18px;

  & ${StyledSelectWrapper} {
    display: flex;

    & label {
      width: auto;
      font-size: ${(props) => props.theme.fontSize.s};
      white-space: nowrap;
      margin: 0;
      align-items: center;
    }
  }

  ${StyledSelectIcons} {
    position: absolute;
    padding-left: ${(props) => props.theme.spacing.one};
    margin: 0;
  }

  & select {
    font-size: ${(props) => props.theme.fontSize.s};
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: ${(props) => props.theme.font.bold};
    padding: 0 36px 0 4px;

    & select:focus {
      outline: none;
    }
  }
`

export const StyledClearFilter = styled.div`
  padding-top: ${(props) => props.theme.spacing.three};
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  white-space: nowrap;
  position: relative;
  z-index: 10;

  & p {
    padding-left: ${(props) => props.theme.spacing.one};
    font-size: ${(props) => props.theme.fontSize.m};
  }

  & svg {
    width: 12px;
    height: 12px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: 0;
  }
`

export const StyledFilterDesktop = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`

export const StyledOverviewCardsContainer = styled.div``

export const StyledOverviewStoryCardWrapper = styled.div`
  width: 100%;
  margin-left: 80px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0;
  }
`

export const StyledNoResult = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;
`

export const StyledNoResultText = styled.div`
  & > h1 {
    font-size: 20px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.four};
  }

  & > p {
    font-weight: ${(props) => props.theme.font.light};
    margin-bottom: ${(props) => props.theme.spacing.three};
    width: 60%;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & > h1 {
      margin-bottom: ${(props) => props.theme.spacing.three};
      width: 90%;
    }

    & > p {
      margin-bottom: ${(props) => props.theme.spacing.two};
      width: 100%;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
`

export const StyledBestsellerCard = styled.div`
  margin-top: ${(props) => props.theme.spacing.seven};
`

export const StyledPagination = styled.div`
  z-index: 10;
  position: relative;
`

export const StyledShape = styled.div<IStyleShapeProps>`
  height: 0;
  position: relative;
  z-index: 1;

  ${(props) => (props.hide_shape ? "display: none;" : "width: 100%;")}

  & svg {
    position: absolute;
    bottom: 0;
  }
`
