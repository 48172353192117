import styled from "styled-components";

interface Props {
  index: number;
}

export const CardItemWrapper = styled.div<Props>`
  position: relative;
  height: 332px;
  display: flex;
  flex-direction: column;
  background: white;
  gap: ${(props) => props.theme.spacing.three};
  padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three}
    ${(props) => props.theme.spacing.five};
  border-radius: ${(props) => props.theme.spacing.one};
  grid-row: 2;
  grid-column: span 4;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 274px;
    grid-row: ${(props) => props.index + 2};
    grid-column: 1 / span 12;
    align-items: center;
    gap: 12px;
    padding: ${(props) => props.theme.spacing.six} ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.four};
  }
`;

export const CardItemIconWrapper = styled.div`
  position: absolute;
  height: 74px;
  width: 74px;
  top: -37px;
  left: calc(50% - 37px);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 62px;
    height: 62px;
    top: -31px;
    left: calc(50% - 31px);
  }
`;

export const CardItemTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 56px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 46px;
  }
`;

export const CardItemTitle = styled.h5`
  margin: 0;
  line-height: 27.84px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
  }
`;

export const CardItemBody = styled.p`
  height: 76px;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    text-align: center;
  }
`;
