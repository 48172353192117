import React from "react";

import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import { sbRichText } from "../../types";
import { Blender } from "../Blender";
import {
  StyledArticleBodyCopy,
  StyledArticleBodyCopyContent,
  StyledArticleBodyCopySubtitle,
  StyledArticleBodyCopyTitle,
  StyledArticleBodyCopyWrapper,
} from "./partials";

const resolver = new RichTextResolver();

export interface ArticleBodyCopyProps {
  title: string;
  subtitle: string;
  body: sbRichText;
  bgColour: "white" | "offwhite" | "black";
  blender?: "off" | "top" | "bottom";
}

export const ArticleBodyCopyCmp: React.FC<ArticleBodyCopyProps> = ({ title, subtitle, body, bgColour, blender }) => {
  return (
    <>
      {blender === "top" && <Blender color={bgColour} position="top" />}
      <StyledArticleBodyCopy data-testid="article-body-copy" bgColour={bgColour}>
        <StyledArticleBodyCopyWrapper>
          {title && (
            <StyledArticleBodyCopyTitle bgColour={bgColour} data-testid="article-body-copy__header">
              {title}
            </StyledArticleBodyCopyTitle>
          )}
          {subtitle && (
            <StyledArticleBodyCopySubtitle bgColour={bgColour} data-testid="article-body-copy__header">
              {subtitle}
            </StyledArticleBodyCopySubtitle>
          )}
          <StyledArticleBodyCopyContent
            bgColour={bgColour}
            dangerouslySetInnerHTML={{
              __html: resolver.render(body),
            }}
          ></StyledArticleBodyCopyContent>
        </StyledArticleBodyCopyWrapper>
      </StyledArticleBodyCopy>
      {blender === "bottom" && <Blender color={bgColour} position="bottom" />}
    </>
  );
};
