import styled from "styled-components";

interface StyleProps {
  readonly isActive?: boolean;
}

export const StyledLinkText = styled.a<StyleProps>`
  white-space: nowrap;
  height: auto;
  float: left;
  text-decoration: ${(props) => (props.isActive ? "underline" : "normal")};
  font-size: ${(props) => props.theme.fontSize.l};
  z-index: 10;
  margin-bottom: 0;
  color: inherit;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: inherit;
    text-decoration: none;
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
    border-left: ${(props) => (props.isActive ? "4px solid #191919" : "none")};
    padding: 16px 0;
    padding-left: ${(props) =>
      props.isActive ? "28px" : props.theme.spacing.four};

    & a {
      padding: ${(props) => props.theme.spacing.two} 0;
      margin-left: ${(props) => (props.isActive ? "28px" : "32px")};
    }
  }
`;
