import React, { useState, useEffect, useRef } from "react";
import { blokType, sbAssetType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { Modal } from "../Modal";
import CohortCard, { ICohortCardProps } from "./CohortCard";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import IconChevronUp from "@ef-global/web-ui-react/lib/icons/IconChevronUp";
import IconPhone from "@ef-global/web-ui-react/lib/icons/IconPhone";
import {
  StyledUpcomingCohorts,
  StyledUpcomingCohortsWrapper,
  StyledUpcomingCohortCards,
  StyledCohortsGrapicOverlay,
  StyledUpcomingCohortsBottom,
  StyledUpcomingCohortsBottomBtnGroup,
} from "./partials";

export interface IUpcomingCohortsProps {
  title: string;
  cards: Array<blokType & ICohortCardProps>;
  component: string;
  contact_number: string;
  graphic_overlay: sbAssetType;
  graphic_overlay_mobile: sbAssetType;
  primary_cta_label: string;
  primary_cta_form: Array<blokType & FormSCProps>;
  primary_cta_color: string;
  secondary_cta_label: string;
  secondary_cta_form: Array<blokType & FormSCProps>;
  show_more_label: string;
  show_less_label: string;
}

const UpcomingCohortsCmp: React.FunctionComponent<IUpcomingCohortsProps> = ({
  title,
  cards,
  component,
  contact_number,
  graphic_overlay,
  graphic_overlay_mobile,
  primary_cta_label,
  primary_cta_form,
  secondary_cta_label,
  secondary_cta_form,
  show_more_label,
  show_less_label,
}) => {
  const hiddenPoints = useRef(null);
  const [selectedCard, setCard] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [prefill, setPrefill] = useState([]);
  const [openModalProps, setOpenModal] = useState({
    isOpen: false,
    form: 0,
  });

  const [maxHeight, setMaxHeight] = useState("1000px");

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Set default card
      setCard(cards[0]._uid);
      setPrefill([
        { name: "opDates", value: cards[0].dates },
        { name: "opPrice", value: cards[0].cost },
      ]);

      // timeoutId for debounce mechanism
      let timeoutId: any = null;
      if (hiddenPoints) {
        setMaxHeight(`${hiddenPoints?.current?.scrollHeight}px`);
      }

      const resizeListener = () => {
        // Get current height of the hidden bullet points container
        if (hiddenPoints) {
          setMaxHeight(`${hiddenPoints?.current?.scrollHeight}px`);
        }
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
      };

      // set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, []);

  function setCardDet(uid: string, dates: string, cost: string): void {
    setPrefill([
      { name: "opDates", value: dates },
      { name: "opPrice", value: cost },
    ]);
    setCard(selectedCard === uid ? "" : uid);
  }

  return (
    <>
      <StyledUpcomingCohortsWrapper
        dGraphic={graphic_overlay?.filename ? true : false}
        mGraphic={graphic_overlay_mobile?.filename ? true : false}
      >
        {graphic_overlay?.filename && (
          <StyledCohortsGrapicOverlay
            placement="top"
            src={graphic_overlay.filename}
            alt={graphic_overlay.alt}
            title={graphic_overlay_mobile.title}
          ></StyledCohortsGrapicOverlay>
        )}
        {graphic_overlay_mobile?.filename && (
          <StyledCohortsGrapicOverlay
            mobile
            placement="top"
            src={graphic_overlay_mobile.filename}
            alt={graphic_overlay_mobile.alt}
            title={graphic_overlay_mobile.title}
          ></StyledCohortsGrapicOverlay>
        )}
        <StyledUpcomingCohorts>
          <h2>{title}</h2>
          <StyledUpcomingCohortCards
            isOpen={showMore}
            ref={hiddenPoints}
            maxHeight={maxHeight}
          >
            {cards &&
              cards.map((c) => (
                <CohortCard
                  key={c._uid}
                  _uid={c._uid}
                  event_type={c.event_type}
                  location={c.location}
                  dates={c.dates}
                  cost={c.cost}
                  timezone={c.timezone}
                  duration={c.duration}
                  selected={c._uid === selectedCard}
                  onclick={(_) => setCardDet(c._uid, c.dates, c.cost)}
                />
              ))}
          </StyledUpcomingCohortCards>
          {cards.length > 2 && (
            <a onClick={() => setShowMore(!showMore)}>
              {showMore ? show_less_label : show_more_label}
              {showMore ? <IconChevronUp /> : <IconChevronDown />}
            </a>
          )}

          <StyledUpcomingCohortsBottom>
            <StyledUpcomingCohortsBottomBtnGroup>
              {primary_cta_label && (
                <ButtonHEF
                  variant="primary"
                  color={"intensity"}
                  parentComponent={component}
                  disabled={selectedCard ? false : true}
                  label={primary_cta_label}
                  onClick={() => setOpenModal({ isOpen: true, form: 1 })}
                />
              )}
              {secondary_cta_label && (
                <ButtonHEF
                  variant="secondary"
                  label={secondary_cta_label}
                  parentComponent={component}
                  onClick={() => setOpenModal({ isOpen: true, form: 2 })}
                />
              )}
            </StyledUpcomingCohortsBottomBtnGroup>

            {contact_number && (
              <a href={`tel:${contact_number}`}>
                <IconPhone /> {contact_number}
              </a>
            )}
          </StyledUpcomingCohortsBottom>
        </StyledUpcomingCohorts>
      </StyledUpcomingCohortsWrapper>
      <Modal
        isOpen={openModalProps.isOpen}
        onClose={() => setOpenModal({ isOpen: false, form: 0 })}
      >
        {openModalProps.form === 1 && (
          <FormSC
            _uid={primary_cta_form[0]._uid}
            component={primary_cta_form[0].component}
            form_handler={primary_cta_form[0].form_handler}
            sf_campaign_id={primary_cta_form[0].sf_campaign_id}
            form={primary_cta_form[0].form}
            title={primary_cta_form[0].title}
            qualifier={primary_cta_form[0].qualifier}
            event_date={
              prefill.find((element) => element.name === "opDates")?.value
            }
            op_price={
              prefill.find((element) => element?.name === "opPrice")?.value
            }
            thank_you_components={primary_cta_form[0].thank_you_components}
            close_on_submit={primary_cta_form[0].close_on_submit}
            onClose={() => setOpenModal({ isOpen: false, form: 1 })}
            prefills={prefill}
          />
        )}
        {openModalProps.form === 2 && (
          <FormSC
            _uid={secondary_cta_form[0]._uid}
            component={secondary_cta_form[0].component}
            form={secondary_cta_form[0].form}
            form_handler={secondary_cta_form[0].form_handler}
            sf_campaign_id={secondary_cta_form[0].sf_campaign_id}
            title={secondary_cta_form[0].title}
            qualifier={secondary_cta_form[0].qualifier}
            event_date={secondary_cta_form[0].event_date}
            event_duration={secondary_cta_form[0].event_duration}
            event_location={secondary_cta_form[0].event_location}
            thank_you_components={secondary_cta_form[0].thank_you_components}
            close_on_submit={secondary_cta_form[0].close_on_submit}
            onClose={() => setOpenModal({ isOpen: false, form: 2 })}
          />
        )}
      </Modal>
    </>
  );
};

export default UpcomingCohortsCmp;
