import React from "react";

import { sbAssetType } from "../../types";
import sbImageService from "../utils/sbImage";
import {
  CaseStudyQuoteBackgroundWrapper,
  CaseStudyQuoteImage,
  CaseStudyQuoteQuotee,
  CaseStudyQuoteText,
  CaseStudyQuoteTextBackground,
  CaseStudyQuoteTextWrapper,
  CaseStudyQuoteWrapper,
} from "./styles/StyledCaseStudyQuote";

export interface CaseStudyQuoteProps {
  quote: string;
  quotee?: string;
  image: sbAssetType;
  extraPadding: boolean;
  backgroundColour: "offwhite" | "white";
}

export const CaseStudyQuoteCmp: React.FC<CaseStudyQuoteProps> = ({
  quote,
  quotee,
  image,
  extraPadding,
  backgroundColour,
}) => {
  return (
    <CaseStudyQuoteBackgroundWrapper backgroundColour={backgroundColour}>
      <CaseStudyQuoteWrapper extraPadding={extraPadding} backgroundColour={backgroundColour}>
        <CaseStudyQuoteTextBackground backgroundColour={backgroundColour} />
        <CaseStudyQuoteImage src={sbImageService(image.filename)} title={image.title} alt={image.alt} />
        <CaseStudyQuoteTextWrapper backgroundColour={backgroundColour}>
          <CaseStudyQuoteText>{quote}</CaseStudyQuoteText>
          <CaseStudyQuoteQuotee>{quotee}</CaseStudyQuoteQuotee>
        </CaseStudyQuoteTextWrapper>
      </CaseStudyQuoteWrapper>
    </CaseStudyQuoteBackgroundWrapper>
  );
};
