import React from "react";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import {
  StyledProductOverviewCardIcon,
  StyledProductCardWrapper,
  StyledProductCardLink,
} from "./partials";
import { sbAssetType } from "../../types";
import { LinkItemProps } from "../LinkItem";

import { sbImageService } from "hult-sb-ui";

export interface ProductCardProps {
  title: string;
  description: string;
  icon: sbAssetType;
  link: Array<LinkItemProps>;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  title,
  description,
  icon,
  link,
}) => {
  function handleClick() {
    window.dataLayer.push({
      event: "link_click",
      eventMeta: {
        p_one: "product-overview-card",
        p_two: link[0]?.name,
        p_three: `/${link[0].link?.cached_url}`,
      },
    });
  }

  return (
    <Surface
      as="a"
      href={`/${link[0].link.cached_url}`}
      deepShadow
      rounded
      onClick={(_) => handleClick()}
    >
      <StyledProductCardWrapper>
        <StyledProductOverviewCardIcon>
          <img
            src={sbImageService(icon.filename, undefined, "40x40")}
            alt={icon?.alt}
            title={icon?.title}
            height="40px"
            width="40px"
          />
        </StyledProductOverviewCardIcon>
        <div>
          <h5>{title}</h5>
          <p>{description}</p>
          {link && (
            <StyledProductCardLink>
              <label>{link[0]?.name} </label>
              <IconArrowRight />
            </StyledProductCardLink>
          )}
        </div>
      </StyledProductCardWrapper>
    </Surface>
  );
};
