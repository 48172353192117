import React from "react";
import {
  StyledDesc,
  StyledTitle,
  StyledTextWrapper,
  StyledIconContainer,
  StyledText,
} from "./partials";
import { FormatTabsIcon, IFormatTabsIcon } from "./FormatTabsIcon";

export interface IFormatTabsDesc {
  formatTitle: string;
  formatDesc: string;
  _uid: string;
  currentId: string;
  icons: Array<IFormatTabsIcon>;
}

export const FormatTabsDesc: React.FC<IFormatTabsDesc> = ({
  formatTitle,
  formatDesc,
  _uid,
  currentId,
  icons,
}) => {
  return (
    <StyledText>
      {currentId === _uid && (
        <StyledTextWrapper is_visible={currentId === _uid}>
          <StyledTitle>{formatTitle}</StyledTitle>
          <StyledDesc>{formatDesc}</StyledDesc>
          <StyledIconContainer>
            {icons.map((icon) => {
              return (
                <FormatTabsIcon
                  iconTitle={icon.iconTitle}
                  iconBody={icon.iconBody}
                  gud_icon={icon.gud_icon}
                />
              );
            })}
          </StyledIconContainer>
        </StyledTextWrapper>
      )}
    </StyledText>
  );
};
