import React from "react"

import { ButtonHEF } from "../Button"
import { CTA } from "../CTAWithImage/CTAwImage"
import { FormSC } from "../Forms/FormSC"
import { Modal } from "../Modal"
import { getUrl } from "../utils/helpers"
import { FormatTabsDesc, IFormatTabsDesc } from "./FormatTabsDesc"
import { FormatTabsItem } from "./FormatTabsItem"
import { ILearningMetaItems, LearningMetaItems } from "./LearningMetaItems"
import {
  LearningMetaContainer,
  StyledFormatTitle,
  StyledHeader,
  StyledInfo,
  StyledInfoWrapper,
  StyledLearningWrapper,
  StyledSpacer,
  StyledSpacerCol,
  StyledSpacerGrey,
  StyledTabHeader,
  StyledTabInfo,
  StyledTabs,
  StyledTabsContainer,
  StyledTabsWrapper,
} from "./partials"

export interface ILearningMetaCmp {
  title: string
  items: Array<ILearningMetaItems>
  brochure_cta: [CTA]
  tabTitle: string
  tabs: Array<IFormatTabsDesc>
}
export const LearningMetaCmp: React.FC<ILearningMetaCmp> = ({
  title,
  items,
  tabTitle,
  tabs,
  brochure_cta,
}: ILearningMetaCmp) => {
  const [visible, setVisible] = React.useState(tabs[0]._uid)

  const [openModal, setOpenModal] = React.useState(false)

  return (
    <>
      <LearningMetaContainer>
        <StyledSpacer>
          <StyledSpacerCol>
            <svg viewBox="0 0 500 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M-72.9074 190.012L-63.5301 271.458C-62.9001 276.651 -60.2773 281.396 -56.2158 284.69C-52.1544 287.984 -46.9726 289.568 -41.7655 289.11L366.833 252.541L481.345 242.293C486.532 241.659 491.27 239.034 494.56 234.971C497.85 230.908 499.435 225.725 498.98 220.516C496.276 178.008 493.647 135.495 490.866 92.9931C489.734 75.6966 487.006 57.5015 475.975 43.8643C475.277 35.2002 474.579 26.5362 473.88 17.8723C473.268 12.6714 470.65 7.91533 466.584 4.61799C462.518 1.32066 457.326 -0.257012 452.115 0.221369L161.847 30.2076C113.253 35.2274 64.6579 40.2475 16.062 45.2681C-5.25506 47.4703 -28.5067 47.5199 -48.9081 54.7277C-58.9537 58.0447 -67.6988 64.4451 -73.9015 73.0196C-81.3889 84.1307 -83.0158 96.7507 -82.0426 109.891C-80.0619 136.632 -75.9743 163.374 -72.9074 190.012Z"
                fill="#F5E6CF"
              />
            </svg>
            <StyledSpacerGrey />
          </StyledSpacerCol>
        </StyledSpacer>
        <StyledLearningWrapper>
          <StyledInfoWrapper>
            <StyledInfo extraBorder={tabs.length == 1}>
              <StyledHeader>{title}</StyledHeader>
              {items.map((item, i) => {
                return <LearningMetaItems key={i} body={item.body} />
              })}
              {brochure_cta && Array.isArray(brochure_cta) && brochure_cta.length >= 1 && (
                <>
                  {Array.isArray(brochure_cta[0].cta_form) && brochure_cta[0].cta_form.length >= 1 && (
                    <ButtonHEF
                      variant="secondary"
                      color={brochure_cta[0].cta_colour}
                      onClick={(_: MouseEvent) => setOpenModal(true)}
                      label={brochure_cta[0].cta_label}
                    />
                  )}
                  {brochure_cta[0].cta_link && brochure_cta[0].cta_form && brochure_cta[0].cta_form.length == 0 && (
                    <ButtonHEF
                      as={"a"}
                      variant="secondary"
                      color={brochure_cta[0].cta_colour}
                      href={getUrl(brochure_cta[0].cta_link)}
                      label={brochure_cta[0].cta_label}
                    />
                  )}
                </>
              )}
              {/*<ButtonHEF
              variant={"secondary"}
              label={"Request course brochure"}
            ></ButtonHEF>*/}
            </StyledInfo>
          </StyledInfoWrapper>
        </StyledLearningWrapper>

        <StyledTabsWrapper>
          <StyledTabsContainer>
            <StyledTabs>
              <StyledTabHeader hidden={tabs.length == 1}>{tabs.length > 1 && tabTitle}</StyledTabHeader>
              <StyledFormatTitle>
                {tabs.map((tab, i) => {
                  if (tabs.length > 1) {
                    return (
                      <FormatTabsItem
                        key={i}
                        formatTitle={tab.formatTitle}
                        _uid={tab._uid}
                        currentId={visible}
                        onClick={() => setVisible(tab._uid)}
                      />
                    )
                  }
                })}
              </StyledFormatTitle>
            </StyledTabs>
          </StyledTabsContainer>
        </StyledTabsWrapper>
        <StyledTabInfo>
          {tabs.map((tab, i) => {
            return (
              <FormatTabsDesc
                key={i}
                formatTitle={tab.formatTitle}
                formatDesc={tab.formatDesc}
                _uid={tab._uid}
                currentId={visible}
                icons={tab.icons}
              />
            )
          })}
        </StyledTabInfo>
      </LearningMetaContainer>
      {Array.isArray(brochure_cta) &&
        brochure_cta.length >= 1 &&
        Array.isArray(brochure_cta[0].cta_form) &&
        brochure_cta[0].cta_form.length === 1 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={(_: void) => setOpenModal(false)}
            _uid={brochure_cta[0].cta_form[0]._uid}
            form={brochure_cta[0].cta_form[0].form}
            component={brochure_cta[0].cta_form[0].component}
            form_handler={brochure_cta[0].cta_form[0].form_handler}
            sf_campaign_id={brochure_cta[0].cta_form[0].sf_campaign_id}
            title={brochure_cta[0].cta_form[0].title}
            qualifier={brochure_cta[0].cta_form[0].qualifier}
            event_date={brochure_cta[0].cta_form[0].event_date}
            event_duration={brochure_cta[0].cta_form[0].event_duration}
            event_location={brochure_cta[0].cta_form[0].event_location}
            thank_you_components={brochure_cta[0].cta_form[0].thank_you_components}
            close_on_submit={brochure_cta[0].cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  )
}
