import styled, { css } from "styled-components";

import bg from "../../../assets/Graphics/CaseStudyHeroBreaker.svg";

interface Props {
  bold?: boolean;
  left?: boolean;
  width?: number;
}

export const CaseStudyHeroBackgroundWrapper = styled.div`
  width: 100vw;
  background: ${({ theme }) => theme.colors.neutrals.offwhite};
`;

export const CaseStudyHeroWrapper = styled.div`
  display: grid;
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  padding: ${({ theme }) => theme.spacing.three} ${({ theme }) => theme.spacing.three} 0;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};
  grid-template-rows: auto;
  z-index: 1;
  position: inherit;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const CaseStudyHeroContentWrapper = styled.div<Props>`
  grid-column: ${({ left }) => (left ? "1 / span 5" : "6 / span 7")};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 960px) {
    grid-column: ${({ left }) => (left ? "1 / span 6" : "7 / span 6")};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    margin-top: 0;
  }
`;

export const CaseStudyHeroTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(152px + ${({ theme }) => theme.spacing.eight});

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    height: auto;
  }
`;

export const CaseStudyHeroTagline = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutrals.coolGrey};
  margin-bottom: ${({ theme }) => theme.spacing.one};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const CaseStudyHeroCompany = styled.h1`
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 32px;
    line-height: 40px;
    margin: 0;
  }
`;

export const CaseStudyHeroImage = styled.img`
  width: 100%;
  object-fit: contain;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const CaseStudyHeroImageMobile = styled.img`
  grid-column: 1 / span 4;
  object-fit: contain;
  margin-bottom: ${({ theme }) => theme.spacing.two};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const CaseStudyHelpBox = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.seven};
  padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  background: white;
  border-radius: ${({ theme }) => theme.spacing.one};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

  :after {
    position: absolute;
    width: 100vw;
    height: 64px;
    content: "";
    left: -${({ width }) => width}px;
    bottom: 0;
    background: no-repeat url(${bg});
    background-size: cover;
    z-index: -1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.four};

    :after {
      position: absolute;
      width: 100vw;
      height: 50%;
      content: "";
      left: -${({ theme }) => theme.spacing.three};
      bottom: 0;
      background: ${({ theme }) => theme.colors.neutrals.offwhite};
      z-index: -1;
    }
  }
`;

export const CaseStudyHeroIntroductionWrapper = styled.div<Props>`
  display: grid;
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  padding: ${({ theme }) => theme.spacing.seven} ${({ theme }) => theme.spacing.three};
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    padding: ${({ theme }) => theme.spacing.six} ${({ theme }) => theme.spacing.three};
  }
`;

export const CaseStudyBodyTitle = styled.p<Props>`
  grid-column: 3 / span 8;
  font-size: 24px;
  font-weight: 450;
  line-height: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
  }
`;

export const CaseStudyParagraph = styled.p<Props>`
  grid-column: 3 / span 8;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 700 : 300)};
  line-height: 24px;
  margin: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
  }
`;
