import React from "react";
import { blokType } from "../../types";
import { JobPostingsCmp, JobPostingsProps } from "./JobPostings";
import { JobRoleCmp, JobRoleProps } from "./JobRole";

export interface IJobPostingsProps {
  blok: blokType & JobPostingsProps;
}
export interface IJobRoleProps {
  blok: blokType & JobRoleProps;
}

export const JobPostings: React.FC<IJobPostingsProps> = ({ blok }) => {
  return (
    <JobPostingsCmp
      title={blok.title}
      error_msg={blok.error_msg}
      job_title_label={blok.job_title_label}
      job_title_placeholder={blok.job_title_placeholder}
      location_label={blok.location_label}
      location_placeholder={blok.location_placeholder}
      category_label={blok.category_label}
      category_placeholder={blok.category_placeholder}
      not_found_title={blok.not_found_title}
      not_found_description={blok.not_found_description}
      clear_filter_button={blok.clear_filter_button}
    />
  );
};
export const JobRole: React.FC<IJobRoleProps> = ({ blok }) => {
  return <JobRoleCmp error_msg={blok.error_msg} />;
};
