import React from "react";

import { Richtext } from "storyblok-js-client";
// @ts-ignore
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import { Footer } from "../../Footer";
import Seo from "../../utils/seo";

interface LegalPageProps extends BaseComponentProps {
  blok: blokType & {
    body?: Array<blokType>;
    meta_data?: Array<blokType>;
    contact_us?: Array<blokType>;
  };
}

const resolver = new RichTextResolver();

const LegalPage: React.FC<LegalPageProps> = ({ story, blok, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      {blok.azureTermsPrivacy && legalContent(blok.azureTermsPrivacy.content.body)}

      <Footer alternatives={alternates} />
    </>
  );
};

function legalContent(content: Richtext): React.ReactElement {
  return (
    <div
      style={{
        maxWidth: "960px",
        margin: "96px auto 0px",
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: resolver.render(content),
        }}
      />
    </div>
  );
}

export default LegalPage;
