import React, { Component } from "react";
import { OptimizeVariant, IOptimizeVariant } from "./OptimizeVariant";

export interface IOptimize {
  experiment_id: string;
  variants: Array<IOptimizeVariant>;
}

export class OptimizeCmp extends Component<IOptimize> {
  state = {
    tried: 0,
    variant: "0",
    loaded: false,
  };

  async componentDidMount() {
    if (typeof window !== "undefined") {
      let intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          this.getVariant();
          clearInterval(intervalId);
        } else {
          const { tried } = this.state;
          if (tried >= 2) {
            clearInterval(intervalId);
            this.setState({ loaded: true });
          } else {
            this.setState({ tried: tried + 1 });
          }
        }
      }, 100);
    }
  }

  getVariant = () => {
    const { experiment_id } = this.props;
    const { google_optimize } = window;

    const variant = google_optimize.get(experiment_id);
    if (variant) {
      this.setState({ variant, loaded: true });
    } else {
      this.setState({ loaded: true });
    }
  };

  render() {
    const { variants } = this.props;
    const { variant, loaded } = this.state;

    return (
      <div
        className="optimize"
        style={{ visibility: loaded ? "visible" : "hidden" }}
      >
        {variant !== null &&
          variants.length &&
          variants.map((optimize_variant: IOptimizeVariant) => (
            <OptimizeVariant
              key={optimize_variant._uid}
              content={optimize_variant.content}
              variant_id={optimize_variant.variant_id}
              current_id={variant}
              _uid={optimize_variant._uid}
              component={optimize_variant.component}
            />
          ))}
      </div>
    );
  }
}
