import React from "react"

import { SbImage } from "hult-sb-ui"

import { sbAssetType } from "../../types"
import { LargeVideoCmp } from "../LargeVideo/LargeVideo"
import { StyledArticleImage, StyledImage } from "./partials"

export interface ArticleImageProps {
  image?: sbAssetType
  video?: sbAssetType
  video_preview?: sbAssetType
  video_link?: string
  caption?: string
}

export const ArticleImageCmp: React.FC<ArticleImageProps> = ({ image, video, video_preview, video_link, caption }) => {
  return (
    <StyledArticleImage data-testid="article-image">
      <StyledImage data-testid="styled-image">
        {image && image.filename !== null && image.filename !== "" && (
          <SbImage image={image} resize="0x540" height="540px" width="810px" data-testid="sb-image" />
        )}
        {video && video.filename !== null && video.filename !== "" && (
          <LargeVideoCmp video={video} video_preview={video_preview} />
        )}
        {video_link && (
          <LargeVideoCmp
            video_link={`${video_link}?modestbranding=1&showinfo=0&rel=0&showsearch=0`}
            video_preview={video_preview}
          />
        )}
      </StyledImage>
      {caption && <p>{caption}</p>}
    </StyledArticleImage>
  )
}
