import { sbLinkType } from "../../types";

export const getUrl = (link: sbLinkType) => {
  if (link.url) return link.url;

  if (link.cached_url) {
    const url = `/${link.cached_url}`;

    return url.charAt(url.length - 1) === "/" ? url : url + "/";
  } else {
    return undefined;
  }
};

export const getKey = (prefix: string, identifier: number | string) => {
  return `${prefix}-${identifier}`;
};

export const getCurrentLang = (path: string) => {
  if (path === "/") {
    return "en";
  } else {
    const langPath = path.replace(/^(\/)/, "");
    return langPath.substring(0, 2);
  }
};
