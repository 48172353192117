import styled from "styled-components";

interface StyledCohortCardProps {
  selected: boolean;
}

export const StyledCohortCard = styled.div`
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledCohortCardHeader = styled.div<StyledCohortCardProps>`
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  ${({ selected, theme }) =>
    selected
      ? `background-color: ${theme.colors.warmths.heritage}; color: ${theme.colors.neutrals.white}`
      : `background-color: ${theme.colors.neutrals.white}; color: ${theme.colors.neutrals.black}`};
  padding: ${({ theme, selected }) =>
    selected ? `0 ${theme.spacing.three}` : `0 ${theme.spacing.three}`};

  > div {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto;
    padding: ${({ theme, selected }) =>
      selected ? `${theme.spacing.two} 0` : `${theme.spacing.two} 0`};
    border-bottom: ${({ theme, selected }) =>
      selected
        ? `1px solid  ${theme.colors.neutrals.white};`
        : `1px solid  ${theme.colors.neutrals.coolGrey};`};
  }
`;

export const StyledCohortCardContent = styled.div<StyledCohortCardProps>`
  ${({ selected, theme }) =>
    selected
      ? `background-color: ${theme.colors.warmths.heritage}; color: ${theme.colors.neutrals.white}`
      : `background-color: ${theme.colors.neutrals.white}; color: ${theme.colors.neutrals.black}`};
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: 0 0 4px 4px;
`;

export const StyledCohortCardCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCohortCardCheckbox = styled.div<StyledCohortCardProps>`
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 16px;
  width: 16px;
  min-width: 16px;
  position: relative;
  margin: 0 ${(props) => props.theme.spacing.two} 0 0;
  border-radius: 2px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.neutrals.white};
  border: 1px solid ${({ selected, theme }) =>
    selected ? theme.colors.neutrals.white : theme.colors.neutrals.coolGrey}};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.warmths.heritage : "black"};

  & input {
    display: none;
    font-size: 14px;
  }
`;

export const StyledCohortCardContentMain = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 60% auto;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.font.bold};
  line-height: 31px;

  & span:nth-child(2) {
    text-align: right;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 20px;
  }
`;

export const StyledCohortCardContentSub = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.font.book};
  font-size: 14px;
`;
