import React from "react";
import SbEditable from "storyblok-react";
import { StaticQuery, graphql } from "gatsby";
import PageContext from "../../context/PageContext";

import {
  Input,
  Select,
  Checkbox,
  Telephone,
  TextArea,
  Radio,
  MultiSelect,
} from "../FormInput/";
import { blokType } from "../../types";
import { getCurrentLang } from "../utils/helpers";

export interface FormFieldProps {
  _uid?: string;
  id: string;
  name: string;
  label: string;
  type: string;
  value?: string;
  isoCountry?: string;
  placeholder?: string;
  default?: string;
  required?: boolean;
  hidden?: boolean;
  cookie?: string;
  options?: string;
  valid?: boolean;
  countryCode?: string;
  richText?: boolean;
  section_id: number;
  required_err_msg?: string;
  invalid_err_msg?: string;
  radio_options?: Array<blokType>;
  multi_select_options?: Array<blokType>;
}

interface GetOptionsProps {
  slug?: string;
  lang: string;
}

const GetOptions: React.FC<GetOptionsProps> = ({ slug, lang }) => (
  <StaticQuery
    query={graphql`
      query {
        allStoryblokDatasourceEntry {
          edges {
            node {
              id
              name
              value
              data_source
              dimension_value
              data_source_dimension
            }
          }
        }
      }
    `}
    render={(data) => {
      let options = (
        <React.Fragment>
          <option></option>
        </React.Fragment>
      );

      if (data && data.allStoryblokDatasourceEntry) {
        options = (
          <React.Fragment>
            {data.allStoryblokDatasourceEntry?.edges.map((o) => {
              if (
                o.node.data_source === slug &&
                o.node.data_source_dimension === lang &&
                lang !== "en"
              ) {
                return (
                  <option key={o.node.name} value={o.node.name}>
                    {o.node.dimension_value}
                  </option>
                );
              } else if (
                lang === "en" &&
                o.node.data_source_dimension === null &&
                o.node.data_source === slug
              ) {
                return (
                  <option key={o.node.name} value={o.node.name}>
                    {o.node.value}
                  </option>
                );
              }
            })}
          </React.Fragment>
        );
      }
      return options;
    }}
  />
);

function buildField(field: FormFieldProps, onValueChange: any, lang?: string) {
  switch (field.type.toLowerCase()) {
    case "tel":
      return (
        <Telephone
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          type={field.type}
          placeholder={field.placeholder}
          required={field.required}
          hidden={field.hidden}
          defaultValue={field.default}
          value={field.value}
          countryCode={field.countryCode}
          isoCountry={field.isoCountry}
          onChange={onValueChange}
          isValid={field.valid}
          required_err_msg={field.required_err_msg}
          invalid_err_msg={field.invalid_err_msg}
        />
      );
    case "text":
    case "email":
      return (
        <Input
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          type={field.type}
          placeholder={field.placeholder}
          required={field.required}
          hidden={field.hidden}
          defaultValue={field.default}
          value={field.value}
          onChange={onValueChange}
          onBlur={onValueChange}
          isValid={field.valid}
          required_err_msg={field.required_err_msg}
          invalid_err_msg={field.invalid_err_msg}
        />
      );
    case "select":
      return (
        <Select
          key={field.id}
          id={field.id}
          section_id={field.section_id}
          name={field.name}
          label={field.label}
          required={field.required}
          hidden={field.hidden}
          defaultValue={field.default}
          onChange={onValueChange}
          isValid={field.valid}
          required_err_msg={field.required_err_msg}
        >
          {<GetOptions slug={field.options} lang={lang} />}
        </Select>
      );
    case "checkbox":
      return (
        <Checkbox
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          value={field.value ? true : false}
          checked={field.value ? true : false}
          required={field.required}
          onChange={onValueChange}
          isValid={field.valid}
          richText={field.richText}
        />
      );
    case "textarea":
      return (
        <TextArea
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          placeholder={field.placeholder}
          required={field.required}
          hidden={field.hidden}
          defaultValue={field.default}
          value={field.value}
          onChange={onValueChange}
          isValid={field.valid}
          required_err_msg={field.required_err_msg}
          invalid_err_msg={field.invalid_err_msg}
        />
      );
    case "radio":
      return (
        <Radio
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          required={field.required}
          hidden={field.hidden}
          defaultValue={field.default}
          value={field.value}
          onChange={onValueChange}
          isValid={field.valid}
          options={field.radio_options}
        />
      );
    case "multi-select":
      return (
        <MultiSelect
          id={field.id}
          section_id={field.section_id}
          key={field.id}
          name={field.name}
          label={field.label}
          required={field.required}
          value={field.value}
          onChange={onValueChange}
          isValid={field.valid}
          options={field.multi_select_options}
        />
      );
    default:
      console.info(`Handle ${field.type}`);
      return null;
  }
}

export interface SbFormFieldProps {
  blok: FormFieldProps & blokType;
  onValueChange?: any;
}

export const FormField: React.FC<SbFormFieldProps> = ({
  blok,
  onValueChange,
}) => {
  return (
    <PageContext.Consumer>
      {(pageContext) => {
        const pagePath = pageContext.data?.pagePath || "";

        const input = buildField(blok, onValueChange, getCurrentLang(pagePath));

        return (
          <SbEditable content={blok}>
            <>{input}</>
          </SbEditable>
        );
      }}
    </PageContext.Consumer>
  );
};
