import React from "react";
import DynamicComponent from "../";
import { blokType } from "../../types";

export interface IOptimizeVariant extends blokType {
  content: Array<blokType>;
  variant_id: string;
  current_id: string;
}

export const OptimizeVariant: React.FC<IOptimizeVariant> = ({
  content,
  variant_id,
  current_id,
}) => {
  return (
    <>
      {content &&
        variant_id === current_id &&
        content.map((c: blokType) => (
          <DynamicComponent key={c._uid} blok={c} />
        ))}
    </>
  );
};
