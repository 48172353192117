import React from "react";

import { sbAssetType } from "../../types";

import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import IconPlusCircleOutline from "@ef-global/web-ui-react/lib/icons/IconPlusCircleOutline";

import {
  StyledFacultyCarouselCard,
  StyledFacultyCarouselCardImg,
  StyledFacultyCarouselCardText,
  StyledFacultyCarouselCardTextBottom,
  StyledFacultyCarouselCardTextHeadline,
} from "./partials";
import { sbImageService } from "hult-sb-ui";

export interface FacultyCarouselCardProps {
  title: string;
  qualifier: string;
  image: sbAssetType;
  body_copy?: string;
  handleClick?: any;
  clickable?: boolean;
}

export const FacultyCarouselCard: React.FC<FacultyCarouselCardProps> = ({
  title,
  qualifier,
  image,
  body_copy,
  handleClick,
  clickable,
}) => {
  return (
    <StyledFacultyCarouselCard onClick={handleClick} clickable={clickable}>
      <Surface as="a" deepShadow rounded>
        <StyledFacultyCarouselCardImg
          src={sbImageService(image.filename, undefined, "0x330", image.focus)}
          alt={image.alt}
          title={image?.title}
        />
        <StyledFacultyCarouselCardText>
          <StyledFacultyCarouselCardTextHeadline>
            {title}
          </StyledFacultyCarouselCardTextHeadline>
          <StyledFacultyCarouselCardTextBottom>
            <p>{qualifier}</p>
            {body_copy && (
              <div>
                <IconPlusCircleOutline />
              </div>
            )}
          </StyledFacultyCarouselCardTextBottom>
        </StyledFacultyCarouselCardText>
      </Surface>
    </StyledFacultyCarouselCard>
  );
};
