import React, { useState } from "react";

import { SbImage } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC } from "../Forms/FormSC";
import { ICTA } from "../FullBleedImgHero/FullBleedImageHero";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import {
  ProductHeroBackgroundWrapper,
  ProductHeroBody,
  ProductHeroCTAWrapper,
  ProductHeroContentWrapper,
  ProductHeroMediaWrapper,
  ProductHeroTitle,
  ProductHeroWrapper,
} from "./styles/StyledProductHero";

export interface ProductHeroProps {
  title: string;
  body: string;
  cta: ICTA[];
  image: sbAssetType;
  flipBreaker: boolean;
}

export const ProductHeroCmp: React.FC<ProductHeroProps> = ({ title, body, image, cta, flipBreaker }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <ProductHeroBackgroundWrapper flipBreaker={flipBreaker}>
        <ProductHeroWrapper>
          <ProductHeroContentWrapper>
            <ProductHeroTitle>{title}</ProductHeroTitle>
            <ProductHeroBody>{body}</ProductHeroBody>
            <ProductHeroCTAWrapper>
              {Array.isArray(cta) &&
                cta.length >= 1 &&
                cta.map((item, index) => (
                  <React.Fragment key={`cta-${index}`}>
                    {item.cta_form && item.cta_form.length ? (
                      <>
                        {" "}
                        {Array.isArray(item.cta_form) && item.cta_form.length >= 1 && (
                          <ButtonHEF
                            as="button"
                            variant={index === 1 ? "secondary" : "primary"}
                            color={item.cta_colour}
                            onClick={() => setOpenModal(true)}
                            label={item.cta_label}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <ButtonHEF
                          as="a"
                          variant={index === 1 ? "secondary" : "primary"}
                          color={item.cta_colour}
                          href={getUrl(item.cta_link)}
                          label={item.cta_label}
                        />
                      </>
                    )}
                  </React.Fragment>
                ))}
            </ProductHeroCTAWrapper>
          </ProductHeroContentWrapper>
          <ProductHeroMediaWrapper>
            <img src={image.filename} />
          </ProductHeroMediaWrapper>
        </ProductHeroWrapper>
      </ProductHeroBackgroundWrapper>
      {cta[0].cta_form && cta[0].cta_form.length > 0 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={() => setOpenModal(false)}
            _uid={cta[0].cta_form[0]._uid}
            form={cta[0].cta_form[0].form}
            component={cta[0].cta_form[0].component}
            form_handler={cta[0].cta_form[0].form_handler}
            sf_campaign_id={cta[0].cta_form[0].sf_campaign_id}
            title={cta[0].cta_form[0].title}
            qualifier={cta[0].cta_form[0].qualifier}
            event_date={cta[0].cta_form[0].event_date}
            event_duration={cta[0].cta_form[0].event_duration}
            event_location={cta[0].cta_form[0].event_location}
            thank_you_components={cta[0].cta_form[0].thank_you_components}
            close_on_submit={cta[0].cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
