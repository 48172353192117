import styled from "styled-components";

export const StyledLargeVideo = styled.div`
  width: 100%;
`;

export const StyledLargeVideoWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledLargeVideoHeader = styled.p`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  margin: 0 0 ${(props) => props.theme.spacing.one} 0;
`;

export const StyledLargeVideoBody = styled.p`
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0 0 ${(props) => props.theme.spacing.four} 0;
`;

export const StyledHeaderAssetWrapper = styled.div`
  height: 540px;
  position: relative;
  width: 100%;
  border-radius: 4px;

  > div {
    height: 540px;
    border-radius: 4px;
  }

  .react-player__preview {
    border-radius: 4px;
  }

  & iframe {
    height: 540px;
    border: none;
    border-radius: inherit;
  }

  & video {
    height: 100%;
    width: 100%;
    border: inherit;
    border-radius: 4px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 400px;
  }
`;

export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;
