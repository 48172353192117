import styled from "styled-components";

interface StyledBodyCenteredWModalProps {
  cta_colour?: "heritage" | "copper" | "intensity";
}

export const StyledBodyCenteredWModal = styled.div<StyledBodyCenteredWModalProps>`
  padding: ${(props) => props.theme.spacing.seven} 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }

  & button {
    margin: 0 auto;
  }
`;

export const StyledBodyCenteredWModalHeader = styled.h2`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 48px;
  max-width: 632px;
  line-height: 56px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing.four} auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
    margin: 0 auto ${(props) => props.theme.spacing.two} 0;
    max-width: 100%;
    line-height: 40px;
  }
`;
