import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { ProductCardsCmp, ProductCardsProps } from "./ProductCards";

export interface ProductCardsWBlokProps {
  blok: ProductCardsProps & blokType;
}

export const ProductCards: React.FC<ProductCardsWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ProductCardsCmp {...blok} />
    </SbEditable>
  );
};
