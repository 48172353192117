import React from "react";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import { uniqueId } from "lodash";

const resolver = new RichTextResolver();

import {
  StyledBodyCopy,
  StyledBodyCopyWrapper,
  StyledBodyCopyTitle,
  StyledBodyCopyContent,
} from "./partials";
import { hefBrandColors } from "../../types";

export interface BodyCopyProps {
  title: string;
  body: any;
  brand_colour?: hefBrandColors;
}

export const BodyCopyCmp: React.FC<BodyCopyProps> = ({
  title,
  body,
  brand_colour,
}) => {
  return (
    <StyledBodyCopy>
      <StyledBodyCopyWrapper>
        {title && <StyledBodyCopyTitle>{title}</StyledBodyCopyTitle>}
        <StyledBodyCopyContent
          color={brand_colour}
          dangerouslySetInnerHTML={{
            __html: resolver.render(body),
          }}
        ></StyledBodyCopyContent>
      </StyledBodyCopyWrapper>
    </StyledBodyCopy>
  );
};
