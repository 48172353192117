import React from "react";
import DynamicComponent from "..";
import { blokType } from "../../types";

import { StyledLandingPageSection } from "./partials";

export interface LandingPageSectionProps {
  section: Array<blokType>;
  custom_id: string;
  id: string;
}

export const LandingPageSectionCmp: React.FC<LandingPageSectionProps> = ({
  section,
  custom_id,
  id,
}) => {
  return (
    <StyledLandingPageSection id={custom_id ? custom_id : id}>
      {section &&
        section.map((c: blokType) => (
          <DynamicComponent key={c._uid} blok={c} />
        ))}
    </StyledLandingPageSection>
  );
};
