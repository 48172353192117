import React from "react";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import { Footer } from "../../Footer";
import Seo from "../../utils/seo";
import { StyledBodyContainer } from "./partials";

interface LandingPageProps extends BaseComponentProps {
  blok: blokType & {
    navigation?: Array<blokType>;
    header?: Array<blokType>;
    body?: Array<blokType>;
    meta_data?: Array<blokType>;
    simple_footer?: boolean;
  };
}

const LandingPage: React.FC<LandingPageProps> = ({ story, blok, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}

      <StyledBodyContainer>
        {blok?.header?.map((headerBlok) => {
          return <DynamicComponent key={headerBlok._uid} blok={headerBlok} />;
        })}

        {blok?.body?.map((bodyBlok) => {
          return <DynamicComponent key={bodyBlok._uid} blok={bodyBlok} />;
        })}
      </StyledBodyContainer>
      <Footer alternatives={alternates} simple={blok.simple_footer} />
    </>
  );
};

export default LandingPage;
