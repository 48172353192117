import styled from "styled-components";

interface StyledBulletListContainerProps {
  isOpen?: boolean;
  maxHeight?: string;
  hiddenDiv?: boolean;
}

export const StyledBulletPointsWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledBulletPointsHeader = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledBulletPointsSubHeader = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: ${(props) => props.theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;

export const StyledBulletPointsListContainer = styled.div<StyledBulletListContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  column-gap: ${(props) => props.theme.spacing.four};
  row-gap: ${(props) => props.theme.spacing.four};
  padding-bottom: ${(props) =>
    props.isOpen && !props.hiddenDiv ? props.theme.spacing.four : "0px"};

  transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;

  max-height: ${(props) =>
    props.isOpen ? (props.maxHeight ? props.maxHeight : "1000px") : "0px"};
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-auto-rows: unset;
  }
`;

export const StyledBulletPointsListMore = styled.a<StyledBulletListContainerProps>`
  display: flex;
  align-items: center;
  flex-flow: row;
  cursor: pointer;
  width: auto;
  color: ${(props) => props.theme.colors.neutrals.black};

  :hover {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  & svg {
    margin-left: ${(props) => props.theme.spacing.two};
  }
`;
