import styled from "styled-components";

interface IStyleBody {
  body_height: number;
  is_active: boolean;
}

export const StyledAccordionItem = styled.div<IStyleBody>`
  color: ${(props) => props.theme.colors.neutrals.black};
  border-bottom: 1px solid rgba(25, 25, 25, 0.2);
  ${(props) =>
    props.is_active &&
    `
    padding-bottom: ${props.theme.spacing.two};
    `}
`;

export const StyledButton = styled.button<IStyleBody>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.theme.colors.neutrals.black};
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s linear;
  padding: ${(props) => props.theme.spacing.four} 0
    ${(props) => props.theme.spacing.two} 0;

  ${(props) =>
    props.is_active &&
    `
    padding: ${props.theme.spacing.two} 0;
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.two} 0;
  }
`;

export const StyledBody = styled.div<IStyleBody>`
  max-width: 560px;
  max-height: 0;
  overflow: hidden;
  transition: 0.2s linear;
  opacity: 0;
  line-height: 24px;
  font-weight: ${(props) => props.theme.font.light};

  ${(props) =>
    props.is_active &&
    `
    max-height: ${props.body_height}px;
    opacity: 1;
    `}
`;
