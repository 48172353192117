import React from "react";

import { NavItemCmp } from "../NavItem";
import { NavItemWSubItemsProps } from "../NavItemWSubItems";
import { isActiveLink, isNavItem, isNavItemWSubItem } from "../SecondaryNavigation/utils";
import { getUrl } from "../utils/helpers";
import {
  StyledSecondaryNavigationMoreNavItemLink,
  StyledSecondaryNavigationMoreNavItemWSubItemLink,
  StyledSecondaryNavigationNavItemDropdownContent,
  StyledSecondaryNavigationNavItemDropdownContentFixed,
  StyledSecondaryNavigationNavItemMoreSubItem,
} from "./partials";

interface Props {
  moreItems: Array<NavItemCmp | NavItemWSubItemsProps>;
  isOpen: boolean;
  url?: string;
}

export const MoreMenu: React.FC<Props> = ({ moreItems, isOpen, url }) => {
  return (
    <StyledSecondaryNavigationNavItemDropdownContent isOpen={isOpen}>
      <StyledSecondaryNavigationNavItemDropdownContentFixed>
        {moreItems.map((item) => (
          <>
            {isNavItem(item) && (
              <StyledSecondaryNavigationMoreNavItemLink
                href={getUrl(item.link)}
                isActive={isActiveLink(url, item.link.cached_url)}
              >
                {item.name}
              </StyledSecondaryNavigationMoreNavItemLink>
            )}
            {isNavItemWSubItem(item) && (
              <StyledSecondaryNavigationMoreNavItemWSubItemLink key={item._uid}>
                {item.title}
                {item.sub_items.map((subItem) => (
                  <StyledSecondaryNavigationNavItemMoreSubItem
                    href={getUrl(subItem.link)}
                    isActive={isActiveLink(url, subItem.link.cached_url)}
                  >
                    {subItem.name}
                  </StyledSecondaryNavigationNavItemMoreSubItem>
                ))}
              </StyledSecondaryNavigationMoreNavItemWSubItemLink>
            )}
          </>
        ))}
      </StyledSecondaryNavigationNavItemDropdownContentFixed>
    </StyledSecondaryNavigationNavItemDropdownContent>
  );
};
