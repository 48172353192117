import React from "react";

import {
  StyledHighlightedListPoint,
  StyledHighlightedListTitle,
  StyledHighlightedPointContent,
} from "./partials/StyledHighlightedPoint";

export interface HighlightedPointProps {
  title?: string;
  point?: string;
  adjustedText?: boolean;
}

export const HighlightedPoint: React.FC<HighlightedPointProps> = ({ title, point, adjustedText }) => {
  return (
    <StyledHighlightedPointContent>
      {title && <StyledHighlightedListTitle adjustedText={adjustedText}>{title}</StyledHighlightedListTitle>}
      {point && <StyledHighlightedListPoint adjustedText={adjustedText}>{point}</StyledHighlightedListPoint>}
    </StyledHighlightedPointContent>
  );
};
