import styled from "styled-components";

export const StyledArticleAuthor = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledArticleAuthorWrapper = styled.div`
  max-width: ${(props) => props.theme.articleMaxWidth};
  margin: 0 auto;
`;

export const StyledArticleAuthorHeader = styled.div`
  display: flex;
  flex-flow: row;
`;

export const StyledArticleAuthorImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.spacing.three};
`;

export const StyledArticleAuthorHeaderText = styled.div``;

export const StyledArticleAuthorHeaderName = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 14px;
  margin-bottom: 0;
`;

export const StyledArticleAuthorHeaderPos = styled.p`
  font-weight: ${(props) => props.theme.font.light};
  font-size: 14px;
  margin-bottom: 0;
`;

export const StyledArticleAuthorBio = styled.div`
  margin: ${(props) => props.theme.spacing.four} 0
    ${(props) => props.theme.spacing.two} 0;

  & p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: ${(props) => props.theme.font.light};
  }
`;

export const StyledArticleAuthorLink = styled.a`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  & svg {
    margin-left: ${(props) => props.theme.spacing.two};
  }
`;
