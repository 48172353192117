import React, { useEffect, useRef, useState } from "react";

import { IconArrowLeft, IconArrowRight } from "@ef-global/web-ui-react/lib/icons/all";
import { Swiper, SwiperSlide } from "swiper/react";

import { ClientStoryCard, ClientStoryCardProps } from "./ClientStoryCard";
import {
  StoryCardsBackground,
  StoryCardsControls,
  StoryCardsControlsItem,
  StoryCardsPagination,
  StoryCardsPaginationContainer,
  StoryCardsPaginationDot,
  StoryCardsTitle,
  StoryCardsWrapper,
} from "./styles/StyledClientStoryCards";

export interface ClientStoryCardsProps {
  title: string;
  breaker: boolean;
  cards: ClientStoryCardProps[];
}

export const ClientStoryCardsCmp: React.FC<ClientStoryCardsProps> = ({ title, breaker, cards }) => {
  const [current, setCurrent] = useState(0);
  const swiperRef = useRef(null);
  const [desktopOffset, setDesktopOffset] = useState<number | null>(null);

  useEffect(() => {
    setDesktopOffset(screen.width > 768 ? 2 : 0); // If desktop, reduce the amount of pagination dots to account for 3 slides per view
  }, []);

  return (
    <StoryCardsBackground breaker={breaker}>
      <StoryCardsWrapper>
        <StoryCardsTitle>{title}</StoryCardsTitle>
        <Swiper
          spaceBetween={8}
          slidesPerView={1.15}
          ref={swiperRef}
          onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
          breakpoints={{ 768: { slidesPerView: 3, spaceBetween: 24, centeredSlides: false } }}
          centeredSlides={true}
        >
          {cards.map((card) => (
            <SwiperSlide>
              <ClientStoryCard {...card} key={card._uid} />
            </SwiperSlide>
          ))}
        </Swiper>
        <StoryCardsPaginationContainer hideControls={cards.length <= 3}>
          {cards.length > 1 && (
            <StoryCardsPagination>
              {[...Array(cards.length - (desktopOffset ?? 2))].map((num, idx) => (
                <StoryCardsPaginationDot key={`pagination-dot-${idx}`} isActive={idx === current} />
              ))}
            </StoryCardsPagination>
          )}
          {cards.length > 1 && (
            <StoryCardsControls>
              <StoryCardsControlsItem onClick={() => swiperRef.current?.swiper.slidePrev()} disabled={current === 0}>
                <IconArrowLeft />
              </StoryCardsControlsItem>
              <StoryCardsControlsItem
                onClick={() => swiperRef.current.swiper.slideNext()}
                disabled={current === cards.length - 3}
              >
                <IconArrowRight />
              </StoryCardsControlsItem>
            </StoryCardsControls>
          )}
        </StoryCardsPaginationContainer>
      </StoryCardsWrapper>
    </StoryCardsBackground>
  );
};
