import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { ArticleAuthorCmp, ArticleAuthorBlokProps } from "./ArticleAuthor";
import { AllStoryblokEntryResult, blokType, storyType } from "../../types";

export interface ArticleAuthorStoryProps extends storyType {
  content: blokType & ArticleAuthorBlokProps;
}

export interface AuthorSCProps extends blokType {
  article_author: string;
}

export interface AuthorQueryProps {
  blok: blokType & AuthorSCProps;
}

export const ArticleAuthorSC: React.FC<AuthorQueryProps> = ({ blok }) => {
  function parseQueryResult(data: AllStoryblokEntryResult, id: string): any {
    let parsedResult: blokType;

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: blokType = node.content;
      if (typeof node.content === "string") content = JSON.parse(node.content);
      if (node.uuid == id) {
        parsedResult = content;
      }
    });

    return parsedResult;
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allStoryblokEntry(
            filter: { field_component: { eq: "article-author" } }
          ) {
            edges {
              node {
                uuid
                id
                content
              }
            }
          }
        }
      `}
      render={(data) => {
        const parsedResult = parseQueryResult(data, blok.article_author);

        return (
          <>
            {parsedResult && (
              <ArticleAuthorCmp
                _uid={parsedResult?._uid}
                component={parsedResult?.component}
                author_name={parsedResult.author_name}
                image={parsedResult.image}
                position_and_company={parsedResult.position_and_company}
                read_more_label={parsedResult.read_more_label}
                bio={parsedResult.bio}
              ></ArticleAuthorCmp>
            )}
          </>
        );
      }}
    />
  );
};
