import styled from "styled-components";

interface StyledHeaderWrapperProps {
  background_colour?: "offwhite" | "intensity" | "heritage";
  mini_color?: "heritage" | "copper" | "intensity";
  text_size?: "small" | "large";
}

export const StyledHeader = styled.div<StyledHeaderWrapperProps>`
  position: relative;
  width: 100%;
  padding: ${(props) => props.theme.spacing.seven} 0;

  ${(props) =>
    props.background_colour === "offwhite" &&
    `
  background-color: ${props.theme.colors.neutrals.offwhite};
  `}
  ${(props) =>
    props.background_colour === "intensity" &&
    `
      background-color: ${props.theme.colors.warmths.intensity};
  `}
  ${(props) =>
    props.background_colour === "heritage" &&
    `
    background-color: ${props.theme.colors.warmths.heritage};
  `}
  

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledHeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  height: auto;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  & h1,
  & h2 {
    font-size: ${(props) => (props.text_size === "large" ? "48px" : "32px")};
    white-space: pre-line;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: 0;
    
    ${(props) => props.background_colour === "heritage" && `color: ${props.theme.colors.neutrals.white}`};
    ${(props) => props.background_colour === "intensity" && `color: ${props.theme.colors.neutrals.white}`};
  }

  & p {
    color: ${(props) =>
      props.mini_color
        ? props.theme.colors.warmths[props.mini_color]
        : props.theme.colors.neutrals.black};

    ${(props) => props.background_colour === "heritage" && `color: ${props.theme.colors.neutrals.white}`};
    ${(props) => props.background_colour === "intensity" && `color: ${props.theme.colors.neutrals.white}`};

    font-size: ${(props) => (props.text_size === "large" ? "16px" : "14px")};
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.one};
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    & h1,
    & h2 {
      font-size: ${(props) => (props.text_size === "large" ? "32px" : "24px")};
    }
  }
`;

export const StyledHeaderContentWrapper = styled.div`
  max-width: 900px;
  width: 80%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    max-width: calc(100% - 240px);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledHeaderAssetWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 280px;
  max-width: 260px;

  & img {
    position: relative;
    border-radius: inherit;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;
