import * as React from "react";
import { Overview, OverviewProps } from "./Overview";
import { blokType, sbLinkType, storyType } from "../../types";
import { OpenProgramsOverviewStoriesCmp } from "./OpenProgramsOverviewStories";
import { OpenProgramsOverviewQueryCmp } from "./OpenProgramsOverviewQuery";
import {
  StyledOpenProgramsOverviewWrapper,
  StyledOpenProgramsOverview,
  StyledOpenProgramsOverviewContent,
  StyledOpenProgramsOverviewTopSVG,
  StyledOpenProgramsOverviewBottomSVG,
} from "./partials";

export interface OpenProgramsOverviewProps {
  blok: blokType &
    OverviewProps & {
      cards: Array<
        storyType & {
          content: string | blokType;
        }
      >;
      folder: sbLinkType;
      card_link_label?: string;
      tag?: Array<string>;
    };
}

export const OpenProgramsOverview: React.FC<OpenProgramsOverviewProps> = ({
  blok,
}) => {
  const {
    title,
    body_copy,
    cta_label,
    cta_link,
    cards,
    folder,
    tag,
    card_link_label,
  } = blok;

  return (
    <StyledOpenProgramsOverviewWrapper>
      <StyledOpenProgramsOverview>
        <StyledOpenProgramsOverviewTopSVG>
          <svg
            viewBox="0 0 1200 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            vectorEffect="non-scaling-stroke"
            preserveAspectRatio="xMidYMin slice"
          >
            <path
              d="M585.404 38.3193C400.793 28.754 215.634 19.1671 31.0226 9.60181C18.7094 8.94472 8.06347 5.52066 -1.80487e-05 0.298008L-2.01941e-05 -63.9982L1200.04 -63.9984L1200.04 15.0017C1200.04 25.5018 1163.99 47.8799 1135.86 55.5839C1092.72 67.057 1045.28 62.5436 999.954 59.9967C954.081 57.4282 908.741 55.2574 862.869 52.6889C770.016 47.8846 677.71 43.1019 585.404 38.3193Z"
              fill="white"
            />
          </svg>
        </StyledOpenProgramsOverviewTopSVG>
        <StyledOpenProgramsOverviewContent
          bodyExists={body_copy ? true : false}
        >
          <Overview
            title={title}
            component="open-programs-overview"
            body_copy={body_copy}
            cta_label={cta_label}
            cta_link={cta_link}
            cta_colour={"intensity"}
            column_layout="three"
          >
            <OpenProgramsOverviewQueryCmp
              folder={folder}
              card_link_label={card_link_label}
              showPagination={!cta_label}
              tag={tag}
            />
          </Overview>
        </StyledOpenProgramsOverviewContent>
        <StyledOpenProgramsOverviewBottomSVG>
          <svg
            viewBox="0 0 1200 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            vectorEffect="non-scaling-stroke"
            preserveAspectRatio="xMidYMin slice"
          >
            <path
              alignmentBaseline="baseline"
              d="M0 70.7954C15.9521 64.2977 31.9767 59.4728 49.1426 55.9031C123.135 40.5152 198.945 41.5665 274.04 44.3383C355.923 47.3547 437.128 41.146 518.965 37.5102C633.914 32.4102 748.917 28.266 863.798 21.6367C887.117 20.291 910.586 17.7563 933.929 17.1637C934.771 17.0108 935.609 16.854 936.695 16.6667C945.336 15.1834 953.996 13.7688 962.648 12.3428C1002.69 5.76325 1044.91 -2.59785 1085.71 0.770287C1129.42 4.37138 1170.1 17.0162 1200 47.2837C1200 47.2837 1200 55.1209 1200 70.7954V70.9609H0V70.7954Z"
              fill="white"
            />
          </svg>
        </StyledOpenProgramsOverviewBottomSVG>
      </StyledOpenProgramsOverview>
    </StyledOpenProgramsOverviewWrapper>
  );
};
