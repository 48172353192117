import styled from "styled-components";

export const StyledImageGalleryItem = styled.div`
  width: 100%;
  height: 636px;
  border-radius: 4px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 225px;
  }

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  > div {
    height: 540px;
    border-radius: 4px;
  }

  .react-player__preview {
    border-radius: 4px;
  }

  & iframe {
    height: 100%;
    border: none;
    border-radius: inherit;
  }

  & video {
    height: 100%;
    width: 100%;
    border: inherit;
    border-radius: 4px;
  }
`;

export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;
