import styled from "styled-components";

import { hefBrandColorsPlusNeutrals } from "../../../types";

interface StyledBlenderContainerProps {
  color?: hefBrandColorsPlusNeutrals;
  position?: "top" | "bottom";
  flip?: boolean;
}

export const StyledBlenderContainer = styled.div<StyledBlenderContainerProps>`
  position: relative;
  height: ${(props) => props.theme.spacing.seven};
  width: 100%;
  margin-bottom: -1px;

  ${({ position, flip }) =>
    position === "bottom"
      ? flip
        ? "transform: rotate(180deg) scaleX(-1)"
        : "transform: rotate(180deg)"
      : flip && "transform: scaleX(-1)"};

  & svg {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: inherit;
    overflow: visible;

    & path {
      fill: ${(props) =>
    props.color
      ? props.color === "heritage" || props.color === "intensity" || props.color === "copper"
        ? props.theme.colors.warmths[props.color]
        : props.theme.colors.neutrals[props.color]
      : props.theme.colors.warmths.heritage};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-height: ${(props) => props.theme.spacing.four};
    height: ${(props) => props.theme.spacing.four};
  }
`;
