import React, { useState } from "react";

import { Modal } from "../Modal";
import { LargeVideoCmp } from "../LargeVideo/LargeVideo";
import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";

import { sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";

import {
  StyledOverviewHeader,
  StyledOverviewHeaderContent,
  StyledOverviewHeaderTitle,
  StyledOverviewHeaderSubHeader,
  StyledOverviewHeaderTextContainer,
  StyledOverviewHeaderImgContainer,
  StyledOverviewHeaderImgPlaceholder,
  StyledIconPlayWrapper,
} from "./partials";

export interface OverviewHeaderProps {
  title: string;
  sub_header: string;
  video_link?: string;
  video?: sbAssetType;
  video_preview?: sbAssetType;
}

export const OverviewHeaderCmp: React.FC<OverviewHeaderProps> = ({
  title,
  sub_header,
  video_link,
  video,
  video_preview,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const videoBool: boolean = !!video?.filename || !!video_link;

  return (
    <>
      <StyledOverviewHeader>
        <StyledOverviewHeaderContent>
          <StyledOverviewHeaderTextContainer>
            <div>
              <StyledOverviewHeaderTitle>{title}</StyledOverviewHeaderTitle>
              <StyledOverviewHeaderSubHeader>
                {sub_header}
              </StyledOverviewHeaderSubHeader>
            </div>
          </StyledOverviewHeaderTextContainer>
          {video_preview && (
            <StyledOverviewHeaderImgContainer
              video={videoBool}
              onClick={videoBool ? (_) => setOpenModal(true) : undefined}
            >
              <StyledOverviewHeaderImgPlaceholder
                src={sbImageService(video_preview.filename, undefined, "0x0")}
                alt={video_preview?.alt}
                title={video_preview?.title}
              />
              {videoBool && (
                <StyledIconPlayWrapper>
                  <IconPlay />
                </StyledIconPlayWrapper>
              )}
            </StyledOverviewHeaderImgContainer>
          )}
        </StyledOverviewHeaderContent>
      </StyledOverviewHeader>
      {videoBool && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          {openModal && (
            <LargeVideoCmp
              video={video}
              video_preview={video_preview}
              video_link={video_link}
              autoplay
            />
          )}
        </Modal>
      )}
    </>
  );
};
