import React from "react";
import { EmailShareButton } from "react-share";
import IconMail from "@ef-global/web-ui-react/lib/icons/IconMail";

interface EmailShareProps {
  share_url: string;
}

const EmailShareCmp: React.FC<EmailShareProps> = ({ share_url }) => (
  <EmailShareButton url={share_url}>
    <IconMail />
  </EmailShareButton>
);

export default EmailShareCmp;
