import styled from "styled-components";

interface StyledLearningMetaItems {}

export const StyledLearningMetaItems = styled.div`
  color: ${(props) => props.theme.colors.neutrals.white};
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  padding: ${(props) => props.theme.spacing.three} 0;
  max-width: 629px;

  &:nth-last-of-type(1) {
    border-bottom: none;
    margin-bottom: ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding-right: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.three};
    padding-top: 0;
  }
`;
