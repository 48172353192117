import styled from "styled-components";

export const StyledArticleShare = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three};
  }
`;

export const StyledArticleShareContent = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  grid-gap: 32px;

  max-width: ${(props) => props.theme.articleMaxWidth};
  margin: 0 auto;

  & p {
    margin: 0;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.bold};
  }
`;

export const StyledShareButton = styled.button`
  display: flex;
  align-items: baseline;
  height: 16px;
  width: 16px;
  position: relative;

  & svg {
    height: 100%;
    width: 100%;
  }
`;
