import styled, { css } from "styled-components";

import breaker from "../../../assets/Graphics/ProductHeroBreaker.svg";
import { StyledButton } from "../../Button/partials";

interface Props {
  flipBreaker?: boolean;
}

export const ProductHeroBackgroundWrapper = styled.div<Props>`
  position: relative;
  width: 100vw;
  background: ${({ theme }) => theme.colors.neutrals.offwhite};

  :after {
    position: absolute;
    width: 100vw;
    height: 64px;
    content: "";
    background: no-repeat url(${breaker});
    background-size: cover;
    bottom: 0px;
    ${({ flipBreaker }) =>
    flipBreaker &&
      css`
        transform: scaleX(-1);
      `}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
      display: none;
    }
  }
`;

export const ProductHeroWrapper = styled.div<Props>`
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.three};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    column-gap: ${({ theme }) => theme.spacing.two};
    row-gap: ${({ theme }) => theme.spacing.three};
    padding-bottom: ${({ theme }) => theme.spacing.six};
  }
`;

export const ProductHeroContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  gap: ${({ theme }) => theme.spacing.three};
  z-index: 10;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: span 4;
    grid-row: 2;

    ${StyledButton} {
      margin-top: 0;
    }

    :before {
      position: absolute;
      width: 100vw;
      height: 32px;
      content: "";
      background: no-repeat url(${breaker});
      background-size: cover;
      left: -${({ theme }) => theme.spacing.three};
      top: -66px;
    }

    :after {
      position: absolute;
      width: 100vw;
      height: calc(100% + 82px);
      content: "";
      background: white;
      background-size: contain;
      background-position: top center;
      bottom: -${({ theme }) => theme.spacing.six};
      left: -${({ theme }) => theme.spacing.three};
      z-index: -1;
    }
  }
`;

export const ProductHeroCTAWrapper = styled.div`
  display: flex;
  gap: 11px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.three};
    align-items: center;
  }
`;

export const ProductHeroMediaWrapper = styled.div`
  grid-column: 7 / span 6;
  display: flex;
  align-items: center;
  min-height: 620px;

  img {
    width: 100%;
    height: 310px;
    overflow-x: visible;
    transform-origin: 120px center;
    transform: scale(2, 2);
    object-fit: contain;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: ${({ theme }) =>
  theme.maxWidth}) {
    img {
      transform-origin: 40px center;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: span 4;
    grid-row: 1;
    height: auto;
    width: 100%;
    justify-self: center;
    min-height: auto;

    img {
      transform: none;
      height: auto;
    }
  }
`;

export const ProductHeroTitle = styled.h1`
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  grid-column: 1 / span 6;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
  }
`;

export const ProductHeroBody = styled.p`
  white-space: pre-line;
  margin: 0;
  font-weight: 300;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: center;
  }
`;
