import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { OptimizeCmp, IOptimize } from "./Optimize";

interface OptimizeWBlokProps {
  blok: IOptimize & blokType;
}

export const Optimize: React.FC<OptimizeWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <OptimizeCmp
        experiment_id={blok.experiment_id}
        variants={blok.variants}
      ></OptimizeCmp>
    </SbEditable>
  );
};
