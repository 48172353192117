import React, { useState } from "react";

import { hefBrandColors, sbLinkType } from "../../../types";
import { ButtonHEF } from "../../Button";
import { FormSC, FormSCProps } from "../../Forms/FormSC";
import { Modal } from "../../Modal";
import { getKey, getUrl } from "../../utils/helpers";
import {
  StyledEventHeadline,
  StyledEventHeadlineAside,
  StyledEventHeadlineAsideBody,
  StyledEventHeadlineAsideHeader,
  StyledEventHeadlineContent,
  StyledEventHeadlineMain,
  StyledEventHeadlineMainBody,
  StyledEventHeadlineMainHeader,
} from "./partials";

export interface EventHeadlineProps {
  _uid: string;
  title: string;
  sub_title: string;
  cta_precursor_title: string;
  cta_precursor_body: string;
  cta_label: string;
  cta_form?: Array<FormSCProps>;
  cta_link?: sbLinkType;
  cta_colour?: hefBrandColors;
  event_location?: string;
  event_date?: string;
  event_time?: string;
}

export const EventHeadline: React.FC<EventHeadlineProps> = ({
  _uid,
  title,
  sub_title,
  cta_precursor_title,
  cta_precursor_body,
  cta_label,
  cta_form,
  cta_link,
  cta_colour = "intensity",
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <StyledEventHeadline key={getKey("event-headline-", _uid)}>
        <StyledEventHeadlineContent>
          <StyledEventHeadlineMain>
            <StyledEventHeadlineMainHeader>{title}</StyledEventHeadlineMainHeader>
            <StyledEventHeadlineMainBody>{sub_title}</StyledEventHeadlineMainBody>
          </StyledEventHeadlineMain>
          <StyledEventHeadlineAside>
            <StyledEventHeadlineAsideHeader>{cta_precursor_title}</StyledEventHeadlineAsideHeader>
            <StyledEventHeadlineAsideBody>{cta_precursor_body}</StyledEventHeadlineAsideBody>
            {Array.isArray(cta_form) && cta_form.length === 1 && (
              <ButtonHEF
                color={cta_colour}
                variant="primary"
                parentComponent={"event-headline"}
                onClick={(_: MouseEvent) => setOpenModal(true)}
                label={cta_label}
              ></ButtonHEF>
            )}
            {Array.isArray(cta_form) && cta_form.length !== 1 && cta_link && (
              <ButtonHEF
                as={"a"}
                variant="primary"
                href={getUrl(cta_link)}
                label={cta_label}
                color={cta_colour}
              ></ButtonHEF>
            )}
          </StyledEventHeadlineAside>
        </StyledEventHeadlineContent>
      </StyledEventHeadline>
      {Array.isArray(cta_form) && cta_form.length === 1 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            title={cta_form[0].title}
            qualifier={cta_form[0].qualifier}
            onClose={(_: void) => setOpenModal(false)}
            _uid={cta_form[0]._uid}
            form={cta_form[0].form}
            component={cta_form[0].component}
            form_handler={cta_form[0].form_handler}
            sf_campaign_id={cta_form[0].sf_campaign_id}
            event_date={cta_form[0].event_date}
            event_duration={cta_form[0].event_duration}
            event_location={cta_form[0].event_location}
            thank_you_components={cta_form[0].thank_you_components}
            close_on_submit={cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
