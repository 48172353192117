import React from "react";

import { sbAssetType } from "../../types";

import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import { sbImageService } from "hult-sb-ui";

import {
  StyledIllustrationCardItem,
  StyledIllustrationCardItemImg,
  StyledIllustrationCardItemImgContainer,
  StyledIllustrationCardItemLink,
} from "./partials";
import { LinkItemProps } from "../LinkItem";
import { getUrl } from "../utils/helpers";

export interface IllustrationCardItemProps {
  image: sbAssetType;
  link: Array<LinkItemProps>;
}

export const IllustrationCardItem: React.FC<IllustrationCardItemProps> = ({
  image,
  link,
}) => {
  function handleClick() {
    window.dataLayer.push({
      event: "link_click",
      eventMeta: {
        p_one: "illustration-card-item",
        p_two: link[0]?.name,
        p_three: getUrl(link[0].link),
      },
    });
  }

  return (
    <StyledIllustrationCardItem>
      <Surface
        as="a"
        href={getUrl(link[0].link)}
        rounded
        outline
        onClick={(_) => handleClick()}
      >
        <StyledIllustrationCardItemImgContainer>
          <StyledIllustrationCardItemImg
            src={sbImageService(
              image.filename,
              undefined,
              "0x234",
              image?.focus
            )}
            alt={image.alt}
            title={image?.title}
          />
        </StyledIllustrationCardItemImgContainer>

        {Array.isArray(link) &&
          link.length === 1 &&
          link.map((item) => (
            <StyledIllustrationCardItemLink>
              <p>{item.name}</p>
              <IconArrowRight />
            </StyledIllustrationCardItemLink>
          ))}
      </Surface>
    </StyledIllustrationCardItem>
  );
};
