import React, { useState, useRef } from "react";

import { Modal } from "../Modal";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import IconArrowLeft from "@ef-global/web-ui-react/lib/icons/IconArrowLeft";

import {
  FacultyCarouselCardProps,
  FacultyCarouselCard,
} from "./FacultyCarouselCard";

import { FacultyCarouselModal } from "./FacultyCarouselModal";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

import {
  StyledFacultyCarousel,
  StyledFacultyCarouselItemWrapper,
  StyledFacultyCarouselTextContainer,
  StyledFacultyCarouselTextHeader,
  StyledFacultyCarouselTextSubheader,
  StyledFacultyCarouselImagesContainer,
  StyledFacultyCarouselPaginationContainer,
  StyledFacultyCarouselPagination,
  StyledFacultyCarouselPaginationDot,
  StyledFacultyCarouselControls,
  StyledFacultyCarouselControlsItem,
} from "./partials";

export interface FacultyCarouselProps {
  title: string;
  sub_header: string;
  cards: Array<FacultyCarouselCardProps>;
  divider: 2 | 4;
  show_qualifier_modal?: boolean;
}

export const FacultyCarouselCmp: React.FC<FacultyCarouselProps> = ({
  title,
  sub_header,
  cards,
  divider = 4,
  show_qualifier_modal = false,
}) => {
  const [current, setCurrent] = useState(0);
  const [modalSlide, setModalSlide] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const swiperRef = useRef(null);

  const groups = Math.ceil(cards.length / divider);
  const split = cards.length < divider ? cards.length : divider;
  const modalCards = cards.filter((item) => item.body_copy);

  function handleOpenModal(idx: number) {
    const modalIdx = modalCards.findIndex(
      (item) => item.title === cards[idx].title
    );

    if (modalIdx != -1) {
      setModalSlide(modalIdx);
    } else {
      setModalSlide(0);
    }

    setOpenModal(true);
  }

  return (
    <>
      <StyledFacultyCarousel>
        <StyledFacultyCarouselItemWrapper
          one={cards.length === 1 ? true : false}
        >
          <StyledFacultyCarouselTextContainer
            one={cards.length === 1 ? true : false}
          >
            <StyledFacultyCarouselTextHeader>
              {title}
            </StyledFacultyCarouselTextHeader>
            <StyledFacultyCarouselTextSubheader>
              {sub_header}
            </StyledFacultyCarouselTextSubheader>
          </StyledFacultyCarouselTextContainer>
          {cards.length > 1 && (
            <>
              <StyledFacultyCarouselImagesContainer>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={"auto"}
                  spaceBetween={16}
                  centeredSlides={false}
                  onSlideChange={(swiper) => setCurrent(swiper?.snapIndex)}
                  breakpoints={{
                    "768": {
                      slidesPerView: split,
                      slidesPerGroup: split,
                      spaceBetween: 24,
                    },
                  }}
                >
                  {cards.map((item, idx) => (
                    <SwiperSlide key={`swiper-slide-${idx}`}>
                      <FacultyCarouselCard
                        title={item.title}
                        qualifier={item.qualifier}
                        image={item.image}
                        body_copy={item.body_copy}
                        clickable={item.body_copy ? true : false}
                        handleClick={
                          item.body_copy
                            ? (_: any) => handleOpenModal(idx)
                            : undefined
                        }
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </StyledFacultyCarouselImagesContainer>
              <StyledFacultyCarouselPaginationContainer>
                {cards.length > split && groups > 0 && (
                  <StyledFacultyCarouselPagination desktop>
                    {[...Array(groups)].map((item, idx) => (
                      <StyledFacultyCarouselPaginationDot
                        key={`pagination-dot-desktop-${idx}`}
                        isActive={idx === current}
                      />
                    ))}
                  </StyledFacultyCarouselPagination>
                )}
                {cards.length > 1 && (
                  <StyledFacultyCarouselPagination>
                    {cards.map((item, idx) => (
                      <StyledFacultyCarouselPaginationDot
                        key={`pagination-dot-mobile-${idx}`}
                        isActive={idx === current}
                      />
                    ))}
                  </StyledFacultyCarouselPagination>
                )}
                {cards.length > split && (
                  <StyledFacultyCarouselControls>
                    <StyledFacultyCarouselControlsItem
                      onClick={() => swiperRef.current.swiper.slidePrev()}
                      disabled={current === 0}
                    >
                      <IconArrowLeft />
                    </StyledFacultyCarouselControlsItem>
                    <StyledFacultyCarouselControlsItem
                      onClick={() => swiperRef.current.swiper.slideNext()}
                      disabled={current === groups - 1}
                    >
                      <IconArrowRight />
                    </StyledFacultyCarouselControlsItem>
                  </StyledFacultyCarouselControls>
                )}
              </StyledFacultyCarouselPaginationContainer>
            </>
          )}
          {cards.length === 1 && (
            <div>
              <FacultyCarouselCard
                title={cards[0].title}
                qualifier={cards[0].qualifier}
                image={cards[0].image}
                body_copy={cards[0].body_copy}
                clickable={cards[0].body_copy ? true : false}
                handleClick={
                  cards[0].body_copy
                    ? (_: any) => setOpenModal(true)
                    : undefined
                }
              />
            </div>
          )}
        </StyledFacultyCarouselItemWrapper>
      </StyledFacultyCarousel>

      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        {openModal && (
          <FacultyCarouselModal
            cards={modalCards}
            initialSlide={modalSlide}
            show_qualifier_modal={show_qualifier_modal}
          />
        )}
      </Modal>
    </>
  );
};
