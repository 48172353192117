import styled from "styled-components";

import { theme } from "../../../styles/theme";

interface StyledHeaderWrapperProps {
  readonly reverse?: boolean;
  background_colour?: "offwhite" | "white" | "black";
  color?: "white" | "offwhite" | "black";
}
interface StyledHeaderDescriptionProps {
  background_colour?: "offwhite" | "white" | "black";
}

export const StyledHeader = styled.div<StyledHeaderWrapperProps>`
  padding: ${(props) => props.theme.spacing.seven} 0;

  ${(props) =>
    props.background_colour === "offwhite" &&
    `
      background-color: #F6F3EF;
  `}
  ${({ background_colour, theme }) =>
    background_colour === "white" &&
    `
      background-color: ${theme.colors.neutrals.white}
  `}
  ${({ background_colour, theme }) =>
    background_colour === "black" &&
    `
      background-color: ${theme.colors.neutrals.black};
      color: ${theme.colors.neutrals.white};
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledHeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  max-width: ${(props) => props.theme.maxWidthWPadding};
  padding: 0 ${({ theme }) => theme.spacing.three};
  margin: 0 auto;

  & h1,
  & h2 {
    font-size: 32px;
    margin-bottom: ${(props) => props.theme.spacing.one};
    color: ${(props) => (props.background_colour === "black" ? theme.colors.neutrals.white : "inherit")};
    font-weight: ${(props) => props.theme.font.bold};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column-reverse;

    & h1,
    & h2 {
      font-size: 24px;
      margin-top: ${(props) => props.theme.spacing.three};
      margin-bottom: ${(props) => props.theme.spacing.one};
      color: ${(props) => (props.background_colour === "black" ? theme.colors.neutrals.white : "inherit")};
      font-weight: ${(props) => props.theme.font.bold};
    }
  }
`;

export const StyledHeaderContentWrapper = styled.div<StyledHeaderWrapperProps>`
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex-basis: 41%;
  font-weight: ${(props) => (props.background_colour !== "black" ? theme.font.light : theme.font.book)};

  & a,
  & a:hover {
    color: ${(props) => (props.background_colour === "black" ? theme.colors.neutrals.white : "inherit")};
    font-weight: ${(props) => (props.background_colour !== "black" ? theme.font.light : theme.font.book)} !important;
  }

  & a:visited {
    text-decoration: underline;
  }

  & p {
    font-weight: ${(props) => (props.background_colour !== "black" ? theme.font.light : theme.font.book)};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-basis: 100%;
  }
`;

export const StyledHeaderDescription = styled.div<StyledHeaderDescriptionProps>`
  white-space: pre-line;
  padding-top: ${(props) => props.theme.spacing.one};
  padding-bottom: ${(props) => props.theme.spacing.two};

  & p {
    margin-bottom: ${(props) => props.theme.spacing.one};
    color: ${(props) => (props.background_colour === "black" ? theme.colors.neutrals.white : "inherit")};
  }

  & ul {
    padding-left: ${(props) => props.theme.spacing.one};
    margin-left: 1em;
    margin-bottom: 0;
    margin-top: 0;

    & p {
      margin-bottom: 0;
    }
  }
`;

export const StyledHeaderAssetWrapper = styled.div<StyledHeaderWrapperProps>`
  max-height: 308px;
  position: relative;
  flex-basis: 50%;
  width: 100%;
  border-radius: 4px;

  & img {
    border-radius: inherit;
    height: 100%;
    max-height: 308px;
    width: 100%;
    object-fit: cover;
  }

  & iframe {
    height: 100%;
    border: none;
    max-height: 263px;
    border-radius: inherit;
  }

  & video {
    height: 100%;
    width: 100%;
    max-height: 263px;
    border: inherit;
    border-radius: 4px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-basis: 100%;
    margin: 0 auto;
    margin-bottom: 0;
  }
`;

export const StyledPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export const StyledIconPlayWrapper = styled.div`
  width: 72px;
  height: 72px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.2);
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;
