import React from "react";

import { hefBrandColorsPlusNeutrals } from "../../types";
import { StyledBlenderContainer } from "./partials";

interface BlenderProps {
  color?: hefBrandColorsPlusNeutrals;
  position?: "top" | "bottom";
  flip?: boolean;
}

export const Blender: React.FC<BlenderProps> = (props) => {
  return (
    <StyledBlenderContainer {...props}>
      <svg
        viewBox="0 0 1200 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        vectorEffect="non-scaling-stroke"
        preserveAspectRatio="xMidYMin slice"
      >
        <path d="M1200 64L0 64L0 63.0936C8.29675 61.2286 14.6313 59.8763 19.0036 59.0368C103.445 42.8272 189.959 43.9344 275.656 46.8518C369.104 50.032 461.774 43.4895 555.162 39.6621C686.349 34.2825 817.592 29.9191 948.687 22.9317C975.299 21.516 1002.09 18.8464 1028.72 18.2195C1029.68 18.0577 1030.64 17.8959 1031.88 17.6987C1041.75 16.1364 1051.62 14.6449 1061.49 13.1433C1107.19 6.2064 1153.43 -2.74584 1200 0.803483C1200 0.803483 1200 21.869 1200 64Z" />
      </svg>
    </StyledBlenderContainer>
  );
};
