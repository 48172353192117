import styled from "styled-components";

import { StyledLinkItem } from "../../LinkItem/partials";

interface Props {
  breaker?: boolean;
  bgColour?: "white" | "offwhite";
  leftSide?: boolean;
}

export const ColumnBodyBackgroundWrapper = styled.div<Props>`
  width: 100vw;
  background: ${({ bgColour, theme }) => bgColour === "offwhite" && theme.colors.neutrals.offwhite};
`;

export const ColumnBodyWrapper = styled.div<Props>`
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  padding: ${({ theme }) => theme.spacing.seven} ${({ theme }) => theme.spacing.three};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};

  ul,
  ol {
    margin: 0;
    padding: 0 0 0 18.5px;
  }

  li {
    padding: 0;
  }

  ul,
  ol,
  p {
    font-weight: 300;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};

    div:first-child {
      margin-bottom: ${({ theme }) => theme.spacing.three};
    }
  }
`;

export const ColumnBodyTitle = styled.h2`
  grid-column: 1 / span 5;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`;

export const ColumnBodyContentWrapper = styled.div<Props>`
  grid-column: ${({ leftSide }) => (leftSide ? "1 / span 5" : "7 / span 5")};

  ${StyledLinkItem} {
    margin-top: ${({ theme }) => theme.spacing.three};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;

    ${StyledLinkItem} {
      margin-top: 0;
    }
  }
`;

export const ColumnBodyRichTextWrapper = styled.div``;
