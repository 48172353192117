import styled from "styled-components";

export const StyledIllustrationCardItem = styled.div`
  position: relative;
  & a {
    padding: 0;
  }
`;

export const StyledIllustrationCardItemImgContainer = styled.div`
  height: auto;
  padding: 4px 4px 0px 4px;
  width: 100%;
`;

export const StyledIllustrationCardItemImg = styled.img`
  border-radius: 4px;
  background-color: #ffffff;
  height: 234px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 214px;
  }
`;

export const StyledIllustrationCardItemLink = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  padding: 24px;
  border-radius: inherit;
  background-color: ${(props) => props.theme.colors.neutrals.white};

  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  vertical-align: bottom;

  & p {
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.black};
    margin: 0;
  }

  & svg {
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.black};
  }

  :lang(ar) {
    & svg {
      transform: rotate(180deg);
      margin-left: ${({ theme }) => theme.spacing.one};
    }
  }
`;
