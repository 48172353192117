import React from "react";
import { Helmet } from "react-helmet";

export interface MetaDataProps {
  meta_title: string;
  meta_description: string;
  og_title: string;
  og_description: string;
  og_image: string;
  og_image_alt: string; // based on og description
  og_type: string;
  og_site_name: string;
  twitter_card: string;
  twitter_image: string;
  twitter_site: string;
  twitter_creator: string; // based on twiter site
  structured_data: string;
  no_index: boolean;
  canonical_link: string;
  og_url: string; // page url
}

export const MetaDataCmp: React.FC<MetaDataProps> = ({
  meta_title,
  meta_description,
  og_title,
  og_description,
  og_image,
  og_image_alt, // based on og description
  og_type,
  og_site_name,
  twitter_card,
  twitter_image,
  twitter_site,
  twitter_creator, // based on twiter site
  structured_data,
  no_index,
  canonical_link,
  og_url,
}) => (
  <Helmet>
    {meta_title && <title>{meta_title}</title>}
    {meta_description && <meta name="description" content={meta_description} />}

    {canonical_link && <link rel="canonical" href={canonical_link} />}
    {no_index && <meta name="robots" content="noindex" />}

    {og_title && <meta property="og:title" content={og_title} />}
    {og_description && (
      <meta property="og:description" content={og_description} />
    )}
    {og_image && <meta property="og:image" content={og_image} />}
    {og_image && og_image_alt && (
      <meta property="og:image:alt" content={og_image_alt} />
    )}

    {og_site_name && <meta property="og:site_name" content={og_site_name} />}
    {og_type && <meta property="og:type" content={og_type} />}
    {og_url && <meta property="og:url" content={og_url} />}

    {twitter_card && <meta name="twitter:card" content={twitter_card} />}
    {twitter_site && <meta name="twitter:site" content={twitter_site} />}
    {twitter_creator && (
      <meta name="twitter:creator" content={twitter_creator} />
    )}
    {twitter_image && (
      <meta
        name="twitter:image"
        property="twitter:image"
        content={twitter_image}
      />
    )}

    {structured_data && (
      <script type="application/ld+json">{structured_data}</script>
    )}
  </Helmet>
);
