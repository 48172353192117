import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { OverviewHeaderCmp, OverviewHeaderProps } from "./OverviewHeader";

interface OverviewHeaderWBlokProps {
  blok: OverviewHeaderProps & blokType;
}

export const OverviewHeader: React.FC<OverviewHeaderWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <OverviewHeaderCmp
        title={blok.title}
        sub_header={blok.sub_header}
        video={blok.video}
        video_preview={blok.video_preview}
        video_link={blok.video_link}
      ></OverviewHeaderCmp>
    </SbEditable>
  );
};
