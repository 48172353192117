import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { MultiQuoteCmp, MultiQuoteProps } from "./MultiQuote";

interface MultiQuoteWBlokProps {
  blok: MultiQuoteProps & blokType;
}

export const MultiQuote: React.FC<MultiQuoteWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <MultiQuoteCmp
        items={blok.items}
        background={blok.background}
        breaker={blok.breaker}
        graphic={blok.graphic}
      ></MultiQuoteCmp>
    </SbEditable>
  );
};
