import * as React from "react";
import {
  StyledGate,
  StyledGateWrapper,
  StyledGateBtnGroup,
  StyledGateForm,
} from "./partials";
import CookieContext from "../../context/CookieContext";
import { blokType, sbLinkType } from "../../types";
import { ButtonHEF } from "../Button";
import { Modal } from "../Modal";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { LinkItem } from "../LinkItem";

interface IGateProps {
  gate_title: string;
  gate_description: string;
  gate_main_cta_label: string;
  gate_main_cta_colour: "heritage" | "copper" | "intensity";
  gate_main_cta_form: Array<blokType & FormSCProps>;
  gate_secondary_cta_label: string;
  gate_secondary_cta_link: sbLinkType;
}

const Gate: React.FunctionComponent<IGateProps> = ({
  gate_title,
  gate_description,
  gate_main_cta_label,
  gate_main_cta_colour,
  gate_main_cta_form,
  gate_secondary_cta_label,
  gate_secondary_cta_link,
}) => {
  const { set } = React.useContext(CookieContext);
  const [openModal, setOpenModal] = React.useState(true);
  const [halfModal, setHalfModal] = React.useState(true);
  const [animation, setAnimation] = React.useState(false);

  function handleFormSubmit() {
    setAnimation(true);

    setTimeout((_) => {
      setOpenModal(false);
      set && set({ gateClose: true });
    }, 500);
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => setHalfModal(true)}
        half={halfModal}
        showClose={!halfModal}
        closeAnimation={false}
        triggerClose={animation}
      >
        <StyledGate formVisible={!halfModal}>
          <StyledGateWrapper>
            <h4>{gate_title}</h4>
            <p>{gate_description}</p>
            <StyledGateBtnGroup>
              <ButtonHEF
                label={gate_main_cta_label}
                color={gate_main_cta_colour}
                parentComponent="content-gate"
                onClick={() => setHalfModal(false)}
              />
              <LinkItem
                link={gate_secondary_cta_link.cached_url}
                name={gate_secondary_cta_label}
                link_target="_self"
                parentComponent="content-gate"
              />
            </StyledGateBtnGroup>
          </StyledGateWrapper>
        </StyledGate>
        <StyledGateForm formVisible={!halfModal}>
          {gate_main_cta_form[0] && (
            <FormSC
              _uid={gate_main_cta_form[0]._uid}
              component={gate_main_cta_form[0].component}
              form={gate_main_cta_form[0].form}
              form_handler={gate_main_cta_form[0].form_handler}
              sf_campaign_id={gate_main_cta_form[0].sf_campaign_id}
              title={gate_main_cta_form[0].title}
              qualifier={gate_main_cta_form[0].qualifier}
              event_date={gate_main_cta_form[0].event_date}
              event_duration={gate_main_cta_form[0].event_duration}
              event_location={gate_main_cta_form[0].event_location}
              thank_you_components={gate_main_cta_form[0].thank_you_components}
              close_on_submit={gate_main_cta_form[0].close_on_submit}
              onClose={() => handleFormSubmit()}
            />
          )}
        </StyledGateForm>
      </Modal>
    </>
  );
};

export default Gate;
