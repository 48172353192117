import styled from "styled-components";
import { focusBorder, validColor } from "./helpers";

interface StyledTelephoneProps {
  hidden?: boolean;
  valid?: boolean;
}

export const StyledTelephoneWrapper = styled.div<StyledTelephoneProps>`
  display: ${({ hidden }) => (hidden ? "none;" : "block;")}
  width: 100%;
  height: auto;
  font-size: 14px;

  & label {
    width: 100%;
    font-size: 14px;
    line-height: 1;
    margin-bottom: ${(props) => props.theme.spacing.one};
    color: #2b2b2b;
    padding: 0 1.6rem 0 0;
    display: flex;
    justify-content: space-between;

    & span{
      color: #EE6160;

    }
  }

  > div {
    display: flex;
    border-radius: 4px;
    border: 1px solid ${(props) => validColor(props.valid)};
  }

  > div:focus-within {
    border: ${focusBorder()}
  }
`;
