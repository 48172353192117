import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { VideoGalleryCmp, VideoGalleryProps } from "./VideoGallery";

interface IVideoGalleryWBlokProps {
  blok: VideoGalleryProps & blokType;
}

export const VideoGallery: React.FC<IVideoGalleryWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <VideoGalleryCmp title={blok.title} videos={blok.videos}></VideoGalleryCmp>
    </SbEditable>
  );
};
