import * as React from "react";
import { Overview, OverviewProps } from "./Overview";
import { blokType, sbLinkType, storyType } from "../../types";
import { StyledEventOverview, StyledOverviewWrapper } from "./partials";
import { EventOverviewStoriesCmp } from "./EventOverviewStories";
import { EventContent } from "../Templates/Event/event";
import { EventOverviewQueryCmp } from "./EventOverviewQuery";

export interface EventOverviewProps {
  blok: blokType &
    OverviewProps & {
      cards: Array<
        storyType & {
          content: string | (EventContent & blokType);
        }
      >;
      folder: sbLinkType;
      card_link_label?: string;
      tag?: Array<string>;
    };
}

export const EventOverview: React.FunctionComponent<EventOverviewProps> = ({
  blok,
}) => {
  const {
    title,
    body_copy,
    cta_label,
    cta_link,
    cta_colour,
    cards,
    folder,
    tag,
    card_link_label,
  } = blok;
  return (
    <StyledEventOverview>
      <StyledOverviewWrapper>
        <Overview
          title={title}
          component="event-overview"
          body_copy={body_copy}
          cta_label={cta_label}
          cta_link={cta_link}
          cta_colour={cta_colour}
        >
          {/* {cards.length > 0 ? (
            <EventOverviewStoriesCmp
              cards={cards}
              card_link_label={card_link_label}
            />
          ) : ( */}
          <EventOverviewQueryCmp
            folder={folder}
            card_link_label={card_link_label}
            showPagination={!cta_label}
            tag={tag}
          />
          {/* )} */}
        </Overview>
      </StyledOverviewWrapper>
    </StyledEventOverview>
  );
};
