import React from "react";
import { uuid } from "uuidv4";

import {
  StyledPodcast,
  StyledPodcastWrapper,
  StyledPodcastHeader,
  StyledPodcastBody,
  StyledPodcastFrameWrapper,
} from "./partials";

export interface PodcastProps {
  title: string;
  description: string;
  podcast: string;
}

export const PodcastCmp: React.FC<PodcastProps> = ({
  title,
  description,
  podcast,
}) => {
  const params = new Proxy(new URLSearchParams(podcast), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  // @ts-ignore
  let display = params?.display;
  // @ts-ignore
  let playlist = params?.playlist;

  function getHeight() {
    let val = 0;

    switch (display) {
      case "vertical":
        val = 500;
        break;
      case "horizontal":
        val = 220;
    }

    return playlist ? `${val + 200}px` : `${val}px`;
  }

  return (
    <StyledPodcast>
      <StyledPodcastWrapper>
        <StyledPodcastHeader>{title}</StyledPodcastHeader>
        <StyledPodcastBody>{description}</StyledPodcastBody>
        <StyledPodcastFrameWrapper>
          <iframe
            // @ts-ignore
            id={typeof params?.id !== undefined ? params.id : uuid()}
            loading="lazy"
            height={getHeight()}
            width="100%"
            src={podcast}
          ></iframe>
          <script src="https://player.ausha.co/ausha-player.js"></script>
        </StyledPodcastFrameWrapper>
      </StyledPodcastWrapper>
    </StyledPodcast>
  );
};
