import styled from "styled-components";

interface StyledAccordion {}

export const StyledAccordion = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledAccordionHeader = styled.div`
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing.two};
  font-size: 32px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.neutrals.black};
  font-weight: ${(props) => props.theme.font.bold};
`;
