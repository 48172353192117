import styled, { css } from "styled-components";
import { hefBrandColors } from "../../../types";
import speckle from "../../../assets/ProductOverview/speckle.png";

interface StyledProductOverviewProps {
  color?: hefBrandColors;
}

export const StyledProductOverviewWrapper = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledProductOverview = styled.div<StyledProductOverviewProps>`
  z-index: 10;
  position: relative;
  padding: ${(props) => props.theme.spacing.eight}
    ${(props) => props.theme.spacing.four};
  background-color: ${(props) =>
    props.color
      ? props.theme.colors.warmths[props.color]
      : props.theme.colors.warmths.heritage};

  // background-image: url(${speckle});
  // background-repeat: no-repeat;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledProductOverviewInner = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const StyledProductOverviewHeader = styled.p`
  color: ${(props) => props.theme.colors.neutrals.white};
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  line-height: 1.25;
  width: 66%;
  margin: 0 0 ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 0 ${(props) => props.theme.spacing.seven} 0;
    font-size: 24px;
    width: 100%;
  }
`;

interface StyledProductOverviewCardsProps {
  layout?: string;
}

export const StyledProductOverviewCards = styled.div<StyledProductOverviewCardsProps>`
  z-index: 10;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.layout === "three-column" ? "1fr 1fr 1fr" : "1fr 1fr"};
  grid-gap: ${(props) => props.theme.spacing.three};
  grid-auto-rows: 1fr;
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
  }
`;

export const StyledProductOverviewLink = styled.div`
  margin-top: ${({ theme }) => theme.spacing.four};
  z-index: 10;

  & a {
    color: ${({ theme }) => theme.colors.neutrals.white};
  }

  & a:hover {
    text-decoration-color: ${({ theme }) =>
      theme.colors.neutrals.white}!important;
    color: ${({ theme }) => theme.colors.neutrals.white};
  }
`;

export const StyledSpeckle = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
