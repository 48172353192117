import React from "react";

import { FeatureStatTwoItem, FeatureStatTwoItemProps } from "./FeatureStatTwoItem";
import { StatTwoWrapper } from "./styles/StyledFeatureStatTwo";

export interface FeatureStatTwoProps {
  items: FeatureStatTwoItemProps[];
  bottomMargin: boolean;
}

export const FeatureStatTwoCmp: React.FC<FeatureStatTwoProps> = ({ items, bottomMargin }) => {
  return (
    <>
      <StatTwoWrapper rows={items.length} bottomMargin={bottomMargin}>
        {items.map((item, i) => (
          <FeatureStatTwoItem {...item} index={i} />
        ))}
      </StatTwoWrapper>
    </>
  );
};
