import styled, { css } from "styled-components";

export const StyledOverview = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledOverviewWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  & h2 {
    font-size: 32px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.one};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 24px;
    }
  }

  > p {
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.light};
    margin-bottom: 0;
  }

  transition: height 0.66s ease-out;
`;

export const StyledOverviewCard = styled.div`
  display: flex;
  flex-flow: row;
  padding: ${(props) => props.theme.spacing.four} 0;
  border-bottom: 1px solid rgba(25, 25, 25, 0.3);
`;

interface StyledOverviewCardsContainerProps {
  column?: "one" | "three";
}

export const StyledOverviewCardsContainer = styled.div<StyledOverviewCardsContainerProps>`
  margin-bottom: ${(props) => props.theme.spacing.five};

  ${({ column }) =>
    column === "three" &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 1fr;
      grid-gap: ${({ theme }) => theme.spacing.three};
      margin-top: ${({ theme }) => theme.spacing.four};

      @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        grid-template-columns: 100%;
      }
    `}
`;

export const StyledOverviewCardImgContainer = styled.div`
  min-width: 200px;
  width: 200px;
  margin-right: ${(props) => props.theme.spacing.seven};
  border-radius: 4px;

  & img {
    width: 200px;
    height: 200px;
    min-height: 200px;
    min-width: 200px;
    border-radius: 4px;
    object-fit: cover;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledOverviewCardAside = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

export const StyledOverviewCardQualifier = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.warmths.heritage};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledOverviewCardTitle = styled.p`
  font-size: 24px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 20px;
  }
`;
