import styled, { css } from "styled-components";

interface StyledIllustrationCardContainerProps {
  nCards: number;
}

export const StyledIllustrationCard = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledIllustrationCardWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledIllustrationCardTitle = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
  max-width: 60%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    max-width: 100%;
  }
`;

export const StyledIllustrationCardBody = styled.p`
  white-space: pre-line;
  margin-bottom: ${(props) => props.theme.spacing.four};
  font-weight: ${(props) => props.theme.font.light};
`;

export const StyledIllustrationCardContainer = styled.div<StyledIllustrationCardContainerProps>`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.three};

  ${(props) =>
    (props.nCards === 3 || props.nCards > 4) &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `}

  ${(props) =>
    (props.nCards < 3 || props.nCards === 4) &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    grid-template-columns: 100%;
  }
`;

export const StyledLinkContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.four};
`;
