import styled from "styled-components";

export const StyledFeatureListItem = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: ${(props) => props.theme.spacing.three};
`;

export const StyledFeatureListItemTextContent = styled.div`
  display: flex;
  flex-flow: column;

  & p {
    margin: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
    justify-content: center;
  }
`;

export const StyledFeatureListItemHeader = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
`;

export const StyledFeatureListItemSubtext = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.light};
`;
