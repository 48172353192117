import styled, { css } from "styled-components";

export interface StyledFormFieldSectionProps {
  layout?: string;
}

export interface StyledFormFieldSectionTitleProps {
  title_size?: string;
}

export const StyledFormFieldSection = styled.div<StyledFormFieldSectionProps>`
  display: grid;
  grid-template-columns: 1fr;
`;

export const StyledFormFieldSectionTitle = styled.h4<StyledFormFieldSectionTitleProps>`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: ${(props) => (props.title_size === "small" ? "16px" : "20px")};
  margin-bottom: ${(props) =>
    props.title_size === "small"
      ? props.theme.spacing.two
      : props.theme.spacing.three}; ;
`;

export const StyledFormFieldSectionFieldsContainer = styled.div<StyledFormFieldSectionProps>`
  display: grid;

  ${(props) =>
    props.layout === "full-width" &&
    css`
      grid-template-columns: 100%;
    `}

  ${(props) =>
    props.layout === "one-column-two-thirds" &&
    css`
      grid-template-columns: 1fr 1fr;
      max-width: 75%;
    `}

  ${(props) =>
    props.layout === "two-columns-two-thirds" &&
    css`
      grid-template-columns: 1fr 1fr;
      max-width: 75%;
    `}

  column-gap: ${(props) => props.theme.spacing.three};
  row-gap: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.spacing.two};
    max-width: 100%;
  }
`;
