import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  LinkCardWithImageCmp,
  LinkCardWithImageProps,
} from "./LinkCardWithImage";

interface LinkCardWithImageWBlokProps {
  blok: LinkCardWithImageProps & blokType;
}

export const LinkCardWithImage: React.FC<LinkCardWithImageWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <LinkCardWithImageCmp
        _uid={blok._uid}
        title={blok.title}
        component={blok.component}
        body={blok.body}
        cards={blok.cards}
        link={blok.link}
      ></LinkCardWithImageCmp>
    </SbEditable>
  );
};
