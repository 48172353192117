import * as React from "react";
import { BannerCmp } from "./Banner";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";

export const Banner = () => {
  const { settings } = React.useContext(SettingsContextHooks);

  return (
    <>
      {settings?.content?.banner_notice && (
        <BannerCmp
          banner_notice={settings?.content?.banner_notice}
          banner_link={settings?.content?.banner_link}
        />
      )}
    </>
  );
};
