import styled from "styled-components";

interface StyledLandingModuleProps {
  background_colour?: string;
  wide?: boolean;
}

export const StyledLandingPageModule = styled.div<StyledLandingModuleProps>`
  margin: 64px auto;

  ${(props) =>
    props.background_colour === "sisal" &&
    `
      background-color: ${props.theme.colors.neutrals.sisal20};
      margin: 64px auto 128px auto;
  `}
  ${(props) =>
    props.background_colour === "white" &&
    `
      background-color: ${props.theme.colors.neutrals.white};
  `}

@media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 64px auto;
  }
`;

export const StyledLandingPageModuleWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 64px 0;
  max-width: ${(props) => props.theme.maxWidth};

  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, [col-start] 1fr);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    gap: 24px;
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
    flex-direction: column;
  }
`;

export const StyledLandingPageModuleContent = styled.div<StyledLandingModuleProps>`
  display: grid;
  grid-column: col-start 1 / span 6;

  ${(props) =>
    props.wide &&
    `
    grid-column: col-start 1 / span 7;
    }
  `};
`;

export const StyledHeader = styled.div`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 32px;
  margin-bottom: 21px;
`;

export const StyledBodyContent = styled.div`
  font-weight: ${(props) => props.theme.font.light};

  & p {
    font-weight: ${(props) => props.theme.font.light};
    line-height: 24px;
  }

  & ul,
  ol {
    padding-left: 20px;
    margin: 0;

    & li,
    ol {
      padding-bottom: 4px;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: 21px;
  `;

export const StyledModuleImgContainer = styled.div<StyledLandingModuleProps>`
  display: grid;
  grid-column: col-start 7 / span 12;
  padding-left: 24px;
  
  ${(props) =>
    props.wide &&
    `
    grid-column: col-start 8 / span 12;
  }
  `}

@media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
  padding-left: 0;
}
`;

export const StyledModuleImg = styled.img<StyledLandingModuleProps>`
  width: 100%;
  height: auto;
  max-height: 310px;
  border-radius: 4px;
  
  ${(props) =>
    props.wide &&
    `
    aspect-ratio: 1/1;
    
    @media screen and (max-width: ${props.theme.breakpoints.m}) {
      aspect-ratio: auto;
    }
    `}
`;


  export const StyledShape = styled.div<StyledLandingModuleProps>`
    height: 0;
    position: relative;
    z-index: 1;
  
    ${(props) =>
      props.background_colour === "white" &&
      `
        display: none;
    `}
  
    & img {
      position: absolute;
      width: 100%;
    }
  `;