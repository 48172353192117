import styled from "styled-components";

interface StyledDescWImageCardContainerProps {
  lessThanThree?: boolean;
}

export const StyledDescWImage = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledDescWImageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledDescWImageTitle = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledDescWImageBody = styled.p`
  white-space: pre-line;
  margin-bottom: ${(props) => props.theme.spacing.four};
  font-weight: ${(props) => props.theme.font.light};
`;

export const StyledDescWImageCardContainer = styled.div<StyledDescWImageCardContainerProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.lessThanThree ? "1fr 1fr" : "1fr 1fr 1fr"};
  row-gap: ${(props) => props.theme.spacing.five};
  column-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    row-gap: ${(props) => props.theme.spacing.four};
  }
`;
