import styled from "styled-components";

type image = {
  imageUrl: string | undefined;
  backgroundColour?: string;
};

export const background = styled.div``;

export const StyledCTAwImage = styled.div<image>`
  background: url(${(props) => props.imageUrl}) no-repeat center
    ${(props) => (props.imageUrl === null ? "hidden" : "")};
  background-size: 95% 406px;
  background-color: ${(props) => props.backgroundColour}
    ${(props) => (props.imageUrl === null ? "" : "hidden")};
  max-width: ${(props) => props.theme.maxWidth};
  height: 406px;
  margin: ${(props) => props.theme.spacing.six} auto;
  display: flex;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
    background: none;
    background-color: #EDDDBF;
    height: fit-content;
  }
`;

export const StyledCTAwImageWrapper = styled.div`
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 0 80px 0 57px;
  display: flex;
  gap: 11%;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: fit-content;
    background: none;
    flex-direction: column;
    gap: 0;
    padding: 56px ${(props) => props.theme.spacing.three} 60px;
  }
`;

export const StyledCTAwImageImageWrapper = styled.div<image>`
  max-width: 385px;

  :after {
    content: url(${(props) => props.imageUrl});
    display: block;
    position: absolute;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      display: none;
    }
  }
`;

export const StyledCTAwImageContentWrapper = styled.div`
  width: 328px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const StyledCTAwImageText = styled.h5`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: ${(props) => props.theme.spacing.four};
    -webkit-line-clamp: 15;
  }
`;
