import styled, { css } from "styled-components";

interface PaginationDotsProps {
  isActive?: boolean;
}

interface ControlsItemProps {
  disabled?: boolean;
}

interface PaginationContainerProps {
  desktop?: boolean;
}

interface StyledFacultyCarouselTextContainerProps {
  one?: boolean;
}

interface StyledFacultyCarouselItemWrapperProps {
  one?: boolean;
}

export const StyledFacultyCarousel = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFacultyCarouselItemWrapper = styled.div<StyledFacultyCarouselItemWrapperProps>`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  ${(props) =>
    props.one &&
    css`
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${(props) => props.theme.spacing.three};
      align-items: center;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        grid-template-columns: 100%;
        grid-auto-flow: row;
      }
    `}
`;

export const StyledFacultyCarouselTextContainer = styled.div<StyledFacultyCarouselTextContainerProps>`
  max-width: 65ch;
  margin-bottom: ${(props) => props.theme.spacing.four};
  ${(props) =>
    props.one &&
    css`
      max-width: 386px;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
        max-width: 100%;
      }
    `}
`;

export const StyledFacultyCarouselTextHeader = styled.h2`
  font-size: 32px;
  max-width: 65ch;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledFacultyCarouselTextSubheader = styled.p`
  font-size: 16px;
  white-space: pre-line;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: 0;
`;

export const StyledFacultyCarouselImagesContainer = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & .swiper-container {
      overflow: visible;
    }
  }
`;

export const StyledFacultyCarouselPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.four};
`;

export const StyledFacultyCarouselPagination = styled.div<PaginationContainerProps>`
  display: ${(props) => (props.desktop ? "grid" : "none")};
  grid-auto-flow: column;
  grid-gap: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.desktop ? "none" : "grid")};
  }
`;

export const StyledFacultyCarouselPaginationDot = styled.div<PaginationDotsProps>`
  height: 4px;
  width: ${(props) => (props.isActive ? "18px" : "4px")};
  border-radius: 4px;
  background-color: black;
`;

export const StyledFacultyCarouselControls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 26px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledFacultyCarouselControlsItem = styled.div<ControlsItemProps>`
  & svg {
    color: ${(props) =>
      props.disabled
        ? props.theme.colors.neutrals.coolGrey
        : props.theme.colors.neutrals.black};
  }

  & svg:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

export const StyledFacultyCarouselOneCardImg = styled.img`
  height: 330px;
  width: 100%;
  object-fit: cover;

  border-radius: 4px;
`;
