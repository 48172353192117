import React from "react";
import { sbAssetType } from "../../types";
import { LinkItemProps } from "../LinkItem";

import {
  StyledVideoGalleryItem,
  StyledVideoThumbnail,
  StyledDuration,
  StyledVideoTitle,
  StyledVideoCta,
} from "./partials";

export interface IVideoGalleryItemProps {
  video_link: string;
  video_title: string;
  thumbnail_img: sbAssetType;
  duration: string;
  onClick: any;
  video_cta: Array<LinkItemProps>;
}

export const VideoGalleryItem: React.FC<IVideoGalleryItemProps> = ({
  video_title,
  thumbnail_img,
  duration,
  onClick,
}) => {
  return (
    <StyledVideoGalleryItem onClick={() => onClick()}>
      <StyledVideoThumbnail>
        <img src={thumbnail_img.filename} />
        {duration && <StyledDuration>{duration}</StyledDuration>}
      </StyledVideoThumbnail>
      {video_title && <StyledVideoTitle>{video_title}</StyledVideoTitle>}
    </StyledVideoGalleryItem>
  );
};
