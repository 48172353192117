import React, { useState } from "react";

import { sbImageService } from "hult-sb-ui";

import { blokType, sbAssetType, sbLinkType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import {
  StyledFullBleedImageHeader,
  StyledFullBleedImageHeaderContainer,
  StyledFullBleedImageHeroBody,
  StyledFullBleedImageHeroHeader,
  StyledFullBleedImageHeroMainContentDesktop,
  StyledFullBleedImageHeroMainContentMobile,
  StyledGrapicOverlay,
  StyledOverlay,
} from "./partials";

export interface ICTA extends blokType {
  cta_label?: string;
  cta_link?: sbLinkType;
  cta_form?: Array<FormSCProps>;
  cta_colour?: "heritage" | "intensity" | "copper";
}

export interface FullBleedImageHeroProps {
  cta?: Array<ICTA>;
  cta_label?: string;
  cta_link?: sbLinkType;
  cta_form?: Array<FormSCProps>;
  header: string;
  body?: string;
  background_image?: sbAssetType;
  graphic_overlay?: sbAssetType;
  graphic_overlay_mobile?: sbAssetType;
  increaseFontSize?: boolean;
  centerText?: boolean;
  mobileImage?: sbAssetType;
  text_color: "light" | "dark";
}

export const FullBleedImageHeroCmp: React.FC<FullBleedImageHeroProps> = ({
  header,
  body,
  background_image,
  graphic_overlay,
  graphic_overlay_mobile,
  cta,
  increaseFontSize,
  centerText,
  mobileImage,
  text_color,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <StyledFullBleedImageHeader
        background_image={background_image && background_image.filename}
        mobileImage={mobileImage && mobileImage.filename}
      >
        <StyledFullBleedImageHeaderContainer
          background_image={background_image && background_image.filename}
          centerText={centerText}
        >
          <StyledFullBleedImageHeroMainContentDesktop increaseFontSize={increaseFontSize}>
            {header && (
              <StyledFullBleedImageHeroHeader increaseFontSize={increaseFontSize} textColor={text_color}>
                {header}
              </StyledFullBleedImageHeroHeader>
            )}
            {body && (
              <StyledFullBleedImageHeroBody increaseFontSize={increaseFontSize} textColor={text_color}>
                {body}
              </StyledFullBleedImageHeroBody>
            )}
            {Array.isArray(cta) && cta.length >= 1 && (
              <>
                {Array.isArray(cta[0].cta_form) && cta[0].cta_form.length >= 1 && (
                  <ButtonHEF
                    variant="primary"
                    color={cta[0].cta_colour}
                    onClick={(_: MouseEvent) => setOpenModal(true)}
                    label={cta[0].cta_label}
                  />
                )}
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  cta[0].cta_link && cta[0].cta_form.length == 0 && (
                    <ButtonHEF
                      as={"a"}
                      variant="primary"
                      color={cta[0].cta_colour}
                      href={getUrl(cta[0].cta_link)}
                      label={cta[0].cta_label}
                    />
                  )
                }
              </>
            )}
          </StyledFullBleedImageHeroMainContentDesktop>
        </StyledFullBleedImageHeaderContainer>
        <StyledOverlay mobileImage={mobileImage && mobileImage.filename} />
        {graphic_overlay?.filename && (
          <StyledGrapicOverlay
            src={sbImageService(graphic_overlay.filename, undefined, "1200x0", graphic_overlay.focus)}
            alt={graphic_overlay.alt}
            title={graphic_overlay.alt}
          ></StyledGrapicOverlay>
        )}
        {graphic_overlay_mobile?.filename && (
          <StyledGrapicOverlay
            src={sbImageService(graphic_overlay_mobile.filename, undefined, "780x0")}
            mobile
            alt={graphic_overlay_mobile.alt}
            title={graphic_overlay_mobile.alt}
          ></StyledGrapicOverlay>
        )}
      </StyledFullBleedImageHeader>
      <StyledFullBleedImageHeroMainContentMobile>
        <StyledFullBleedImageHeroHeader>{header && header}</StyledFullBleedImageHeroHeader>
        <StyledFullBleedImageHeroBody>{body && body}</StyledFullBleedImageHeroBody>
        {Array.isArray(cta) && cta.length >= 1 && (
          <>
            {Array.isArray(cta[0].cta_form) && cta[0].cta_form.length >= 1 && (
              <ButtonHEF
                variant="primary"
                color={cta[0].cta_colour}
                onClick={(_: MouseEvent) => setOpenModal(true)}
                label={cta[0].cta_label}
              />
            )}
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cta[0].cta_link && cta[0].cta_form.length === 0 && (
                <ButtonHEF
                  as={"a"}
                  color={cta[0].cta_colour}
                  variant="primary"
                  href={getUrl(cta[0].cta_link)}
                  label={cta[0].cta_label}
                />
              )
            }
          </>
        )}
      </StyledFullBleedImageHeroMainContentMobile>
      {Array.isArray(cta) && cta.length >= 1 && Array.isArray(cta[0].cta_form) && cta[0].cta_form.length === 1 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={(_: void) => setOpenModal(false)}
            _uid={cta[0].cta_form[0]._uid}
            form={cta[0].cta_form[0].form}
            component={cta[0].cta_form[0].component}
            form_handler={cta[0].cta_form[0].form_handler}
            sf_campaign_id={cta[0].cta_form[0].sf_campaign_id}
            title={cta[0].cta_form[0].title}
            qualifier={cta[0].cta_form[0].qualifier}
            event_date={cta[0].cta_form[0].event_date}
            event_duration={cta[0].cta_form[0].event_duration}
            event_location={cta[0].cta_form[0].event_location}
            thank_you_components={cta[0].cta_form[0].thank_you_components}
            close_on_submit={cta[0].cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </div>
  );
};
