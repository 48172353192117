import React from "react";
import { NavItemCmp } from ".";
import { getUrl } from "../utils/helpers";
import { StyledLinkText } from "./partials";

type Props = NavItemCmp & { isActive: boolean };

export const NavItem: React.FC<Props> = ({ name, link, isActive }) => {
return (
    <StyledLinkText href={getUrl(link)} isActive={isActive}>
      {name}
    </StyledLinkText>
  );
};
