import React, { useState, useRef } from "react";
import { StyledAccordionItem, StyledButton, StyledBody } from "./partials";

import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import IconChevronUp from "@ef-global/web-ui-react/lib/icons/IconChevronUp";

export interface IAccordionItem {
  title: string;
  body: string;
}

export const AccordionItem: React.FC<IAccordionItem> = ({ title, body }) => {
  const hiddenContent = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);

  function handleClick(){
    if (hiddenContent.current) {
      setBodyHeight(hiddenContent?.current?.scrollHeight)
      setIsActive(!isActive)
    }
  } 

  return (
    <StyledAccordionItem is_active={isActive}>
      <StyledButton is_active={isActive} onClick={() => handleClick()}>
        {title && title}
        {isActive ? <IconChevronUp /> : <IconChevronDown />}
      </StyledButton>
      <StyledBody body_height={bodyHeight} is_active={isActive} ref={hiddenContent}>
        {body}
      </StyledBody>
    </StyledAccordionItem>
  );
};