import styled from "styled-components";
import { StyledMenuItem } from "../MenuItem/partials";
import { StyledButton } from "../../Button/partials/StyledButton";
import { LandingPageHeaderProps } from "../LandingPageHeader";

interface StyledLandingPageProps {
  background_colour?: string;
  background_image?: string;
  open?: boolean;
  maxHeight?: string;
  mobile?: boolean;
  overlay_opacity?: string;
}

export const StyledLandingPageContainer = styled.div<StyledLandingPageProps>``;

export const StyledLandingPageHeader = styled.div<StyledLandingPageProps>`
  position: relative;
  ${(props) =>
    props.background_colour === "albescent" &&
    `
      background-color: ${props.theme.colors.neutrals.albescent};
  `}
  ${(props) =>
    props.background_colour === "heritage" &&
    `
      background-color: ${props.theme.colors.warmths.heritage};
  `}

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("${(props) => props.background_image}");
`;

export const StyledLandingPageHeaderContainer = styled.div<LandingPageHeaderProps>`
  margin: 0 auto;
  display: grid;
  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.four} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    height: ${(props) => props.background_image && `50vh`};
    padding: ${(props) => props.theme.spacing.one}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

// DESKTOP NAV
export const StyledLandingPageNav = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  position: relative;
  z-index: 15;
`;

export const StyledNavDesktop = styled.div<StyledLandingPageProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMenuItems = styled.div<StyledLandingPageProps>`
  ${StyledMenuItem} {
    ${(props) =>
      props.background_colour === "albescent"
        ? `color: ${props.theme.colors.neutrals.black};`
        : `color: ${props.theme.colors.neutrals.white}; text-decoration-color: white;`};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledButtonContainerDesktop = styled.div<StyledLandingPageProps>`
  ${StyledButton} {
    ${(props) =>
      props.background_colour === "albescent"
        ? `background: ${props.theme.colors.neutrals.black};`
        : `background: ${props.theme.colors.neutrals.white}; color:${props.theme.colors.neutrals.black}`};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    opacity: ${(props) => props.open && "0"};
  }
`;

export const StyledBodyCenteredWModal = styled.div<StyledLandingPageProps>``;

// MOBILE NAV
export const StyledNavMobile = styled.nav<StyledLandingPageProps>`
  display: block;
  position: absolute;
  top: -8px;
  left: -24px;
  height: auto;
  width: 100vw;
  z-index: 8;
  box-shadow: ${({ open }) => open ? "0px 16px 16px rgba(25, 25, 25, 0.30)" : "initial"};
  background: ${(props) => props.background_colour === "albescent"
      ? `${props.theme.colors.neutrals.albescent};`
      : `${props.theme.colors.warmths.heritage};`};
  background: ${(props) => !props.open && props.background_image && `none`};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledButtonContainerMobile = styled.div<StyledLandingPageProps>`
  margin: 0 auto;

  ${StyledButton} {
    background: ${(props) =>
      props.background_colour === "albescent"
        ? `${props.theme.colors.neutrals.black};`
        : `${props.theme.colors.neutrals.white}; color:${props.theme.colors.neutrals.black};`};
  }
`;

export const StyledIcon = styled.i<StyledLandingPageProps>`
  cursor: pointer;
  font-size: 18px;

  & svg {
    color: ${(props) =>
      props.background_colour === "albescent"
        ? `${props.theme.colors.neutrals.black};`
        : `${props.theme.colors.neutrals.white};`};
    transform: rotate${(props) => (props.open ? "(180deg)" : "(0)")};
    transition: 0.2s linear;
  }
`;

export const StyledHeader = styled.header<StyledLandingPageProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 24px 24px 0 24px;

  ${StyledButton} {
    transition: 0.2s;
    opacity: ${(props) => (props.open ? 0 : 1)};
  }
`;

export const StyledMenu = styled.div<StyledLandingPageProps>`
  overflow: hidden;
  padding: 0 24px 24px;
  transition: 0.2s;
  max-height: ${(props) => (props.open ? props.maxHeight : 0)}px;
`;

export const StyledMenuInner = styled.div<StyledLandingPageProps>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  transition: 0s;
  opacity: ${(props) => (props.open ? 1 : 0)};

  & a {
    ${(props) =>
      props.background_colour === "albescent"
        ? `color: ${props.theme.colors.neutrals.black};`
        : `color: ${props.theme.colors.neutrals.white}; text-decoration-color: white;`};

    &:hover {
      ${(props) =>
        props.background_colour === "albescent"
          ? `color: ${props.theme.colors.neutrals.black};`
          : `color: ${props.theme.colors.neutrals.white}; text-decoration-color: white;`};
    }
  }
`;

// HERO
export const StyledLandingPageHero = styled.div`
  display: grid;
  align-items: center;
  padding: ${(props) => props.theme.spacing.seven} 0 188px 0;
  grid-template-columns: repeat(12, [col-start] 1fr);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.five} 0;
    flex-flow: column-reverse;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledLandingPageHeroMainContentDesktop = styled.div`
  display: grid;
  grid-column: col-start 1 / span 5;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
    z-index: 5;
    position: relative;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledLandingPageHeroMainContentMobile = styled.div`
  padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const StyledLandingPageHeroHeader = styled.h1<StyledLandingPageProps>`
  position: relative;
  z-index: 10;
  font-size: 48px;
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) =>
    props.background_colour === "albescent"
      ? `${props.theme.colors.neutrals.black};`
      : `${props.theme.colors.neutrals.white};`};
  margin-bottom: ${(props) => props.theme.spacing.one};
  line-height: 56px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    color: black;
    text-align: center;
  }
`;

export const StyledLandingPageHeroBody = styled.p<StyledLandingPageProps>`
  position: relative;
  z-index: 10;
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;
  line-height: 24px;
  color: ${(props) =>
    props.background_colour === "albescent"
      ? `${props.theme.colors.neutrals.black};`
      : `${props.theme.colors.neutrals.white};`};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    color: black;
    text-align: center;
    font-weight: ${(props) => props.theme.font.book};
  }
`;

export const StyledLandingPageHeroAside = styled.div<StyledLandingPageProps>`
  display: grid;
  grid-column: col-start 7 / span 12;
  & img {
    ${(props) =>
      props.background_image
        ? `opacity: 0;`
        : `
        display: block;
        overflow: visible;
        object-fit: contain;
        opacity: 1;
        `}
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    img {
      position: relative;
      height: auto;
      top: initial;
      right: initial;
      ${(props) => props.background_image && `display: none;`}
      padding-top: 16px;
    }
  }
`;

export const StyledGrapicOverlay = styled.img<StyledLandingPageProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: contain;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.mobile ? "block" : "none")};
    bottom: -1px;
  }
`;

export const StyledOverlay = styled.div<StyledLandingPageProps>`
  ${(props) =>
    props.background_image
      ? `position: absolute; height: auto; width: 100%; top: 0; bottom: 0; background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${props.overlay_opacity}));`
      : "display: none"};
`;
