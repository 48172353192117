import React from "react";
import { blokType, hefBrandColors, sbAssetType } from "../../types";
import { getKey } from "../utils/helpers";

import { sbImageService } from "hult-sb-ui";

import { ContactUsCard } from "./ContactUsCard";

import {
  StyledContactUs,
  StyledContactUsContent,
  StyledContactUsHeadline,
  StyledContactUsDesc,
  StyledContactUsCardContainer,
  StyledSideImageWrapper,
} from "./partials";

export interface ContactUsProps extends blokType {
  title: string;
  description: string;
  component: string;
  cards: Array<any>;
  side_image: sbAssetType;
  background_image_card: sbAssetType;
  brand_colour?: hefBrandColors;
}

export const ContactUs: React.FC<ContactUsProps> = ({
  _uid,
  title,
  component,
  description,
  cards,
  side_image,
  background_image_card,
  brand_colour,
}) => {
  return (
    <StyledContactUs>
      {side_image?.filename && (
        <StyledSideImageWrapper>
          <picture>
            <source
              srcSet={sbImageService(side_image.filename, undefined, "392x0")}
              type="image/webp"
            />
            <img
              src={sbImageService(side_image.filename, 95, "392x0")}
              alt={side_image.alt}
              title={side_image.title}
              loading="eager"
              width="392px"
              height="376px"
            />
          </picture>
        </StyledSideImageWrapper>
      )}
      <StyledContactUsContent>
        <StyledContactUsHeadline>{title}</StyledContactUsHeadline>
        <StyledContactUsDesc>{description}</StyledContactUsDesc>
        {Array.isArray(cards) && cards.length > 0 && (
          <StyledContactUsCardContainer
            cards={cards.length}
            data-testid="contact-us-card-container"
          >
            {cards.map((item, idx) => (
              <ContactUsCard
                key={getKey(`${_uid}-contact-us-card-item`, idx)}
                component={component}
                title={item.title}
                body={item.body}
                brand_colour={brand_colour}
                form={item.form}
                tel={item.tel}
                background_image_card={background_image_card}
                interaction_id={item.interaction_id}
              />
            ))}
          </StyledContactUsCardContainer>
        )}
      </StyledContactUsContent>
    </StyledContactUs>
  );
};
