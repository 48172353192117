import styled from "styled-components";

export const StyledFormatTabsIcon = styled.div`
  display: flex;
  margin: 0 ${(props) => props.theme.spacing.three}
    ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 0 ${(props) => props.theme.spacing.four} 0;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.warmths.heritage};
  color: ${(props) => props.theme.colors.neutrals.white};
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50px;
  margin-right: ${(props) => props.theme.spacing.three};
`;

export const StyledIconTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.font.bold};
`;

export const StyledIconBody = styled.div`
  font-size: ${(props) => props.theme.fontSize.m};
  font-weight: ${(props) => props.theme.font.light};
`;

export const StyledBodyWrapper = styled.div``;
