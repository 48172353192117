import React from "react";

import {
  StyledDigitalCoachWrapper,
  StyledTitle,
  StyledDesc,
  StyledDigitalCoach,
} from "./partials";

export interface DigitalCoachProps {
  title: string;
  desc: string;
  link: string;
}

export const DigitalCoachCmp: React.FC<DigitalCoachProps> = ({
  title,
  desc,
  link,
}) => {
  return (
    <StyledDigitalCoachWrapper>
      <StyledTitle>{title && title}</StyledTitle>
      <StyledDesc>{desc && desc}</StyledDesc>
      <StyledDigitalCoach>
        <iframe src={link}></iframe>
      </StyledDigitalCoach>
    </StyledDigitalCoachWrapper>
  );
};
