import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  ProductOverviewCmp,
  ProductOverviewProps,
} from "./MiniatureProductOverview";

interface MiniatureProductOverviewWBlokProps {
  blok: ProductOverviewProps & blokType;
}

export const MiniatureProductOverview: React.FC<MiniatureProductOverviewWBlokProps> =
  ({ blok }) => {
    return (
      <SbEditable content={blok}>
        <ProductOverviewCmp
          header={blok.header}
          subtitle={blok.subtitle}
          component={blok.component}
          link={blok.link}
          cards={blok.cards}
          background_colour={blok.background_colour}
          blender={blok?.blender}
        ></ProductOverviewCmp>
      </SbEditable>
    );
  };
