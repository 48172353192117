import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { CTAwImageCmp, CTAwImageProps } from "./CTAwImage";

export interface CTAwImageBlokProps {
  blok: CTAwImageProps & blokType;
}

export const CTAwImage: React.FC<CTAwImageBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CTAwImageCmp
        heading={blok.heading}
        cta={blok.cta}
        image={blok.image}
        desktop_background={blok.desktop_background}
        graphic_overlay={blok.graphic_overlay}
        background_colour={blok.background_colour}
      />
    </SbEditable>
  );
};
