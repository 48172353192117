import styled from "styled-components";

export const StyledWrapper = styled.div`
  border-radius: 50px;
`;

export const StyledVideoGalleryItem = styled.div`
  width: 100%;
`;

export const StyledVideoThumbnail = styled.div`
  position: relative;
  cursor: pointer;

  & img {
    width: 100%;
    border-radius: 8px;
  }
`;

export const StyledDuration = styled.div`
  position: absolute;
  bottom: 12px;
  right: 8px;

  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.neutrals.white};
  background-color: ${(props) => props.theme.colors.neutrals.black};
  border-radius: 4px;
  padding: 8px 12px;
`;

export const StyledVideoTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.m};
  font-weight: ${(props) => props.theme.font.bold};
  margin-top: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-weight: ${(props) => props.theme.font.book};
    margin-top: ${(props) => props.theme.spacing.one};
    font-size: ${(props) => props.theme.fontSize.s};
  }
`;

export const StyledVideoCta = styled.div`
  display: none;
`;

