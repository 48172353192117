import styled from "styled-components";

export const StyledUpcomingCohortsWrapper = styled.div`
  grid-column: col-start 8 / span 5;
  background: #f0f0f0;
  border-radius: 20px 0 0 0;

  display: grid;
  grid-template-columns: repeat(5, [col-start] 1fr);
  grid-gap: 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: col-start 1 / span 12;
    border-radius: 0;
  }
`;

export const StyledUpcomingCohorts = styled.div`
  grid-column: col-start 2 / span 4;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: col-start 1 / span 5;
  }

  & h2 {
    font-size: 32px;
    font-weight: ${(props) => props.theme.font.bold};
    margin-bottom: ${(props) => props.theme.spacing.four};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 24px;
    }
  }

  > a {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: ${(props) => props.theme.spacing.four};

    & svg {
      margin-left: ${({ theme }) => theme.spacing.two};
    }
  }

  > a:before,
  > a:after {
    cursor: initial;
    content: "";
    border-top: 1px solid rgba(25, 25, 25, 0.3);
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  > a:after {
    margin: 0 0 0 20px;
  }
`;

interface StyledCohortCardsProps {
  isOpen?: boolean;
  maxHeight?: string;
  startHeight?: string;
  hiddenDiv?: boolean;
}

export const StyledUpcomingCohortCards = styled.div<StyledCohortCardsProps>`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.two};
  grid-template-columns: 1fr;
  overflow: hidden;

  transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;

  max-height: ${(props) =>
    props.isOpen
      ? props.maxHeight
        ? props.maxHeight
        : props.maxHeight
      : props.startHeight};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-height: ${(props) =>
      props.isOpen
        ? props.maxHeight
          ? props.maxHeight
          : props.maxHeight
        : props.startHeight};
  }
`;

export const StyledUpcomingCohortsBottom = styled.div`
  margin: ${(props) => props.theme.spacing.four} auto 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;

  & a {
    margin-top: ${({ theme }) => theme.spacing.one};
    display: flex;
    align-items: center;
    justify-content: center;

    float: right;
    font-size: 14px;

    & svg {
      height: 16px;
      width: 16px;

      margin-right: ${(props) => props.theme.spacing.two};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & a {
      justify-content: center;
      float: initial;
      margin-top: ${(props) => props.theme.spacing.three};
    }
  }

  & button {
    width: 100%;
  }
`;
