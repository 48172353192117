import styled from "styled-components";

export const StyledLinkCardWImageCard = styled.div`
  position: relative;
  & a {
    padding: 0;
  }
`;

export const StyledLinkCardWImageCardImg = styled.img`
  border-radius: 4px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const StyledLinkCardWImageCardLink = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px;
  border-radius: inherit;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));

  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  vertical-align: bottom;

  & p {
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.white};
    margin: 0;
  }

  & svg {
    font-weight: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.colors.neutrals.white};
  }

  :lang(ar) {
    & svg {
      transform: rotate(180deg);
    }
  }
`;
