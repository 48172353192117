import React, { useState } from "react";
import { NavItemWSubItemsProps } from ".";
import {
  StyledNavItemWSubItems,
  StyledNavItemWSubItemsText,
  StyledNavItemWSubItemsArrow,
  StyledNavItemWSubItemsWrapper,
} from "./partials/StyledNavItemWSubItems";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import IconChevronUp from "@ef-global/web-ui-react/lib/icons/IconChevronUp";

type Props = Omit<NavItemWSubItemsProps, "sub_items" | "component"> & {
  handleClick: (a: string) => void;
  isActive: [
    isCurrentDropdown: boolean,
    activeSubItem: boolean,
    isOtherDropdownActive: boolean
  ];
};

export const NavItemWSubItems: React.FC<Props> = ({
  title,
  handleClick,
  _uid,
  isActive,
}) => {
  return (
    <StyledNavItemWSubItemsWrapper>
      <StyledNavItemWSubItems
        isActive={isActive[1] ? !isActive[2] : isActive[0]}
        onClick={() => handleClick(_uid)}
      >
        <StyledNavItemWSubItemsText>{title}</StyledNavItemWSubItemsText>
      </StyledNavItemWSubItems>
      <StyledNavItemWSubItemsArrow onClick={() => handleClick(_uid)}>
        {!isActive[0] && <IconChevronDown />}
        {isActive[0] && <IconChevronUp />}
      </StyledNavItemWSubItemsArrow>
    </StyledNavItemWSubItemsWrapper>
  );
};
