export default function sbImageService(
  imageSrc: string,
  resize = "500x500",
  quality = 90,
  format?: string
) {
  if (imageSrc && !isSvg(imageSrc) && imageSrc.includes("//a.storyblok.com")) {
    const imgUrl = format
      ? `img2.storyblok.com/${resize}/filters:quality(${quality}):format(${format})`
      : `img2.storyblok.com/${resize}/filters:quality(${quality})`;

    return imageSrc.replace("a.storyblok.com", imgUrl);
  }

  return imageSrc;
}

export function isSvg(imageSrc: string) {
  if (imageSrc) return imageSrc.endsWith(".svg");
  return false;
}
