import React, { useState, useEffect } from "react";
import queryString from "query-string";
import IconLocation from "@ef-global/web-ui-react/lib/icons/IconLocation";
import IconBriefcase from "@ef-global/web-ui-react/lib/icons/IconBriefcase";
import IconClockOutline from "@ef-global/web-ui-react/lib/icons/IconClockOutline";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import IconArrowLeft from "@ef-global/web-ui-react/lib/icons/IconArrowLeft";

import {
  StyledJobDetailsWrapper,
  StyledJobDetails,
  StyledDetailTitle,
  StyledDetailExperience,
  StyledDetailLocation,
  StyledDetailDesc,
  StyledDetailCategory,
  StyledInfoWrapper,
  StyledIcon,
  StyledJobType,
  StyledLink,
} from "./partials";
import { ButtonHEF } from "../Button";

const resolver = new RichTextResolver();

export interface JobRoleProps {
  error_msg: string;
}

export const JobRoleCmp: React.FC<JobRoleProps> = ({ error_msg }) => {
  const [jobDetails, setJobDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const backClick = window.location.href.split("?")[0];

  useEffect(() => {
    if (typeof window !== "undefined") {
      const query = queryString.parse(window.location.search);
      const id = query.jid;
      fetch(`${process.env.GATSBY_EF_JOBS_DETAIL_API}${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${error_msg}`);
          }
          return response.json();
        })
        .then((actualData) => {
          setJobDetails(actualData);
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
          setJobDetails(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <StyledJobDetailsWrapper>
      {loading && <div>A moment please...</div>}
      {error && <div>{`${error}`}</div>}

      {jobDetails && (
        <StyledJobDetails>
          {jobDetails.categories &&
            jobDetails.categories.map((category) => (
              <StyledDetailCategory>
                {category.categoryName}
              </StyledDetailCategory>
            ))}
          <StyledDetailTitle>{jobDetails.jobTitle}</StyledDetailTitle>

          <StyledInfoWrapper>
            {jobDetails.jobType && (
              <StyledJobType>
                <StyledIcon>
                  <IconClockOutline />
                </StyledIcon>
                {jobDetails.jobType}
              </StyledJobType>
            )}
            {jobDetails.jobExperienceLevel && (
              <StyledDetailExperience>
                <StyledIcon>
                  <IconBriefcase />
                </StyledIcon>
                {jobDetails.jobExperienceLevel}
              </StyledDetailExperience>
            )}
            {jobDetails.locations &&
              jobDetails.locations.map((location) => (
                <StyledDetailLocation>
                  <StyledIcon>
                    <IconLocation />
                  </StyledIcon>
                  {location.city}, {location.country}
                </StyledDetailLocation>
              ))}
          </StyledInfoWrapper>

          <StyledDetailDesc
            dangerouslySetInnerHTML={
              jobDetails.jobDescription.type === "doc"
                ? {
                    __html: resolver.render(jobDetails.jobDescription),
                  }
                : { __html: `<p>${jobDetails.jobDescription}</p>` }
            }
          ></StyledDetailDesc>

          <ButtonHEF
            as="a"
            label={"Start Application"}
            href={jobDetails.jobApplyUrl}
          />

          <StyledLink href={backClick}>
            <IconArrowLeft /> Back to job postings
          </StyledLink>
        </StyledJobDetails>
      )}
    </StyledJobDetailsWrapper>
  );
};
