import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { HighlightedListCmp, HighlightedListProps } from "./HighlightedList";

interface HighlightedListWBlokProps {
  blok: HighlightedListProps & blokType;
}

export const HighlightedList: React.FC<HighlightedListWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HighlightedListCmp
        key={blok._uid}
        qualifying_title={blok.qualifying_title}
        highlighted_points={blok.highlighted_points}
        speckle_image={blok.speckle_image}
        top_image={blok.top_image}
        background_colour={blok.background_colour}
        adjustedText={blok.adjustedText}
      ></HighlightedListCmp>
    </SbEditable>
  );
};
