import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import IconTriangleDown from "@ef-global/web-ui-react/lib/icons/IconTriangleDown";

import { StaticQuery, graphql } from "gatsby";
import { DropdownListItem } from "./DropdownListItem";
import {
  StyledDropdownContainer,
  StyledDropDownListContainer,
  StyledDropDownHeader,
  StyledFlagContainer,
} from "./partials";

interface GetOptionsProps {
  slug: string;
  onClickHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const GetOptions: React.FC<GetOptionsProps> = ({ slug, onClickHandler }) => (
  <StaticQuery
    query={graphql`
      query {
        allStoryblokDatasourceEntry {
          edges {
            node {
              id
              name
              value
              data_source
            }
          }
        }
      }
    `}
    render={(data) => {
      let options = (
        <React.Fragment>
          <></>
        </React.Fragment>
      );

      if (data && data.allStoryblokDatasourceEntry) {
        options = (
          <React.Fragment>
            {data.allStoryblokDatasourceEntry?.edges.map((o) => {
              if (o.node.data_source === slug) {
                return (
                  <DropdownListItem
                    key={o.node.id}
                    onItemClick={(_: Event) => onClickHandler(o.node)}
                  >
                    <StyledFlagContainer>
                      <span>
                        {o.node.name.substring(3)} ({o.node.value})
                      </span>
                    </StyledFlagContainer>
                  </DropdownListItem>
                );
              }
            })}
          </React.Fragment>
        );
      }
      return options;
    }}
  />
);

interface TelephoneDropdownProps {
  countryCode?: string;
  onChange?: any;
  valid?: boolean;
  isoCountry?: string;
}

export const TelephoneDropdown: React.FC<TelephoneDropdownProps> = ({
  countryCode = "+1",
  onChange,
  valid,
  isoCountry = "US",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const myRef = useRef();

  function toggleList() {
    setIsOpen(!isOpen);
  }

  function handleClick(e: ChangeEvent<HTMLInputElement>) {
    onChange({ countryCode: e.value, isoCountry: e.name.substring(0, 2) });
    setIsOpen(false);
  }

  const handleClickOutside = (e: Event) => {
    if (!myRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <StyledDropdownContainer>
      <StyledDropDownHeader onClick={toggleList}>
        {
          <StyledFlagContainer>
            <span>{countryCode}</span>
            <IconTriangleDown />
          </StyledFlagContainer>
        }
      </StyledDropDownHeader>
      {isOpen && (
        <StyledDropDownListContainer ref={myRef}>
          <ul>
            <GetOptions slug="form_phone_codes" onClickHandler={handleClick} />
          </ul>
        </StyledDropDownListContainer>
      )}
    </StyledDropdownContainer>
  );
};
