import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { ProductHeroCmp, ProductHeroProps } from "./ProductHero";

export interface HeroWBlokProps {
  blok: ProductHeroProps & blokType;
}

export const ProductHero: React.FC<HeroWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ProductHeroCmp {...blok} />
    </SbEditable>
  );
};
