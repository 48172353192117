import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { CaseStudyQuoteCmp, CaseStudyQuoteProps } from "./CaseStudyQuote";

export interface CaseStudyQuoteWBloKProps {
  blok: CaseStudyQuoteProps & blokType;
}

export const CaseStudyQuote: React.FC<CaseStudyQuoteWBloKProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CaseStudyQuoteCmp {...blok} />
    </SbEditable>
  );
};
