import React from "react";

import { sbAssetType, sbLinkType } from "../../types";

import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import { LinkItem } from "../LinkItem";

import {
  StyledCardWithTextItem,
  StyledCardWithTextItemImg,
  StyledCardWithTextContentContainer,
  StyledCardWithTextItemTitle,
  StyledCardWithTextItemDescription,
  StyledCardWithTextItemLinkItem,
} from "./partials";

export interface CardWithTextItemProps {
  title: string;
  description: string;
  image: sbAssetType;
  link: sbLinkType;
  linkLabel: string;
}

export const CardWithTextItem: React.FC<CardWithTextItemProps> = ({
  title,
  description,
  image,
  link,
  linkLabel,
}) => {
  return (
    <StyledCardWithTextItem>
      <Surface
        as="a"
        href={link?.url ? link?.url : `/${link.cached_url}`}
        deepShadow
        rounded
      >
        <StyledCardWithTextItemImg
          src={image.filename}
          alt={image.alt}
          title={image?.title}
        />
        <StyledCardWithTextContentContainer>
          <StyledCardWithTextItemTitle>{title}</StyledCardWithTextItemTitle>
          <StyledCardWithTextItemDescription>
            {description}
          </StyledCardWithTextItemDescription>
          <StyledCardWithTextItemLinkItem>
            {linkLabel && (
              <LinkItem
                name={linkLabel}
                link={link}
                spaceApart={false}
                parentComponent=""
                link_target="_blank"
              ></LinkItem>
            )}
          </StyledCardWithTextItemLinkItem>
        </StyledCardWithTextContentContainer>
      </Surface>
    </StyledCardWithTextItem>
  );
};
