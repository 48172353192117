import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  DescriptionWithImageCmp,
  DescriptionWithImageProps,
} from "./DescriptionWithImage";

interface DescriptionWithImageWBlokProps {
  blok: DescriptionWithImageProps & blokType;
}

export const DescriptionWithImage: React.FC<DescriptionWithImageWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <DescriptionWithImageCmp
        title={blok.title}
        body={blok.body}
        cards={blok.cards}
      ></DescriptionWithImageCmp>
    </SbEditable>
  );
};
