import React from "react";

import { sbAssetType } from "../../types";
import sbImageService from "../utils/sbImage";
import { CaseStudyImpactImageBackgroundWrapper } from "./styles/StyledCaseStudyImpactImage";

export interface CaseStudyImpactImageProps {
  image: sbAssetType;
}

export const CaseStudyImpactImageCmp: React.FC<CaseStudyImpactImageProps> = ({ image }) => {
  return (
    <CaseStudyImpactImageBackgroundWrapper>
      <img src={sbImageService(image.filename, "0x0")} title={image.title} alt={image.alt} />
    </CaseStudyImpactImageBackgroundWrapper>
  );
};
