import React from "react";
// @ts-ignore
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import module_shape from "../../assets/LandingPage/module_shape.svg";

import {
  StyledLandingPageModule,
  StyledLandingPageModuleWrapper,
  StyledLandingPageModuleContent,
  StyledHeader,
  StyledBodyContent,
  StyledButtonContainer,
  StyledModuleImgContainer,
  StyledModuleImg,
  StyledShape,
} from "./partials";
import { sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";
import { LinkItemProps, LinkItem } from "../LinkItem";

const resolver = new RichTextResolver();

export interface LandingPageModuleProps {
  header: string;
  body?: string;
  image: sbAssetType;
  background_colour?: string;
  link?: Array<LinkItemProps>;
  wide: boolean;
}

export const LandingPageModuleCmp: React.FC<LandingPageModuleProps> = ({
  header,
  body,
  image,
  background_colour,
  link,
  wide,
}) => {
  return (
    <StyledLandingPageModule background_colour={background_colour}>
      <StyledLandingPageModuleWrapper>
        <StyledLandingPageModuleContent wide={wide}>
          <StyledHeader>{header}</StyledHeader>
          <StyledBodyContent
            dangerouslySetInnerHTML={{
              __html: resolver.render(body),
            }}
          ></StyledBodyContent>

          <StyledButtonContainer>
            {Array.isArray(link) && link.length === 1 && (
              <LinkItem {...link[0]} />
            )}
          </StyledButtonContainer>
        </StyledLandingPageModuleContent>

        <StyledModuleImgContainer wide={wide}>
          {image && (
            <StyledModuleImg
              src={sbImageService(image.filename, undefined, "410x0")}
              alt={image.alt}
              title={image?.title}
              loading="lazy"
              wide={wide}
            />
          )}
        </StyledModuleImgContainer>
      </StyledLandingPageModuleWrapper>
      <StyledShape background_colour={background_colour}>
        <img src={module_shape} />
      </StyledShape>
    </StyledLandingPageModule>
  );
};
