import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { HeaderCmp, HeaderProps } from "./HeaderTwo";

interface HeaderWBlokProps {
  blok: HeaderProps & blokType;
}

export const BodyTwo: React.FC<HeaderWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <HeaderCmp
        title={blok.title}
        mini_title={blok?.mini_title}
        image={blok.image}
        mini_title_colour={blok.mini_title_colour}
        text_size={blok.text_size}
        blender={blok?.blender}
        background_colour={blok?.background_colour}
        body_variant
      ></HeaderCmp>
    </SbEditable>
  );
};
