import styled from "styled-components";

import quote from "../../../assets/Graphics/Quote.svg";

interface Props {
  extraPadding?: boolean;
  backgroundColour: "offwhite" | "white";
}

export const CaseStudyQuoteBackgroundWrapper = styled.div<Props>`
  width: 100vw;
  background: ${({ backgroundColour, theme }) =>
    backgroundColour === "white" ? theme.colors.neutrals.white : theme.colors.neutrals.offwhite};
`;

export const CaseStudyQuoteWrapper = styled.div<Props>`
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  min-height: 386px;
  margin: auto;
  padding: ${({ theme, extraPadding }) => (extraPadding ? theme.spacing.six : 0)} ${({ theme }) => theme.spacing.three};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.three};
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, calc((100vw - 64px) / 2)) auto;
    grid-gap: 0;
  }
`;

export const CaseStudyQuoteImage = styled.img`
  grid-column: 1 / span 5;
  grid-row: 1;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
    padding: 0 ${({ theme }) => theme.spacing.three};
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

export const CaseStudyQuoteTextBackground = styled.div<Props>`
  grid-column: 4 / span 9;
  grid-row: 1;
  width: 100%;
  height: 244px;
  background: ${({ theme, backgroundColour }) =>
    backgroundColour === "white" ? theme.colors.warmths.black : theme.colors.warmths.heritage};
  border-radius: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.five};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 2 / span 2;
    height: 100%;
    margin-top: 0;
  }
`;

export const CaseStudyQuoteTextWrapper = styled.div<Props>`
  position: relative;
  grid-column: 6 / span 7;
  grid-row: 1;
  margin: ${({ theme }) => theme.spacing.five} ${({ theme }) => theme.spacing.three} 0 0;

  :before {
    position: absolute;
    width: 85px;
    height: 70px;
    content: "";
    top: -${({ theme }) => theme.spacing.two};
    left: 0;
    background: no-repeat url(${({ backgroundColour }) => backgroundColour === "offwhite" && `${quote}`});
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 3;
    margin: 0 ${({ theme }) => theme.spacing.three} ${({ theme }) => theme.spacing.seven};
    justify-self: center;
  }
`;

export const CaseStudyQuoteText = styled.blockquote`
  position: relative;
  margin: 0 0 ${({ theme }) => theme.spacing.one} 0;
  font-size: 24px;
  font-weight: 450;
  line-height: 28px;
  color: white;
`;

export const CaseStudyQuoteQuotee = styled.p`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 19px;
  color: white;
`;
