import styled from "styled-components";

interface PaginationDotsProps {
  isActive?: boolean;
}

interface ControlsItemProps {
  disabled?: boolean;
}

export const StyledImageGallery = styled.div``;

export const StyledImageGalleryItemWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledImageGalleryImagesContainer = styled.div`
  & .swiper-container {
    overflow: visible;
  }

  & .swiper-slide {
    margin-right: ${(props) => props.theme.spacing.three};
  }

  & .swiper-slide:last-child {
    margin-right: 0px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & .swiper-slide {
      margin-right: ${(props) => props.theme.spacing.one};
    }
  }
`;

export const StyledImageGalleryPaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.four};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: ${(props) => props.theme.spacing.three};
  }
`;

export const StyledImageGalleryPagination = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${(props) => props.theme.spacing.one};
`;

export const StyledImageGalleryPaginationDot = styled.div<PaginationDotsProps>`
  height: 4px;
  width: ${(props) => (props.isActive ? "18px" : "4px")};
  border-radius: 4px;
  background-color: black;
`;

export const StyledImageGalleryControls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 26px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledImageGalleryControlsItem = styled.div<ControlsItemProps>`
  & svg {
    color: ${(props) =>
      props.disabled
        ? props.theme.colors.neutrals.coolGrey
        : props.theme.colors.neutrals.black};
  }

  & svg:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;
