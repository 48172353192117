import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

interface SeoProps {
  description?: string;
  meta?: Array<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
  pathname: string;
  keywords?: Array<string>;
  title: string;
  image?: string;
  alternates?: Array<string>;
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        googleSiteVerification
      }
    }
  }
`;

const SEO: React.FC<SeoProps> = ({
  description,
  meta = [],
  pathname,
  keywords = [],
  title,
  image,
  alternates,
}) => {
  function getHrefLang(path: string): string {
    if (path === "/") {
      return "en";
    }

    let langPath = path;
    if (path.charAt(0) == "/") {
      langPath = path.substring(1);
    }

    if (langPath.substring(0, 2) === "jp") {
      return "ja";
    } else {
      return langPath.substring(0, 2);
    }
  }

  function getLang(path: string): string {
    if (typeof window !== "undefined") {
      if (path === "/") {
        return "en";
      }
      if (path.substring(0, 2) === "zh") {
        return "zh-CN";
      } else if (path === "/") {
      } else {
        return path.substring(0, 2);
      }
    }

    return "en";
  }

  function getAltLink(path: string) {
    let langPath = path;
    if (path.charAt(0) == "/") {
      langPath = path.substring(1);
    }
    return langPath;
  }

  function getDirection(path: string): string {
    return path.substring(0, 2) === "ar" ? "rtl" : "ltr";
  }

  function getOGUrl(siteUrl: string) {
    return pathname ? `${siteUrl}/` : siteUrl;
  }

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const siteMetadata = data.site.siteMetadata;
        const seo = {
          siteUrl: siteMetadata.siteUrl,
          image: image || `${siteMetadata.siteUrl}`,
          metaDescription: description || siteMetadata.description,
        };
        return (
          <Helmet
            htmlAttributes={{
              lang: getLang(pathname),
              dir: getDirection(pathname),
            }}
            title={title || siteMetadata.title}
            meta={[
              {
                name: "description",
                content: seo.metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: seo.metaDescription,
              },
              {
                property: "og:image",
                content: seo.image,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:url",
                content: getOGUrl(seo.siteUrl),
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteMetadata.author,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: seo.metaDescription,
              },
              {
                name: "google-site-verification",
                content: siteMetadata.googleSiteVerification,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            {Array.isArray(alternates) &&
              alternates.map((item, idx) => (
                <link
                  key={`alt-link-${idx}`}
                  rel="alternate"
                  href={`${seo.siteUrl}/${getAltLink(item)}`}
                  hrefLang={getHrefLang(item)}
                />
              ))}
            )
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;
