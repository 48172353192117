require("dotenv").config();

module.exports = {
  siteMetadata: {
    title: "Learning for the new world of work | Corporate Education | Hult EF",
    description:
      "We are corporate education experts who work with top organizations to help them develop their talent for key career moments in a world without borders.",
    author: "EF Global Creative",
    siteUrl: process.env.GATSBY_HOST,
    googleSiteVerification: process.env.GATSBY_GOOGLE_SITE_TOKEN,
  },
  trailingSlash: "always",
  plugins: [
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
        includeLinks: false,
        resolveRelations: ["legal-page.azureTermsPrivacy"],
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: `${__dirname}/src/assets`,
      },
    },
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    {
      resolve: "gatsby-plugin-styled-components",
      options: {
        // Add any options here
      },
    },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "Hult/EF ",
        short_name: "Hult/EF",
        start_url: "/",
        background_color: "#FFFFFF",
        theme_color: "#FFFFFF",
        display: "minimal-ui",
        icon: "src/assets/favicon.png",
      },
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        output: "/",
        excludes: ["/editor", "404"],
      },
    },
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        host: process.env.GATSBY_APP_ENV === "PROD" ? process.env.GATSBY_HOST : undefined,
        sitemap: [`${process.env.GATSBY_HOST}/sitemap-index.xml`],
        policy: [
          {
            userAgent: "*",
            disallow:
              process.env.GATSBY_APP_ENV === "PROD"
                ? ["/editor", "/fscallback", "/*/templates/", "/*/landing/", "/*/lp/", "/*/_config/"]
                : ["/"],
          },
        ],
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: process.env.GATSBY_TAG_MANAGER_ID,
        includeInDevelopment: true,
      },
    },
  ],
};
