import React, { useRef, useState } from "react";

import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";
import { sbImageService } from "hult-sb-ui";

import { sbAssetType, sbLinkType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import { MenuItemCmp, MenuItemProps } from "./MenuItem/MenuItem";
import {
  StyledBodyCenteredWModal,
  StyledButtonContainerDesktop,
  StyledButtonContainerMobile,
  StyledGrapicOverlay,
  StyledHeader,
  StyledIcon,
  StyledLandingPageContainer,
  StyledLandingPageHeader,
  StyledLandingPageHeaderContainer,
  StyledLandingPageHero,
  StyledLandingPageHeroAside,
  StyledLandingPageHeroBody,
  StyledLandingPageHeroHeader,
  StyledLandingPageHeroMainContentDesktop,
  StyledLandingPageHeroMainContentMobile,
  StyledLandingPageNav,
  StyledMenu,
  StyledMenuInner,
  StyledMenuItems,
  StyledNavDesktop,
  StyledNavMobile,
  StyledOverlay,
} from "./partials";

export interface LandingPageHeaderProps {
  menu_items: Array<MenuItemProps>;
  cta_label?: string;
  cta_link?: sbLinkType;
  cta_form?: Array<FormSCProps>;
  header: string;
  body?: string;
  image: sbAssetType;
  background_colour?: string;
  background_image?: sbAssetType;
  graphic_overlay: sbAssetType;
  graphic_overlay_mobile?: sbAssetType;
  overlay_opacity?: string;
}

export const LandingPageHeaderCmp: React.FC<LandingPageHeaderProps> = ({
  menu_items,
  cta_label,
  cta_link,
  cta_form,
  header,
  body,
  image,
  background_colour,
  background_image,
  graphic_overlay,
  graphic_overlay_mobile,
  overlay_opacity,
}) => {
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  function handleClick(e: any, id: any) {
    if (id?.url) {
      e.preventDefault();
    }

    let site = null;
    if (typeof document !== "undefined") {
      site = document.getElementById("site");
    } else return;

    let offsetTop = 0;
    let parentAdditionalOffset = 0;
    if (id.url) {
      const section = document.querySelector(id.url);
      offsetTop = section?.offsetTop;
      if (section) {
        const parent = section?.parentElement;
        if (parent) {
          parentAdditionalOffset = parent?.offsetTop;
        }
      }
    }
    const offset = offsetTop + parentAdditionalOffset;

    window?.scrollTo({ top: offset, left: 0, behavior: "smooth" });
  }

  function toggleNav() {
    if (contentRef.current) {
      setMaxHeight(contentRef?.current?.scrollHeight);
      setOpen(!open);
    }
  }

  return (
    <StyledLandingPageContainer>
      <StyledLandingPageHeader
        background_colour={background_colour}
        background_image={background_image && background_image.filename}
      >
        <StyledLandingPageHeaderContainer background_image={background_image && background_image.filename}>
          <StyledLandingPageNav>
            {/* NAV DESKTOP */}
            <StyledNavDesktop>
              <StyledMenuItems background_colour={background_colour}>
                {menu_items.map((item) => (
                  <MenuItemCmp
                    cta_label={item.cta_label}
                    cta_link={item.cta_link}
                    onClick={(e: any, link: any) => handleClick(e, link)}
                  />
                ))}
              </StyledMenuItems>
              {Array.isArray(cta_form) && cta_form.length === 1 && (
                <StyledButtonContainerDesktop background_colour={background_colour} open={open}>
                  <ButtonHEF
                    variant="primary"
                    onClick={(_: MouseEvent) => setOpenModal(true)}
                    label={cta_label}
                  ></ButtonHEF>
                </StyledButtonContainerDesktop>
              )}
              {Array.isArray(cta_form) && cta_form.length !== 1 && cta_link && (
                <StyledButtonContainerDesktop background_colour={background_colour} open={open}>
                  <ButtonHEF as={"a"} variant="primary" href={getUrl(cta_link)} label={cta_label}></ButtonHEF>
                </StyledButtonContainerDesktop>
              )}
              {Array.isArray(cta_form) && cta_form.length === 1 && (
                <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
                  <FormSC
                    onClose={(_: void) => setOpenModal(false)}
                    _uid={cta_form[0]._uid}
                    form={cta_form[0].form}
                    component={cta_form[0].component}
                    form_handler={cta_form[0].form_handler}
                    sf_campaign_id={cta_form[0].sf_campaign_id}
                    title={cta_form[0].title}
                    qualifier={cta_form[0].qualifier}
                    event_date={cta_form[0].event_date}
                    event_duration={cta_form[0].event_duration}
                    event_location={cta_form[0].event_location}
                    thank_you_components={cta_form[0].thank_you_components}
                    close_on_submit={cta_form[0].close_on_submit}
                  />
                </Modal>
              )}
            </StyledNavDesktop>

            {/* NAV MOBILE */}
            <StyledNavMobile
              open={open}
              background_image={background_image && background_image.filename}
              background_colour={background_colour}
            >
              <StyledHeader open={open} onClick={() => toggleNav()}>
                <StyledIcon open={open} background_colour={background_colour}>
                  <IconChevronDown />
                </StyledIcon>
              </StyledHeader>

              <StyledMenu ref={contentRef} open={open} maxHeight={maxHeight}>
                <StyledMenuInner open={open} background_colour={background_colour}>
                  {menu_items.map((item) => (
                    <MenuItemCmp
                      cta_label={item.cta_label}
                      cta_link={item.cta_link}
                      onClick={(e: React.MouseEvent<Element, MouseEvent>, link: sbLinkType) => handleClick(e, link)}
                    />
                  ))}
                  <StyledButtonContainerMobile background_colour={background_colour}>
                    <StyledBodyCenteredWModal>
                      {Array.isArray(cta_form) && cta_form.length === 1 && (
                        <ButtonHEF
                          variant="primary"
                          onClick={(_: MouseEvent) => setOpenModal(true)}
                          label={cta_label}
                        ></ButtonHEF>
                      )}
                      {Array.isArray(cta_form) && cta_form.length !== 1 && cta_link && (
                        <ButtonHEF as={"a"} variant="primary" href={getUrl(cta_link)} label={cta_label}></ButtonHEF>
                      )}
                    </StyledBodyCenteredWModal>
                  </StyledButtonContainerMobile>
                </StyledMenuInner>
              </StyledMenu>
            </StyledNavMobile>
          </StyledLandingPageNav>

          {/* HEADER */}
          <StyledLandingPageHero>
            <StyledLandingPageHeroMainContentDesktop>
              <StyledLandingPageHeroHeader background_colour={background_colour}>
                {header && header}
              </StyledLandingPageHeroHeader>
              <StyledLandingPageHeroBody background_colour={background_colour}>
                {body && body}
              </StyledLandingPageHeroBody>
            </StyledLandingPageHeroMainContentDesktop>
            <StyledLandingPageHeroAside background_image={background_image && background_image.filename}>
              <img
                src={sbImageService(image.filename, undefined, "0x468")}
                width={"450px"}
                height={"380px"}
                alt={image.alt}
                title={image.title}
                loading={"eager"}
              />
            </StyledLandingPageHeroAside>
          </StyledLandingPageHero>
        </StyledLandingPageHeaderContainer>
        <StyledOverlay
          background_image={background_image && background_image.filename}
          overlay_opacity={overlay_opacity}
        />
        {graphic_overlay?.filename && (
          <StyledGrapicOverlay
            src={sbImageService(graphic_overlay.filename, undefined, "1200x0", graphic_overlay.focus)}
            alt={graphic_overlay.alt}
            title={graphic_overlay.alt}
          ></StyledGrapicOverlay>
        )}
        {graphic_overlay_mobile?.filename && (
          <StyledGrapicOverlay
            src={sbImageService(graphic_overlay_mobile.filename, undefined, "780x0")}
            mobile
            alt={graphic_overlay_mobile.alt}
            title={graphic_overlay_mobile.alt}
          ></StyledGrapicOverlay>
        )}
      </StyledLandingPageHeader>
      <StyledLandingPageHeroMainContentMobile>
        <StyledLandingPageHeroHeader>{header && header}</StyledLandingPageHeroHeader>
        <StyledLandingPageHeroBody>{body && body}</StyledLandingPageHeroBody>
      </StyledLandingPageHeroMainContentMobile>
    </StyledLandingPageContainer>
  );
};
