import styled from "styled-components";

export const LogoRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.theme.maxWidthWPadding};
  min-height: 160px;
  margin: 0 auto;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.five} ${(props) => props.theme.spacing.three};
`;

export const LogoRowTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const StyledLogoRow = styled.div`
  display: flex;

  & .swiper-container {
    width: 95%;
    align-self: center;
    justify-content: center;
  }

  & .swiper-slide {
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 84px;
  }

  & .swiper-slide img {
    max-width: 168px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: 100vw;

    & .swiper-container {
      width: 90%;
    }

    & .swiper-slide {
      width: 120px;
    }

    & .swiper-slide img {
      max-width: 120px;
    }
  }
`;

export const StyledChevronWrapper = styled.div`
  width: 16px;
  align-self: center;
  cursor: pointer;
`;

export const StyledLogosWrapper = styled.div``;
