import * as React from "react";
import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import {
  StyledOpenProgramsOverviewCard,
  StyledOpenProgramsLearningFormats,
  StyledOpenProgramsLearningMain,
  StyledOpenProgramsCardTitle,
  StyledOpenProgramsCardQualifier,
  StyledOpenProgramsCardLink,
} from "./partials";
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight";

export interface OpenProgramsOverviewCard {
  id: number;
  title: string;
  qualifier: string;
  link: string;
  card_link_label?: string;
  learning_formats?: string;
}

export const OpenProgramsOverviewCardCmp: React.FC<{
  card: OpenProgramsOverviewCard;
}> = ({ card }) => {
  const label = card.card_link_label ? card.card_link_label : "Read more";

  function handleClick() {
    window.dataLayer.push({
      event: "link_click",
      eventMeta: {
        p_one: "open-programs-card",
        p_two: label,
        p_three: card.link,
      },
    });
  }

  return (
    <Surface
      as="a"
      href={card.link}
      shadow
      rounded
      onClick={(_) => handleClick()}
    >
      <StyledOpenProgramsOverviewCard>
        <StyledOpenProgramsLearningFormats>
          {card.learning_formats && <p>{card.learning_formats}</p>}
        </StyledOpenProgramsLearningFormats>
        <StyledOpenProgramsLearningMain>
          {card.qualifier && (
            <StyledOpenProgramsCardQualifier>
              {card.qualifier}
            </StyledOpenProgramsCardQualifier>
          )}
          <div>
            {card.title && (
              <StyledOpenProgramsCardTitle>
                {card.title}
              </StyledOpenProgramsCardTitle>
            )}
            {card.link && (
              <StyledOpenProgramsCardLink>
                {card.card_link_label ? card.card_link_label : "Read more"}
                <IconArrowRight />
              </StyledOpenProgramsCardLink>
            )}
          </div>
        </StyledOpenProgramsLearningMain>
      </StyledOpenProgramsOverviewCard>
    </Surface>
  );
};
