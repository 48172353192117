import styled from "styled-components";

export const StyledSurfaceWrapper = styled.div`
  & svg {
    opacity: 0;
    transition: transform 500ms;
  }

  :hover {
    & svg {
      opacity: 1;
      transform: translateX(16px);
    }
  }

  > a {
    height: 100%;
  }
`;

export const StyledProductCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  & a {
    position: relative;
    width: inherit !important;
    margin: 0 !important;
  }

  & p {
    overflow: hidden;
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.bold};
    margin: 0;
  }
`;

export const StyledProductCardTitleContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

export const StyledProductOverviewCardIcon = styled.div`
  height: 40px;
  width: 40px;

  margin-right: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-right: ${(props) => props.theme.spacing.three};
  }
`;

export const StyledProductCardLink = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.neutrals.black};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: inherit;

  & label {
    cursor: inherit;
  }
`;
