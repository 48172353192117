import styled from "styled-components";

export const StyledFeatureStat = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFeatureStatWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledFeatureStatHeader = styled.h2`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledFeatureStatSubHeader = styled.p`
  white-space: pre-line;
  margin-bottom: ${(props) => props.theme.spacing.four};
  font-weight: ${(props) => props.theme.font.light};
`;

export const StyledFeatureStatList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: ${(props) => props.theme.spacing.four};
  column-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
  }
`;
