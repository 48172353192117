import React from "react"

import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay"
import ReactPlayer from "react-player"

import { sbAssetType } from "../../types"
import {
  StyledHeaderAssetWrapper,
  StyledIconPlayWrapper,
  StyledLargeVideo,
  StyledLargeVideoBody,
  StyledLargeVideoHeader,
  StyledLargeVideoWrapper,
} from "./partials"

export interface LargeVideoProps {
  title?: string
  body_copy?: string
  video?: sbAssetType
  video_preview?: sbAssetType
  video_link?: string
  autoplay?: boolean
}

export const LargeVideoCmp: React.FC<LargeVideoProps> = ({
  title,
  body_copy,
  video,
  video_preview,
  video_link,
  autoplay = false,
}) => {
  return (
    <StyledLargeVideo data-testid="large-video">
      <StyledLargeVideoWrapper>
        {title && <StyledLargeVideoHeader>{title}</StyledLargeVideoHeader>}
        {body_copy && <StyledLargeVideoBody>{body_copy}</StyledLargeVideoBody>}

        <StyledHeaderAssetWrapper>
          {/* If video and video_preview available only show video */}
          {video?.filename && video_preview?.filename && (
            <ReactPlayer
              url={video.filename}
              light={!autoplay ? video_preview.filename : false}
              width="100%"
              height="100%"
              playing={true}
              playIcon={
                <StyledIconPlayWrapper>
                  <IconPlay />
                </StyledIconPlayWrapper>
              }
              controls={true}
            />
          )}
          {/* If internal video available dont show iframe */}
          {video_link && !video?.filename && video_preview?.filename && (
            <ReactPlayer
              url={video_link}
              light={!autoplay ? video_preview.filename : false}
              width="100%"
              height="100%"
              playIcon={
                <StyledIconPlayWrapper>
                  <IconPlay />
                </StyledIconPlayWrapper>
              }
              playing={true}
              controls={true}
            />
          )}
          {video_link && !video?.filename && !video_preview?.filename && (
            <ReactPlayer
              url={video_link}
              light={!autoplay ? true : false}
              width="100%"
              height="100%"
              playIcon={
                <StyledIconPlayWrapper>
                  <IconPlay />
                </StyledIconPlayWrapper>
              }
              playing={true}
              controls={true}
            />
          )}
        </StyledHeaderAssetWrapper>
      </StyledLargeVideoWrapper>
    </StyledLargeVideo>
  )
}
