import styled from "styled-components";

interface StyledHighlightedPointProps {
  adjustedText?: boolean;
}

export const StyledHighlightedPointContent = styled.div``;

export const StyledHighlightedListTitle = styled.p<StyledHighlightedPointProps>`
  font-size: 24px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: 15px;
  line-height: 28px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.neutrals.white};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    > p {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const StyledHighlightedListPoint = styled.p<StyledHighlightedPointProps>`
  font-size: 16px;
  font-weight: ${({ theme, adjustedText }) => (adjustedText ? theme.font.book : theme.font.bold)};
  margin: 0;
  line-height: ${({ adjustedText }) => (adjustedText ? "24px" : "40px")};
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.neutrals.white};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    > p {
      font-size: ${({ adjustedText }) => (adjustedText ? "16px" : "24px")};
      line-height: 32px;
    }
  }
`;
