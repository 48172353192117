import React, { useContext } from "react";
// import Components from "./components";
import SettingsContext from "../context/SettingsContext";

import DynamicComponent from "./";

const Blank = (props) => {
  const { settings } = useContext(SettingsContext);
  return (
    <>
      {props.blok.body &&
        props.blok.body.map((blok) => (
          <DynamicComponent key={blok._uid} blok={blok} settings={settings} />
        ))}
    </>
  );
};

export default Blank;
