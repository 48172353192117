import React from "react";

import { ImpactBoxBody, ImpactBoxTitle, ImpactBoxWrapper } from "./styles/StyledImpactBox";

export interface ImpactBoxProps {
  title: string;
  body: string;
  id?: string;
}

export const ImpactBox: React.FC<ImpactBoxProps> = ({ title, body, id }) => {
  return (
    <ImpactBoxWrapper id={id}>
      <ImpactBoxTitle>{title}</ImpactBoxTitle>
      <ImpactBoxBody>{body}</ImpactBoxBody>
    </ImpactBoxWrapper>
  );
};
