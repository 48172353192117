import React from "react";

import sbImageService from "../utils/sbImage";

import { sbAssetType } from "../../types";

import {
  StyledArticleAuthorHeader,
  StyledArticleAuthorImage,
  StyledArticleAuthorHeaderName,
  StyledArticleAuthorHeaderPos,
  StyledArticleAuthorHeaderText,
} from "./partials";

export interface ArticleAuthorHeaderProps {
  image: sbAssetType;
  author_name: string;
  position_and_company: string;
}

export const ArticleAuthorHeader: React.FC<ArticleAuthorHeaderProps> = ({
  image,
  author_name,
  position_and_company,
}) => {
  return (
    <StyledArticleAuthorHeader>
      {image && (
        <StyledArticleAuthorImage
          alt={image.alt}
          src={sbImageService(image.filename, "48x48")}
          loading={"lazy"}
        />
      )}
      <StyledArticleAuthorHeaderText>
        {author_name && (
          <StyledArticleAuthorHeaderName>
            {author_name}
          </StyledArticleAuthorHeaderName>
        )}
        {position_and_company && (
          <StyledArticleAuthorHeaderPos>
            {position_and_company}
          </StyledArticleAuthorHeaderPos>
        )}
      </StyledArticleAuthorHeaderText>
    </StyledArticleAuthorHeader>
  );
};
