import { sbLinkType } from "../../types";
import { NavItem, NavItemCmp } from "../NavItem";
import { NavItemWSubItemsProps } from "../NavItemWSubItems";
import { getUrl } from "../utils/helpers";

export function isActiveLink(
  currentUrl: string | undefined,
  cachedUrl: string
) {
  return currentUrl ? currentUrl.indexOf(cachedUrl) !== -1 : false;
}

export const isArrayWithSubItems = (
  items: Array<NavItem | NavItemWSubItemsProps>
): items is Array<NavItemWSubItemsProps> => {
  return items.some((item) => isNavItemWSubItem(item));
};

export const isNavItem = (
  item: NavItemCmp | NavItemWSubItemsProps
): item is NavItem => {
  return "link" in item;
};

export const isNavItemWSubItem = (
  item: NavItemWSubItemsProps | NavItemCmp
): item is NavItemWSubItemsProps => {
  return "sub_items" in item;
};
