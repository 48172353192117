import React, { useState } from "react";
import IconPlusCircleOutline from "@ef-global/web-ui-react/lib/icons/IconPlusCircleOutline";
import { Modal } from "../Modal";
import { ArticleAuthorHeader } from "./ArticleAuthorHeader";
import { ArticleAuthorModalContent } from "./ArticleAuthorModalContent";

import sbTextArea from "../utils/sbTextArea";

import { sbAssetType, blokType } from "../../types";

import {
  StyledArticleAuthor,
  StyledArticleAuthorWrapper,
  StyledArticleAuthorBio,
  StyledArticleAuthorLink,
} from "./partials";

export interface ArticleAuthorBlokProps extends blokType {
  image: sbAssetType;
  author_name: string;
  position_and_company: string;
  bio: string;
  read_more_label: string;
}

export const ArticleAuthorCmp: React.FC<ArticleAuthorBlokProps> = ({
  image,
  author_name,
  position_and_company,
  bio,
  read_more_label,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <StyledArticleAuthor>
      <StyledArticleAuthorWrapper>
        <ArticleAuthorHeader
          image={image}
          author_name={author_name}
          position_and_company={position_and_company}
        />
        {bio && (
          <>
            <StyledArticleAuthorBio>{sbTextArea(bio)}</StyledArticleAuthorBio>
            <StyledArticleAuthorLink onClick={() => setOpenModal(true)}>
              {read_more_label}
              <IconPlusCircleOutline />
            </StyledArticleAuthorLink>
            <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
              <ArticleAuthorModalContent
                image={image}
                author_name={author_name}
                bio={bio}
              />
            </Modal>
          </>
        )}
      </StyledArticleAuthorWrapper>
    </StyledArticleAuthor>
  );
};
