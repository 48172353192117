import React, { useEffect, useRef, useState } from "react";
import IconClose from "@ef-global/web-ui-react/lib/icons/IconClose";
import { ButtonHEF } from "../Button";

import {
  StyledModal,
  StyledModalContent,
  StyledModalBody,
  StyledModalHeader,
  StyledHeader,
  StyledIconContainer,
} from "./partials";

export interface ModalProps {
  isOpen?: boolean;
  onClose?: any;
  showClose?: boolean;
  half?: boolean;
  closeAnimation?: boolean;
  triggerClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen = false,
  half = false,
  onClose,
  closeAnimation = true,
  showClose = true,
  triggerClose = false,
}) => {
  const bodyEl = useRef(null);
  const [hasOverflow, setOverflow] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  let body = null;
  let site = null;
  let banner = null;
  if (typeof document !== "undefined") {
    body = document.querySelector("body");
    site = document.getElementById("site");
    banner = document.getElementById("banner");
  }

  if (isOpen && body) {
    body.style.overflow = "hidden";
  } else if (!isOpen && body) {
    body.style.overflow = "auto";
  }

  if (isOpen && site && banner) {
    banner.style.display = "none";
    site.style.top = "0";
  } else if (!isOpen && site && banner) {
    banner.style.display = "block";
    site.style.top = "48px";
  }

  useEffect(() => {
    // @ts-ignore
    setOverflow(bodyEl.current?.scrollHeight > bodyEl.current?.clientHeight);

    if (triggerClose) {
      setIsClosing(true);
      setTimeout(function () {
        setIsClosing(false);
      }, 500);
    }
  }, [triggerClose]);

  function handleClose() {
    if (closeAnimation) {
      setIsClosing(true);
      setTimeout(function () {
        onClose();
        setIsClosing(false);
      }, 500);
    } else if (isOpen) {
      onClose();
    }
  }

  return (
    <StyledModal isOpen={isOpen}>
      <StyledModalContent
        isOverflow={hasOverflow}
        isHalf={half}
        handleClose={isClosing}
      >
        {showClose && (
          <StyledModalHeader isOverflow={hasOverflow}>
            <StyledHeader>Filter</StyledHeader>
            <StyledIconContainer onClick={handleClose}>
              <IconClose />
            </StyledIconContainer>
          </StyledModalHeader>
        )}
        <StyledModalBody header={showClose} ref={bodyEl}>
          {children}
        </StyledModalBody>
        <ButtonHEF
          variant="primary"
          label="Apply"
          onClick={handleClose}
        ></ButtonHEF>
      </StyledModalContent>
    </StyledModal>
  );
};
