import styled from "styled-components";

interface StyleProps {
  readonly isActive: boolean;
}

export const StyledNavItemWSubItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
`;

export const StyledNavItemWSubItems = styled.div<StyleProps>`
  white-space: nowrap;
  height: auto;
  display: flex;
  font-size: ${(props) => props.theme.fontSize.l};
  text-decoration: ${(props) => (props.isActive ? "underline" : "none")};

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 83%;
    display: inherit;
    font-size: ${(props) => props.theme.fontSize.l};
    border-left: ${(props) => (props.isActive ? "4px solid #191919" : "none")};
    font-weight: ${(props) =>
      props.isActive ? props.theme.font.bold : "none"};
    text-decoration: none;

    :hover {
      text-decoration: none;
    }

    & p {
      padding: ${(props) => props.theme.spacing.two} 0;
      margin-left: ${(props) =>
        props.isActive ? "28px" : props.theme.spacing.four};
    }
  }
`;

export const StyledNavItemWSubItemsText = styled.p`
  font-weight: inherit;
  margin-bottom: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    :hover {
      text-decoration: none;
    }
  }
`;

export const StyledNavItemWSubItemsArrow = styled.div`
  cursor: pointer;
  padding-left: ${(props) => props.theme.spacing.one};
  padding-top: 0.25em;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-left: 0;
    padding-bottom: ${(props) => props.theme.spacing.two};
    padding-top: 18px;
  }
`;
