import React, { useEffect, useRef, useState } from "react";

import IconChevronLeft from "@ef-global/web-ui-react/lib/icons/IconChevronLeft";
import IconChevronRight from "@ef-global/web-ui-react/lib/icons/IconChevronRight";
import { Swiper, SwiperSlide } from "swiper/react";

import { LogoRowImageCmp, LogoRowImageProps } from "./LogoRowImage";
import { LogoRowTitle, LogoRowWrapper, StyledChevronWrapper, StyledLogoRow } from "./partials/StyledLogoRow";

export interface LogoRowProps {
  title?: string;
  logos: [LogoRowImageProps];
}

export const LogoRowCmp: React.FC<LogoRowProps> = ({ title, logos }) => {
  const swiperRef = useRef(null);
  const [slideCount, setSlideCount] = useState<number | undefined>(undefined);
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (typeof window !== undefined && swiperRef !== null) {
        setInnerWidth(swiperRef.current?.clientWidth ?? 0);
      }
    };

    window?.addEventListener("resize", updateWindowDimensions);

    updateWindowDimensions();

    return () => window?.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setSlideCount(innerWidth <= 645 ? 2 : 4);
  }, [innerWidth]);

  return (
    <LogoRowWrapper>
      {title && <LogoRowTitle>{title}</LogoRowTitle>}
      <StyledLogoRow>
        <StyledChevronWrapper onClick={() => swiperRef.current?.swiper.slidePrev()}>
          <IconChevronLeft />
        </StyledChevronWrapper>
        <Swiper ref={swiperRef} slidesPerView={slideCount ?? 4} slidesPerGroup={1} loop={true}>
          {logos.map((logo, index) => (
            <SwiperSlide key={`slide-${index}`}>{logo.image && <LogoRowImageCmp image={logo.image} />}</SwiperSlide>
          ))}
        </Swiper>
        <StyledChevronWrapper onClick={() => swiperRef.current?.swiper.slideNext()}>
          <IconChevronRight />
        </StyledChevronWrapper>
      </StyledLogoRow>
    </LogoRowWrapper>
  );
};
