import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { CardWithTextCmp, CardWithTextProps } from "./CardWithText";

interface CardWithTextWBlokProps {
  blok: CardWithTextProps & blokType;
}

export const CardWithText: React.FC<CardWithTextWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CardWithTextCmp
        title={blok.title}
        cards={blok.cards}
        background={blok.background}
        breaker={blok.breaker}
      ></CardWithTextCmp>
    </SbEditable>
  );
};
