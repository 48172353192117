import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FullBleedImageCmp, FullBleedImageProps } from "./FullBleedImage";

interface FullBleedImageWBlokProps {
  blok: FullBleedImageProps & blokType;
}

export const FullBleedImage: React.FC<FullBleedImageWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <FullBleedImageCmp
        image={blok.image}
        quote={blok.quote}
        quote_by={blok.quote_by}
        graphic_overlay={blok.graphic_overlay}
        graphic_overlay_mobile={blok.graphic_overlay_mobile}
        graphic_overlay_placement={blok.graphic_overlay_placement}
      ></FullBleedImageCmp>
    </SbEditable>
  );
};
