import React, { useState } from "react";
import { getKey } from "../utils/helpers";

import {
  StyledRadioWrapper,
  StyledRadioItemsWrapper,
  StyledRadioItem,
  StyledErrorMessage,
} from "./partials";

export interface RadioProps {
  id: string;
  section_id: number;
  name: string;
  label: string;
  defaultValue?: string;
  onChange?: any;
  options?: Array<any>;
  required?: boolean;
  hidden?: boolean;
  isValid?: boolean;
  value?: string;
  required_err_msg?: string;
}

export const Radio: React.FC<RadioProps> = ({
  id = "",
  section_id,
  name = "",
  label = "",
  options = [],
  onChange = () => {},
  defaultValue = "",
  required = false,
  hidden = false,
  isValid = undefined,
  required_err_msg = "Please select at least one option",
}) => {
  const [opt, setOpt] = useState(defaultValue);

  function handleClick(val: string) {
    onChange(id, {
      value: val,
      valid: true,
      section_id: section_id,
    });
  }

  return (
    <>
      {!hidden && (
        <StyledRadioWrapper key={id}>
          {label && (
            <label>
              {label} {required && " *"}
            </label>
          )}

          <StyledRadioItemsWrapper>
            {Array.isArray(options) &&
              options.length > 1 &&
              options.map((item, idx) => (
                <StyledRadioItem
                  key={getKey("radio-option", idx)}
                  onClick={(_) => handleClick(item.value)}
                >
                  <input
                    type="radio"
                    required={required}
                    value={item.value}
                    name={name}
                    checked={opt === item.value}
                    onChange={(e) => setOpt(e.currentTarget.value)}
                  />
                  <label>{item.label}</label>
                </StyledRadioItem>
              ))}
          </StyledRadioItemsWrapper>
          {isValid === false && (
            <StyledErrorMessage>{required_err_msg}</StyledErrorMessage>
          )}
        </StyledRadioWrapper>
      )}
    </>
  );
};
