import styled from "styled-components";

export const StyledMenuItem = styled.a`
  font-size: 14px;
  padding-right: 32px;
  
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 16px;
    padding-right: 0;
  }
`;
