import React from "react";
import { sbAssetType } from "../../types";

import { SbImage, sbImageService } from "hult-sb-ui";

import {
  StyledFullBleedImgContainer,
  StyledFullBleedImg,
  StyledFullBleedGrapicOverlay,
  StyledQuoteContainer,
  StyledQuote,
  StyledQuoteBy,
  StyledQuoteWrapper,
  StyledOverlay,
} from "./partials";

export interface FullBleedImageProps {
  image: sbAssetType;
  quote: string;
  quote_by: string;
  graphic_overlay?: sbAssetType;
  graphic_overlay_mobile?: sbAssetType;
  graphic_overlay_placement?: string;
}

export const FullBleedImageCmp: React.FC<FullBleedImageProps> = ({
  image,
  quote,
  quote_by,
  graphic_overlay,
  graphic_overlay_mobile,
  graphic_overlay_placement,
}) => {
  return (
    <StyledFullBleedImgContainer>
      <StyledFullBleedImg>
        <SbImage image={image} loading="lazy" resize="1200x0"/>
        {graphic_overlay?.filename && (
          <StyledFullBleedGrapicOverlay
            src={sbImageService(
              graphic_overlay.filename,
              undefined,
              "1200x0",
              graphic_overlay.focus
            )}
            placement={graphic_overlay_placement}
            alt={graphic_overlay.alt}
            title={graphic_overlay.alt}
          ></StyledFullBleedGrapicOverlay>
        )}
        {graphic_overlay_mobile?.filename && (
          <StyledFullBleedGrapicOverlay
            src={sbImageService(
              graphic_overlay_mobile.filename,
              undefined,
              "780x0"
            )}
            mobile
            placement={graphic_overlay_placement}
            alt={graphic_overlay_mobile.alt}
            title={graphic_overlay_mobile.alt}
          ></StyledFullBleedGrapicOverlay>
        )}

        {quote && (
          <StyledQuoteWrapper>
            <StyledQuoteContainer>
              <StyledQuote>{quote && quote}</StyledQuote>
              <StyledQuoteBy>{quote_by && quote_by}</StyledQuoteBy>
            </StyledQuoteContainer>
          </StyledQuoteWrapper>
        )}
        <StyledOverlay quote={quote}/>
      </StyledFullBleedImg>
    </StyledFullBleedImgContainer>
  );
};
