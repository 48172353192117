import React from "react";

import { SbImage } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { Blender } from "../Blender";
import { LinkItem, LinkItemProps } from "../LinkItem";
import {
  BodyThreeBackground,
  BodyThreeBody,
  BodyThreeContentWrapper,
  BodyThreeImageWrapper,
  BodyThreeTitle,
  BodyThreeWrapper,
} from "./partials/StyledBodyThree";

export interface BodyThreeProps {
  title: string;
  body: string;
  image: sbAssetType;
  link?: LinkItemProps[];
  breaker: boolean;
  breakerPosition: "top" | "bottom";
  backgroundColour: string;
  flipBreaker: boolean;
}

export const BodyThreeCmp: React.FC<BodyThreeProps> = ({
  title,
  body,
  image,
  link,
  backgroundColour,
  breaker,
  breakerPosition = "top",
  flipBreaker,
}) => {
  return (
    <>
      {breaker && breakerPosition === "top" && (
        <Blender
          color={backgroundColour === "black" ? "black" : "offwhite"}
          position={breakerPosition}
          flip={flipBreaker}
        />
      )}
      <BodyThreeBackground bgColour={backgroundColour}>
        <BodyThreeWrapper>
          <BodyThreeImageWrapper>
            <SbImage image={image} />
          </BodyThreeImageWrapper>
          <BodyThreeContentWrapper bgColour={backgroundColour}>
            <BodyThreeTitle>{title}</BodyThreeTitle>
            <BodyThreeBody bgColour={backgroundColour}>{body}</BodyThreeBody>
            {Array.isArray(link) && link.length > 0 && <LinkItem {...link[0]} />}
          </BodyThreeContentWrapper>
        </BodyThreeWrapper>
      </BodyThreeBackground>
      {breaker && breakerPosition === "bottom" && (
        <Blender
          color={backgroundColour === "black" ? "black" : "offwhite"}
          position={breakerPosition}
          flip={flipBreaker}
        />
      )}
    </>
  );
};
