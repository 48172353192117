import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { LargeVideoCmp, LargeVideoProps } from "./LargeVideo";

interface LargeVideoWBlokProps {
  blok: LargeVideoProps & blokType;
}

export const LargeVideo: React.FC<LargeVideoWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <LargeVideoCmp
        title={blok.title}
        body_copy={blok.body_copy}
        video={blok.video}
        video_link={blok.video_link}
        video_preview={blok.video_preview}
      ></LargeVideoCmp>
    </SbEditable>
  );
};
