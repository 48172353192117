import styled from "styled-components";

export const StyledProductCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  padding: ${(props) => props.theme.spacing.one};

  & a {
    position: relative;
    width: inherit !important;
    margin: 0 !important;
  }

  & h5 {
    margin: ${(props) => props.theme.spacing.one} 0;
  }

  & p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: ${(props) => props.theme.font.light};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      -webkit-line-clamp: 3;
    }
  }
`;

export const StyledProductOverviewCardIcon = styled.div`
  height: 40px;
  width: 40px;

  margin: ${(props) => props.theme.spacing.two} 0
    ${(props) => props.theme.spacing.two} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: ${(props) => props.theme.spacing.two} 0
      ${(props) => props.theme.spacing.three} 0;
  }
`;

export const StyledProductCardLink = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: inherit;

  & label {
    cursor: inherit;
  }

  & a:hover {
      color: ${({ theme }) => theme.colors.neutrals.white};
    }
  }

  :lang(ar) {
    & svg {
      transform: rotate(180deg);
    }
  }
`;
