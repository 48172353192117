import React from "react";
import { blokType } from "../../types";
import { AccordionCmp, IAccordionCmp } from "./Accordion";

export interface IAccordion {
  blok: blokType & IAccordionCmp;
}

export const Accordion: React.FC<IAccordion> = ({ blok }) => {
  return <AccordionCmp title={blok.title} items={blok.items} />;
};
