import React from "react";
import { blokType } from "../../types";
import { OtherProgramsCmp, OtherProgramsProps } from "./OtherPrograms";

export interface IOtherProgramsProps {
  blok: blokType & OtherProgramsProps;
}

export const OtherPrograms: React.FC<IOtherProgramsProps> = ({ blok }) => {
  return (
    <OtherProgramsCmp
      title={blok.title}
      desc={blok.desc}
      hide_shape={blok.hide_shape}
      cta_label={blok.cta_label}
      cta_link={blok.cta_link}
    />
  );
};
