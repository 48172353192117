import styled from "styled-components";

interface StyledHighlightedListProps {
  background_color?: "heritage" | "copper" | "intensity" | undefined;
  adjustedText?: boolean;
}

export const StyledHighlightedListWrapper = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledHighlightedList = styled.div<StyledHighlightedListProps>`
  position: relative;
  background-color: ${(props) =>
    props.background_color
      ? props.theme.colors.warmths[props.background_color]
      : props.theme.colors.warmths["intensity"]};
  border-radius: 40px;
`;

export const StyledHighlightedListContent = styled.div<StyledHighlightedListProps>`
  z-index: 5;
  padding: ${(props) => props.theme.spacing.eight} ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledAssetWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-width: ${(props) => props.theme.maxWidth};
  width: 100%;
  top: 32px;
  z-index: 0;
  margin: auto;

  & img {
    display: block;
    width: 100%;
    max-width: 960px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: calc(100% - 60px);
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    top: 38px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledHighlightedListContentText = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  display: grid;
  grid-template-columns: 75%;
  grid-auto-flow: row;
  grid-gap: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledHighlightedListContentQual = styled.h2<StyledHighlightedListProps>`
  font-size: ${({ adjustedText }) => (adjustedText ? "32px" : "16px")};
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
  color: ${({ theme }) => theme.colors.neutrals.white};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${({ adjustedText }) => (adjustedText ? "24px" : "14px")};
  }
`;

export const StyledHighlightedListContentPoint = styled.div<StyledHighlightedListProps>`
  > hr {
    margin: ${({ theme }) => theme.spacing.three} 0 0 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    > p {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;
