import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { SubscribeFormCmp, SubscribeFormProps } from "./SubscribeForm";
import { AllStoryblokEntryResult, blokType, storyType } from "../../types";

export interface SubscribeFormStoryProps extends storyType {
  content: blokType & SubscribeFormProps;
}

export interface SubscribeFormSCProps {
  form_handler: string;
  form: string;
}

export interface SubscribeFormQueryProps {
  blok: blokType & SubscribeFormSCProps;
}

export const SubscribeFormSC: React.FC<SubscribeFormQueryProps> = ({
  blok,
}) => {
  function parseQueryResult(data: AllStoryblokEntryResult, id: string): any {
    let parsedResult: blokType;

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: blokType = node.content;
      if (typeof node.content === "string") content = JSON.parse(node.content);
      if (node.uuid == id) {
        parsedResult = content;
      }
    });

    return parsedResult;
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allStoryblokEntry(
            filter: { field_component: { eq: "subscribe-form" } }
          ) {
            edges {
              node {
                uuid
                id
                content
              }
            }
          }
        }
      `}
      render={(data) => {
        const parsedResult = parseQueryResult(data, blok.form);

        return (
          <>
            {parsedResult && (
              <SubscribeFormCmp
                form_handler={blok.form_handler}
                title={parsedResult.title}
                component={parsedResult.component}
                body_copy={parsedResult.body_copy}
                email_placeholder={
                  parsedResult?.email_placeholder
                    ? parsedResult.email_placeholder
                    : undefined
                }
                sign_up_label={
                  parsedResult?.sign_up_label
                    ? parsedResult.sign_up_label
                    : undefined
                }
                privacy={
                  parsedResult.privacy?.content
                    ? JSON.stringify(parsedResult.privacy?.content[0])
                    : undefined
                }
                thank_you_header={
                  parsedResult.thank_you_header
                    ? parsedResult.thank_you_header
                    : undefined
                }
                thank_you_body={
                  parsedResult.thank_you_body
                    ? parsedResult.thank_you_body
                    : undefined
                }
                required_err_msg={
                  parsedResult.required_err_msg
                    ? parsedResult.required_err_msg
                    : undefined
                }
                invalid_err_msg={
                  parsedResult.invalid_err_msg
                    ? parsedResult.invalid_err_msg
                    : undefined
                }
                cta_colour={parsedResult.cta_colour}
              ></SubscribeFormCmp>
            )}
          </>
        );
      }}
    />
  );
};
