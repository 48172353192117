import React from "react";
import { StyledLearningMetaItems } from "./partials";

export interface ILearningMetaItems {
  body: string;
}

export const LearningMetaItems: React.FC<ILearningMetaItems> = ({ body }) => {
  return <StyledLearningMetaItems>{body && body}</StyledLearningMetaItems>;
};
