import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { GeoHandlerCmp, GeoHandlerProp } from "./GeoHandler";

interface IGeoHandlerProps {
  blok: GeoHandlerProp & blokType;
}

export const GeoHandler: React.FC<IGeoHandlerProps> = ({ blok }) => {
  return (
    <GeoHandlerCmp
      country={blok.country}
      content={blok.content}
      fallback={blok.fallback}     
    />
  );
};
