import styled from "styled-components";
import IconCheckCircle from "@ef-global/web-ui-react/lib/icons/IconCheckCircle";
import { validColor } from "./helpers";

export const StyledCheckValidation = styled(IconCheckCircle)`
  color: ${validColor(true)};
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  margin: 0 ${(props) => props.theme.spacing.two} 0 0;
  pointer-events: none;
`;
