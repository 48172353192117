import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FeatureListCmp, FeatureListProps } from "./FeatureList";

interface FeatureListWBlokProps {
  blok: FeatureListProps & blokType;
}

export const FeatureList: React.FC<FeatureListWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FeatureListCmp
        title={blok.title}
        description={blok.description}
        features={blok.features}
      />
    </SbEditable>
  );
};
