import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { ClientStoryCardsCmp, ClientStoryCardsProps } from "./ClientStoryCards";

export interface ClientStoryCardsWBlokProps {
  blok: ClientStoryCardsProps & blokType;
}

export const ClientStoryCards: React.FC<ClientStoryCardsWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ClientStoryCardsCmp {...blok} />
    </SbEditable>
  );
};
