import React from "react";
import { sbAssetType } from "../../types";
import { LinkItemProps, LinkItem } from "../LinkItem";
import { sbImageService } from "hult-sb-ui";

import {
  StyledDescWImageCard,
  StyledDescWImageCardImg,
  StyledDescWImageCardTitle,
  StyledDescWImageCardBody,
} from "./partials";

export interface DescriptionWithImageCardProps {
  title: string;
  description: string;
  image: sbAssetType;
  link: Array<LinkItemProps>;
}

export const DescriptionWithImageCard: React.FC<DescriptionWithImageCardProps> =
  ({ title, description, image, link }) => {
    return (
      <StyledDescWImageCard>
        <StyledDescWImageCardImg
          src={sbImageService(image.filename, undefined, "410x0", image.focus)}
          alt={image.alt}
          title={image?.title}
          loading="lazy"
        />
        <StyledDescWImageCardTitle>{title}</StyledDescWImageCardTitle>
        <StyledDescWImageCardBody linkBottom={link.length == 1}>
          {description}
        </StyledDescWImageCardBody>
        {Array.isArray(link) && link.length === 1 && (
          <LinkItem
            {...link[0]}
            parentComponent="description-with-image-card"
          />
        )}
      </StyledDescWImageCard>
    );
  };
