import styled from "styled-components";

interface IStyledBanner {
  hoverLink?: boolean;
}

export const StyledBanner = styled.a<IStyledBanner>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.two};
  text-align: center;
  background: rgba(214, 204, 191, 0.4);
  background: #f7f5f2;
  touch-action: none;
  z-index: -1;

  & p {
    font-size: 11px;
    font-weight: ${({ theme }) => theme.font.black};
    margin: 0 auto;
    text-align: center;
    width: fit-content;
  }

  & :hover {
    text-decoration: ${({ hoverLink }) => (hoverLink ? "underline" : "none")};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & p {
      font-size: 9px;
    }
  }
`;
