import styled, { css } from "styled-components";

import breaker from "../../../assets/Graphics/CaseStudyImpactBreaker.svg";
import scribble from "../../../assets/Graphics/StatScribble.svg";

interface Props {
  offset?: number;
  showBg?: boolean;
  tabletBreakpoint?: boolean;
}

export const CaseStudyImpactBackgroundWrapper = styled.div`
  position: relative;
  width: 100vw;

  img {
    width: 100%;
  }

  :after {
    position: absolute;
    width: 100%;
    height: 96px;
    content: "";
    bottom: 0;
    left: 0;
    background: no-repeat url(${breaker}) top center;
    background-size: cover;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
      height: ${({ theme }) => theme.spacing.four};
    }
  }
`;

export const CaseStudyImpactWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  padding: 0 ${({ theme }) => theme.spacing.three} ${({ theme }) => theme.spacing.seven};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};
  grid-template-rows: auto auto 1fr;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto) 1fr;
    padding: ${({ theme }) => theme.spacing.four} ${({ theme }) => theme.spacing.three};
  }
`;

export const CaseStudyImpactTagline = styled.h3`
  grid-column: 1 / span 5;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutrals.coolGrey};
  margin: 0 0 ${({ theme }) => theme.spacing.one} 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 968px) {
    grid-column: 1 / span 6;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 1;
  }
`;

export const CaseStudyImpactTitle = styled.h2`
  grid-column: 1 / span 5;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 968px) {
    grid-column: 1 / span 6;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 2;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
`;

export const CaseStudyImpactBody = styled.p`
  grid-column: 1 / span 5;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 0 0 ${({ theme }) => theme.spacing.four} 0;
  white-space: pre-wrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 968px) {
    grid-column: 1 / span 6;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: 1 / span 4;
    grid-row: 3;
    margin: 0 0 ${({ theme }) => theme.spacing.seven} 0;
  }
`;

export const CaseStudyImpactBoxesWrapper = styled.div<Props>`
  grid-column: 7 / span 6;
  grid-row: 1 / span 3;
  display: flex;
  gap: ${({ theme, tabletBreakpoint }) => (tabletBreakpoint ? theme.spacing.four : theme.spacing.three)};
  flex-direction: ${({ tabletBreakpoint }) => (tabletBreakpoint ? "column" : "inherit")};
  padding-left: ${({ tabletBreakpoint }) => (tabletBreakpoint ? "17%" : "0")};

  ${({ showBg }) =>
    showBg &&
    css`
      background: no-repeat url(${scribble}) center;
    `}

  @media screen and (max-width: 968px) {
    display: none;
  }
`;

export const CaseStudyImpactBoxesColumnOne = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${({ tabletBreakpoint }) => (tabletBreakpoint ? "24px" : "72px")};
  width: ${({ theme, tabletBreakpoint }) => (tabletBreakpoint ? "auto" : `calc(50% - ${theme.spacing.three})`)};
`;

export const CaseStudyImpactBoxesColumnTwo = styled(CaseStudyImpactBoxesColumnOne)<Props>`
  margin-top: ${({ offset, tabletBreakpoint }) => (tabletBreakpoint ? 0 : offset)}px;
`;

export const CaseStudyImpactBoxesWrapperMobile = styled.div<Props>`
  grid-column: 1 / span 4;
  grid-row: 4;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.three};

  ${({ showBg }) =>
    showBg &&
    css`
      background: no-repeat url(${scribble}) center;
      background-size: auto clamp(100px, 600px, 600px);
    `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: 968px) {
    grid-column: 7 / span 6;
    grid-row: 1 / span 4;
    padding-left: 17%;
    background-position: center left;
  }

  @media screen and (min-width: 969px) {
    display: none;
  }
`;
