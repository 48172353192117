import React from "react"

import { Surface } from "@ef-global/web-ui-react/lib/components/Surface"
import IconArrowRight from "@ef-global/web-ui-react/lib/icons/IconArrowRight"

import { sbAssetType } from "../../types"
import { LinkItemProps } from "../LinkItem"
import { getUrl } from "../utils/helpers"
import {
  StyledProductCardLink,
  StyledProductCardTitleContainer,
  StyledProductCardWrapper,
  StyledProductOverviewCardIcon,
  StyledSurfaceWrapper,
} from "./partials"

export interface ProductCardProps {
  title: string
  icon: sbAssetType
  link: Array<LinkItemProps>
}

export const ProductCard: React.FC<ProductCardProps> = ({ title, icon, link }) => {
  return (
    <StyledSurfaceWrapper>
      <Surface as="a" href={getUrl(link)} deepShadow rounded>
        <StyledProductCardWrapper>
          <StyledProductCardTitleContainer>
            <StyledProductOverviewCardIcon>
              <img src={icon.filename} alt={icon?.alt} title={icon?.title} />
            </StyledProductOverviewCardIcon>
            <p>{title}</p>
          </StyledProductCardTitleContainer>
          {link && (
            <StyledProductCardLink>
              <IconArrowRight />
            </StyledProductCardLink>
          )}
        </StyledProductCardWrapper>
      </Surface>
    </StyledSurfaceWrapper>
  )
}
