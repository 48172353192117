import React, {useState} from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { FormSC, FormSCProps } from "../Forms/FormSC";
import { StyledFormEmbed, StyledFormEmbedWrapper, StyledSpacer, StyledSpacerCol } from "./form-embed.styles";

export interface FormEmbedProps {
  form: Array<FormSCProps>;
}

interface FormEmbedWBlokProps {
  blok: FormEmbedProps & blokType;
}

export const FormEmbedSB: React.FC<FormEmbedWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <FormEmbed form={blok.form}></FormEmbed>
    </SbEditable>
  );
};

const FormEmbed: React.FC<FormEmbedProps> = ({ form }) => {

  const [submit, setSubmit] = useState(false);

  return (
    <StyledFormEmbedWrapper submit={submit}>
            <StyledSpacer>
        <StyledSpacerCol submit={submit}>
          <svg
            viewBox="0 0 500 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-72.9074 190.012L-63.5301 271.458C-62.9001 276.651 -60.2773 281.396 -56.2158 284.69C-52.1544 287.984 -46.9726 289.568 -41.7655 289.11L366.833 252.541L481.345 242.293C486.532 241.659 491.27 239.034 494.56 234.971C497.85 230.908 499.435 225.725 498.98 220.516C496.276 178.008 493.647 135.495 490.866 92.9931C489.734 75.6966 487.006 57.5015 475.975 43.8643C475.277 35.2002 474.579 26.5362 473.88 17.8723C473.268 12.6714 470.65 7.91533 466.584 4.61799C462.518 1.32066 457.326 -0.257012 452.115 0.221369L161.847 30.2076C113.253 35.2274 64.6579 40.2475 16.062 45.2681C-5.25506 47.4703 -28.5067 47.5199 -48.9081 54.7277C-58.9537 58.0447 -67.6988 64.4451 -73.9015 73.0196C-81.3889 84.1307 -83.0158 96.7507 -82.0426 109.891C-80.0619 136.632 -75.9743 163.374 -72.9074 190.012Z"
              fill="#F5E6CF"
            />
          </svg>
        </StyledSpacerCol>
      </StyledSpacer>
      <StyledFormEmbed submit={submit}>
        {Array.isArray(form) && form.length > 0 && (
          <FormSC
            _uid={form[0]._uid}
            form={form[0].form}
            component={form[0].component}
            form_handler={form[0].form_handler}
            sf_campaign_id={form[0].sf_campaign_id}
            title={form[0].title}
            qualifier={form[0].qualifier}
            event_date={form[0].event_date}
            event_duration={form[0].event_duration}
            event_location={form[0].event_location}
            thank_you_components={form[0].thank_you_components}
            close_on_submit={false}
            onSubmit={_ => setSubmit(true)}
          />
        )}
      </StyledFormEmbed>
    </StyledFormEmbedWrapper>
  );
};
