import React from "react";

import { LinkItemProps, LinkItem } from "../LinkItem";
import { ClientLogosCard } from "./ClientLogosCard";

import {
  StyledClientLogos,
  StyledClientLogosWrapper,
  StyledClientLogosHeader,
  StyledClientLogosCardsWrapper,
  StyledClientLogosSubheader,
} from "./partials";

export interface ClientLogosProps {
  title: string;
  component?: string;
  sub_header: string;
  client_cards: Array<any>;
  link?: LinkItemProps;
}

export const ClientLogosCmp: React.FC<ClientLogosProps> = ({
  title,
  component,
  sub_header,
  link,
  client_cards,
}) => {
  const half = Math.ceil(client_cards.length / 2);

  const firstHalf = client_cards.slice(0, half);
  const secondHalf = client_cards.slice(-half);

  return (
    <StyledClientLogos>
      <StyledClientLogosWrapper>
        <StyledClientLogosHeader>{title}</StyledClientLogosHeader>
        <StyledClientLogosSubheader>{sub_header}</StyledClientLogosSubheader>
      </StyledClientLogosWrapper>
      <StyledClientLogosCardsWrapper addWrapper>
        {client_cards.map((item, idx) => (
          <ClientLogosCard key={idx} logo={item.logo} />
        ))}
      </StyledClientLogosCardsWrapper>

      <StyledClientLogosWrapper>
        {Array.isArray(link) && link.length === 1 && (
          <LinkItem parentComponent={component} {...link[0]} />
        )}
      </StyledClientLogosWrapper>
    </StyledClientLogos>
  );
};
