import styled from "styled-components";
import arrow from "../../../assets/SecondaryNavigation/Arrow.svg";
import scratch from "../../../assets/SecondaryNavigation/Scratch.svg";
import arrowMobile from "../../../assets/SecondaryNavigation/ArrowMobile.svg";

interface ActiveProps {
  readonly isActive?: boolean;
}

interface OpenMenuProps {
  readonly isOpen?: boolean;
}

type PopOutProps = ActiveProps & {
  readonly itemCount: number;
};

export const StyledSecondaryNavigationVariantWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};
`;

export const StyledSecondaryNavigationBar = styled.div`
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1999;
  padding: 28px 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 16px 0;
    height: auto;
  }
`;

export const StyledSecondaryNavigationBarWrapper = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & label {
    line-height: 24px;
    white-space: nowrap;
    flex-basis: 25%;
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: normal;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    flex-direction: column;

    & label {
      flex-basis: 100%;
      font-size: ${(props) => props.theme.fontSize.m};
    }
  }
`;

export const StyledSecondaryNavigationCore = styled.div`
  display: flex;
  padding: 0 ${(props) => props.theme.spacing.six} 0 0;
  z-index: 2000;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-left: 0;
    padding: 0 ${(props) => props.theme.spacing.three} 0
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledSecondaryNavigationItemsWrapper = styled.div<OpenMenuProps>`
  position: relative;
  display: block;
  overflow-y: visible;
  overflow-x: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.isOpen ? "" : "none")};
    position: absolute;
    right: 0%;
    left: 0;
    background-color: ${(props) => props.theme.colors.neutrals.sisal20};
    padding-bottom: 94px;
    background-image: url(${arrowMobile});
    background-repeat: no-repeat;
    background-position: bottom right;
    box-shadow: 0 18px 18px rgba(0, 0, 0, 0.5);
  }
`;

export const StyledSecondaryNavigationItems = styled.ul<OpenMenuProps>`
  height: 100%;
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.four};
  grid-auto-flow: column;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  padding: 0;

  & ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    grid-auto-flow: row;
    grid-gap: 0;
    display: ${(props) => (props.isOpen ? "" : "none")};
    margin-top: ${(props) => props.theme.spacing.five};
  }
`;

export const StyledSecondaryNavigationSubItemMobile = styled.div<ActiveProps>`
  display: none;
  z-index: 10;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    width: 100%;
    padding-left: ${(props) =>
      props.isActive ? "44px" : props.theme.spacing.four};
    border-left: ${(props) => (props.isActive ? "4px solid #191919" : "none")};
  }
`;

export const StyledSecondaryNavigationNavBarIcon = styled.div`
  display: none;
  cursor: pointer;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
  }
`;

export const StyledSecondaryNavigationNavItem = styled.li<ActiveProps>`
  white-space: nowrap;
  height: auto;
  float: left;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  font-size: ${(props) => props.theme.fontSize.m};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: inherit;
    font-size: ${(props) => props.theme.fontSize.l};
    border-left: ${(props) => (props.isActive ? "4px solid #191919" : "none")};

    & a {
      padding: ${(props) => props.theme.spacing.two} 0;
      margin-left: ${(props) => (props.isActive ? "28px" : "32px")};
    }
  }
`;

export const StyledSecondaryNavigationNavItemMore = styled.li`
  color: var(--color-link, rgba(25, 25, 25, 0.8));
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  white-space: nowrap;
  cursor: pointer;
  height: 100%;
  float: left;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize.l};

  :hover {
    text-decoration: underline;
  }

  & svg {
    align-self: center;
    margin-bottom: 2px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledSecondaryNavigationNavItemDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledNavItemSubItemsPopOutImageWrapper = styled.div<PopOutProps>`
  display: ${(props) => (props.itemCount ? "" : "none")};
  height: ${(props) => (props.itemCount > 3 ? "216px" : "128px")};
  max-width: 1200px;
  margin: 0 auto;
  z-index: 3;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: ${(props) =>
      props.itemCount > 3 ? "bottom" : "top 12px"}
    left;
`;

export const StyledNavItemSubItemsPopOutWrapper = styled.div<PopOutProps>`
  display: ${(props) => (props.itemCount ? "" : "none")};
  height: ${(props) => (props.itemCount > 3 ? "216px" : "128px")};
  left: 0%;
  right: 0%;
  position: absolute;
  z-index: 6;
  background-color: ${(props) => props.theme.colors.neutrals.sisal20};
  box-shadow: 0 18px 18px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledNavItemSubItemsPopOut = styled.div<PopOutProps>`
  display: ${(props) => (props.itemCount ? "grid" : "none")};
  height: ${(props) => (props.itemCount > 3 ? "216px" : "128px")};
  grid-template-columns: repeat(
    ${(props) => (props.itemCount !== 4 ? 3 : 2)},
    222px
  );
  grid-template-rows: repeat(
    ${(props) => (props.itemCount > 3 ? 2 : 1)},
    100px
  );
  column-gap: ${(props) => props.theme.spacing.three};
  margin: 0 auto;
  width: ${(props) => props.theme.maxWidth};
  padding-left: 222px;
  padding-top: ${(props) => props.theme.spacing.one};
  padding-bottom: ${(props) => (props.itemCount <= 3 ? "20px" : "0")};
  position: relative;
  z-index: 7;
  background-image: url(${scratch});
  background-repeat: no-repeat;
  background-position: top 35px left;
`;
