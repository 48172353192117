import styled from "styled-components";

interface StyleProps {
  readonly isActive?: boolean;
  readonly isOpen?: boolean;
}

export const StyledSecondaryNavigationNavItemDropdownContent = styled.div<StyleProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};

  position: absolute;
  top: 30px;
  right: 0;
  overflow: visible;
`;

export const StyledSecondaryNavigationNavItemDropdownContentFixed = styled.div`
  position: fixed;
  transform: translateX(-100%);
  list-style-type: none;
  z-index: 2004;
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.one};
  padding: ${(props) => props.theme.spacing.one};
  border-radius: 4px;
  width: auto;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(25, 25, 25, 0.16);
`;

export const StyledSecondaryNavigationMoreNavItemLink = styled.a<StyleProps>`
  white-space: nowrap;
  height: 100%;
  float: left;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  font-size: ${(props) => props.theme.fontSize.m};
  padding: ${(props) => props.theme.spacing.one};
  border-radius: 4px;

  :hover {
    background-color: #f7f5f2;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;

export const StyledSecondaryNavigationMoreNavItemWSubItemLink = styled.div`
  white-space: nowrap;
  height: 100%;
  float: left;
  font-size: ${(props) => props.theme.fontSize.m};
  padding: ${(props) => props.theme.spacing.one};
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;

export const StyledSecondaryNavigationNavItemMoreSubItem = styled.a<StyleProps>`
  padding: 12px 14px 8px 14px;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};

  :hover {
    background-color: #f7f5f2;
    cursor: pointer;
  }
`;
