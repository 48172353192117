import styled from "styled-components";
import { hefBrandColors } from "../../../types";

export const StyledMultiSelectContainer = styled.div`
  display: "inline-block";

  & label {
    width: 100%;
    font-size: 14px;
    line-height: 1;
    margin-bottom: ${(props) => props.theme.spacing.one};
    color: ${(props) => props.theme.colors.neutrals.black};
    display: flex;
    justify-content: space-between;

    & span {
      color: #ee6160;
    }
  }
`;

export const StyledMultiSelectWrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0 -${(props) => props.theme.spacing.two} -${(props) =>
      props.theme.spacing.two}
    0;
`;

interface StyledMultiSelectItemProps {
  isActive?: boolean;
  color?: hefBrandColors;
}

export const StyledMultiSelectItem = styled.div<StyledMultiSelectItemProps>`
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.warmths.heritage
      : props.theme.colors.neutrals.white};
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.neutrals.white
      : props.theme.colors.neutrals.black};
  height: auto;
  width: min-content;
  cursor: pointer;
  white-space: nowrap;
  padding: ${(props) => props.theme.spacing.two}
    ${(props) => props.theme.spacing.three};
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.colors.warmths.heritage
        : props.theme.colors.neutrals.coolGrey};
  margin: 0 ${(props) => props.theme.spacing.two}
    ${(props) => props.theme.spacing.two} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    padding: ${(props) => props.theme.spacing.two}
      ${(props) => props.theme.spacing.two};
  }
`;

export const StyledMultiSelectCheckbox = styled.div<StyledMultiSelectItemProps>`
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 16px;
  width: 16px;
  position: relative;
  margin: 0 0 0 ${(props) => props.theme.spacing.two};
  border-radius: 2px;
  border: ${(props) =>
    props.isActive
      ? `none`
      : `1px solid ${props.theme.colors.neutrals.coolGrey}`};
  cursor: pointer;
  background: ${(props) => props.theme.colors.neutrals.white};
  color: ${(props) => (props.isActive ? "green" : "black")};

  & input {
    display: none;
  }

  & svg {
    height: 20px;
    object-fit: contain;
    color: ${(props) => props.theme.colors.warmths.heritage};
`;
