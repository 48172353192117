import * as React from "react";
import GateCmp from "./Gate";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";

export const Gate = () => {
  const { settings } = React.useContext(SettingsContextHooks);

  return (
    <GateCmp
      gate_title={settings?.content?.gate_title || ""}
      gate_description={settings?.content?.gate_description || ""}
      gate_main_cta_label={settings?.content?.gate_main_cta_label || ""}
      gate_main_cta_colour={
        settings?.content?.gate_main_cta_colour || "heritage"
      }
      gate_main_cta_form={settings?.content?.gate_main_cta_form || []}
      gate_secondary_cta_label={
        settings?.content?.gate_secondary_cta_label || ""
      }
      gate_secondary_cta_link={
        settings?.content?.gate_secondary_cta_link || {
          cached_url: "",
          fieldtype: "",
          id: "",
          url: "",
        }
      }
    />
  );
};
