import React from "react";

import {
  TextTwoColumnsItemProps,
  TextTwoColumnsItem,
} from "./TextTwoColumnsItem";

import { LinkItem, LinkItemProps } from "../LinkItem";
import {
  StyledTextTwoColumns,
  StyledTextTwoColumnsWrapper,
  StyledTextTwoColumnsHeader,
  StyledTextTwoColumnsListWrapper,
} from "./partials";

export interface TextTwoColumnsProps {
  component: string;
  qualifying_title: string;
  columns: Array<TextTwoColumnsItemProps>;
  link?: Array<LinkItemProps>;
}

export const TextTwoColumnsCmp: React.FC<TextTwoColumnsProps> = ({
  component,
  qualifying_title,
  columns,
  link,
}) => {
  return (
    <StyledTextTwoColumns>
      <StyledTextTwoColumnsWrapper>
        <StyledTextTwoColumnsHeader>
          {qualifying_title}
        </StyledTextTwoColumnsHeader>
        <StyledTextTwoColumnsListWrapper>
          {columns.map((item, idx) => (
            <TextTwoColumnsItem
              key={idx}
              title={item.title}
              description={item.description}
            />
          ))}
        </StyledTextTwoColumnsListWrapper>
        {Array.isArray(link) && link.length === 1 && (
          <LinkItem
            link={link[0].link}
            name={link[0].name}
            parentComponent={component}
          />
        )}
      </StyledTextTwoColumnsWrapper>
    </StyledTextTwoColumns>
  );
};
