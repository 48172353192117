import React from "react";
import {
  IllustrationCardItem,
  IllustrationCardItemProps,
} from "./IllustrationCardItem";

import { LinkItemProps, LinkItem } from "../LinkItem";

import {
  StyledIllustrationCard,
  StyledIllustrationCardWrapper,
  StyledIllustrationCardTitle,
  StyledIllustrationCardBody,
  StyledIllustrationCardContainer,
  StyledLinkContainer,
} from "./partials";
import { getKey } from "../utils/helpers";

export interface IllustrationCardProps {
  _uid: string;
  component: string;
  title: string;
  body_copy: string;
  cards: Array<IllustrationCardItemProps>;
  link?: Array<LinkItemProps>;
}

export const IllustrationCardCmp: React.FC<IllustrationCardProps> = ({
  _uid,
  title,
  component,
  body_copy,
  cards,
  link,
}) => {
  return (
    <StyledIllustrationCard>
      <StyledIllustrationCardWrapper>
        <StyledIllustrationCardTitle>{title}</StyledIllustrationCardTitle>
        <StyledIllustrationCardBody>{body_copy}</StyledIllustrationCardBody>
        <StyledIllustrationCardContainer nCards={cards.length}>
          {cards.map((item, idx) => (
            <IllustrationCardItem
              key={getKey(`${_uid}-illustration-card-item`, idx)}
              image={item.image}
              link={item.link}
            />
          ))}
        </StyledIllustrationCardContainer>

        {Array.isArray(link) && link.length === 1 && (
          <StyledLinkContainer>
            <LinkItem {...link[0]} parentComponent={component} />
          </StyledLinkContainer>
        )}
      </StyledIllustrationCardWrapper>
    </StyledIllustrationCard>
  );
};
