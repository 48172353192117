import React from "react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

// import { StyledWhatsAppShare } from "./partials";

interface WhatsAppShareProps {
  share_url: string;
}

const WhatsAppShareCmp: React.FC<WhatsAppShareProps> = ({ share_url }) => (
  <WhatsappShareButton url={share_url}>
    <WhatsappIcon
      size={16}
      iconFillColor={"rgb(25,25,25)"}
      bgStyle={{ fill: "rgba(0,0,0,0)" }}
      round
    />
  </WhatsappShareButton>
);

export default WhatsAppShareCmp;
