import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { BodyCopyCmp, BodyCopyProps } from "./BodyCopy";

interface BodyCopyWBlokProps {
  blok: BodyCopyProps & blokType;
}

export const BodyCopy: React.FC<BodyCopyWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <BodyCopyCmp
        title={blok.title}
        body={blok.body}
        brand_colour={blok.brand_colour}
      ></BodyCopyCmp>
    </SbEditable>
  );
};
