import React from "react";
import {
  StyledProductOverviewWrapper,
  StyledProductOverview,
  StyledProductOverviewInner,
  StyledProductOverviewHeader,
  StyledProductOverviewCards,
  StyledProductOverviewSubTitle,
} from "./partials/StyledProductOverview";
import { Blender } from "../Blender";
import { hefBrandColorsPlusNeutrals } from "../../types";
import { ProductCard, ProductCardProps } from "./MiniatureProductCard";
import { LinkItemProps, LinkItem } from "../LinkItem";

export interface ProductOverviewProps {
  header: string;
  subtitle: string;
  component: string;
  cards: Array<ProductCardProps>;
  link: Array<LinkItemProps>;
  background_colour?: hefBrandColorsPlusNeutrals;
  blender?: "off" | "top" | "bottom";
}

export const ProductOverviewCmp: React.FC<ProductOverviewProps> = ({
  header,
  subtitle,
  component,
  cards,
  link,
  background_colour,
  blender,
}) => {
  return (
    <StyledProductOverviewWrapper
      color={background_colour ? background_colour : undefined}
    >
      {blender === "top" && (
        <Blender color={background_colour ? background_colour : "heritage"} />
      )}
      <StyledProductOverview
        color={background_colour ? background_colour : undefined}
      >
        <StyledProductOverviewInner>
          <StyledProductOverviewHeader>{header}</StyledProductOverviewHeader>
          <StyledProductOverviewSubTitle>
            {subtitle}
          </StyledProductOverviewSubTitle>
          <StyledProductOverviewCards>
            {cards.map((item, idx) => (
              <ProductCard
                key={idx}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </StyledProductOverviewCards>
          {Array.isArray(link) && link.length === 1 && (
            <LinkItem {...link[0]} parentComponent={component} />
          )}
        </StyledProductOverviewInner>
      </StyledProductOverview>
      {blender === "bottom" && (
        <Blender
          color={background_colour ? background_colour : "heritage"}
          position="bottom"
        />
      )}
    </StyledProductOverviewWrapper>
  );
};
