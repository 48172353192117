import styled from "styled-components";

export const StyledOpenProgramsHeader = styled.div`
  height: auto;
  width: 100%;
  background: linear-gradient(90deg, #ffffff 65%, #f0f0f0 35%);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    background: #ffffff;
  }
`;

export const StyledOpenProgramsHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    background: #ffffff;
  }

  > div {
    padding: ${({ theme }) => theme.spacing.seven} 0;
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin: 0 24px;

    > div {
      padding: ${({ theme }) => theme.spacing.seven} 0;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 auto;
    grid-gap: 0px;

    > div {
      :first-child {
        padding: ${({ theme }) => theme.spacing.four}
          ${({ theme }) => theme.spacing.three}
          ${({ theme }) => theme.spacing.seven}
          ${({ theme }) => theme.spacing.three};
      }

      :nth-child(2) {
        padding: ${({ theme }) => theme.spacing.seven}
          ${({ theme }) => theme.spacing.three}
          ${({ theme }) => theme.spacing.four}
          ${({ theme }) => theme.spacing.three};
      }
    }
  }
`;
