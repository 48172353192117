import React from "react";

import {
  StyledArticleAuthor,
  StyledArticleHeadline,
  StyledArticleHeadlineContent,
  StyledArticleQualifyingTitle,
  StyledArticleTitle,
} from "./partials";

export interface ArticleHeadlineProps {
  qualifying_title: string;
  title: string;
  author_name: string;
  qualifier_colour: string;
}

export const ArticleHeadlineCmp: React.FC<ArticleHeadlineProps> = ({
  qualifying_title,
  title,
  author_name,
  qualifier_colour,
}) => {
  return (
    <StyledArticleHeadline>
      <StyledArticleHeadlineContent>
        {qualifying_title && (
          <StyledArticleQualifyingTitle qualifier_colour={qualifier_colour} author_exists={author_name ? true : false}>
            {qualifying_title}
          </StyledArticleQualifyingTitle>
        )}
        {title && <StyledArticleTitle>{title}</StyledArticleTitle>}
        {author_name && <StyledArticleAuthor>{author_name}</StyledArticleAuthor>}
      </StyledArticleHeadlineContent>
    </StyledArticleHeadline>
  );
};
