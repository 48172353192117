import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { CaseStudyImpactImageCmp, CaseStudyImpactImageProps } from "./CaseStudyImpactImage";

export interface CaseStudyImpactImageWBlokProps {
  blok: CaseStudyImpactImageProps & blokType;
}

export const CaseStudyImpactImage: React.FC<CaseStudyImpactImageWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CaseStudyImpactImageCmp {...blok} />
    </SbEditable>
  );
};
