import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { PodcastCmp, PodcastProps } from "./Podcast";

interface PodcastWBlokProps {
  blok: PodcastProps & blokType;
}

export const Podcast: React.FC<PodcastWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <PodcastCmp
        title={blok.title}
        description={blok.description}
        podcast={blok.podcast}
      />
    </SbEditable>
  );
};
