import styled from "styled-components";

export const StyledFooter = styled.footer`
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  position: relative;

  padding: ${(props) => props.theme.spacing.eight} 0 0 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.eight} ${(props) => props.theme.spacing.three} 0
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFooterContentContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const StyledFooterLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.seven};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
  }
`;

export const StyledFooterLogoPartnerLogos = styled.div`
  display: grid;
  width: 176px;
  grid-auto-flow: column;
  grid-template-columns: 58px 78px;
  grid-gap: ${(props) => props.theme.spacing.five};

  & img {
    height: 40px;
    object-fit: contain;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    border-top: 1px solid rgba(25, 25, 25, 0.2);
    padding-top: 24px;
  }
`;

export const StyledFooterLogo = styled.img`
  width: 170px;
  object-fit: contain;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFooterLinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: ${(props) => props.theme.spacing.three};

  margin-bottom: ${(props) => props.theme.spacing.six};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-gap: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledFooterBottomBreaker = styled.hr`
  margin-bottom: 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StyledFooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => props.theme.spacing.two} 0;
  border-top: 1px solid rgba(25, 25, 25, 0.2);

  font-size: 12px;
  font-weight: ${(props) => props.theme.font.book};

  & select {
    color: ${({ theme }) => theme.colors.neutrals.black};
    background-color: transparent;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    border: none;
    padding: 0;
    display: grid;
    row-gap: ${(props) => props.theme.spacing.six};
  }
`;

interface IStyledPicture {
  linkable?: boolean;
}

export const StyledPicture = styled.picture<IStyledPicture>`
  cursor: ${({ linkable }) => (linkable ? "pointer" : "auto")};
`;

export const StyledFooterCopright = styled.div`
  & p {
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.book};
  }
`;

export const StyledFooterCoprightContainer = styled.div`
  display: flex;
  margin-left: auto;

  & img {
    margin-left: ${({ theme }) => theme.spacing.one};
  }
`;
