import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { TextTwoColumnsCmp, TextTwoColumnsProps } from "./TextTwoColumns";

interface TextTwoColumnsWBlokProps {
  blok: TextTwoColumnsProps & blokType;
}

export const TextTwoColumns: React.FC<TextTwoColumnsWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <TextTwoColumnsCmp
        component={blok.component}
        qualifying_title={blok.qualifying_title}
        columns={blok.columns}
        link={blok.link}
      ></TextTwoColumnsCmp>
    </SbEditable>
  );
};
