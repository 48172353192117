import styled, { css } from "styled-components";

import breaker from "../../../assets/Graphics/CaseStudyImpactBreaker.svg";

export const CaseStudyImpactImageBackgroundWrapper = styled.div`
  position: relative;
  width: 100vw;

  img {
    width: 100%;
  }

  :after {
    position: absolute;
    width: 100%;
    height: 96px;
    content: "";
    bottom: 0;
    left: 0;
    background: no-repeat url(${breaker}) top center;
    background-size: cover;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
      height: ${({ theme }) => theme.spacing.four};
    }
  }
`;
