import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";
import { ArticlePodcastCmp, ArticlePodcastProps } from "./ArticlePodcast";

type ArticlePodcastWBlokProps = {
  blok: ArticlePodcastProps & blokType;
};

export const ArticlePodcast: React.FC<ArticlePodcastWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <ArticlePodcastCmp
        title={blok.title}
        description={blok.description}
        podcast={blok.podcast}
      />
    </SbEditable>
  );
};
