import styled from "styled-components";

export const StyledFormContainer = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.seven};
  padding: ${(props) => props.theme.spacing.seven} 0
    ${(props) => props.theme.spacing.eight} 0;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.eight}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledFormHeaderContainer = styled.div``;

export const StyledFormHeaderQualifier = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 14px;
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledFormHeaderTitle = styled.h3`
  font-weight: ${(props) => props.theme.font.bold};
  font-size: 32px;
  margin-bottom: 0;
  max-width: 50%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
    max-width: 100%;
  }
`;

export const StyledFormHeaderEventDetailsContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: ${(props) => props.theme.spacing.three} -${(props) =>
      props.theme.spacing.four} -${(props) => props.theme.spacing.two} 0;

  & div {
    margin-right: ${(props) => props.theme.spacing.four};
    margin-bottom: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledFormHeaderEventDetailsItem = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  white-space: nowrap;

  & svg {
    margin-right: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledForm = styled.form`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & button {
      width: 100%;
    }
  }
`;

export const StyledFormFields = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.seven};
`;

export const StyledThankYouContainer = styled.div`
  & div {
    padding: 0;
  }
`;
