import styled from "styled-components";

export const StyledOPHeaderContent = styled.div`
  grid-column: col-start 1 / span 6;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: col-start 1 / span 12;
  }
`;

export const StyledOPHeaderContentQualifier = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.warmths.heritage};
  margin-bottom: ${({ theme }) => theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${({ theme }) => theme.spacing.one};
  }
`;

export const StyledOPHeaderContentTitle = styled.h1`
  font-size: 48px;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.neutrals.black};
  margin-bottom: ${({ theme }) => theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 32px;
  }
`;

export const StyledOPHeaderContentPara = styled.p`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.light};
  color: ${({ theme }) => theme.colors.neutrals.black};
  white-space: pre-wrap;
  margin: 0;
`;

export const StyledOPHeaderContentParaContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.seven};

  & h2 {
    font-size: 32px;
    margin-bottom: ${({ theme }) => theme.spacing.one};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 24px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledOPHeaderContentIntro = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.seven};

  & button {
    margin-top: ${({ theme }) => theme.spacing.three};
  }
`;
