import React from "react";
import {
  SecondaryNavigationCmp,
  SecondaryNavigationProps,
} from "./SecondaryNavigation";
import { graphql, StaticQuery } from "gatsby";
import { AllStoryblokEntryResult, blokType, storyType } from "../../types";

export interface SecondaryNavStoryProps extends storyType {
  content: blokType & SecondaryNavigationProps;
}

export interface SecondaryNavigationSCProps {
  secondary_navigation: string;
}

export interface SecondaryNavigationQueryProps {
  blok: storyType & SecondaryNavigationSCProps;
}

export const SecondaryNavigationSC: React.FC<SecondaryNavigationQueryProps> = ({
  blok,
}) => {
  function parseQueryResult(data: AllStoryblokEntryResult, id: string): any {
    let parsedResult: blokType;

    data.allStoryblokEntry.edges.forEach((edge) => {
      const { node } = edge;
      let content: blokType = node.content;
      if (typeof node.content === "string") content = JSON.parse(node.content);
      if (node.uuid == id) {
        parsedResult = content;
      }
    });

    return parsedResult;
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allStoryblokEntry(
            filter: { field_component: { eq: "secondary-navigation" } }
          ) {
            edges {
              node {
                uuid
                id
                content
              }
            }
            totalCount
          }
        }
      `}
      render={(data) => {
        const parsedResult = parseQueryResult(data, blok.secondary_navigation);

        return (
          <>
            {parsedResult && (
              <SecondaryNavigationCmp
                _uid={parsedResult._uid}
                component={parsedResult.component}
                section_label={parsedResult.section_label}
                menu_items={parsedResult.menu_items}
              ></SecondaryNavigationCmp>
            )}
          </>
        );
      }}
    />
  );
};
