import React from "react";

import {
  StyledFeatureStatItem,
  StyledFeatureStatItemHeader,
  StyledFeatureStatItemBody,
} from "./partials";

export interface FeatureStatItemProps {
  stat: string;
  stat_body: string;
}

export const FeatureStatItem: React.FC<FeatureStatItemProps> = ({
  stat,
  stat_body,
}) => {
  return (
    <StyledFeatureStatItem>
      <StyledFeatureStatItemHeader>{stat}</StyledFeatureStatItemHeader>
      <StyledFeatureStatItemBody>{stat_body}</StyledFeatureStatItemBody>
    </StyledFeatureStatItem>
  );
};
