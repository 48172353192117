import styled from "styled-components";

interface StyledPageProps {
  header_spacing?: "white" | "sisal";
}

export const StyledBodyContainer = styled.main`
  top: 100px;
  left: 0;
  position: relative;
  height: auto;
  width: 100%;
  padding-bottom: 100px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    top: 75px;
    padding-bottom: 75px;
  }
`;

export const StyledSpacer = styled.div<StyledPageProps>`
  height: ${(props) => props.theme.spacing.seven};
  width: 100%;
  background-color: ${({header_spacing, theme}) => header_spacing === "sisal" ? theme.colors.neutrals.sisal : theme.colors.neutrals.white};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: ${(props) => props.theme.spacing.four};
  }
`;
