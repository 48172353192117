import React from "react"

import { blokType } from "../../types"
import { ILearningMetaCmp, LearningMetaCmp } from "./LearningMeta"

export interface ILearningMeta {
  blok: blokType & ILearningMetaCmp
}

export const LearningMeta: React.FC<ILearningMeta> = ({ blok }) => {
  return (
    <LearningMetaCmp
      title={blok.title}
      items={blok.items}
      brochure_cta={blok.brochure_cta}
      tabTitle={blok.tabTitle}
      tabs={blok.tabs}
    />
  )
}
