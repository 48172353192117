import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { LogoRowCmp, LogoRowProps } from "./LogoRow";
import { LogoRowImageCmp, LogoRowImageProps } from "./LogoRowImage";

interface LogoRowWBlokProps {
  blok: LogoRowProps & blokType;
}

interface LogoRowImageWBlokProps {
  blok: LogoRowImageProps & blokType;
}

export const LogoRow: React.FC<LogoRowWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <LogoRowCmp {...blok} />
    </SbEditable>
  );
};

export const LogoRowImage: React.FC<LogoRowImageWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <LogoRowImageCmp image={blok.image} />
    </SbEditable>
  );
};
