import React, { useEffect, useState } from "react";

import { sbAssetType } from "../../types";
import sbImageService from "../utils/sbImage";
import { ImpactBox, ImpactBoxProps } from "./ImpactBox";
import {
  CaseStudyImpactBackgroundWrapper,
  CaseStudyImpactBody,
  CaseStudyImpactBoxesColumnOne,
  CaseStudyImpactBoxesColumnTwo,
  CaseStudyImpactBoxesWrapper,
  CaseStudyImpactBoxesWrapperMobile,
  CaseStudyImpactTagline,
  CaseStudyImpactTitle,
  CaseStudyImpactWrapper,
} from "./styles/StyledCaseStudyImpact";

export interface CaseStudyImpactProps {
  tagline: string;
  title: string;
  body: string;
  image: sbAssetType;
  boxes: ImpactBoxProps[];
  tabletBreakpoint?: boolean;
}

export const CaseStudyImpactCmp: React.FC<CaseStudyImpactProps> = ({
  tagline,
  title,
  body,
  image,
  boxes,
  tabletBreakpoint,
}) => {
  const [boxHeight, setBoxHeight] = useState<number>(0);

  useEffect(() => {
    const box = document.getElementById("cs-impact-box-0");

    if (!!box) setBoxHeight(box.offsetHeight);
  }, []);

  return (
    <>
      <CaseStudyImpactBackgroundWrapper>
        <img src={sbImageService(image.filename, "0x0")} title={image.title} alt={image.alt} />
      </CaseStudyImpactBackgroundWrapper>
      <CaseStudyImpactWrapper>
        <CaseStudyImpactTagline>{tagline}</CaseStudyImpactTagline>
        <CaseStudyImpactTitle>{title}</CaseStudyImpactTitle>
        <CaseStudyImpactBody>{body}</CaseStudyImpactBody>
        <CaseStudyImpactBoxesWrapper showBg={boxes.length > 2} tabletBreakpoint={tabletBreakpoint}>
          <CaseStudyImpactBoxesColumnOne tabletBreakpoint={tabletBreakpoint}>
            {boxes.map((box, idx) => {
              if (idx % 2 === 0) return <ImpactBox {...box} id={`cs-impact-box-${idx}`} />;
            })}
          </CaseStudyImpactBoxesColumnOne>
          <CaseStudyImpactBoxesColumnTwo tabletBreakpoint={tabletBreakpoint} offset={boxHeight / 2}>
            {boxes.map((box, idx) => {
              if (idx % 2 !== 0) return <ImpactBox {...box} />;
            })}
          </CaseStudyImpactBoxesColumnTwo>
        </CaseStudyImpactBoxesWrapper>
        <CaseStudyImpactBoxesWrapperMobile showBg={boxes.length > 2}>
          {boxes.map((box) => (
            <ImpactBox {...box} />
          ))}
        </CaseStudyImpactBoxesWrapperMobile>
      </CaseStudyImpactWrapper>
    </>
  );
};
