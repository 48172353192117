import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { CallOutMessageCmp, CallOutMessageProps } from "./CallOutMessage";

interface CallOutMessageWBlokProps {
  blok: CallOutMessageProps & blokType;
}

export const CallOutMesssage: React.FC<CallOutMessageWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CallOutMessageCmp
        text={blok.text}
        background={blok.background}
      ></CallOutMessageCmp>
    </SbEditable>
  );
};
