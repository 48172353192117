import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { ArticleBodyCopyCmp, ArticleBodyCopyProps } from "./ArticleBodyCopy";

interface ArticleBodyCopyWBlokProps {
  blok: ArticleBodyCopyProps & blokType;
}

export const ArticleBodyCopy: React.FC<ArticleBodyCopyWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ArticleBodyCopyCmp {...blok} />
    </SbEditable>
  );
};
