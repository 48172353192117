import React, { ChangeEvent, useContext } from "react";

import IconGlobe from "@ef-global/web-ui-react/lib/icons/IconGlobe";

import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { LinkItemProps } from "../LinkItem";
import { getKey, getUrl } from "../utils/helpers";
import { StyledLanguageSelectorContainer } from "./partials";

interface LanguageSelectorProps {
  right?: boolean;
  alternatives?: string[];
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ right, alternatives }) => {
  const { settings } = useContext(SettingsContextHooks);

  function constructAlternatePagePath(language: string): string {
    //when selecting english as a language the select value is just a / so needs custom logic
    const isEnglishPage = language === "/";

    const alternatePagePath = alternatives?.find((alt) => {
      return isEnglishPage
        ? alt.substring(0, 2).includes("en")
        : language.includes(alt.substring(0, 2)) && alt.length > 1;
    });

    //check if it's home path
    return alternatePagePath
      ? alternatePagePath === language
        ? language
        : `/${alternatePagePath}${alternatePagePath.charAt(alternatePagePath.length - 1) === "/" ? "" : "/"}`
      : language;
  }

  function isURL(url: string): boolean {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  }

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e?.target?.value !== null && typeof window !== "undefined") {
      if (isURL(e.target.value)) {
        window.location.href = e.target.value;
      } else {
        const url = (window.location.origin + constructAlternatePagePath(e.target.value)).replace(/([^:]\/)\/+/g, "$1"); // Removes any unwanted double slashes

        window.location.href = url;
      }
    }
  }

  const languages = settings?.content?.language_links;

  return (
    <StyledLanguageSelectorContainer right={right}>
      <IconGlobe />
      <select onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e)} dir={right ? "rtl" : "ltr"}>
        {languages.map((item: LinkItemProps, idx: number) => (
          <option key={getKey("language-link-", item?._uid ? item._uid : idx)} value={getUrl(item.link)}>
            {item.name}
          </option>
        ))}
      </select>
    </StyledLanguageSelectorContainer>
  );
};
