import { SbImage } from "hult-sb-ui";
import React, { useState } from "react";
import { blokType, sbAssetType, sbLinkType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import {
  StyledCTAwImage,
  StyledCTAwImageContentWrapper,
  StyledCTAwImageImageWrapper,
  StyledCTAwImageText,
  StyledCTAwImageWrapper,
} from "./partials";

export interface CTA extends blokType {
  cta_label?: string;
  cta_link?: sbLinkType;
  cta_form?: Array<FormSCProps>;
  cta_colour?: "heritage" | "intensity" | "copper";
}

export interface CTAwImageProps {
  heading: string;
  cta: Array<CTA>;
  image: sbAssetType;
  desktop_background?: sbAssetType;
  graphic_overlay?: sbAssetType;
  background_colour?: string;
}

export const CTAwImageCmp: React.FC<CTAwImageProps> = ({
  heading,
  cta,
  image,
  desktop_background,
  graphic_overlay,
  background_colour = "#F6F3EF",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <StyledCTAwImage
        imageUrl={desktop_background?.filename}
        backgroundColour={background_colour}
      >
        <StyledCTAwImageWrapper>
          <StyledCTAwImageImageWrapper imageUrl={graphic_overlay?.filename}>
            <SbImage image={image} />
          </StyledCTAwImageImageWrapper>
          <StyledCTAwImageContentWrapper>
            <StyledCTAwImageText>{heading}</StyledCTAwImageText>
            <>
              {cta[0].cta_form && cta[0].cta_form.length > 0 && (
                <ButtonHEF
                  variant="primary"
                  color={cta[0].cta_colour}
                  onClick={() => setIsModalOpen(true)}
                  label={cta[0].cta_label}
                />
              )}
              {cta[0].cta_link && cta[0].cta_form?.length === 0 && (
                <ButtonHEF
                  as={"a"}
                  color={cta[0].cta_colour}
                  variant="primary"
                  href={getUrl(cta[0].cta_link)}
                  label={cta[0].cta_label}
                />
              )}
            </>
          </StyledCTAwImageContentWrapper>
        </StyledCTAwImageWrapper>
      </StyledCTAwImage>
      {cta[0].cta_form && cta[0].cta_form.length > 0 && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <FormSC
            onClose={() => setIsModalOpen(false)}
            _uid={cta[0].cta_form[0]._uid}
            form={cta[0].cta_form[0].form}
            component={cta[0].cta_form[0].component}
            form_handler={cta[0].cta_form[0].form_handler}
            sf_campaign_id={cta[0].cta_form[0].sf_campaign_id}
            title={cta[0].cta_form[0].title}
            qualifier={cta[0].cta_form[0].qualifier}
            event_date={cta[0].cta_form[0].event_date}
            event_duration={cta[0].cta_form[0].event_duration}
            event_location={cta[0].cta_form[0].event_location}
            thank_you_components={cta[0].cta_form[0].thank_you_components}
            close_on_submit={cta[0].cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
