import React, { useState } from "react";

import { SbImage } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { ButtonHEF } from "../Button";
import { FormSC } from "../Forms/FormSC";
import { ICTA } from "../FullBleedImgHero/FullBleedImageHero";
import { Modal } from "../Modal";
import { getUrl } from "../utils/helpers";
import {
  CardItemBody,
  CardItemIconWrapper,
  CardItemTitle,
  CardItemTitleWrapper,
  CardItemWrapper,
} from "./styles/StyledProductCardsItem";

export interface ProductCardsItemProps {
  title: string;
  description: string;
  cta: ICTA[];
  icon: sbAssetType;
  index: number;
  count: number;
  _uid: string;
}

export const ProductCardsItem: React.FC<ProductCardsItemProps> = ({ title, description, cta, index, icon }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <CardItemWrapper index={index}>
        <CardItemIconWrapper>
          <SbImage image={icon} />
        </CardItemIconWrapper>
        <CardItemTitleWrapper>
          <CardItemTitle>{title}</CardItemTitle>
        </CardItemTitleWrapper>
        <CardItemBody>{description}</CardItemBody>
        {Array.isArray(cta) && cta[0].cta_link && cta[0].cta_form?.length === 0 && (
          <ButtonHEF
            as={"a"}
            variant="primary"
            color={cta[0].cta_colour}
            href={getUrl(cta[0].cta_link)}
            label={cta[0].cta_label}
          />
        )}
        {Array.isArray(cta) && Array.isArray(cta[0].cta_form) && cta[0].cta_form?.length > 0 && (
          <ButtonHEF
            as={"button"}
            variant="primary"
            color={cta[0].cta_colour}
            onClick={() => setOpenModal(true)}
            label={cta[0].cta_label}
          />
        )}
      </CardItemWrapper>
      {cta[0].cta_form && cta[0].cta_form.length > 0 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={() => setOpenModal(false)}
            _uid={cta[0].cta_form[0]._uid}
            form={cta[0].cta_form[0].form}
            component={cta[0].cta_form[0].component}
            form_handler={cta[0].cta_form[0].form_handler}
            sf_campaign_id={cta[0].cta_form[0].sf_campaign_id}
            title={cta[0].cta_form[0].title}
            qualifier={cta[0].cta_form[0].qualifier}
            event_date={cta[0].cta_form[0].event_date}
            event_duration={cta[0].cta_form[0].event_duration}
            event_location={cta[0].cta_form[0].event_location}
            thank_you_components={cta[0].cta_form[0].thank_you_components}
            close_on_submit={cta[0].cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
