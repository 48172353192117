import React from "react";
import { hefBrandColorsPlusNeutrals } from "../../types";
import { Blender } from "../Blender";
import { QuoteCmp } from "../Quote/Quote";
import { QuoteProps } from "../Quote/Quote";
import Scribble from "../../assets/Graphics/Scribble.svg";

import {
  StyledMultiQuoteWrapper,
  StyledMultiQuoteBlender,
  StyledMultiQuoteGraphic,
  StyledMultiQuote,
} from "./partials";

export interface MultiQuoteProps {
  items: QuoteProps[];
  background: hefBrandColorsPlusNeutrals;
  breaker: string;
  graphic: boolean;
}

export const MultiQuoteCmp: React.FC<MultiQuoteProps> = ({
  items,
  background,
  graphic,
  breaker,
}) => {
  return (
    <>
      <StyledMultiQuoteBlender>
        {graphic === true && breaker === "top" && (
          <StyledMultiQuoteGraphic>
            <img src={Scribble} alt="stylish graphic"></img>
          </StyledMultiQuoteGraphic>
        )}
        {breaker === "top" && <Blender color={background} position={breaker} />}
      </StyledMultiQuoteBlender>
      <StyledMultiQuote background={background}>
        <StyledMultiQuoteWrapper>
          {items.map((item, index) => (
            <QuoteCmp
              key={index}
              quote={item.quote}
              author={item.author}
              author_picture={item.author_picture}
              company_logo={item.company_logo}
            />
          ))}
        </StyledMultiQuoteWrapper>
      </StyledMultiQuote>
      <StyledMultiQuoteBlender>
        {breaker === "bottom" && (
          <Blender color={background} position={breaker} />
        )}
        {graphic === true && breaker === "bottom" && (
          <StyledMultiQuoteGraphic>
            <img src={Scribble} alt="stylish graphic"></img>
          </StyledMultiQuoteGraphic>
        )}
      </StyledMultiQuoteBlender>
    </>
  );
};
