import React from "react";

import {
  StyledOpenProgramsHeadline,
  StyledOpenProgramsHeadlineContent,
  StyledOpenProgramsHeadlineHeader,
  StyledOpenProgramsHeadlineQualifier,
  StyledOpenProgramsHeadlineDesc,
} from "./partials";

interface OpenProgramsHeadlineProps {
  _uid: string;
  qualifier: string;
  header: string;
  body: string;
}

export const OpenProgramsHeadline: React.FC<OpenProgramsHeadlineProps> = ({
  qualifier,
  header,
  body,
}) => {
  return (
    <StyledOpenProgramsHeadline>
      <StyledOpenProgramsHeadlineContent>
        <StyledOpenProgramsHeadlineQualifier>
          {qualifier}
        </StyledOpenProgramsHeadlineQualifier>
        <StyledOpenProgramsHeadlineHeader>
          {header}
        </StyledOpenProgramsHeadlineHeader>
        <StyledOpenProgramsHeadlineDesc>{body}</StyledOpenProgramsHeadlineDesc>
      </StyledOpenProgramsHeadlineContent>
    </StyledOpenProgramsHeadline>
  );
};
