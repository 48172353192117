import React from "react";

import { sbImageService } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { HighlightedPoint, HighlightedPointProps } from "./HighlightedPoint";
import {
  StyledAssetWrapper,
  StyledHighlightedList,
  StyledHighlightedListContent,
  StyledHighlightedListContentPoint,
  StyledHighlightedListContentQual,
  StyledHighlightedListContentText,
  StyledHighlightedListWrapper,
} from "./partials";

export interface HighlightedListProps {
  qualifying_title: string;
  highlighted_points: Array<HighlightedPointProps>;
  speckle_image?: sbAssetType;
  top_image?: sbAssetType;
  background_colour?: "heritage" | "copper" | "intensity";
  adjustedText?: boolean;
}

export const HighlightedListCmp: React.FC<HighlightedListProps> = ({
  qualifying_title,
  highlighted_points,
  speckle_image,
  top_image,
  background_colour,
  adjustedText,
}) => {
  let url = "";
  if (speckle_image?.filename) {
    url = sbImageService(speckle_image.filename, undefined, "1200x0");
  }

  const backgroundStyle = {
    backgroundImage: `url('${url}')`,
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
  };

  return (
    <StyledHighlightedListWrapper>
      {top_image?.filename && (
        <StyledAssetWrapper>
          <picture>
            <source srcSet={sbImageService(top_image.filename, undefined, "960x0")} type="image/webp" />
            <img
              src={sbImageService(top_image.filename, undefined, "960x0")}
              alt={top_image.alt}
              title={top_image.title}
              loading="lazy"
            />
          </picture>
        </StyledAssetWrapper>
      )}
      <StyledHighlightedList background_color={background_colour} adjustedText={adjustedText}>
        <StyledHighlightedListContent style={url ? backgroundStyle : undefined} adjustedText={adjustedText}>
          <StyledHighlightedListContentText>
            <StyledHighlightedListContentQual adjustedText={adjustedText}>
              {qualifying_title}
            </StyledHighlightedListContentQual>
            {highlighted_points.map((item, idx) => (
              <StyledHighlightedListContentPoint adjustedText={adjustedText} key={`highlighted-point-hr-${idx}`}>
                <HighlightedPoint title={item.title} point={item.point} adjustedText={item.adjustedText} />
                {highlighted_points.length > 1 && highlighted_points.length - 1 !== idx && (
                  <hr key={`highlighted-point-hr-${idx}`}></hr>
                )}
              </StyledHighlightedListContentPoint>
            ))}
          </StyledHighlightedListContentText>
        </StyledHighlightedListContent>
      </StyledHighlightedList>
    </StyledHighlightedListWrapper>
  );
};
