import React, { useState, useRef, useEffect } from "react";
import IconArrowDown from "@ef-global/web-ui-react/lib/icons/IconArrowDown";

import {
  StyledHomeHero,
  StyledHomeHeroMain,
  StyledHomeHeroHeader,
  StyledHomeHeroBody,
  StyledHomeHeroLinkContainer,
  StyledHomeHeroButton,
  StyledHomeHeroMainContent,
  StyledHomeHeroAside,
} from "./partials";
import { hefBrandColors, sbAssetType } from "../../types";
import { sbImageService } from "hult-sb-ui";

export interface HomeHeroProps {
  header: string;
  body?: string;
  image: sbAssetType;
  cta_label?: string;
  cta_colour?: hefBrandColors;
}

export const HomeHeroCmp: React.FC<HomeHeroProps> = ({
  header,
  body,
  image,
  cta_label,
  cta_colour,
}) => {
  const hero = useRef(null);
  const [heroHeight, setHeroHeight] = useState(750 + 150);

  let site = null;
  if (typeof document !== "undefined") {
    site = document.getElementById("site");
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Set initial hero height
      setHeroHeight(hero?.current?.scrollHeight + 150);

      // timeoutId for debounce mechanism
      let timeoutId: any = null;

      const resizeListener = () => {
        // Get current height of the hidden bullet points container
        if (hero) {
          // Hero height + Global nav
          setHeroHeight(hero?.current?.scrollHeight + 150);
        }
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
      };

      // set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [hero]);

  function handleScroll() {
    if (site) {
      site.scrollTo({ top: heroHeight, left: 0, behavior: "smooth" });
    }
  }

  return (
    <StyledHomeHero ref={hero}>
      <StyledHomeHeroMain>
        <StyledHomeHeroMainContent>
          <StyledHomeHeroHeader>{header}</StyledHomeHeroHeader>
          <StyledHomeHeroBody>{body}</StyledHomeHeroBody>
          <StyledHomeHeroLinkContainer onClick={(_) => handleScroll()}>
            <a>{cta_label}</a>
            <StyledHomeHeroButton colour={cta_colour}>
              <IconArrowDown />
            </StyledHomeHeroButton>
          </StyledHomeHeroLinkContainer>
        </StyledHomeHeroMainContent>
      </StyledHomeHeroMain>
      <StyledHomeHeroAside>
        <img
          src={sbImageService(image.filename, undefined, "0x707")}
          alt={image.alt}
          title={image.title}
          loading={"eager"}
          width="638px"
          height="707px"
        />
      </StyledHomeHeroAside>
    </StyledHomeHero>
  );
};
