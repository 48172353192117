import React, { ReactNode, useEffect, useRef, useState } from "react";
import IconChevronDown from "@ef-global/web-ui-react/lib/icons/IconChevronDown";

import {
  StyledFilterAccordion,
  StyledFilterAccordionHeader,
  StyledFilterAccordionBody,
} from "./partials";

export interface FilterProps {
  title: string
  children?: ReactNode
}

export const Filter: React.FC<FilterProps> = ({
  title,
  children
}) => {

  const [isOpen, setIsOpen] = useState(true);
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const bodyRef = useRef();
  const [bodyHeight, setBodyHeight] = useState(0);

  useEffect(() => {
    if (!headerRef.current && ! bodyRef.current) {
      setHeaderHeight(headerRef?.current?.getBoundingClientRect().height)
      setBodyHeight(bodyRef?.current?.getBoundingClientRect().height)
      headerRef.current = true;
      bodyRef.current = true;
    } else {
      setHeaderHeight(headerRef?.current?.getBoundingClientRect().height)
      setBodyHeight(bodyRef?.current?.getBoundingClientRect().height)
    }
  });

  return (
    <>
      <StyledFilterAccordion
        open={isOpen}
        headerHeight={headerHeight}
        bodyHeight={bodyHeight}
      >
        <StyledFilterAccordionHeader
          ref={headerRef}
          onClick={() => setIsOpen(!isOpen)}
          open={isOpen}
        >
          <p>{title}</p>
          <IconChevronDown />
        </StyledFilterAccordionHeader>
        <StyledFilterAccordionBody ref={bodyRef}>
          {children}
        </StyledFilterAccordionBody>
      </StyledFilterAccordion>
    </>
  );
};
