import * as React from "react";
import { Pagination } from "@ef-global/web-ui-react/lib/components/Pagination";
import { ThemeConsumer } from "styled-components";
import "@ef-global/web-ui/scss/components/_pagination.scss";

import { StyledPagination } from "./partials";

export interface IPaginationProps {
  breakLabel: string;
  pageCount: number;
  pageRangeDisplayed: number;
  marginPagesDisplayed: number;
  page?: number;
  onPageChange: (e: number) => void;
}

const PaginationCmp: React.FC<IPaginationProps> = ({
  breakLabel,
  pageCount,
  pageRangeDisplayed = 4,
  marginPagesDisplayed = 2,
  page,
  onPageChange = () => {},
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageWidth, setPageWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : 800
  );
  const pages: Array<any> = [];

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      // timeoutId for debounce mechanism
      let timeoutId: any = null;

      const resizeListener = () => {
        setPageWidth(window.innerWidth);
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
      };
      // set resize listener
      window.addEventListener("resize", resizeListener);
      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, []);

  React.useEffect(() => setCurrentPage(page || 0), [page]);

  function getPageElement(index: number) {
    return (
      <Pagination.Page
        key={index}
        active={currentPage === index}
        more={false}
        onClick={() => {
          setCurrentPage(index);
          onPageChange(index);
        }}
      >
        {index + 1}
      </Pagination.Page>
    );
  }

  function getBreakElement(index: number) {
    return (
      <Pagination.Page key={index} active={false} more>
        {breakLabel}
      </Pagination.Page>
    );
  }

  if (pageCount <= pageRangeDisplayed) {
    for (let index = 0; index < pageCount; index++) {
      pages.push(getPageElement(index));
    }
  } else {
    let leftSide = pageRangeDisplayed / 2;
    let rightSide = pageRangeDisplayed - leftSide;
    let breakView;
    // If the selected page index is on the default right side of the pagination,
    // we consider that the new right side is made up of it (= only one break element).
    // If the selected page index is on the default left side of the pagination,
    // we consider that the new left side is made up of it (= only one break element).
    if (currentPage > pageCount - pageRangeDisplayed / 2) {
      rightSide = pageCount - currentPage;
      leftSide = pageRangeDisplayed - rightSide;
    } else if (currentPage < pageRangeDisplayed / 2) {
      leftSide = currentPage;
      rightSide = pageRangeDisplayed - leftSide;
    }

    for (let index = 0; index < pageCount; index++) {
      const page = index + 1;
      switch (true) {
        case page <= marginPagesDisplayed:
          pages.push(getPageElement(index));
          break;
        case page > pageCount - marginPagesDisplayed:
          pages.push(getPageElement(index));
          break;
        case index >= currentPage - leftSide &&
          index <= currentPage + rightSide:
          pages.push(getPageElement(index));
          break;
        case breakLabel && pages[pages.length - 1] !== breakView:
          breakView = getBreakElement(index);
          pages.push(breakView);
          break;
        default:
          break;
      }
    }
  }

  return (
    <ThemeConsumer>
      {(theme) => {
        const breakpoint = parseInt(theme.breakpoints.m.replace("px", ""));
        return (
          <StyledPagination>
            <Pagination
              small={pageWidth < breakpoint}
              onPageChange={(e) => {
                let nCurrentPage = currentPage + e;
                if (e === -10) {
                  nCurrentPage = 0;
                } else if (e === 10) {
                  nCurrentPage = pageCount - 1;
                }

                if (nCurrentPage >= 0 && nCurrentPage < pageCount) {
                  setCurrentPage(nCurrentPage);
                  onPageChange(nCurrentPage);
                }
              }}
            >
              {pageWidth < breakpoint ? `${currentPage + 1}` : pages}
            </Pagination>
          </StyledPagination>
        );
      }}
    </ThemeConsumer>
  );
};

export default PaginationCmp;
