import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import {
  LandingPageHeaderCmp,
  LandingPageHeaderProps,
} from "./LandingPageHeader";

interface LandingPageHeaderWBlokProps {
  blok: LandingPageHeaderProps & blokType;
}

export const LandingPageHeader: React.FC<LandingPageHeaderWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <LandingPageHeaderCmp
        menu_items={blok.menu_items}
        cta_label={blok.cta_label}
        cta_link={blok.cta_link}
        cta_form={blok.cta_form}
        header={blok.header}
        body={blok.body}
        image={blok.image}
        background_colour={blok.background_colour}
        background_image={blok.background_image}
        graphic_overlay={blok.graphic_overlay}
        graphic_overlay_mobile={blok.graphic_overlay_mobile}
        overlay_opacity={blok.overlay_opacity}
      ></LandingPageHeaderCmp>
    </SbEditable>
  );
};
