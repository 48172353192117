import React, { useState } from "react";

import { IconArrowRight } from "@ef-global/web-ui-react/lib/icons/all";
import { SbImage } from "hult-sb-ui";

import { sbAssetType, sbLinkType } from "../../types";
import { getUrl } from "../utils/helpers";
import {
  StoryCardBody,
  StoryCardDip,
  StoryCardHeading,
  StoryCardHoverBodyWrapper,
  StoryCardHoverWrapper,
  StoryCardImageWrapper,
  StoryCardLinkWrapper,
  StoryCardMobileLinkWrapper,
  StoryCardWrapper,
} from "./styles/StyledClientStoryCard";

export interface ClientStoryCardProps {
  title: string;
  body: string;
  linkLabel: string;
  link: sbLinkType;
  image: sbAssetType;
  hasMouseover: boolean;
  _uid: string;
}

export const ClientStoryCard: React.FC<ClientStoryCardProps> = ({ title, body, link, linkLabel, image }) => {
  const [showHover, setShowHover] = useState(false);

  return (
    <>
      <StoryCardWrapper showHover={showHover} onMouseEnter={() => setShowHover(true)}>
        <StoryCardImageWrapper>
          <StoryCardMobileLinkWrapper href={getUrl(link)}>
            <SbImage image={image} />
          </StoryCardMobileLinkWrapper>
        </StoryCardImageWrapper>
        <StoryCardMobileLinkWrapper href={getUrl(link)}>
          <StoryCardDip>
            <StoryCardHeading>{title}</StoryCardHeading>
            <IconArrowRight />
          </StoryCardDip>
        </StoryCardMobileLinkWrapper>
      </StoryCardWrapper>
      <StoryCardHoverWrapper onMouseLeave={() => setShowHover(false)} showHover={showHover}>
        <StoryCardHeading>{title}</StoryCardHeading>
        <StoryCardHoverBodyWrapper>
          <StoryCardBody>{body}</StoryCardBody>
          <StoryCardLinkWrapper href={getUrl(link)}>
            <StoryCardBody>{linkLabel}</StoryCardBody>
            <IconArrowRight />
          </StoryCardLinkWrapper>
        </StoryCardHoverBodyWrapper>
      </StoryCardHoverWrapper>
    </>
  );
};
