import React from "react";
import Cookies from "js-cookie";
import DynamicComponent from "../";
import { blokType } from "../../types";

export interface GeoHandlerProp {
  country: string;
  content: Array<blokType>;
  fallback: Array<blokType>;
}

export const GeoHandlerCmp: React.FC<GeoHandlerProp> = ({ country, content, fallback }) => {

  const countryValue = Cookies.get('uCountry') 
  
  return (
    <> 
      {country === countryValue ? 
        <> 
          {content &&
            content.map((c: blokType) => (
              <DynamicComponent key={c._uid} blok={c} />
          ))}
        </> 
      :
        <>
          {fallback &&
            fallback.map((c: blokType) => (
              <DynamicComponent key={c._uid} blok={c} />
          ))}
        </> 
      }
    </> 
  );
};



