import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { blokType } from "../../types";

import { ArticleShareCmp, ArticleShareBlokProps } from "./ArticleShare";

interface ArticleShareProps {
  blok: blokType & ArticleShareBlokProps;
  path: string;
}

const ArticleShare: React.FC<ArticleShareProps> = ({ blok, path }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => {
      const shareUrl =
        blok.share_url || `${data?.site?.siteMetadata?.siteUrl}/${path}`;

      return (
        <ArticleShareCmp
          share_article_label={blok.share_article_label}
          share_url={shareUrl}
          facebook={blok.facebook}
          linkedin={blok.linkedin}
          twitter={blok.twitter}
          whatsapp={blok.whatsapp}
          email={blok.email}
        />
      );
    }}
  />
);

export default ArticleShare;
