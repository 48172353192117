import styled from "styled-components";
import { StyledButton } from "../Button/partials";

import {
  StyledCheckbox,
  StyledDropDownHeader,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledMultiSelectCheckbox,
  StyledMultiSelectItem,
  StyledRadioItem,
  StyledSelectIcons,
} from "../FormInput/partials";

import {
  StyledFormFieldSectionTitle,
  StyledFormHeaderEventDetailsContainer,
  StyledFormHeaderQualifier,
  StyledFormHeaderTitle,
} from "../Forms/partials";

interface IStyleSubmit {
  submit: boolean;
}

export const StyledFormEmbed = styled.div<IStyleSubmit>`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.warmths.heritage};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: ${({ theme }) => theme.spacing.seven}
    ${({ theme }) => theme.spacing.eight} ${({ theme }) => theme.spacing.seven}
    0;

  ${(props) => props.submit && `background: white;`}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${({ theme }) => theme.spacing.four} 0 0 0;
  }

  > div {
    overflow: hidden;
  }

  &
    ${StyledFormHeaderTitle},
    ${StyledFormHeaderQualifier},
    ${StyledFormHeaderEventDetailsContainer},
    ${StyledFormFieldSectionTitle},
    ${StyledCheckbox},
    label {
    color: ${({ theme }) => theme.colors.neutrals.white};

    &:hover{
      color: ${({ theme }) => theme.colors.neutrals.white};
    }
  }

  & ${StyledErrorMessage}{
    color: white;
    margin: 0;
  }
  
  & input[type="radio"]:checked + label,
  input[type="radio"]:not(:checked) + label {
    color: ${(props) => props.theme.colors.neutrals.white};
  }

  & ${StyledDropDownHeader} {
    background-color: ${({ theme }) => theme.colors.neutrals.sisal20};
  }

  & ${StyledButton} {
    background: ${({ theme }) => theme.colors.neutrals.white};
    color: ${({ theme }) => theme.colors.neutrals.black};
  }

  & ${StyledMultiSelectItem} {
    background-color: white;
    color: black;
  }

  & ${StyledMultiSelectCheckbox} {
    border: 1px solid ${({ theme }) => theme.colors.neutrals.coolGrey};
  }

  & ${StyledCheckbox} {
    > div {
      background: white;
      color: ${({ theme }) => theme.colors.warmths.heritage};
    }
  }
`;

export const StyledFormEmbedWrapper = styled.div<IStyleSubmit>`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.warmths.heritage} 60%,
    ${({ theme }) => theme.colors.neutrals.white} 35%
  );
  width: 100%;

  ${(props) => props.submit && `background: white;`}
`;

export const StyledSpacer = styled.div`
  height: 48px;
  background: ${({ theme }) => theme.colors.neutrals.white};
  width: 100%;
`;

export const StyledSpacerCol = styled.div<IStyleSubmit>`
  display: grid;
  position: relative;
  height: 100%;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  max-width: 960px;
  margin: 0 auto;

  & svg {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    max-height: 48px;
    width: 60%;

    ${(props) => props.submit && `display: none;`}

  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & svg {
      left: 0;
      max-height: 48px;
      width: 100%;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin: 0 24px;
  }
`;
