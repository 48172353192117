import styled from "styled-components";

export const StyledFilterFormWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.four} 0;
`;

export const StyledFilterFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  row-gap: ${(props) => props.theme.spacing.four};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: ${(props) => props.theme.spacing.four};
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;
