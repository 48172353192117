import React, { createContext, useEffect, useState } from "react";

import { IFooter } from "../components/Footer";
import { FormSCProps } from "../components/Forms/FormSC";
import { NavItemProps } from "../components/MegaMenu/NavItem";
import { IStoryType, blokType, sbAssetType, sbLinkType, sbRichText } from "../types";

interface ISettingsContent {
  logo?: sbAssetType;
  logo_url?: sbLinkType;
  footer?: Array<IFooter>;
  menu_label?: string;
  close_label?: string;
  main_nav_closed?: Array<blokType>;
  main_nav_open?: Array<blokType>;
  side_nav_open?: Array<blokType>;
  contact_number?: string;
  contact_email?: string;
  mobile_cta?: Array<blokType>;
  language_links?: Array<blokType>;
  cookie_title?: string;
  cookie_notice?: sbRichText;
  gate_title?: string;
  gate_description?: string;
  gate_main_cta_label?: string;
  gate_main_cta_colour?: "heritage" | "copper" | "intensity";
  gate_main_cta_form?: Array<blokType & FormSCProps>;
  gate_secondary_cta_label?: string;
  gate_secondary_cta_link?: sbLinkType;
  disableGlobal: boolean;
  navItems: NavItemProps[];
  contactLabel: string;
  contactLink: sbLinkType;
  contactNumber: string;
  backButtonText: string;
  backToHomeText: string;
  banner_notice: string;
  banner_link: sbLinkType;
}

export interface ISettings extends IStoryType {
  content?: blokType & ISettingsContent;
}

interface ISettingsContextProps {
  settings?: ISettings;
  setSettings?: React.Dispatch<React.SetStateAction<ISettings>>;
}

const defaultValue: ISettingsContextProps = {
  settings: {},
  setSettings: () => null,
};

export const SettingsContextHooks = createContext(defaultValue);

const SettingsContextHooksProvider: React.FC<ISettingsContextProps> = ({
  children,
  settings: settingsFromProps = {},
}) => {
  const [settings, setSettings] = useState(settingsFromProps);

  return <SettingsContextHooks.Provider value={{ settings, setSettings }}>{children}</SettingsContextHooks.Provider>;
};

export default SettingsContextHooksProvider;
