import styled from "styled-components";

interface StyledTab {
  is_visible: string;
}

export const StyledTabWrapper = styled.div``;

export const StyledFormatTitle = styled.div`
  display: flex;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0 0 0 ${(props) => props.theme.spacing.three};
    overflow: auto;

    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    // Hide scrollbar for Chrome, Safari and Opera
    & ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledFormatTab = styled.div<StyledTab>`
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing.two}
    ${(props) => props.theme.spacing.four};
  font-size: ${(props) => props.theme.fontSize.m};
  font-weight: ${(props) => props.theme.font.bold};
  color: ${(props) => props.theme.colors.neutrals.white};
  cursor: pointer;

  ${(props) =>
    props.is_visible &&
    `
    background-color: ${props.theme.colors.neutrals.white};
    color: ${props.theme.colors.neutrals.black};
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.two}
      ${(props) => props.theme.spacing.three};
    margin: 0;
    white-space: nowrap;
  }
`;

export const StyledText = styled.div`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 24px;
  margin: 0 auto;
  max-width: 960px;

  @media screen and (max-width: ${(props) => props.theme.maxWidthWPadding}) {
    margin-left: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-left: 0px;
  }
`;

export const StyledTextWrapper = styled.div<StyledTab>`
  grid-column: col-start 1 / span 12;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.seven} 0;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: ${(props) => props.theme.font.bold};
`;

export const StyledDesc = styled.div`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.font.light};
  width: 550px;
  margin: ${(props) => props.theme.spacing.one}
    ${(props) => props.theme.spacing.eight}
    ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 100%;
    margin: ${(props) => props.theme.spacing.one} 0
      ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledIconContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  max-width: 960px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: auto;
  }
`;

export const StyledDescWrapper = styled.div`
  display: flex;
  width: 100%;
`;
