import styled from "styled-components";

export const StyledTextTwoColumns = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledTextTwoColumnsWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`;

export const StyledTextTwoColumnsHeader = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-bottom: ${(props) => props.theme.spacing.three};
  }
`;

export const StyledTextTwoColumnsListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${(props) => props.theme.spacing.four};
  column-gap: ${(props) => props.theme.spacing.eight};
  margin-bottom: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: 100%;
    row-gap: ${(props) => props.theme.spacing.three};
    margin-bottom: ${(props) => props.theme.spacing.three};
  }
`;
