import React from "react";

import {
  StyledFeatureStat,
  StyledFeatureStatWrapper,
  StyledFeatureStatList,
  StyledFeatureStatHeader,
  StyledFeatureStatSubHeader,
} from "./partials";
import { FeatureStatItem } from "./FeatureStatItem";

export interface FeatureStatProps {
  title: string;
  sub_header: string;
  stat_items: Array<any>;
}

export const FeatureStatCmp: React.FC<FeatureStatProps> = ({
  title,
  sub_header,
  stat_items,
}) => {
  return (
    <StyledFeatureStat>
      <StyledFeatureStatWrapper>
        <StyledFeatureStatHeader>{title}</StyledFeatureStatHeader>
        <StyledFeatureStatSubHeader>{sub_header}</StyledFeatureStatSubHeader>
        <StyledFeatureStatList>
          {stat_items.map((item, idx) => (
            <FeatureStatItem
              key={idx}
              stat={item.stat}
              stat_body={item.stat_body}
            />
          ))}
        </StyledFeatureStatList>
      </StyledFeatureStatWrapper>
    </StyledFeatureStat>
  );
};
