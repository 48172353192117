import React from "react";

import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";
import { SbImage } from "hult-sb-ui";
import ReactPlayer from "react-player";

import { sbAssetType } from "../../types";
import { StyledIconPlayWrapper, StyledImageGalleryItem } from "./partials";

export interface ImageGalleryItemProps {
  image: sbAssetType;
  video: sbAssetType;
  video_link: string;
  video_preview: sbAssetType;
}

const reactPlayerInline = {
  borderRadius: "4px",
};

export const ImageGalleryItem: React.FC<ImageGalleryItemProps> = ({ image, video, video_link, video_preview }) => {
  return (
    <StyledImageGalleryItem>
      {image?.filename && <SbImage image={image} resize="1200x0" loading={"lazy"} />}
      {/* If image available only show image */}
      {video?.filename && !image?.filename && video_preview?.filename && (
        <ReactPlayer
          url={video.filename}
          light={video_preview.filename}
          width="100%"
          height="100%"
          playing={true}
          style={reactPlayerInline}
          playIcon={
            <StyledIconPlayWrapper>
              <IconPlay />
            </StyledIconPlayWrapper>
          }
          controls={true}
        />
      )}
      {/* If image or video available dont show iframe */}
      {video_link && (!image?.filename || !video?.filename) && video_preview?.filename && (
        <ReactPlayer
          url={video_link}
          light={video_preview.filename}
          width="100%"
          height="100%"
          style={reactPlayerInline}
          playIcon={
            <StyledIconPlayWrapper>
              <IconPlay />
            </StyledIconPlayWrapper>
          }
          playing={true}
          controls={true}
        />
      )}
    </StyledImageGalleryItem>
  );
};
