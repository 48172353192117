import React from "react";

import DynamicComponent from "../..";
import { BaseComponentProps, blokType } from "../../../types";
import { Footer } from "../../Footer";
import Seo from "../../utils/seo";
import { StyledBodyContainer, StyledSpacer } from "./partials";

export interface CareersContent extends blokType {
  secondary_navigation?: Array<blokType>;
  header?: Array<blokType>;
  job_postings: Array<blokType>;
  contact_us?: Array<blokType>;
  meta_data?: Array<blokType>;
}
interface CareersProps extends BaseComponentProps {
  blok: CareersContent;
}

export const Careers: React.FC<CareersProps> = ({ blok, story, alternates }) => {
  return (
    <>
      <Seo
        title={story?.name || ""}
        pathname={story?.slug === "home" ? "" : story?.full_slug || ""}
        alternates={alternates}
      />
      {blok?.meta_data?.map((seoBlok) => {
        return (
          <DynamicComponent key={seoBlok._uid} blok={seoBlok} path={story?.path ? story?.path : story?.full_slug} />
        );
      })}
      <StyledBodyContainer>
        {blok?.secondary_navigation?.map((navBlok) => {
          return <DynamicComponent key={navBlok._uid} blok={navBlok} />;
        })}

        <StyledSpacer />

        {blok?.header?.map((headerBlok) => {
          return <DynamicComponent key={headerBlok._uid} blok={headerBlok} />;
        })}
        {blok?.job_postings?.map((afterBlok) => {
          return <DynamicComponent key={afterBlok._uid} blok={afterBlok} />;
        })}
        {blok?.contact_us?.map((contactBody) => {
          return <DynamicComponent key={contactBody._uid} blok={contactBody} />;
        })}
      </StyledBodyContainer>
      <Footer alternatives={alternates} />
    </>
  );
};
