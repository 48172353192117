import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { ImageGalleryCmp, ImageGalleryProps } from "./ImageGallery";

interface ImageGalleryWBlokProps {
  blok: ImageGalleryProps & blokType;
}

export const ImageGallery: React.FC<ImageGalleryWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <ImageGalleryCmp images={blok.images}></ImageGalleryCmp>
    </SbEditable>
  );
};
