import styled, { css } from "styled-components";

interface StyledFullBleedGrapicOverlayProps {
  placement?: string;
  mobile?: boolean;
  quote?: boolean;
}


export const StyledFullBleedImgContainer = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0;
  }
`;

export const StyledFullBleedImg = styled.div`
  position: relative;
  height: 100%;

  & picture {
    & img {
      height: 700px;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & picture {
      & img {
        height: 350px;
      }
    }
  }
`;

export const StyledOverlay = styled.div<StyledFullBleedGrapicOverlayProps>`
  position: absolute;
  height: auto;
  width: 100%;
  top: 0;
  bottom: 6px;
  background: ${(props) => (props.quote && "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))")};
`

export const StyledFullBleedGrapicOverlay = styled.img<StyledFullBleedGrapicOverlayProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: contain;

  ${(props) =>
    props.placement === "top" &&
    css`
      top: -1px;
      -webkit-transform: rotate(-180deg); /* Safari */
      -moz-transform: rotate(-180deg); /* Firefox 3.6 Firefox 4 */
      -ms-transform: rotate(-180deg); /* IE9 */
      -o-transform: rotate(-180deg); /* Opera */
      transform: rotate(-180deg); /* W3C */
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: ${(props) => (props.mobile ? "block" : "none")};
  }
`;

export const StyledQuoteContainer = styled.div`
  border-left: 4px solid ${(props) => props.theme.colors.neutrals.white};
  padding-left: ${(props) => props.theme.spacing.three};
  color: ${(props) => props.theme.colors.neutrals.white};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    width: 60%;
    margin-left: ${(props) => props.theme.spacing.one};
    padding-left: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledQuoteWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const StyledQuote = styled.div`
  max-width: 496px;
  width: 100%;
  font-size: 24px;
  font-style: italic;
  font-weight: ${(props) => props.theme.font.light};
  padding-bottom: ${(props) => props.theme.spacing.three};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 16px;
    padding-bottom: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledQuoteBy = styled.div`
  font-size: 12px;
`;
