import { SbImage } from "hult-sb-ui";
import React from "react";
import { sbAssetType } from "../../types";

export interface LogoRowImageProps {
  image: sbAssetType;
}

export const LogoRowImageCmp: React.FC<LogoRowImageProps> = ({ image }) => {
  return <SbImage image={image} loading={"eager"} />;
};
