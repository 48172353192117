import React, { useState } from "react";

import {
  StyledBodyCenteredWModal,
  StyledBodyCenteredWModalHeader,
} from "./partials";
import { Modal } from "../Modal";
import { FormSC, FormSCProps } from "../Forms/FormSC";
import { hefBrandColors, sbLinkType } from "../../types";
import { getKey, getUrl } from "../utils/helpers";
import { ButtonHEF } from "../Button";

export interface BodyCenteredWithCTACmpProps {
  _uid: string;
  title: string;
  component: string;
  cta_label: string;
  cta_link?: sbLinkType;
  cta_form?: Array<FormSCProps>;
  cta_colour?: hefBrandColors;
}

export const BodyCenteredWithCTACmp: React.FC<BodyCenteredWithCTACmpProps> = ({
  _uid,
  title,
  component,
  cta_label,
  cta_form,
  cta_link,
  cta_colour,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <StyledBodyCenteredWModal
        key={getKey("body-centered-with-cta", _uid)}
        cta_colour={cta_colour}
      >
        <StyledBodyCenteredWModalHeader>{title}</StyledBodyCenteredWModalHeader>
        {Array.isArray(cta_form) && cta_form.length === 1 && (
          <ButtonHEF
            variant="primary"
            parentComponent={component}
            onClick={(_: MouseEvent) => setOpenModal(true)}
            label={cta_label}
            color={cta_colour}
          ></ButtonHEF>
        )}
        {Array.isArray(cta_form) && cta_form.length !== 1 && cta_link && (
          <ButtonHEF
            as={"a"}
            variant="primary"
            parentComponent={component}
            href={getUrl(cta_link)}
            label={cta_label}
            color={cta_colour}
          ></ButtonHEF>
        )}
      </StyledBodyCenteredWModal>
      {Array.isArray(cta_form) && cta_form.length === 1 && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <FormSC
            onClose={(_: void) => setOpenModal(false)}
            _uid={cta_form[0]._uid}
            form={cta_form[0].form}
            component={cta_form[0].component}
            form_handler={cta_form[0].form_handler}
            sf_campaign_id={cta_form[0].sf_campaign_id}
            title={cta_form[0].title}
            qualifier={cta_form[0].qualifier}
            event_date={cta_form[0].event_date}
            event_duration={cta_form[0].event_duration}
            event_location={cta_form[0].event_location}
            thank_you_components={cta_form[0].thank_you_components}
            close_on_submit={cta_form[0].close_on_submit}
          />
        </Modal>
      )}
    </>
  );
};
