import styled from "styled-components";
import { focusBorder, validColor } from "./helpers";

interface StyledInputProps {
  hidden?: boolean;
  valid?: boolean;
}

export const StyledInputWrapper = styled.div<StyledInputProps>`
  display: ${({ hidden }) => (hidden ? "none" : "inline-block")};
  width: 100%;
  height: auto;

  & label {
    width: 100%;
    font-size: 14px;
    line-height: 1;
    margin-bottom: ${(props) => props.theme.spacing.one};
    color: ${(props) => props.theme.colors.neutrals.black};
    display: flex;
    justify-content: space-between;

    & span {
      color: #ee6160;
    }
  }

  > div {
    border-radius: 4px;
    border: 1px solid ${(props) => validColor(props.valid)};
  }

  > div:focus-within {
    border: ${focusBorder()};
  }

  & svg {
    position: absolute;
    right: 0;
  }
`;

export const StyledInput = styled.div<StyledInputProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: auto;
  width: 100%;
  position: relative;
  background-color: white;

  & input {
    width: 100%;
    min-height: 24px;
    border: none;

    line-height: 1;
    padding: 12px ${(props) => props.theme.spacing.two};
    font-size: 16px;
    margin: 0;
    -webkit-appearance: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(43, 43, 43, 0.3);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(43, 43, 43, 0.3);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(43, 43, 43, 0.3);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(43, 43, 43, 0.3);
    }
    &:focus {
      outline: none;
    }
  }

  & textarea {
    border: none;
    padding: 12px ${(props) => props.theme.spacing.two};

    &:focus {
      outline: none;
    }
  }
`;

export const StyledErrorMessage = styled.span`
  color: ${validColor(false)};
  font-size: 12px;
  margin-left: ${(props) => props.theme.spacing.two};
`;
