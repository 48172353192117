import styled from "styled-components";
import { StyledQuoteText, StyledQuoteWrapper } from "../../Quote/partials";

interface IStyledMultiQuote {
  background: string;
}

export const StyledMultiQuote = styled.div<IStyledMultiQuote>`
  background: ${({ background, theme }) =>
    background === "offwhite" ? theme.colors.neutrals.offwhite : "white"};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0px 24px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0px;
  }
`;

export const StyledMultiQuoteWrapper = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  width: 100%;
  grid-template-columns: 50% 50%;
  display: grid;
  padding: 64px 0px;
  & div {
    padding: 0px;
    margin-right: 26px;
    & ${StyledQuoteText} {
      font-size: 24px;
    }
  }
  & div:nth-child(2) {
    & ${StyledQuoteWrapper} {
      border: none;
      margin-left: 26px;
      margin-right: 0px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-template-columns: auto;
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
    & div {
      margin-right: 0;
    }
    & div:nth-child(2) {
      & ${StyledQuoteWrapper} {
        margin-top: 64px;
        border-left: 4px solid black;
        margin-left: 0px;
      }
    }
  }
`;

export const StyledMultiQuoteBlender = styled.div`
  display: flex;
  position: relative;
`;

export const StyledMultiQuoteGraphic = styled.div`
  position: absolute;
  z-index: 1;
  right: 10%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}) {
    right: 25%;
  }
`;
