import styled, { css } from "styled-components";

import bg from "../../../assets/Graphics/ClientStoryBreaker.svg";

interface Props {
  breaker?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  hideControls?: boolean;
}

export const StoryCardsBackground = styled.div<Props>`
  position: relative;
  width: 100vw;
  background: ${(props) => props.theme.colors.neutrals.offwhite};
  padding-top: ${(props) => props.breaker && "64px"};
  ${(props) =>
    props.breaker &&
    css`
      :after {
        width: 100vw;
        height: 64px;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: no-repeat url(${bg});
        background-size: cover;
        background-position: bottom;

        @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
          display: none;
        }
      }
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: 0;
  }
`;

export const StoryCardsWrapper = styled.div`
  width: ${(props) => props.theme.maxWidth};
  margin: auto;
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.six};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} 0 ${(props) => props.theme.spacing.six};
  }
`;

export const StoryCardsTitle = styled.h3`
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: ${(props) => props.theme.spacing.six};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0 ${(props) => props.theme.spacing.three};
    margin-bottom: ${(props) => props.theme.spacing.four};
  }
`;

export const StoryCardsPaginationContainer = styled.div<Props>`
  display: ${(props) => (props.hideControls ? "none" : "flex")};
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing.four};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin-top: ${(props) => props.theme.spacing.three};
    display: flex;
    padding: 0 ${(props) => props.theme.spacing.three};
  }
`;

export const StoryCardsPagination = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${(props) => props.theme.spacing.one};
`;

export const StoryCardsPaginationDot = styled.div<Props>`
  height: 4px;
  width: ${(props) => (props.isActive ? "18px" : "4px")};
  border-radius: 4px;
  background-color: black;
`;

export const StoryCardsControls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 26px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const StoryCardsControlsItem = styled.div<Props>`
  & svg {
    color: ${(props) => (props.disabled ? props.theme.colors.neutrals.coolGrey : props.theme.colors.neutrals.black)};
  }

  & svg:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;
