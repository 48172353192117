import styled from "styled-components";

export const StyledArticleImage = styled.div`
  width: 100%;
  max-width: 960px;
  margin: ${(props) => props.theme.spacing.seven} auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    margin: ${(props) => props.theme.spacing.four} auto;
    padding: 0 ${(props) => props.theme.spacing.three};
    max-width: calc(960px);
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: calc(608px + ${(props) => props.theme.spacing.six});
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: calc(342px + ${(props) => props.theme.spacing.six});
  }

  & p {
    font-size: ${(props) => props.theme.fontSize.s};
    margin-top: ${(props) => props.theme.spacing.two};
  }
`;

export const StyledImage = styled.div`
  width: 100%;
  max-height: 540px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-height: 342px;
    img {
      object-fit: contain;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.s}) {
    max-height: 193px;
  }

  & img,
  & video {
    width: 100%;
  }
`;
