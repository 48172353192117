import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { CaseStudyImpactCmp, CaseStudyImpactProps } from "./CaseStudyImpact";

export interface CaseStudyImpactWBlokProps {
  blok: CaseStudyImpactProps & blokType;
}

export const CaseStudyImpact: React.FC<CaseStudyImpactWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <CaseStudyImpactCmp {...blok} />
    </SbEditable>
  );
};
