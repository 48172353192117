import React from "react";
import IconStar from "@ef-global/web-ui-react/lib/icons/IconStar";
import {
  StyledOverviewStoryCard,
  StyledCardTitle,
  StyledCardQualifier,
  StyledCardFilterInfo,
  StyledCardCourseInfo,
  StyledBestsellerWrapper,
  StyledBestseller,
  StyledCardLearningFormats,
  StyledCardFormat,
} from "./partials";

export interface OverviewStoriesProps {
  title: string;
  bestseller: boolean
  qualifier: string
  learning_formats: Array<any>
  full_slug: string
}

export const OverviewStories: React.FC<OverviewStoriesProps> = ({
  title,
  bestseller,
  qualifier,
  learning_formats,
  full_slug,
}) => {
  
  return (
    <StyledOverviewStoryCard href={`/${full_slug}`}>
      <StyledCardCourseInfo>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledBestsellerWrapper>
          <StyledBestseller>
            {bestseller === true ? <IconStar /> : ""}
          </StyledBestseller>
          <StyledBestseller>
            {bestseller === true ? "Flagship" : ""}
          </StyledBestseller>
        </StyledBestsellerWrapper>
      </StyledCardCourseInfo>
      <StyledCardFilterInfo>
        <StyledCardQualifier>{qualifier}</StyledCardQualifier>
        <StyledCardLearningFormats>
          {learning_formats &&
            learning_formats.map((format: any) => (
              <StyledCardFormat key={format}>
                <li>{format}</li>
              </StyledCardFormat>
            ))}
        </StyledCardLearningFormats>
      </StyledCardFilterInfo>
    </StyledOverviewStoryCard>
  );
};
