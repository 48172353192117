import * as React from "react";

import { SbImage } from "hult-sb-ui";

import { sbAssetType } from "../../types";
import { LinkItem } from "../LinkItem";
import {
  StyledArticleOverviewCard,
  StyledArticleOverviewCardAside,
  StyledOverviewCardImgContainer,
  StyledOverviewCardQualifier,
  StyledOverviewCardTitle,
} from "./partials";

export interface ArticleOverviewCard {
  id: number;
  title: string;
  qualifier: string;
  image?: sbAssetType;
  link: string;
  qualifier_colour?: string;
  card_link_label?: string;
}

export const ArticleOverviewCardCmp: React.FC<{ card: ArticleOverviewCard }> = ({ card }) => (
  <StyledArticleOverviewCard qualifier_colour={card.qualifier_colour}>
    {card.image && (
      <StyledOverviewCardImgContainer>
        <SbImage image={card.image} resize="200x200" height="200px" width="200px" loading="lazy" />
      </StyledOverviewCardImgContainer>
    )}
    <StyledArticleOverviewCardAside>
      {card.qualifier && <StyledOverviewCardQualifier>{card.qualifier}</StyledOverviewCardQualifier>}
      {card.title && <StyledOverviewCardTitle>{card.title}</StyledOverviewCardTitle>}
      {card.link && (
        <LinkItem
          link={card.link}
          link_target="_self"
          name={card.card_link_label ? card.card_link_label : "Read more"}
          parentComponent="article-overview-card"
        />
      )}
    </StyledArticleOverviewCardAside>
  </StyledArticleOverviewCard>
);
