import styled from "styled-components";

import { StyledButton } from "../../Button/partials";

interface Props {
  extraMargin: boolean;
}

export const HeroWrapper = styled.div<Props>`
  max-width: ${({ theme }) => theme.maxWidthWPadding};
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${({ theme }) => theme.spacing.three};
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme, extraMargin }) => extraMargin && theme.spacing.seven};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    column-gap: ${({ theme }) => theme.spacing.two};
    row-gap: ${({ theme }) => theme.spacing.three};
  }
`;

export const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 5;
  gap: ${({ theme }) => theme.spacing.three};

  ${StyledButton} {
    margin-top: ${({ theme }) => theme.spacing.two};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: span 4;
    grid-row: 2;

    ${StyledButton} {
      margin-top: 0;
    }
  }
`;

export const HeroCTAWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.two};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.three};
    align-items: center;
  }
`;

export const HeroImageAbsolute = styled.div`
  position: absolute;
  width: calc(100% + 120px);
  height: 100%;
  top: 50%;

  img {
    transform: translateY(-50%);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const HeroMediaWrapper = styled.div`
  position: relative;
  grid-column: 6 / span 7;
  display: flex;
  align-items: center;
  height: 680px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-column: span 4;
    grid-row: 1;
    height: auto;
    width: 100%;
    justify-self: center;
  }
`;

export const HeroImageMobile = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: block;
  }
`;

export const HeroDesktopTitleWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }

  h2:nth-of-type(2) {
    position: relative;
    color: black;
    z-index: 0;

    ::before {
      position: absolute;
      width: 295px;
      height: 72px;
      content: "";
      left: -25px;
      bottom: -3px;
      z-index: -1;
    }
  }

  h2:nth-of-type(3) {
    position: relative;
    color: black;
    z-index: 0;

    ::before {
      position: absolute;
      width: 295px;
      height: 72px;
      content: "";
      left: -18px;
      z-index: -1;
    }
  }
`;

export const HeroMobileTitleWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: block;
    text-align: center;
  }
`;

export const HeroTitle = styled.h2`
  margin: 0;
`;

export const HeroBody = styled.p`
  white-space: pre-line;
  margin: 0;
  font-weight: 300;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: center;
  }
`;
