import React from "react";
import SbEditable from "storyblok-react";
import { blokType } from "../../types";

import { SecondaryNavigationCmp } from "./SecondaryNavigation";

interface SecondaryNavigationProps {
  section_label: string;
  menu_items?: any;
}

interface SecondaryNavigationWBlokProps {
  blok: SecondaryNavigationProps & blokType;
}

export const SecondaryNavigation: React.FC<SecondaryNavigationWBlokProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <SecondaryNavigationCmp
        _uid={blok._uid}
        component={blok.component}
        section_label={blok.section_label}
        menu_items={blok.menu_items}
        more_label={blok.more_label}
      />
    </SbEditable>
  );
};
