import React, { useState } from "react";
import IconCheck from "@ef-global/web-ui-react/lib/icons/IconCheck";

import { StyledMultiSelectItem, StyledMultiSelectCheckbox } from "../partials";
import { useEffect } from "react";

interface MultiSelectOptionProps {
  label: string;
  value: string;
  checked: boolean;
  onChange?: any;
}

export const MultiSelectOption: React.FC<MultiSelectOptionProps> = ({
  label,
  value,
  onChange,
  checked,
}) => {
  const [active, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(!active);
    onChange(value, !active);
  }

  useEffect(() => {
    setIsActive(checked);
  }, [checked]);

  return (
    <StyledMultiSelectItem
      isActive={active}
      onClick={(_) => handleClick()}
      data-testid="multi-select-option"
    >
      {label}
      <StyledMultiSelectCheckbox isActive={active}>
        <input
          value={value}
          type="checkbox"
          checked={active}
          readOnly={false}
          onChange={() => void 0}
        />
        {active && <IconCheck />}
      </StyledMultiSelectCheckbox>
    </StyledMultiSelectItem>
  );
};
