import * as React from "react";
import {
  StyledEventOverviewCard,
  StyledEventCardImgContainer,
  StyledEventOverviewCardAside,
  StyledEventOverviewDetails,
  StyledEventOverviewDetailsLocation,
  StyledEventOverviewCardTitle,
} from "./partials";
import { sbAssetType } from "../../types";
import { SbImage } from "hult-sb-ui";
import { LinkItem } from "../LinkItem";

import IconCalendarOutline from "@ef-global/web-ui-react/lib/icons/IconCalendarOutline";
import IconClockOutline from "@ef-global/web-ui-react/lib/icons/IconClockOutline";
import IconLocationOutline from "@ef-global/web-ui-react/lib/icons/IconLocationOutline";
import IconOnlineCourseOutline from "@ef-global/web-ui-react/lib/icons/IconOnlineCourseOutline";

export interface EventOverviewCardProps {
  id: number;
  title?: string;
  date?: string;
  time?: string;
  location?: string;
  image?: sbAssetType;
  link?: string;
  card_link_label?: string;
}

export const EventOverviewCardCmp: React.FC<{ card: EventOverviewCardProps }> =
  ({ card }) => (
    <StyledEventOverviewCard>
      {card.image && (
        <StyledEventCardImgContainer>
          <SbImage
            image={card.image}
            resize="200x200"
            height="200px"
            width="200px"
            loading="lazy"
          />
        </StyledEventCardImgContainer>
      )}
      <StyledEventOverviewCardAside>
        <StyledEventOverviewDetails>
          {card.date && (
            <span>
              <IconCalendarOutline />
              {card.date}
            </span>
          )}
          {card.time && (
            <span>
              <IconClockOutline />
              {card.time}
            </span>
          )}
          {card.location && (
            <StyledEventOverviewDetailsLocation>
              {card.location.toLowerCase() === "online" && (
                <IconOnlineCourseOutline />
              )}
              {card.location.toLowerCase() !== "online" && (
                <IconLocationOutline />
              )}
              {card.location}
            </StyledEventOverviewDetailsLocation>
          )}
        </StyledEventOverviewDetails>

        {card.title && (
          <StyledEventOverviewCardTitle>
            {card.title}
          </StyledEventOverviewCardTitle>
        )}
        {card.link && (
          <LinkItem
            link={card.link}
            name={card.card_link_label ? card.card_link_label : "Read more"}
            parentComponent="event-overview-card"
          />
        )}
      </StyledEventOverviewCardAside>
    </StyledEventOverviewCard>
  );
