import React from "react";
import {
  StyledProductOverviewWrapper,
  StyledProductOverview,
  StyledProductOverviewInner,
  StyledProductOverviewHeader,
  StyledProductOverviewCards,
  StyledProductOverviewLink,
} from "./partials/StyledProductOverview";
import { hefBrandColors, sbAssetType } from "../../types";
import { ProductCard, ProductCardProps } from "./ProductCard";
import { Blender } from "../Blender";
import { LinkItemProps, LinkItem } from "../LinkItem";

export interface ProductOverviewProps {
  component: string;
  header: string;
  cards: Array<ProductCardProps>;
  background_colour?: hefBrandColors;
  background_image?: sbAssetType;
  layout?: string;
  breaker?: boolean;
  link: Array<LinkItemProps>;
}

export const ProductOverviewCmp: React.FC<ProductOverviewProps> = ({
  component,
  header,
  cards,
  background_colour,
  background_image,
  layout,
  breaker,
  link,
}) => {
  const backgroundStyle = {
    backgroundImage: `url(${background_image?.filename})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <StyledProductOverviewWrapper>
      {breaker && (
        <Blender color={background_colour ? background_colour : "heritage"} />
      )}
      <StyledProductOverview
        style={backgroundStyle}
        color={background_colour ? background_colour : undefined}
      >
        <StyledProductOverviewInner>
          <StyledProductOverviewHeader>{header}</StyledProductOverviewHeader>
          <StyledProductOverviewCards layout={layout}>
            {cards.map((item, idx) => (
              <ProductCard
                key={idx}
                title={item.title}
                description={item.description}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </StyledProductOverviewCards>
          <StyledProductOverviewLink>
            {Array.isArray(link) && link.length === 1 && (
              <LinkItem {...link[0]} parentComponent={component} />
            )}
          </StyledProductOverviewLink>
        </StyledProductOverviewInner>
      </StyledProductOverview>
    </StyledProductOverviewWrapper>
  );
};
