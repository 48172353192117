import styled from "styled-components";

interface Props {
  index: number;
}

export const StatItemWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.one};
  grid-column: ${(props) => (props.index % 2 === 0 ? 1 : 7)} / span 5;
  grid-row: ${(props) => Math.ceil((props.index + 1) / 2)};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    grid-column: 1 / span 1;
    grid-row: auto;
  }
`;

export const StatItemTitle = styled.p`
  margin: 0;
  font-size: 80px;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0px;
  }
`;

export const StatItemDescription = styled.p``;
