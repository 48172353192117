import React from "react";

import IconPlay from "@ef-global/web-ui-react/lib/icons/IconPlay";
import { SbImage, sbImageService } from "hult-sb-ui";
import ReactPlayer from "react-player";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";

import { sbAssetType, sbRichText } from "../../types";
import { Blender } from "../Blender";
import { LinkItem, LinkItemProps } from "../LinkItem";
import {
  StyledHeader,
  StyledHeaderAssetWrapper,
  StyledHeaderContentWrapper,
  StyledHeaderDescription,
  StyledHeaderWrapper,
  StyledIconPlayWrapper,
} from "./partials";

export interface HeaderProps {
  title: string;
  description: sbRichText;
  component?: string;
  image?: sbAssetType;
  video?: sbAssetType;
  video_preview?: sbAssetType;
  asset_link?: string;
  link?: Array<LinkItemProps>;
  alternative_variant?: boolean;
  body_variant?: boolean;
  loading?: "lazy" | "eager";
  blender?: "off" | "top" | "bottom";
  background_colour: "white" | "offwhite" | "black";
}

const reactPlayerInline = {
  borderRadius: "4px",
};

const resolver = new RichTextResolver();

export const HeaderCmp: React.FC<HeaderProps> = ({
  title,
  description,
  component,
  link,
  image,
  video,
  video_preview,
  asset_link,
  alternative_variant = false,
  body_variant = false,
  loading = "eager",
  blender,
  background_colour,
}) => {
  const descriptionIsRichText = typeof description === "object";

  return (
    <>
      {blender === "top" && <Blender color={background_colour} position="top" />}
      <StyledHeader reverse={alternative_variant} background_colour={background_colour}>
        <StyledHeaderWrapper reverse={alternative_variant}>
          <StyledHeaderContentWrapper reverse={alternative_variant} background_colour={background_colour}>
            {!body_variant && <h1>{title}</h1>}
            {body_variant && <h2>{title}</h2>}
            {descriptionIsRichText && (
              <StyledHeaderDescription
                dangerouslySetInnerHTML={{
                  __html: resolver.render(description),
                }}
              />
            )}
            {!descriptionIsRichText && (
              <StyledHeaderDescription background_colour={background_colour}>
                <p>{description}</p>
              </StyledHeaderDescription>
            )}
            {Array.isArray(link) && link.length === 1 && <LinkItem {...link[0]} parentComponent={component} />}
          </StyledHeaderContentWrapper>
          <StyledHeaderAssetWrapper reverse={alternative_variant}>
            {image?.filename && <SbImage image={image} loading={loading} />}
            {/* If image available only show image */}
            {video?.filename && !image?.filename && video_preview?.filename && (
              <ReactPlayer
                url={video.filename}
                light={video_preview.filename}
                width="100%"
                height="263px"
                playing={true}
                style={reactPlayerInline}
                playIcon={
                  <StyledIconPlayWrapper>
                    <IconPlay />
                  </StyledIconPlayWrapper>
                }
                controls={true}
              />
            )}
            {/* If image or video available dont show iframe */}
            {asset_link && (!image?.filename || !video?.filename) && video_preview?.filename && (
              <ReactPlayer
                url={asset_link}
                light={video_preview.filename}
                width="100%"
                height="263px"
                style={reactPlayerInline}
                playIcon={
                  <StyledIconPlayWrapper>
                    <IconPlay />
                  </StyledIconPlayWrapper>
                }
                playing={true}
                controls={true}
              />
            )}
          </StyledHeaderAssetWrapper>
        </StyledHeaderWrapper>
      </StyledHeader>
      {blender === "bottom" && <Blender color={background_colour} position="bottom" />}
    </>
  );
};
