import styled from "styled-components";

export const StyledDigitalCoachWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven} ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four} ${(props) => props.theme.spacing.three};
  }
`;

export const StyledDigitalCoach = styled.div`
  width: 100%;
  height: 540px;
  
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    height: 400px;
  }

  & iframe {
    height: 100%;
    width: 100%;
    border: inherit;
    border-radius: 4px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  padding-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledDesc = styled.div`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.font.light};
  padding-bottom: ${(props) => props.theme.spacing.two};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 16px;
  }
`;
