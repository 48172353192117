import styled from "styled-components";
import { validColor, validBackground } from "./helpers";

interface StyledCheckboxProps {
  valid?: boolean;
  checked?: boolean;
  hidden?: boolean;
}

export const StyledCheckboxWrapper = styled.div<StyledCheckboxProps>`
  display: ${({ hidden }) => (hidden ? "none;" : "inline-block;")}
  width: 100%;

`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  width: 100%;

  & label {
    width: auto;
    font-size: 14px;
    line-height: 24px;
  }

  & a {
    font-size: 14px;
    color: inherit;
    text-decoration: underline;
  }

  & div {
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    height: 24px;
    width: 24px;
    min-width: 24px;
    position: relative;
    margin: 0 ${(props) => props.theme.spacing.two} 0 0;
    border-radius: 4px;
    cursor: pointer;
    background: ${({ valid, checked }) => validBackground(valid, checked)};
    border: 1px solid ${({ valid, checked }) => validColor(valid, checked)};
    color: ${(props) => (props.valid || props.checked ? "white" : "black")};
  }

  & input {
    display: none;
    font-size: 14px;
  }

  & svg {
    height: 1.6rem;
  }

  > svg {
    margin: 0.6rem 2.8rem 0 0.8rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${(props) => props.theme.spacing.one};
  }
`;
