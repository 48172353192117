import React from "react";
import { hefBrandColorsPlusNeutrals } from "../../types";
import { CardWithTextItem, CardWithTextItemProps } from "./CardWithTextItem";
import { Blender } from "../Blender";
import speckle from "../../assets/ProductOverview/speckle.png";

import {
  StyledCardWithText,
  StyledCardWithTextWrapper,
  StyledCardWithTextContent,
  StyledCardWithTextHeader,
  StyledCardWithTextCardsContainer,
} from "./partials";

export interface CardWithTextProps {
  title: string;
  cards: Array<CardWithTextItemProps>;
  background: hefBrandColorsPlusNeutrals;
  breaker: string;
}

export const CardWithTextCmp: React.FC<CardWithTextProps> = ({
  title,
  cards,
  background,
  breaker,
}) => {
  return (
    <>
      <StyledCardWithText>
        {breaker === "top" && <Blender color={background} position={breaker} />}
        <StyledCardWithTextWrapper color={background} imgUrl={speckle}>
          <StyledCardWithTextContent>
            <StyledCardWithTextHeader color={background}>
              {title}
            </StyledCardWithTextHeader>
            <StyledCardWithTextCardsContainer lessThanThree={cards.length <= 2}>
              {cards.map((item, idx) => (
                <CardWithTextItem
                  key={`card-with-text-item-${idx}`}
                  title={item.title}
                  description={item.description}
                  link={item.link}
                  linkLabel={item.linkLabel}
                  image={item.image}
                />
              ))}
            </StyledCardWithTextCardsContainer>
          </StyledCardWithTextContent>
        </StyledCardWithTextWrapper>
        {breaker === "bottom" && (
          <Blender color={background} position={breaker} />
        )}
      </StyledCardWithText>
    </>
  );
};
