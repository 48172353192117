import React from "react";

import SbEditable from "storyblok-react";

import { blokType } from "../../types";
import { BodyThreeCmp, BodyThreeProps } from "./BodyThree";

export interface BodyThreeWBlokProps {
  blok: BodyThreeProps & blokType;
}

export const BodyThree: React.FC<BodyThreeWBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <BodyThreeCmp {...blok} />
    </SbEditable>
  );
};
