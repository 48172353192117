import React from "react";
import {
  StyledCohortCard,
  StyledCohortCardHeader,
  StyledCohortCardCheckbox,
  StyledCohortCardCheckboxContainer,
  StyledCohortCardContent,
  StyledCohortCardContentMain,
  StyledCohortCardContentSub,
} from "./partials";

import IconCheck from "@ef-global/web-ui-react/lib/icons/IconCheck";

export interface ICohortCardProps {
  _uid: string;
  event_type: string;
  location: string;
  dates: string;
  timezone: string;
  duration: string;
  cost: string;
  selected: boolean;
  onclick: () => void;
}

const CohortCard: React.FunctionComponent<ICohortCardProps> = ({
  _uid,
  event_type,
  location,
  dates,
  timezone,
  duration,
  cost,
  selected,
  onclick,
}) => {
  return (
    <StyledCohortCard onClick={onclick}>
      <StyledCohortCardHeader selected={selected}>
        <div>
          <StyledCohortCardCheckboxContainer>
            <StyledCohortCardCheckbox selected={selected}>
              <input type="checkbox" onChange={() => void 0} />
              {selected && <IconCheck />}
            </StyledCohortCardCheckbox>
            <span>{event_type}</span>
          </StyledCohortCardCheckboxContainer>
          {location && <span>{location}</span>}
        </div>
      </StyledCohortCardHeader>

      <StyledCohortCardContent selected={selected}>
        <StyledCohortCardContentMain>
          <span>{dates}</span>
          <span>{cost}</span>
        </StyledCohortCardContentMain>
        <StyledCohortCardContentSub>
          <span>{timezone}</span>
          <span>{duration}</span>
        </StyledCohortCardContentSub>
      </StyledCohortCardContent>
    </StyledCohortCard>
  );
};

export default CohortCard;
