import React from "react";
import { NavItemSubItemProps } from ".";
import { getUrl } from "../utils/helpers";
import {
  StyledNavItemSubItem,
  StyledNavItemSubItemDescription,
  StyledNavItemSubItemTitle,
} from "./partials";

type Props = NavItemSubItemProps & { isActive: boolean };

export const NavItemSubItem: React.FC<Props> = ({
  name,
  description,
  link,
  isActive,
}) => {
  return (
    <StyledNavItemSubItem>
      <a href={getUrl(link)}>
        <StyledNavItemSubItemTitle isActive={isActive}>
          {name}
        </StyledNavItemSubItemTitle>
        <StyledNavItemSubItemDescription>
          {description}
        </StyledNavItemSubItemDescription>
      </a>
    </StyledNavItemSubItem>
  );
};
