import styled from "styled-components";

interface StyledArticleQualifyingTitleProps {
  qualifier_colour: string;
  author_exists?: boolean;
}

export const StyledArticleHeadline = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing.seven} 0
    ${(props) => props.theme.spacing.three} 0;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    margin: 0;
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledArticleHeadlineContent = styled.div`
  max-width: ${(props) => props.theme.articleMaxWidth};
  margin: 0 auto;
`;

export const StyledArticleQualifyingTitle = styled.p<StyledArticleQualifyingTitleProps>`
  font-size: ${(props) => props.theme.fontSize.m};
  ${(props) =>
    props.qualifier_colour === "heritage" &&
    `
        color: ${props.theme.colors.warmths.heritage};
    `}
  ${(props) =>
    props.qualifier_colour === "intensity" &&
    `
        color: ${props.theme.colors.warmths.intensity};
    `}
    ${(props) =>
    props.qualifier_colour === "copper" &&
    `
        color: ${props.theme.colors.warmths.copper};
    `}

    margin-bottom: ${(props) =>
    props.author_exists ? props.theme.spacing.three : props.theme.spacing.one};
`;
export const StyledArticleTitle = styled.h1`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;
export const StyledArticleAuthor = styled.p`
  font-weight: ${(props) => props.theme.font.bold};
  margin-top: ${(props) => props.theme.spacing.one};
  font-size: ${(props) => props.theme.fontSize.m};
`;
