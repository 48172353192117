import styled from "styled-components";

export const StyledEventHeadline = styled.div`
  padding: ${(props) => props.theme.spacing.seven} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three}
      ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: ${(props) => props.theme.spacing.seven}
      ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: ${(props) => props.theme.spacing.four}
      ${(props) => props.theme.spacing.three};
  }
`;

export const StyledEventHeadlineContent = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.spacing.three};
  
`;

export const StyledEventHeadlineMain = styled.div`
  max-width: 50%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;

export const StyledEventHeadlineMainHeader = styled.h1`
  font-size: 32px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    font-size: 24px;
  }
`;

export const StyledEventHeadlineMainBody = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.light};
  margin: 0;
`;

export const StyledEventHeadlineAside = styled.div`
  max-width: 304px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`;

export const StyledEventHeadlineAsideHeader = styled.p`
  font-size: 16px;
  font-weight: ${(props) => props.theme.font.bold};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;

export const StyledEventHeadlineAsideBody = styled.p`
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.light};
  margin-bottom: ${(props) => props.theme.spacing.two};
`;
