import styled from "styled-components";

export const StyledDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  height: auto;
  width: 88px;
  position: relative;
  background: rgba(214, 204, 191, 0.2);
  border-radius: 2px;

  & ul {
    margin: 0;
  }
`;

export const StyledDropDownHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  min-width: 88px;
  min-height: 24px;

  line-height: 1;
  font-size: 14px;
  margin: 0;
  -webkit-appearance: none;

  & span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & svg {
    height: 1.6rem;
    margin: 0;
  }
`;

export const StyledDropDownListContainer = styled.div`
  position: absolute;
  display: block;
  height: auto;
  max-height: 200px;
  width: 290px;
  z-index: 1;
  top: calc(100% + 10px);
  background-color: white;
  padding: ${(props) => props.theme.spacing.one};
  box-shadow: 2px 5px 8px #888888;

  & ul {
    padding: 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    max-height: 150px;
    overflow-y: scroll;
    list-style: none;

    line-height: 1;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border: none;
      background-color: #ffffff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 14px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #191919;
    }
  }
`;

export const StyledDropdownListItem = styled.li`
  display: block;
  position: relative;
  margin: 0;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  :before {
    content: none !important;
  }
`;

export const StyledFlagContainer = styled.span`
  display: flex;
  width: 100%;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 10px 16px;

  & img {
    object-fit: contain;
    font-size: 20px;
    margin-right: ${(props) => props.theme.spacing.one};
  }

  & span {
    margin: 0;
  }
`;
