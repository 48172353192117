import * as React from "react";
import { blokType } from "../../types";
import UpcomingCohortsCmp, { IUpcomingCohortsProps } from "./UpcomingCohorts";

interface IUpcomingCohortsBlokProps {
  blok: blokType & IUpcomingCohortsProps;
}

const UpcomingCohorts: React.FunctionComponent<IUpcomingCohortsBlokProps> = ({
  blok,
}) => {
  return (
    <UpcomingCohortsCmp
      title={blok.title}
      cards={blok.cards}
      component={blok.component}
      contact_number={blok.contact_number}
      graphic_overlay={blok.graphic_overlay}
      graphic_overlay_mobile={blok.graphic_overlay_mobile}
      primary_cta_label={blok.primary_cta_label}
      primary_cta_color={blok.primary_cta_color}
      primary_cta_form={blok.primary_cta_form}
      secondary_cta_label={blok.secondary_cta_label}
      secondary_cta_form={blok.secondary_cta_form}
      show_more_label={blok.show_more_label}
      show_less_label={blok.show_less_label}
    />
  );
};

export default UpcomingCohorts;
