import styled from "styled-components";

interface StyledFeatureListItemIconProps {
  background_color?: "heritage" | "copper" | "intensity" | undefined;
}

export const StyledFeatureListItemIcon = styled.div<StyledFeatureListItemIconProps>`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  padding: 16px;
  background-color: ${(props) =>
    props.background_color
      ? props.theme.colors.warmths[props.background_color]
      : props.theme.colors.warmths.intensity};
  color: ${(props) => props.theme.colors.neutrals.white};

  & img {
    filter: invert(1);
  }

  margin-right: 24px;
`;
